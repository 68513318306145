import React from "react";
import { Card } from "react-bootstrap";

const TrafficTrackingCard = () => {

  return (
    <>
      <Card>
        
      </Card>
    </>
  )
}

export default TrafficTrackingCard;