import React from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "../hooks/useLocalization";
import AdminBots from "./AdminBots";
import AdminUsers from "./AdminUsers";
import api from "../net/api"
import AdminRoles from "./AdminRoles";

const AdminPanel = () => {
  const { t } = useTranslation();
  const { checkPermission, checkRole } = useAuth();

  return (
    <Card className="wordpress-header">
      <Card.Header className="d-flex align-items-center">
        <Card.Title tag="h5" className="mb-0 flex-1">
          {t("Admin Panel")}
        </Card.Title>
      </Card.Header>
      <Tabs defaultActiveKey="bots" fill>
        <Tab eventKey="bots" title="Bots">
          <AdminBots />
        </Tab>
        {checkPermission(api.PERMISSION.ACC_FETCH) &&
          <Tab eventKey="users" title="Users">
            <AdminUsers />
          </Tab>
        }
        {checkRole(api.ROLES.SUPER_ADMIN) &&
          <Tab eventKey="roles" title="Roles">
            <AdminRoles />
          </Tab>
        }
      </Tabs>
    </Card>
  )
}

export default AdminPanel;