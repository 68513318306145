import React, { useCallback, useEffect, useState } from "react";

import Sidebar from "./Sidebar";
import useBots from "../../hooks/useBots";
import {
  Codepen,
  Database,
  Grid,
  Image,
  PieChart,
  Play,
  Sliders,
} from "react-feather";
import { useTranslation } from "../../hooks/useLocalization";
import { ChatGPTIcon } from "../bots/gptsettings/icons/ChatGPTIcon";

const SidebarBots = (props) => {
  const { t } = useTranslation();
  const { activeBot, accessBotLevels } = useBots();
  const [items, setItems] = useState([]);

  const management = useCallback(
    (levels) => ({
      href: "/bots",
      icon: Sliders,
      title: t("Management"),
      children: [
        {
          href: "/bots/settings",
          title: t("General Settings"),
        },
      ]
        .concat(
          levels.files
            ? [
              {
                href: "/bots/files",
                title: t("Backup/Restore"),
              },
            ]
            : []
        )
        .concat([
          {
            href: "/bots/delete",
            title: t("Delete Bot"),
          },
        ]),
    }),
    [t]
  );

  const testing = useCallback(
    () => ({
      href: "/bots/testing",
      title: t("Testing"),
      icon: Play,
    }),
    [t]
  );

  const editor = useCallback(
    () => ({
      href: "/bots/editor",
      title: t("Editor"),
      icon: Codepen,
    }),
    [t]
  );

  const setup = useCallback(
    () => ({
      href: "/bots/setup",
      title: t("Setup"),
      icon: Image,
    }),
    [t]
  );

  const channels = useCallback(
    () => ({
      href: "/bots/channels",
      title: t("Integrations"),
      icon: Grid,
    }),
    [t]
  );

  const assistant = useCallback(
    () => ({
      href: "/bots",
      icon: ChatGPTIcon, // ChatGPT,
      title: t("GPT Assistant Settings"),
      children: [
        {
          href: "/bots/new_source",
          title: t("New Source"),
        },
        {
          href: "/bots/sources",
          title: t("Sources Library"),
        },
        {
          href: "/bots/gptsettings",
          title: t("GPT Settings"),
        },
      ]
    }),
    [t]
  );

  const analytics = useCallback(
    () => ({
      href: "/bots/analytics",
      title: t("Analytics"),
      icon: PieChart,
    }),
    [t]
  );

  const data = useCallback(
    () => ({
      href: "/bots/data",
      title: t("Storage"),
      icon: Database,
    }),
    [t]
  );

  const navItems = useCallback(
    (bot) => {
      const getFilesAlert = (bot) => {
        const levels = accessBotLevels(bot);
        if (!levels.fileSystem) return "warning";
        else if (levels.autoUpload) {
          if (bot.updateError) return "danger";
          else return "success";
        }
      };

      let pages = [];

      if (bot && bot.id !== "") {
        const levels = accessBotLevels(bot);
        if (levels.manage) {
          const customManagement = management(levels);
          const itemFiles = customManagement.children.find(
            ({ href }) => href === "/bots/files"
          );
          if (itemFiles) {
            const variant = getFilesAlert(bot);
            itemFiles.badge = variant ? " " : void 0;
            itemFiles.variant = variant;
          }
          pages = [...pages, ...[customManagement]];
        }
        if (levels.channels) {
          pages = [...pages, ...[channels()]];
        }
        if (levels.channels) {
          pages = [...pages, ...[assistant()]];
        }
        if (levels.testing) {
          pages = [...pages, ...[testing()]];
        }
        if (levels.editor) {
          pages = [...pages, ...[editor()]];
        }
        if (levels.setup) {
          pages = [...pages, ...[setup()]];
        }
        if (levels.data) {
          pages = [...pages, ...[data()]];
        }
        if (levels.analytics) {
          pages = [...pages, ...[analytics()]];
        }
      }

      return [{ title: "", pages }];
    },
    [
      accessBotLevels,
      analytics,
      assistant,
      channels,
      data,
      editor,
      management,
      setup,
      testing,
    ]
  );

  useEffect(() => setItems(navItems(activeBot)), [navItems, activeBot]);

  return <Sidebar items={items} {...props} showFooter={true} />;
};

export default SidebarBots;
