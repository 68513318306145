const REGEXP_CSS = /\.css$/i;
const REGEXP_JSON = /\.(json)$/i;
const REGEXP_YML = /\.(yml)|(yaml)$/i;
const REGEXP_IMG = /\.(png)|(webp)|(svg)|(jpg)|(jpeg)$/i;
const REGEXP_IMG_A = /\.(png)|(webp)|(svg)$/i;

const REGEXP_OPTION_MAIN = /config\.(yml)|(yaml)$/i;
const REGEXP_OPTION_ICON = /icon\.(png)|(webp)|(svg)$/i;
const REGEXP_OPTION_STYLE = /style\.css$/i;

export {
  REGEXP_CSS,
  REGEXP_JSON,
  REGEXP_YML,
  REGEXP_IMG,
  REGEXP_IMG_A,
  REGEXP_OPTION_MAIN,
  REGEXP_OPTION_ICON,
  REGEXP_OPTION_STYLE,
};
