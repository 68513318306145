import { Formik } from "formik";
import { useEffect, useRef } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import useBots from "../../hooks/useBots";
import * as Yup from "yup";
import { scrollIntoView } from "../../utils/scroll";
import useMessage from "../../hooks/useMessage";
import logger from "../../utils/logger";
import { useTranslation } from "../../hooks/useLocalization";
import useTutorial from "../../hooks/useTutorial";

import useAnalytics from "../../hooks/useAnalytics";

const CloneBot = ({ id, name = "", description = "", setOnSubmit, onComplete, }) => {
  const { t, tt } = useTranslation();
  const { CONSOLE_EVENTS } = useAnalytics();
  const submitRef = useRef();
  // const { onCopyEvent } = useClipboard();
  const { showMessageWithDebug } = useMessage();
  const { cloneBot, generateBundle, activeBot } = useBots();
  // const [required, setRequired] = useState({});
  const nameRef = useRef();
  useEffect(() => nameRef.current?.focus(), [nameRef]);
  const { tutoStorage, setProp, setTutoSTorage, tutorials, setTutorials,
    updateStep, tuto_2_4, checkTuto2Complete, } = useTutorial();

  useEffect(() => {
    if (setOnSubmit) {
      setOnSubmit(() =>
        submitRef.current?.dispatchEvent(new MouseEvent("click"))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{ name, description }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .matches(
            /^[aA-zZ\s0-9_()]+$/g,
            t("Must contain only Latin characters")
          )
          .min(5, tt("Must be at least N characters", 5))
          .max(150)
        // .required("Bot name is required"),
        // description: Yup.string().max(255),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        ({ values } = {
          values: { ...values, bundle: generateBundle(values.name) },
        });

        try {
          scrollIntoView("name");
          const bot = await cloneBot(id, { ...values, status: "private", });
          onComplete && onComplete(bot);
          await CONSOLE_EVENTS.EV_CloneBot.send({ action: 'complete', params: { bid: bot.id, from: id } })
        } catch (error) {
          logger.error(error);
          const { message = t("Something went wrong"), debug } = error;
          showMessageWithDebug({ error: message, debug });
          setErrors({ submit: message });
          setStatus({ success: false });
          setSubmitting(false);
        }

        if (!tutoStorage.tuto_2._3) {
          tutoStorage.tuto_2._3 = true;
          setProp("tutorial", tutoStorage)
          setTutoSTorage(tutoStorage);
          updateStep(1, 3, "from CloneBot");
          tutorials[1].onClick = tuto_2_4;
          tutorials[1].href = "/bots/editor?id=" + activeBot?.id;
          setTutorials([...tutorials])
          checkTuto2Complete();
          let tuto = document.getElementById("qubot-editor-run-tuto");
          let tuto1 = document.getElementById("qubot-run-tuto");
          tuto.style.display = tuto1.style.display = "block";
          let open = document.getElementById("qubot-open");
          let runBot = document.getElementById("qubot-editor-run");
          open.onmouseup = runBot.onmouseup = () => {
            if (!tutoStorage.tuto_2._4) {
              tutoStorage.tuto_2._4 = true;
              setProp("tutorial", tutoStorage)
              setTutoSTorage(tutoStorage);
              updateStep(1, 4, "from CloneBot");
              tutorials[1].onClick = null;
              setTutorials([...tutorials])
              checkTuto2Complete();
            }
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, }) => (
        <Form onSubmit={handleSubmit}>
          {/* {errors.submit && (
                  <Alert className="align-items-center" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )} */}
          <Row>
            <Col xl md="6">
              <Form.Group /*className="mb-3"*/>
                <FloatingLabel label={`${t("Bot name")} *`}>
                  <Form.Control
                    required
                    id="name"
                    type="text"
                    name="name"
                    placeholder={`${t("Bot name")} *`}
                    ref={nameRef}
                    value={values.name}
                    isInvalid={Boolean(touched.name && errors.name)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </Form.Group>
            </Col>
            {/* <Col>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>
                          Bundle is generated automatically from company domain
                          and bot name.
                        </Tooltip>
                      }
                    >
                      <Form.Group className="mb-3">
                        <InputGroup>
                          <InputGroup.Text id="bundle">bundle:</InputGroup.Text>
                          <Form.Control
                            readOnly
                            id="bundle"
                            type="text"
                            name="bundle"
                            placeholder="Bot bundle"
                            className="cursor-copy"
                            value={generateBundle(values.name)}
                            onClick={onCopyEvent}
                          />
                        </InputGroup>
                      </Form.Group>
                    </OverlayTrigger>
                  </Col> */}
          </Row>

          {/* <Form.Group>
                  <Form.Control
                    as="textarea"
                    id="description"
                    type="text"
                    name="description"
                    placeholder="Description"
                    value={values.description}
                    isInvalid={Boolean(
                      touched.description && errors.description
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.description && (
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  )}
                </Form.Group> */}

          <div className="text-center mt-3 w-100 d-grid">
            <Button hidden type="submit" variant="primary" ref={submitRef} disabled={isSubmitting}>
              {t("Clone Bot")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CloneBot;
