import { useTranslation } from "../hooks/useLocalization";

const JSONViewer = ({ json }) => {
  const { t } = useTranslation();
  try {
    return JSON.stringify(json, null, "\t")
      .split("\n")
      .map((line, index) => (
        <span key={"line" + index}>
          {new Array(line.length - line.trimLeft().length)
            .fill("")
            .map((value, index) => (
              <span key={"space" + index}>&emsp;</span>
            ))}
          <span key={"line-body" + index}>{line.trim()}</span>
          <br key={"br" + index} />
        </span>
      ));
  } catch (error) {
    const { message = t("Something went wrong") } = error;
    return message;
  }
};

export default JSONViewer;
