import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Scrollbar from "../../../components/Scrollbar";
import Bots from "../../../pages/dashboards/Bots";
import { useTranslation } from "../../../hooks/useLocalization";

const BotsModal = ({ open, setOpen, handlers = {} }) => {
  const { t } = useTranslation();
  const onClose = () => setOpen(false);

  if (open) {
    let wrap = document.getElementById("qubot-wrapper");
    let open = document.getElementById("qubot-open");

    if (!wrap || !open) return

    wrap.classList.remove("qubot-show");
    wrap.classList.add("qubot-hide");

    open.classList.remove("qubot-hide");
    open.classList.add("qubot-show");
  }

  return (
    <Modal show={open} onHide={onClose} size="xl" enforceFocus={false}>
      <Helmet title={t("My Bots")} />
      <Modal.Header closeButton>{t("My Bots")}</Modal.Header>
      <div className="modal-body-container ps-dark">
        <Scrollbar>
          <Modal.Body className="p-4.5 bg-body">
            <Bots handlers={handlers} hideTemplates />
          </Modal.Body>
        </Scrollbar>
      </div>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BotsModal;
