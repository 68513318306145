import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import useSettingsState from "../hooks/useSettingsState";

const tempOptions = {};

const initialState = {};

const UIContext = React.createContext(initialState);

function UIProvider({ children }) {
  const location = useLocation();
  const { pathname } = location;
  const [storedSettings, setStoredSettings] = useSettingsState(
    "dashboardUI",
    initialState
  );
  const [settings, setSettings] = useState(storedSettings);

  const temp = (group, name, useLocalStorage) => {
    const tempOptionsGroup = tempOptions[group] || [];
    const tempTndex = tempOptionsGroup.indexOf(name);
    if (useLocalStorage) {
      if (tempTndex > -1) {
        tempOptions[group].splice(tempTndex, 1);
      }
    } else {
      if (tempTndex < 0) {
        tempOptions[group] ||= [];
        tempOptions[group].push(name);
      }
    }
  };

  const save = (group, settingsGroup) => {
    const storedSettingsGroup = {};
    const tempOptionsGroup = tempOptions[group] || [];
    for (const key in settingsGroup) {
      if (tempOptionsGroup.indexOf(key) < 0) {
        storedSettingsGroup[key] = settingsGroup[key];
      }
    }
    setStoredSettings({ ...storedSettings, [group]: storedSettingsGroup });
    setSettings({ ...settings, [group]: settingsGroup });
  };

  const encode = (value) => {
    if (!value) {
      return value;
    }
    if (typeof value === "object") {
      for (const key in value) value[key] = encode(value[key]);
      return value;
    }
    return value instanceof Date ? value.toISOString() : value;
  };

  const decode = (value) => {
    if (!value) {
      return value;
    }
    if (typeof value === "object") {
      for (const key in value) value[key] = decode(value[key]);
      return value;
    }
    if (typeof value === "string" && /^\d\d\d\d-\d\d-\d\d/.test(value)) {
      return new Date(value);
    }
    return value;
  };

  const useGroupOption = (
    group,
    name,
    defaultValue = null,
    useLocalStorage = true
  ) => {
    temp(group, name, useLocalStorage);
    let settingsGroup = (settings[group] ||= {});
    if (!(name in settingsGroup)) {
      settingsGroup[name] = encode(defaultValue);
      setTimeout(() => save(group, settingsGroup));
    }
    return [
      decode(settingsGroup[name]),
      (value) => {
        if (settingsGroup[name] !== value) {
          settingsGroup[name] = encode(value);
          save(group, settingsGroup);
        }
      },
    ];
  };

  const useOption = (name, defaultValue, group = null) => {
    return useGroupOption(group || pathname, name, defaultValue, true);
  };

  const useOptionTemp = (name, defaultValue, group = null) => {
    return useGroupOption(group || pathname, name, defaultValue, false);
  };

  return (
    <UIContext.Provider
      value={{
        useOption,
        useOptionTemp,
      }}
    >
      {children}
    </UIContext.Provider>
  );
}

export { UIProvider, UIContext };
