import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import SignIn from "../../components/auth/SignIn";
// import avatar from "../../assets/img/avatars/no-avatar.jpg";
import useAuth from "../../hooks/useAuth";
import logger from "../../utils/logger";
import { useTranslation } from "../../hooks/useLocalization";

const SignInPage = () => {
  logger.render(SignInPage);
  const { t, tt } = useTranslation();
  const { user } = useAuth();
  return (
    <React.Fragment>
      <Helmet title={t("Sign In")} />
      {(user && (
        <div className="text-center mt-4">
          <h2>{tt("Welcome back", user.firstName)}</h2>
          <p className="lead">{t("Sign in to your account to continue")}</p>
        </div>
      )) || (
        <div className="text-center mt-4">
          <h2>{t("Sign in to your account")}</h2>
          <p className="lead"></p>
        </div>
      )}

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            {/* <div className="text-center">
              {user && (
                <img
                  src={avatar}
                  alt={`${user.displayName}`}
                  className="img-fluid rounded-circle"
                  width="96"
                  height="96"
                />
              )}
            </div> */}
            <SignIn />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignInPage;
