import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Edit2 } from "react-feather";
import { useTranslation } from "../../hooks/useLocalization";
// import useBots from "../../hooks/useBots";
// import { useNavigate } from "react-router-dom";

const EditBot = ({ bot }) => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  // const { setBotActive } = useBots();

  const onClickHandler = () => {
    // setBotActive(bot.id)
    // navigate("/bots/editor?bid=" + bot.id);
    window.open("/bots/editor?bid=" + bot.id, "_blank");
  }

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>{t("Edit")}</Tooltip>}>
      <Button variant="light" className="m-1 p-1" onClick={onClickHandler}>
        <Edit2 />
      </Button>
    </OverlayTrigger>
  )
}

export default EditBot;