import React from "react";

import { Dropdown, ListGroup } from "react-bootstrap";
import { AlertCircle, Bell, CheckCircle, Coffee, Home, UserPlus } from "react-feather";
import { useTranslation } from "../../hooks/useLocalization";
import useMessage from "../../hooks/useMessage";
import Scrollbar from "../Scrollbar";
import NavbarDropdownItem from "./NavbarDropdownItem";
import NavbarTutorialItem from "./NavbarTutorialItem";
import { ProgressBar } from "react-bootstrap";
import CircularProgressBar from "../../utils/CicrularProgressBar";
import { NOTIFICATION_STATUS } from "../../contexts/NotificationContext";

const NavbarDropdown = ({
  tutorials,
  notifications,
  count,
  showBadge,
  header,
  footer,
  icon: Icon,
  className,
  onToggle,
}) => {
  const { t } = useTranslation();
  const { showMessage } = useMessage();

  const getAllProgressTuto = () => {
    let total = 0;
    let completed = 0;
    tutorials.forEach((item) => {
      total += item.totalSteps;
      completed += item.completedSteps;
    })
    return Math.round(100 * completed / total)
  }

  return (
    <Dropdown
      className={`me-2 nav-item ${className}`}
      align="start"
      drop="up"
      onToggle={onToggle}
    >
      <Dropdown.Toggle as="a" className="nav-link nav-icon dropdown-toggle" id="qb-console-dnd-tuto">
        <div className="position-relative">
          <Icon className="align-middle" size={18} />
          {showBadge ? (
            <span className="indicator">{showBadge | 0 || count}</span>
          ) : null}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu drop="end" className="dropdown-menu-lg py-0">
        {tutorials.length ? (
          <>
            <div className="dropdown-menu-header position-relative">
              {t("Onboarding")}
            </div>
            <ProgressBar now={getAllProgressTuto()} className="mx-1" style={{ height: "0.25rem" }} />
            <div className="text-muted small mx-1">{getAllProgressTuto() + "%"}</div>
            <ListGroup>
              <div className="ps-dark">
                <Scrollbar>
                  <div className="dropdown-content">
                    {tutorials?.map((item, key) => {
                      const { completedSteps, totalSteps, title, href, onClick } = item;
                      let icon = <CheckCircle size={18} className="text-primary" />;
                      if (completedSteps !== totalSteps) {
                        icon = <CircularProgressBar
                          percentage={Math.round(100 * completedSteps / totalSteps)}
                          strokeWidth="2"
                          sqSize="20"
                        />;
                      } else {
                        if (key === 0) item.onClick = () => showMessage({ save: t("Registration completed") })
                        if (key === 1) item.onClick = () => showMessage({ save: t("Bot is created") })
                        if (key === 2) item.onClick = () => showMessage({ save: t("Setups viewed") })
                        item.href = "";
                      }
                      return (
                        <NavbarTutorialItem
                          key={key}
                          icon={icon}
                          title={title}
                          complSteps={completedSteps}
                          totalSteps={totalSteps}
                          href={href}
                          onClick={onClick}
                        />
                      );
                    })}
                  </div>
                </Scrollbar>
              </div>
            </ListGroup>
          </>
        ) : ""}
        <div className="dropdown-menu-header position-relative">
          {count} {header}
        </div>
        <ListGroup>
          <div className="ps-dark">
            <Scrollbar>
              <div className="dropdown-content">
                {notifications?.map((item, key) => {
                  let icon = <Bell size={18} className="text-warning" />;
                  if (item.type === "settings") {
                    icon = <CheckCircle size={18} className="text-secondary" />;
                  }
                  if (item.type === "important") {
                    icon = <AlertCircle size={18} className="text-danger" />;
                  }
                  if (item.type === "login") {
                    icon = <Home size={18} className="text-primary" />;
                  }
                  if (item.type === "request") {
                    icon = <UserPlus size={18} className="text-success" />;
                  }
                  if (item.type === "success") {
                    icon = <Coffee size={18} className="text-success" />;
                  }
                  return (
                    <NavbarDropdownItem
                      key={key}
                      icon={icon}
                      title={item.title}
                      description={item.description}
                      time={item.time}
                      href={item.href}
                      mark={item.status !== NOTIFICATION_STATUS.CLICKED}
                      onClick={item.onClick}
                    />
                  );
                })}
              </div>
            </Scrollbar>
          </div>
        </ListGroup>
        <Dropdown.Header className="dropdown-menu-footer">
          <span className="text-muted">{footer}</span>
        </Dropdown.Header>
      </Dropdown.Menu>
    </Dropdown>
  )
};

export default NavbarDropdown;
