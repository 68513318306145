const DATE_FORMAT = {
  DATE: "dd/MM/yy",
  HOUR: "HH:mm",
};

const parseRows = (rows = [], { format }) => {
  return rows.map((row) => ({
    ...row,
    time: format === DATE_FORMAT.DATE ? row.time.split("T")[0] : row.time,
  }));
};

const tooltipFormat = ({ format }) => {
  return format === DATE_FORMAT.DATE ? "dd MMM yyyy" : "HH:mm";
};

const parseSessions = (rows, props) => {
  const { chartName, filename, format = DATE_FORMAT.DATE } = props;
  rows = parseRows(rows, props);
  // const palette = usePalette();

  const data = [
    {
      name: chartName || "",
      data: rows.map(({ count }) => count),
    },
  ];

  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        export: {
          csv: { filename },
          png: { filename },
          svg: { filename },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
      hover: { size: 6 },
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      type: "datetime",
      tooltip: { enabled: false },
      categories: rows.map(({ time }) => time),
      labels: { format, datetimeUTC: false },
    },
    yaxis: {
      labels: { formatter: (value) => value.toFixed(0) },
    },
    tooltip: {
      x: {
        format: tooltipFormat({ format }),
      },
    },
    colors: [/*palette.primary*/ "#3B82EC"],
  };

  const total = rows.reduce((p, { count: c }) => p + c, 0) | 0;
  return { data, options, total };
};

const parseUniqueUsers = (rows, props) => {
  const { chartName, filename, format = DATE_FORMAT.DATE } = props;
  rows = parseRows(rows, props);
  // const palette = usePalette();

  const data = [
    {
      name: chartName || "",
      data: rows.map(({ count }) => count),
    },
  ];

  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        export: {
          csv: { filename },
          png: { filename },
          svg: { filename },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
      hover: { size: 6 },
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      type: "datetime",
      tooltip: { enabled: false },
      categories: rows.map(({ time }) => time),
      labels: { format, datetimeUTC: false },
    },
    yaxis: {
      labels: { formatter: (value) => value.toFixed(0) },
    },
    tooltip: {
      x: {
        format: tooltipFormat({ format }),
      },
    },
    colors: [/*palette.primary*/ "#3B82EC"],
  };

  const total = rows.reduce((p, { count: c }) => p + c, 0) | 0;
  return { data, options, total };
};

const parseEventProps = (rows, props) => {
  const { filename, eventName, format = DATE_FORMAT.DATE } = props;
  rows = parseRows(rows, props);
  // const palette = usePalette();

  const data = [
    {
      name: eventName || "",
      data: rows.map(({ count }) => count),
    },
  ];

  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        export: {
          csv: { filename },
          png: { filename },
          svg: { filename },
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      type: "datetime",
      tooltip: { enabled: false },
      categories: rows.map(({ time }) => time),
      labels: { format, datetimeUTC: false },
    },
    yaxis: {
      labels: { formatter: (value) => value.toFixed(0) },
    },
    tooltip: {
      x: {
        format: tooltipFormat({ format }),
      },
    },
    colors: [/*palette.warning*/ "#f0ad4e"],
  };

  const total = rows.reduce((p, { count: c }) => p + c, 0) | 0;
  return { data, options, total };
};

const parseEventsConversion = (rows, props) => {
  const { chartName, filename, locked } = props;
  // const palette = usePalette();

  const data = [
    {
      name: chartName || "",
      data: rows.map(({ value }) => value),
    },
  ];

  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        export: {
          csv: { filename },
          png: { filename },
          svg: { filename },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: rows.map(({ event }) => (locked ? "?" : event)),
    },
    yaxis: {
      labels: {
        formatter: (value, series) => {
          if (locked) {
            return "?";
          }
          if (
            typeof series === "object" &&
            series.dataPointIndex > -1 &&
            series.dataPointIndex < rows.length
          ) {
            const item = rows[series.dataPointIndex];
            const { count, total } = item;
            if (count > -1 && total > -1) {
              return `${count.toFixed(0)} / ${total.toFixed(0)}`;
            }
          }
          return value.toFixed(0);
        },
      },
    },
    colors: [/*palette.warning*/ "#f0ad4e"],
  };

  const total = rows.reduce((p, { value: c }) => p + c, 0) | 0;
  return { data, options, total };
};

const parseSessionsChannel = (rows, props) => {
  const { filename } = props;
  const data = rows.map(({ count }) => count);

  const options = {
    chart: {
      toolbar: {
        export: {
          csv: { filename },
          png: { filename },
          svg: { filename },
        },
      },
    },
    labels: rows.map(({ channel }) => channel),
    theme: { monochrome: { enabled: true } },
  };

  const total = rows.reduce((p, { count: c }) => p + c, 0) | 0;
  return { data, options, total };
};

export {
  DATE_FORMAT,
  parseSessions,
  parseUniqueUsers,
  parseEventProps,
  parseEventsConversion,
  parseSessionsChannel,
};
