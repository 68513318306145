import React, { useCallback, useEffect, useMemo } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
  Button,
} from "react-bootstrap";
import {
  parseSessions,
  parseEventProps,
  parseUniqueUsers,
  parseEventsConversion,
  parseSessionsChannel,
  DATE_FORMAT,
} from "./utils";
import { RefreshCw, RotateCw } from "react-feather";
import useAnalytics from "../../../hooks/useAnalytics";
import Navigation from "../../Navigation";
import RefreshAlert from "../../bots/RefreshAlert";
import {
  createInterval,
  FILTERS,
  dateToString,
  INTERVAL,
} from "../../../contexts/bots/filters";
import Select from "react-select";
import AntiSpam from "../../../utils/spam";
import useUI from "../../../hooks/useUI";
import logger from "../../../utils/logger";
import useBots from "../../../hooks/useBots";
import useBilling from "../../../hooks/useBilling";
import { FEATURES } from "../../../contexts/billing/BillingContext";
import ReportSessionsCard from "./cards/ReportSessionsCard";
import ReportUsersCard from "./cards/ReportUsersCard";
import ReportEventsCard from "./cards/ReportEventsCard";
import ReportConversionCard from "./cards/ReportConversionCard";
import ReportChannelsCard from "./cards/ReportChannelsCard";
import { useTranslation } from "../../../hooks/useLocalization";

const CHART_TYPE = {
  SESSIONS: "sessions",
  SESSIONS_CHANNEL: "sessions_channel",
  UNIQUE_USERS: "unique_users",
  EVENTS: "events",
  EVENTS_CONVERSION: "events_conversion",
};

const antiSpam = new AntiSpam();

const Analytics = () => {
  logger.render(Analytics);
  const { t, tt } = useTranslation();
  const billing = useBilling();
  const { refreshFeatures, needUpgradePlan } = billing;
  const { activeBot } = useBots();
  const { id, name: botName } = activeBot || {};
  const { useOptionTemp } = useUI();
  const { TODAY, YESTERDAY, LAST_WEEK, LAST_MONTH, CUSTOM } = INTERVAL;
  const {
    filters = {},
    reports = {},
    isLoading = {},
    loadingError,
    refreshReports,
    getEventProps,
    isInitialized,
  } = useAnalytics();

  const {
    sessions,
    channels,
    uniqueUsers,
    eventsConversion,
    eventNames,
    eventProps,
    sessionsChannel,
  } = reports;

  const [selectedChannels, setSelectedChannels] = useOptionTemp("channels", []);
  const [navDate, setNavDate] = useOptionTemp("navDate", LAST_MONTH);
  const [intervalValues, setIntervalValues] = useOptionTemp(
    "intervalValues",
    createInterval(LAST_MONTH)
  );
  const intervalHashes = useMemo(
    () =>
      intervalValues.map(
        (dates) =>
          `${dates[0] && dates[0].getTime()},${dates[1] && dates[1].getTime()}`
      ),
    [intervalValues]
  );

  const getFilters = useCallback(
    ({ interval, intervalValues, channels, eventName }) => ({
      [FILTERS.INTERVAL]: interval || filters[FILTERS.INTERVAL],
      [FILTERS.INTERVAL_VALUES]:
        intervalValues || filters[FILTERS.INTERVAL_VALUES],
      [FILTERS.CHANNELS]: channels || filters[FILTERS.CHANNELS],
      [FILTERS.EVENT_NAME]: eventName || filters[FILTERS.EVENT_NAME],
    }),
    [filters]
  );

  const refresh = useCallback(() => {
    antiSpam.action(async () => {
      await refreshFeatures();
      await refreshReports(
        getFilters({
          interval: navDate,
          intervalValues,
          channels: (selectedChannels || []).map(({ value }) => value),
        })
      );
    });
  }, [
    refreshFeatures,
    refreshReports,
    getFilters,
    navDate,
    intervalValues,
    selectedChannels,
  ]);

  useEffect(() => {
    if (isInitialized && billing.isInitialized) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInitialized,
    billing.isInitialized,
    // navDate,
    // intervalValues,
    intervalHashes,
    selectedChannels,
    id,
  ]);

  useEffect(() => {
    if (navDate !== CUSTOM) {
      setIntervalValues(createInterval(navDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navDate, CUSTOM]);

  const onSelectInterval = (eventKey) => {
    setNavDate(eventKey);
  };

  const onFormDateChange = (event, index) => {
    const { value } = event.target;
    const newIntervalValues = [...intervalValues];
    newIntervalValues[index] = value ? new Date(value) : new Date();
    const [from = new Date(), to = new Date()] = newIntervalValues;
    if (from.getTime() > to.getTime()) from.setTime(new Date(to.getTime()));
    setIntervalValues(newIntervalValues);
  };

  const onSelectEventName = (eventKey) => {
    if (isInitialized && eventKey !== filters[FILTERS.EVENT_NAME]) {
      getEventProps(
        getFilters({
          interval: navDate,
          intervalValues,
          channels: (selectedChannels || []).map(({ value }) => value),
          eventName: eventKey,
        })
      );
    }
  };

  const checkPro = useCallback(
    (type) => {
      return (
        type === CHART_TYPE.EVENTS_CONVERSION &&
        needUpgradePlan(FEATURES.ANALYTICS)
      );
    },
    [needUpgradePlan]
  );

  const options = useCallback(
    (type, chartName) => {
      const [from, to] =
        navDate === INTERVAL.CUSTOM ? intervalValues : createInterval(navDate);
      const [fromStr, toStr] = [dateToString(from, ""), dateToString(to, "")];
      const period = fromStr === toStr ? fromStr : `${fromStr}-${toStr}`;
      const filename = `${botName} ${chartName} ${period}`;
      const format =
        filters[FILTERS.GROUP] === "hour" ? DATE_FORMAT.HOUR : DATE_FORMAT.DATE;
      const locked = checkPro(type);
      return { filename, format, chartName, locked };
    },
    [filters, intervalValues, navDate, botName, checkPro]
  );

  const listChannels = useMemo(
    () =>
      (channels || []).map((name) => ({
        value: name,
        label: name,
      })),
    [channels]
  );

  const chartSessions = useMemo(
    () => parseSessions(sessions, options(CHART_TYPE.SESSIONS, t("Sessions"))),
    [sessions, options, t]
  );

  const chartUniqueUsers = useMemo(
    () =>
      parseUniqueUsers(
        uniqueUsers,
        options(CHART_TYPE.UNIQUE_USERS, t("Unique Users"))
      ),
    [uniqueUsers, options, t]
  );

  const chartEventsConversion = useMemo(
    () =>
      parseEventsConversion(
        eventsConversion,
        options(CHART_TYPE.EVENTS_CONVERSION, t("Conversion"))
      ),
    [eventsConversion, options, t]
  );

  const chartEventProps = useMemo(
    () =>
      parseEventProps(eventProps, {
        ...options(
          CHART_TYPE.EVENTS,
          tt("Event X", filters[FILTERS.EVENT_NAME])
        ),
        eventName: filters[FILTERS.EVENT_NAME],
      }),
    [eventProps, options, filters, tt]
  );

  const chartSessionsChannel = useMemo(
    () =>
      parseSessionsChannel(sessionsChannel, options(t("Sessions Per Channel"))),
    [sessionsChannel, options, t]
  );

  const isLoadingAny = Object.keys(isLoading).some(
    (key) => isLoading[key] === true
  );

  const navItems = [
    { name: t("Today"), value: TODAY },
    { name: t("Yesterday"), value: YESTERDAY },
    { name: t("Last Week"), value: LAST_WEEK },
    { name: t("Last Month"), value: LAST_MONTH },
    { name: t("Custom"), value: CUSTOM },
  ];

  return (
    <>
      <Card className="flex-fill mb-4">
        <Card.Body className="analytics-header">
          <Row className="mt-n1">
            <Col className="d-flex gap-2 align-items-center">
              <Navigation
                activeKey={navDate}
                items={navItems}
                onSelect={onSelectInterval}
              />
              {navDate === CUSTOM && (
                <>
                  <Form.Control
                    type="date"
                    value={dateToString(intervalValues[0])}
                    onChange={(event) => onFormDateChange(event, 0)}
                    className="date-picker"
                  />
                  <Form.Control
                    type="date"
                    value={dateToString(intervalValues[1])}
                    onChange={(event) => onFormDateChange(event, 1)}
                    className="date-picker"
                  />
                </>
              )}
              <nav className="ms-auto" />
              <Select
                id="channels"
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder={t("Add Filter")}
                options={listChannels}
                defaultValue={selectedChannels}
                onChange={setSelectedChannels}
                isMulti
              />
              <Button
                variant="outline-primary"
                className="shadow-sm"
                disabled={isLoadingAny}
                onClick={refresh}
              >
                {isLoadingAny ? (
                  <RotateCw className="feather fa-spin" />
                ) : (
                  <RefreshCw className="feather" />
                )}
                <span className="ms-2">{t("Refresh")}</span>
              </Button>
            </Col>
          </Row>
          <ProgressBar
            className={`position-absolute mt-2 ${
              isLoadingAny ? "" : "opacity-0"
            }`}
            striped
            animated
            variant="primary"
            now={isLoadingAny ? 90 : 0}
          />
        </Card.Body>
      </Card>
      {loadingError && (
        <Col md="12" className="mb-4">
          <RefreshAlert
            title={t("Reports")}
            error={loadingError}
            onRetry={refresh}
          />
        </Col>
      )}
      <Container fluid className="analytics p-0">
        <Row>
          <Col md="6">
            <ReportSessionsCard
              report={chartSessions}
              proPlan={checkPro(CHART_TYPE.SESSIONS)}
              isLoading={isLoading.sessions}
            />
          </Col>
          <Col md="6">
            <ReportUsersCard
              report={chartUniqueUsers}
              proPlan={checkPro(CHART_TYPE.UNIQUE_USERS)}
              isLoading={isLoading.uniqueUsers}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <ReportEventsCard
              report={chartEventProps}
              proPlan={checkPro(CHART_TYPE.EVENTS)}
              isLoading={isLoading.eventProps}
              filters={filters}
              eventNames={eventNames}
              onSelectEventName={onSelectEventName}
            />
          </Col>
          <Col md="6">
            <ReportConversionCard
              report={chartEventsConversion}
              proPlan={checkPro(CHART_TYPE.EVENTS_CONVERSION)}
              isLoading={isLoading.eventsConversion}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <ReportChannelsCard
              report={chartSessionsChannel}
              proPlan={checkPro(CHART_TYPE.SESSIONS_CHANNEL)}
              isLoading={isLoading.sessionsChannel}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Analytics;
