import { useState } from "react";
import chatgpt from "../../../../assets/img/brands/chatgpt.svg";
import screen_1 from "../../../../assets/img/screenshots/modules/screen_chat_gpt_1.png";
import screen_2 from "../../../../assets/img/screenshots/modules/screen_chat_gpt_2.png";
import screen_3 from "../../../../assets/img/screenshots/modules/screen_chat_gpt_3.png";
import screen_4 from "../../../../assets/img/screenshots/modules/screen_chat_gpt_4.png";
import { BOT_MODULE } from "../../../../contexts/bots/BotsContext";
import useBots from "../../../../hooks/useBots";
import { useTranslation } from "../../../../hooks/useLocalization";
import useMessage from "../../../../hooks/useMessage";
import { Accordion } from "../../../accordion/Accordion";
import ZoomImage from "../../../ZoomImage";
import ChannelInput from "./submit/ChannelInput";
import ChannelInputState from "./submit/ChannelInputState";
import ChannelState from "./submit/ChannelState";

const ChatGPTIcon = ({ className }) => (
  <img
    src={chatgpt || ""}
    alt=""
    width={20}
    height={20}
    className={className}
    style={{ transform: "scale(0.9)" }}
  />
);

const ChatGPTLogin = () => {
  const { t } = useTranslation();
  const text = t("ChatGPT.Step-1-1").split('#link#')
  return (
    <>
      <p>{text[0]}
        <a href="https://platform.openai.com/" alt="openai" target="_blank" rel="noreferrer" >
          https://platform.openai.com/
        </a>
        {text[1]}</p>
      <ZoomImage>
        <img alt="screen_1" src={screen_1} className="m-3 ms-0 mw-100" />
      </ZoomImage>
    </>
  );
};

const ChatGPTOpenMenu = () => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t("ChatGPT.Step-1-2")}</p>
      <ZoomImage>
        <img alt="screen_2" src={screen_2} className="m-3 ms-0 mw-100" />
      </ZoomImage>
    </>
  );
};

const ChatGPTCreateKey = () => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t("ChatGPT.Step-1-3")}</p>
      <ZoomImage>
        <img alt="screen_3" src={screen_3} className="m-3 ms-0 mw-100" />
      </ZoomImage>
    </>
  );
};

const ChatGPTSetKey = ({ id, channel, levels }) => {
  const { t } = useTranslation();
  const { updateBot } = useBots();
  const [isSubmitting, setSubmitting] = useState();
  const { showMessageWithDebug } = useMessage();

  const onSubmit = async (props) => {
    try {
      setSubmitting(true);
      await updateBot(id, {
        modules: [
          { ...channel, name: BOT_MODULE.CHATGPT, ...props },
        ],
      });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setSubmitting(false);
    }
  };

  const onChange__api_key = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit({ api_key: value });
    }
  };

  return (
    <>
      <p>{t("ChatGPT.Step-1-4")}</p>
      <ZoomImage>
        <img alt="screen_4" src={screen_4} className="m-3 ms-0 mw-100" />
      </ZoomImage>
      <ChannelInput
        strict
        title={t("API Key")}
        field="api_key"
        channel={channel}
        disabled={!levels.manage}
        isSubmitting={isSubmitting}
        onChange={onChange__api_key}
      />
      <ChannelInputState
        fields={["api_key"]}
        channel={channel}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

const ChatGPTSetKeyNoInput = ({ id, channel, levels }) => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t("ChatGPT.Step-1-4-1")}</p>
      <ZoomImage>
        <img alt="screen_4" src={screen_4} className="m-3 ms-0 mw-100" />
      </ZoomImage>
    </>
  );
};
const ChatGPTConfigure = ({ channel, levels, bot, handlers }) => {
  const { tt } = useTranslation();
  const { id } = bot || {};
  return (
    <>
      <Accordion defaultActiveKey="0" className="accordion-steps no-animation">
        <Accordion.Item eventKey="0" className="bg-white">
          <Accordion.Header>{tt("Step N", 1)}</Accordion.Header>
          <Accordion.Body>
            <ChatGPTLogin />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{tt("Step N", 2)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <ChatGPTOpenMenu />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{tt("Step N", 3)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <ChatGPTCreateKey />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{tt("Step N", 4)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <ChatGPTSetKey id={id} channel={channel} levels={levels} />
            <ChannelState channel={channel} handlers={handlers} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const ChatGPTConfigureSimple = () => {
  const { tt } = useTranslation();
  return (
    <>
      <Accordion defaultActiveKey="0" className="accordion-steps">
        <Accordion.Item eventKey="0" className="bg-white">
          <Accordion.Header>{tt("Step N", 1)}</Accordion.Header>
          <Accordion.Body>
            <ChatGPTLogin />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{tt("Step N", 2)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <ChatGPTOpenMenu />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{tt("Step N", 3)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <ChatGPTCreateKey />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{tt("Step N", 4)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <ChatGPTSetKeyNoInput />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const ChatGPTGetHandlers = ({ url, validated, handlers }) => {
  if (validated && url) {
    handlers = {
      ...handlers,
      onLink: () => {
        window.open(url, "_blank");
      },
    };
  }
  return handlers;
};

export { ChatGPTIcon, ChatGPTConfigure, ChatGPTGetHandlers, ChatGPTConfigureSimple };
