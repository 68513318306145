import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import DebugLogs from "../modals/DebugLogs";
import Scrollbar from "../components/Scrollbar";

const initialState = { title: null, debug: null };

const OffcanvasContext = React.createContext(initialState);

function OffcanvasProvider({ children }) {
  const [offcanvasData, setOffcanvasData] = useState(initialState);
  useEffect(() => () => setOffcanvasData(null), []);
  const title = offcanvasData?.title || "";
  const debug = offcanvasData?.debug;
  const show = !!debug;
  return (
    <OffcanvasContext.Provider
      value={{
        offcanvasData,
        setOffcanvasData,
      }}
    >
      <Offcanvas
        scroll={true}
        show={show}
        onHide={() => setOffcanvasData(null)}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <div className="offcanvas-body-container ps-dark">
          <Scrollbar>
            <Offcanvas.Body>
              <DebugLogs debug={debug} />
            </Offcanvas.Body>
          </Scrollbar>
        </div>
      </Offcanvas>
      {children}
    </OffcanvasContext.Provider>
  );
}

export { OffcanvasProvider, OffcanvasContext };
