/*eslint no-template-curly-in-string: "off"*/
/*eslint no-useless-computed-key: "off"*/
import { Case, Names } from "./languages";

const uk = {
  translation: {
    // языки
    [Names.en]: "English - Англійська",
    [Names.uk]: "Українська",
    // валюта
    ["USD"]: "долар США",
    ["N USD"]: "${1} доларів США",
    // коды ошибок
    ["errorCode_0"]: "Невідома помилка.",
    ["errorCode_1"]: "Функція не зазначена в цій версії API.",
    ["errorCode_2"]: "Об'єкт не існує.",
    ["errorCode_3"]: "Неправильні дані запиту.",
    ["errorCode_4"]: "Бота не існує.",
    ["errorCode_5"]: "Логін або пароль неправильний.",
    ["errorCode_6"]: "Користувач вже існує.",
    ["errorCode_7"]: "У дозволі відмовлено.",
    ["errorCode_8"]: "Стану не існує",
    ["errorCode_9"]: "Кнопка не існує в поточному стані.",
    ["errorCode_10"]: "Вхідні дані не існують у поточному стані.",
    ["errorCode_11"]: "Невідома дія користувача.",
    ["errorCode_12"]:
      "Ваш обліковий запис не підтверджено. Новий лист підтвердження надіслано на вашу електронну адресу.",
    ["errorCode_13"]: "Помилка в конфігурації бота.",
    ["errorCode_14"]: "Бот вже існує.",
    ["errorCode_15"]: "Не вдалося перевірити маркер доступу.",
    ["errorCode_16"]: "Канал вимкнено.",
    // счетчики
    ["K"]: "${1} тис",
    ["M"]: " ${1} млн",
    ["N days"]: "${1} днів",
    ["1 month"]: "1 місяць",
    ["N months"]: "${1} місяців",
    ["/mo"]: "${1}/міс",
    ["Price /mo"]: "${1}${2}/міс",
    ["Regular Price /mo"]: "Звичайна ціна: ${1}${2}/міс",
    // главный экран
    ["Qudata"]: "Qudata",
    ["Qudata Console"]: "Консоль Qudata",
    ["Search"]: "Пошук…",
    ["Bots not found"]: "Для ${1} ботів не знайдено.",
    ["All Bots"]: "Усі боти",
    ["Shared Bots"]: "Спільні боти",
    ["Templates"]: "Шаблони",
    ["Examples"]: "Приклади",
    ["Unknown Bots"]: "Невідомі боти",
    ["My Bots"]: "Мої боти",
    ["By name"]: "По імені",
    ["Recently"]: "Нещодавно",
    ["Sort"]: "Сортувати",
    ["Refresh"]: "Оновити",
    ["Bots Dashboard"]: "Панель інструментів ботів",
    ["Failed to download bot"]: "Не вдалося завантажити бота",
    ["Under review"]: "Розглядається",
    ["Published"]: "Опубліковано",
    ["Maintenance"]: "Технічне обслуговування",
    ["In development"]: "У розробці",
    ["Woops"]: "Упс",
    ["Clear"]: "Очистити",
    ["Refresh failed"]:
      "Не вдалося завантажити ${1}. Перевірте налаштування мережі та ${2}.",
    ["Try Again"]: "Спробуйте ще раз",
    ["Blank bot"]: "Новий бот",
    ["New Assistant"]: "Новий помічник",
    ["Create with ChatGPT"]: "Створюйте бота з підтримкою ChatGPT",
    ["Connect Wordpress"]: "Підключіться до плагіна Wordpress",
    ["By"]: "Від",
    ["Clone"]: "Клонувати",
    ["General Settings"]: "Загальні налаштування",
    ["Backup/Restore"]: "Резервне копіювання/Відновлення",
    ["Download Archive"]: "Завантажити архів",
    ["Channels"]: "Канали",
    ["Storage"]: "Зберігання",
    ["Analytics"]: "Аналітика",
    ["Play"]: "Грати",
    ["Management"]: "Менеджмент",
    ["Delete Bot"]: "Видалити бота",
    ["Testing"]: "Тестування",
    ["Editor"]: "Редактор",
    ["Edit"]: "Редагувати",
    ["Setup"]: "Налаштування",
    ["Payments"]: "Платежі",
    ["Payments history"]: "Історія платежів",
    ["Traffic tracking"]: "Контроль трафіку",
    ["Help"]: "Допомога",
    ["Logout"]: "Вийти",
    ["Language"]: "Мова",
    // подписки
    ["Subscription"]: "Підписка",
    ["X subscription"]: "${1} підписка",
    ["Third-party services"]: "Сторонні послуги",
    ["Something went wrong"]: "Щось пішло не так",
    ["Manage subscription"]: "Керувати підпискою",
    ["Auto billing"]: "Автоматичне виставлення рахунків",
    ["Auto billing is active"]: "Автоматичне виставлення рахунків активне",
    ["Auto billing deactivated"]: "Автоматичне виставлення рахунків вимкнено",
    ["Pay on invoice"]: "Оплатіть ${1} за рахунок-фактуру",
    ["Period"]: "Період",
    ["Amount"]: "Сума",
    ["Status"]: "Статус",
    ["Payment"]: "Платіж",
    ["Payment pending"]: "Очікується оплата",
    ["Payment confirmation"]: "Підтвердження оплати",
    ["Completed"]: "Завершено",
    ["Estimated billing date"]: "Орієнтовна дата виставлення рахунку ${1}",
    ["Packages & Prices"]: "Пакети та ціни",
    ["Hide all features"]: "Приховати всі характеристики",
    ["Compare all features"]: "Порівняйте всі характеристики",
    ["If you wish to unsubscribe"]:
      "Якщо ви хочете скасувати підписку, натисніть ${1}.\nЗверніть увагу, що після скасування підписки деякі функції можуть стати недоступними.",
    ["here"]: "тут",
    ["You have been invoiced"]:
      "Вам виставлено рахунок за підписку. Будь ласка, перевірте свою ${1} та електронну пошту.",
    ["payment history"]: "історія платежів",
    ["payment history" + Case.Genitive]: "історію платежів",
    ["Flexible plans"]: "Гнучкі плани, які ви шукали.",
    ["You’re subscribed"]: "Ви підписалися на план ${1}",
    ["Free"]: "Безкоштовно",
    ["Start-Up"]: "Стартап",
    ["Premium"]: "Преміум",
    ["Enterprise"]: "Підприємство",
    ["The plan change canceled"]: "Зміну плану скасовано",
    ["Your plan will change"]:
      "З ${3} ваш план зміниться з ${1} на ${2}. Якщо ви хочете зберегти ${1}, натисніть ${4}, щоб скасувати.",
    ["You have successfully unsubscribed"]: "Ви успішно скасували підписку",
    ["Your current plan"]: "Ваш поточний план",
    ["Your next plan"]: "Ваш наступний план",
    ["Change plan"]: "Змінити план",
    ["Upgrade"]: "Підвищити",
    ["Undo"]: "Скасувати",
    ["Plan change is scheduled"]: "Зміна плану запланована",
    ["The plan has been successfully upgraded"]: "План успішно оновлено",
    ["Get off for the first month"]: "Отримайте знижку ${1} за перший місяць",
    ["will expire on"]: "закінчується ${1}",
    ["will be activated"]: "буде активовано ${1}",
    ["Visual editor"]: "Візуальний редактор",
    ["Chatbot templates"]: "Шаблони чат-ботів",
    ["Communication channels"]: "Каналів зв’язку ${1}",
    ["N Message Limit"]: "Обмеження повідомлень ${1}/міс",
    ["Basic analytics"]: "Базова аналітика",
    ["Basic NLU"]: "Базовий NLU",
    ["All communication channels"]: "Всі канали зв'язку",
    ["Standard integrations"]: "Стандартні інтеграції",
    ["Advanced analytics"]: "Розширена аналітика",
    ["Advanced NLU"]: "Розширений NLU",
    ["Extra integrations"]: "Додаткові інтеграції",
    ["API integrations"]: "API інтеграції",
    ["ChatGPT integration"]: "ChatGPT інтеграція",
    ["NLU customisation"]: "Налаштування NLU",
    ["Voice chatbot"]: "Голосовий чат-бот",
    ["Voice chatbot Info"]: "Голосовий режим доступний для ботів, розроблених командою QuData на замовлення клієнта.",
    ["ChatGPT integration Info"]: "Інтеграція з ChatGPT передбачає підключення наявного облікового запису ChatGPT клієнта.",
    ["Plans"]: "Плани",
    ["Development"]: "Розробка",
    ["Visual chatbot editor"]: "Візуальний редактор чат-бота",
    ["NLU custom models"]: "Спеціальні моделі NLU",
    ["Multiple languages setup"]: "Налаштування кількох мов",
    ["Interface customization"]: "Налаштування інтерфейсу",
    ["Optional links"]: "Додаткові посилання",
    ["Optional links Info"]: "Посилання «Powered By» або Qudata є необов’язковими для платних планів і їх можна вимкнути",
    ["Communication and channels"]: "Зв'язок і канали",
    ["Web widget"]: "Веб-віджет",
    ["Messengers"]: "Месенджери",
    ["Free plan includes"]:
      "Безкоштовний план включає веб-віджет і 1 канал месенджера.",
    ["Facebook Messenger"]: "Facebook Messenger",
    ["Chatbot Analytics"]: "Аналітика чат-ботів",
    ["Basic"]: "Базовий",
    ["Advanced"]: "Розширений",
    ["Advanced Analytics provides"]:
      "Розширена аналітика забезпечує розширену ємність для зберігання даних і створення спеціальних подій.",
    ["Google Analytics Integration"]: "Інтеграція Google Analytics",
    ["Data storage"]: "Зберігання даних",
    ["Google Calendar Integration"]: "Інтеграція Google Calendar",
    ["Google Sheets Integration"]: "Інтеграція Google Sheets",
    ["QuData storage"]: "Сховище QuData",
    ["Store the information about"]:
      "Зберігати інформацію про історію чатів протягом певного періоду часу відповідно до обраного плану.",
    ["Unlimited"]: "Необмежено",
    ["Special options"]: "Особливі можливості",
    ["Bots Hosting"]: "Хостинг ботів",
    ["Number of domains"]: "Кількість доменів",
    ["Message Limit"]: "Ліміт повідомлень",
    ["The number of exchanged messages"]:
      "Кількість обміну повідомленнями між чат-ботом і клієнтами обмежена відповідно до обраного плану (1 розмова може складатися з кількох повідомлень).",
    ["QuBot - Chatbot Builder"]: "QuBot - конструктор чат-ботів",
    ["WordPress plugin"]: "Плагін WordPress",
    ["3rd party apps integration"]: "Інтеграція сторонніх програм",
    ["3rd party apps integration Info"]: "Інтеграція сторонніх програм вимагає додаткових витрат для будь-якого плану.",
    ["Page targeting"]: "Націлювання на сторінку",
    ["Page targeting Info"]: "Додайте чат-бота на окремі сторінки свого сайту.",
    ["Chatbot testing"]: "Тестування чат-бота",
    ["On request"]: "На запит",
    ["Appreciate the opportunity"]:
      "Цінуйте можливість протестувати ваших чат-ботів нашою досвідченою командою.",
    ["Support"]: "Підтримка",
    ["E-mail"]: "Електронна пошта",
    ["Upgrade your subscription"]: "Розширте свою підписку!",
    ["Pay now"]: "Платити зараз",
    ["purpose"]: "Платежна програма",
    ["period"]: "Нарахований період",
    ["updated"]: "Оновлено",
    ["Bot files updated"]: "Файли бота оновлені",
    ["amount"]: "Сума",
    ["Messages"]: "Повідомлення",
    ["MessagesTooltip"]: "Кількість повідомлень, якими обмінюються чат-бот і клієнти, обмежена відповідно до обраного плану (1 розмова може складатися з кількох повідомлень).",
    ["Messages total: N"]: "Усього повідомлень: ${1}",
    ["Free messages: N"]: "Безкоштовні повідомлення: ${1}",
    ["Paid messages: N"]: "Платні повідомлення: ${1}",
    ["Current plan"]: "Ваш поточний план ${1} включає ${2} повідомлень на місяць. За перевищення ліміту повідомлень стягується плата в розмірі 0,001 $/повідомлення.\nВи можете збільшити ліміт повідомлень, оновивши свій план ${3}.",
    // логин
    ["Welcome back"]: "Ласкаво просимо назад, ${1}",
    ["Sign In"]: "Увійти",
    ["Sign in"]: "Увійти",
    ["Sign in to your account"]: "Увійдіть у свій акаунт",
    ["Sign in to your account to continue"]:
      "Увійдіть у свій акаунт, щоб продовжити",
    ["Your account has not been confirmed"]:
      "Ваш акаунт не підтверджено. Будь ласка, перевірте свою електронну пошту.",
    ["Must be a valid email"]: "Повинна бути дійсна електронна пошта",
    ["Email"]: "Електронна пошта",
    ["Enter your email"]: "Введіть свою електронну пошту",
    ["Password"]: "Пароль",
    ["Enter your password"]: "Введіть ваш пароль",
    ["Remember me next time"]: "Згадайте мене наступного разу",
    ["No Account?"]: "Немає акаунту?",
    ["Forgot password?"]: "Забули пароль?",
    ["Login with Google"]: "Увійдіть через Google",
    ["Login with Facebook"]: "Увійти через Facebook",
    ["Sign up with Google"]: "Зареєструйтеся через Google",
    ["Sign up with Facebook"]: "Зареєструйтеся через Facebook",
    ["Login or password incorrect."]: "Логін або пароль неправильний.",
    // регистрация
    ["Sign Up"]: "Зареєструватися",
    ["Sign up"]: "Зареєструватися",
    ["Already have an account?"]: "Вже є аккаунт?",
    ["Login here"]: "Увійдіть тут",
    ["You’re sign up!"]:
      "Ви зареєструвалися! Будь ласка, перевірте свою електронну пошту на підтвердження, яке ми вам щойно надіслали.",
    ["Must be at least N characters"]: "Має містити не менше ${1} символів",
    ["Must contain only Latin characters"]: "Має містити лише латинські символи, цифри, пробіли та символи _()",
    ["Password must contain"]:
      "Повинен містити принаймні одну цифру, одну велику та одну малу літеру",
    ["Both password need to be the same"]:
      "Обидва паролі мають бути однаковими",
    ["First name"]: "Ім'я",
    ["Last name"]: "Прізвище",
    ["Email address"]: "Адреса електронної пошти",
    ["Confirm password"]: "Підтвердьте пароль",
    ["By clicking the Sign Up button"]:
      "Натискаючи кнопку «Зареєструватися», ви погоджуєтесь з нашими ${1} та ${2}",
    ["Terms & Conditions"]: "Положення та умови",
    ["Terms & Conditions" + Case.Instrumental]: "Положеннями та умовами",
    ["Privacy Policy"]: "Політика конфіденційності",
    ["Privacy Policy" + Case.Instrumental]: "Політикою конфіденційності",
    ["Email confirmation"]: "Підтвердження електронної пошти",
    ["Please wait for the operation to complete"]:
      "Будь ласка, дочекайтеся завершення операції",
    ["Please, fill in the form to complete the registration"]:
      "Будь ласка, заповніть форму, щоб завершити реєстрацію",
    ["Company name"]: "Назва компанії",
    ["Business website URL"]: "URL-адреса бізнес-сайту",
    ["Number of employees"]: "Кількість працівників",
    ["Industry"]: "Галузь промисловості",
    ["Your position"]: "Ваша позиція",
    ["Phone number"]: "Номер телефону",
    ["Save changes"]: "Зберегти зміни",
    ["Complete"]: "Завершити",
    ["Please check your email"]: "Будь ласка, перевірте свою електронну пошту",
    ["Reset Password"]: "Скинути пароль",
    ["We have sent you an email with further instructions"]:
      "Ми надіслали вам електронний лист із подальшими інструкціями. Будь ласка, дотримуйтесь інструкцій, щоб змінити пароль.",
    ["Email Address"]: "Адреса електронної пошти",
    ["Enter your email to reset your password"]:
      "Введіть свою електронну адресу, щоб скинути пароль",
    ["Remember your password?"]: "Пам'ятайте свій пароль?",
    ["Upload"]: "Завантажити",
    ["Display name"]: "Відображуване ім'я",
    ["Your changes have been successfully saved"]:
      "Ваші зміни успішно збережено",
    ["Note"]: "Примітка",
    ["Click on the message to see More Details"]:
      "Натисніть на повідомлення, щоб переглянути докладнішу інформацію",
    ["More details"]: "Детальніше",
    ["Connection timeout"]: "Тайм-аут з’єднання",
    ["Reset your password"]: "Скинути пароль.",
    ["After deleting your account"]:
      "Після видалення облікового запису ви не зможете ввійти за допомогою цієї електронної пошти, доки не зареєструєтеся знову",
    ["Confirm deletion"]: "Підтвердження видалення",
    // 404, 500 Error
    ["Error"]: "Помилка",
    ["Page not found"]: "Сторінку не знайдено.",
    ["The page you are looking for might have been removed"]:
      "Можливо, сторінку, яку ви шукаєте, видалено.",
    ["Return to website"]: "Повернутися на веб-сайт",
    ["Internal server error"]: "Внутрішня помилка сервера.",
    ["The server encountered something unexpected"]:
      "Сервер зіткнувся з чимось неочікуваним, що не дозволило йому виконати запит.",
    // профиль
    ["Account Settings"]: "Налаштування аккаунта",
    ["Profile"]: "Профіль",
    ["Details"]: "Деталі",
    ["Billing"]: "Розрахунки",
    ["Clear billing"]: "Видалити платежі",
    ["User Profile"]: "Профіль користувача",
    ["Additional Details"]: "Додаткові деталі",
    ["Billing Information"]: "Інформація про розрахунки",
    ["Change Password"]: "Змінити пароль",
    ["Delete Account"]: "Видалити аккаунт",
    ["Settings"]: "Налаштування",
    ["Payment history has been deleted"]: "Історія платежів видалена.",
    ["This feature is only available for test"]:
      "Ця функція доступна лише для тестування.",
    ["Clear payment history"]: "Очистити історію платежів",
    ["Activity Report"]: "Звіт про діяльність",
    ["Download CSV"]: "Завантажити CSV",
    ["Admin Panel"]: "Панель адміністратора",
    // модальные окна
    ["Close"]: "Закрити",
    ["Create"]: "Створити",
    ["Delete"]: "Видалити",
    ["Rename"]: "Перейменувати",
    ["Clone Bot"]: "Клонувати бота",
    ["Create Bot"]: "Створити бота",
    ["After deleting this bot"]:
      "Після видалення цього бота ви більше не зможете ним користуватися.",
    ["The bot was successfully deleted"]: "Бот було успішно видалено",
    ["Rename Bot"]: "Перейменувати бота",
    // хранилище
    ["Today"]: "Сьогодні",
    ["Yesterday"]: "Вчора",
    ["Last Week"]: "Минулий тиждень",
    ["Last Month"]: "Минулий місяць",
    ["Custom"]: "На замовлення",
    ["not serializable"]: "не можна серіалізувати",
    ["Date"]: "Дата",
    ["Time"]: "Час",
    ["Kind"]: "Вид",
    // аналитика
    ["Sessions"]: "Сесії",
    ["Unique Users"]: "Унікальні користувачі",
    ["Conversion"]: "Конверсія події",
    ["Event X"]: "Подія ${1}",
    ["Sessions Per Channel"]: "Сеансів на канал",
    ["Reports"]: "Звіти",
    ["Events"]: "Події",
    ["Total N"]: "Всього: ${1}",
    ["The number of sessions for"]:
      "Кількість сеансів за вибраний період за каналами.",
    ["Aggregated event conversions for"]:
      "Зведені конверсії подій за вибраний період. Він розраховується за: конверсії = кількість подій / кількість сеансів * 100%.",
    ["The number of times the selected event occurred"]:
      "Кількість випадків, коли відбулася вибрана подія. Будь-яка спеціальна подія має бути вбудована в код бота, щоб з’явитися в списку цільових подій.",
    ["No event selected"]: "Не вибрано жодної події",
    ["Empty"]: "Пусто",
    ["The total number of bot launches"]: "Загальна кількість запусків бота.",
    ["The total number of unique bot launches"]:
      "Загальна кількість унікальних запусків бота.",
    ["Add Filter"]: "Додати фільтр…",
    // настройки бота
    ["General info"]: "Загальна інформація",
    ["Name"]: "Ім'я",
    ["Bot name"]: "Ім’я бота",
    ["Bot name is required"]: "Ім’я бота обов’язкове",
    ["The data is up to date"]: "Дані актуальні",
    ["Bot bundle"]: "Пакет бота",
    ["Bundle is generated automatically"]:
      "Пакет створюється автоматично з домену компанії та імені бота.",
    ["Description"]: "Опис",
    ["Default language"]: "Мова за замовчуванням",
    ["Bot status"]: "Статус бота",
    ["Assistant"]: "Помічник",
    ["Bot kind"]: "Тип бота",
    ["User"]: "Користувач",
    ["Init"]: "Инициализировать",
    ["Template"]: "Шаблон",
    ["Example"]: "Пример",
    // уведомления
    ["Account details required"]: "Потрібні додаткові дані акаунту",
    ["Please fill in all fields in the form"]:
      "Будь ласка, заповніть усі поля у формі.",
    ["Notifications"]: "Сповіщення",
    ["You have no notifications"]: "У вас немає сповіщень",
    ["Reminder"]: "Нагадування",
    ["notification.next-subscription-3"]:
      "Ваш наступний рахунок за підписку має бути виставлений через 3 дні.",
    ["notification.next-subscription-2"]:
      "Наступний рахунок за підписку має бути сплачений через 2 дні.",
    ["notification.next-subscription-1"]:
      "Ваш наступний рахунок за підписку має бути оплачений через 1 день.",
    ["notification.invoice"]:
      "Вам виставлено рахунок за підписку. Будь ласка, перевірте свою історію платежів та електронну пошту.",
    ["notification.invoice-asap"]:
      "Вам виставлено рахунок за підписку. Будь ласка, перевірте свою історію платежів та електронну пошту.",
    ["notification.subscription-canceled"]:
      "На жаль, вашу підписку скасовано через несплату. Ваш поточний план змінено на Безкоштовний.",
    ["notification.subscription-renewed"]:
      "Вітаю! Вашу підписку успішно поновлено.",
    // сеть
    ["Connection error"]: "Помилка з'єднання",
    ["No data after loading"]: "Немає даних після завантаження",
    ["Unsupported response data format"]:
      "Непідтримуваний формат даних відповіді",
    // разное
    ["Text"]: "Текст",
    ["copied"]: "скопійовано",
    // каналы
    ["Integrations"]: "Інтеграції",
    ["Workflow"]: "Робочий процес",
    ["Wordpress"]: "Wordpress",
    ["WebChat"]: "Веб-чат",
    ["Messenger"]: "Messenger",
    ["Zendesk"]: "Zendesk",
    ["Dropbox"]: "Dropbox",
    ["Viber"]: "Viber",
    ["Telegram"]: "Telegram",
    ["WhatsApp"]: "WhatsApp",
    ["Whatsapp Info"]: "WhatsApp не включає комісію Facebook",
    ["LiveChat"]: "LiveChat",
    ["LiveChat Info"]: "Інтеграція LiveChat вимагає реєстрації облікового запису LiveChat і додаткових витрат.\n${1}\nПідпишіться на наш план і створіть обліковий запис LiveChat із подовженою пробною версією до 60 днів, а також 10% знижкою на перший платіж",
    ["Special Offer"]: "Спеціальна пропозиція!",
    ["Unknown"]: "Невідомо",
    ["Channel is active"]: "Канал активний",
    ["Channel deactivated"]: "Канал деактивовано",
    ["Open"]: "Відкрити",
    ["Click to configure"]: "Натисніть, щоб налаштувати",
    ["Back"]: "Назад",
    ["Next"]: "Далі",
    ["Skip"]: "Пропустити",
    ["Pro"]: "Професіонал",
    ["Step N"]: "Крок ${1}",
    ["That’s it!"]: "Ось і все!",
    ["This was all the steps"]:
      "Це були всі кроки для налаштування каналу. Не забудьте переконатися, що канал активований.",
    ["This was all the steps..."]:
      "Це були всі кроки для налаштування каналу. Не забудьте переконатися, що",
    ["...channel is activated"]:
      " канал активований.",
    ["To activate your chatbot, click..."]: "Щоб активувати чат-бот, перейдіть у розділ Загальні налаштування на панелі навігації.\nУ пункті «Статус бота» оберіть зі списку статусів «Опубліковано» та збережіть зміни.",
    ["optional"]: "необов’язково",
    ["Validation failed"]: "Помилка перевірки.",
    ["Okay"]: "Гаразд",
    ["Please make sure you copy the correct values"]:
      "Переконайтеся, що ви скопіювали правильні значення, і повторіть спробу. Щоб виправити це, дотримуйтесь інструкцій на цьому кроці.",
    ["Copy"]: "Копіювати",
    ["Value must be at most N characters"]:
      "Значення має бути не більше ніж ${1} символів",
    ["Deactivate the channel"]: "Деактивувати канал",
    ["Deactivate"]: "Деактивувати",
    ["Cancel"]: "Скасувати",
    ["Are you sure that you want to deactivate the X"]:
      "Ви впевнені, що хочете деактивувати канал ${1}? У цьому випадку він перестане функціонувати. Щоб підтримувати правильну роботу каналу, тримайте його активним.",
    ["While channel is deactivated..."]:
      "Коли канал вимкнено, віджет бота не працюватиме. Переведіть канал у активний стан, перш ніж розміщувати посилання на віджет на своєму веб-сайті.",
    ["Please follow the previous steps"]:
      "Будь ласка, виконайте попередні кроки",
    // канал Web
    ["Web.Header"]: "Як розгорнути чат-бота на веб-сторінці.",
    ["Web.Step-1"]: "Надайте доменні імена, де буде розміщено бота.",
    ["Web.Step-2"]: "Вставте віджет бота на свій сайт:",
    ["Web.Step-2-1"]: "Скопіюйте наступний код у буфер обміну.",
    ["Web.Step-2-2"]:
      "Відкрийте свою веб-сторінку та отримайте доступ до HTML. Прокрутіть униз і вставте його.",
    ["Web.Step-2-3"]:
      "Перезавантажте сторінку.\nБот з’явиться у вигляді кнопки в нижньому правому куті.",
    ["Add Domain"]: "Додати домен…",
    ["Upgrade your subscription to add more domain names"]:
      "Розширте свою підписку, щоб додати більше доменних імен.",
    ["HTML code"]: "HTML код",
    // канал Wordpress
    ["Wordpress.Header"]:
      "Якщо ви використовуєте WordPress, розширте свій досвід! Спробуйте плагін QuBot, конструктор чат-ботів із різноманітними готовими до використання шаблонами чат-ботів для будь-яких цілей вашого бізнесу.",
    ["Wordpress.Step-1"]:
      "Якщо ви вже використовуєте плагін, дотримуйтесь інструкцій нижче, щоб підключити бота, створеного за допомогою QuBot, до цієї консолі, щоб переглянути статистику бота та ввімкнути кілька каналів.",
    ["Wordpress.Step-1-1"]: "Скопіюйте Маркер доступу в буфер обміну.",
    ["Wordpress.Step-2"]: "Вставте Маркер доступу в плагін WordPress:",
    ["Wordpress.Step-2-1"]: "Відкрийте свого бота у плагіні Wordpress «QuBot».",
    ["Wordpress.Step-2-2"]:
      "Перейдіть до налаштувань Launch, як показано нижче.",
    ["Wordpress.Step-2-3"]: "Вставте токен у поле ID.",
    ["Wordpress.Step-2-4"]:
      "Тоді ви зможете працювати над своїм ботом як у плагіні, так і в цій консолі.",
    ["Access Token"]: "Маркер доступу",
    ["QuBot – Chatbot Builder with Templates"]:
      "QuBot – Конструктор чат-ботів із шаблонами",
    ["QuData"]: "QuData",
    ["Get Plugin"]: "Отримати плагін",
    // канал Zendesk
    ["Zendesk.Step-1"]: "Надайте свій аккаунт Zendesk.",
    ["Zendesk.Step-1-1"]:
      "Зареєструйтеся на ${1} та створіть свій аккаунт (пробний або спонсорований аккаунт служби підтримки Zendesk). Введіть URL-адресу свого аккаунту в поле введення нижче (наприклад, https://myaccountname.zendesk.com).",
    ["Zendesk.Step-1-2"]:
      "Пробний доступ триває лише 14 днів, переконайтеся, що після закінчення пробного періоду ваш план підписки на Zendesk відповідає мінімальним вимогам для роботи Chat API:",
    ["Zendesk.Step-1-3"]: "Zendesk Chat: Підписка Enterprise",
    ["Zendesk.Step-1-4"]: "Zendesk Support Suite: підписка Essential або вище",
    ["Zendesk.Step-2"]: "Створіть адміністратора.",
    ["Zendesk.Step-2-1"]:
      "У головному меню створіть нового користувача (Administrator), який діятиме від імені бота. Ви також можете використовувати профіль реєстрації.",
    ["Zendesk.Step-3"]: "Створіть API token для адміністратора.",
    ["Zendesk.Step-3-1"]:
      "У аккаунтi адміністратора перейдіть до налаштувань, а потім до ${1}.",
    ["Zendesk.Step-3-2"]:
      "У Центрі адміністрування перейдіть до ${1}. На вкладці Налаштування увімкніть доступ до Token access.",
    ["Zendesk.Step-3-3"]:
      "Натисніть кнопку ${1}, скопіюйте код із поля ${2} та натисніть кнопку ${3}.",
    ["Zendesk.Step-4"]: "Надайте API token.",
    ["Account URL"]: "URL-адреса акаунта",
    ["Admin Center"]: "Admin Center",
    ["Apps and integrations > Zendesk API"]:
      "Apps and integrations > Zendesk API",
    ["Add API token"]: "Add API token",
    ["API token"]: "API token",
    ["Save"]: "Save",
    // канал Dropbox
    ["Dropbox.Step-1"]: "Увійдіть у свій акаунт Dropbox.",
    ["Dropbox.Step-1-1"]:
      "Якщо у вас немає аккаунту Dropbox, зареєструйтеся на ${1}",
    ["Dropbox.Step-2"]: "Створіть додаток:",
    ["Dropbox.Step-2-1"]:
      "На порталі розробників перейдіть до консолі програми за адресою ${1}",
    ["Dropbox.Step-2-2"]: "На консолі програми натисніть кнопку ${1}.",
    ["Dropbox.Step-2-3"]:
      "Виберіть тип доступу ${1}, назвіть програму, прийміть Загальні положення та умови та створіть програму.",
    ["Dropbox.Step-3"]: "У своїй програмі перейдіть на вкладку ${1} та встановіть такі прапорці: files.metadata.write, files.content.write, files.content.read, sharing.write. Потім натисніть кнопку ${2}.",
    ["Dropbox.Step-4"]: "У своїй програмі перейдіть на вкладку ${1}, скопіюйте ${2} та ${3} та вставте їх нижче.",
    ["Dropbox.Step-5"]: "Натисніть кнопку ${1} і дотримуйтесь інструкцій у вікні Dropbox, щоб створити код доступу.",
    ["Dropbox.Step-5-1"]: "Отримавши ${1}, введіть його нижче.",
    ["Permissions"]: "Дозволи",
    ["Submit"]: "Надіслати",
    ["App key"]: "ключ програми",
    ["App secret"]: "секрет програми",
    ["Get code"]: "Отримати код",
    ["Access Code"]: "Код доступу",
    ["Create app"]: "Create app",
    ["Full Dropbox"]: "Full Dropbox",
    ["OAuth 2 access token"]: "OAuth 2 access token",
    ["Finish Step 4 to continue"]: "Щоб продовжити, завершіть крок 4.",
    // канал Viber
    ["Viber.Step-1"]: "Завантажте офіційний додаток Viber на свій телефон:",
    ["Viber.Step-2"]: "Створіть публічний аккаунт Viber:",
    ["Viber.Step-3"]: "Увійдіть в панель адміністратора Viber.",
    ["Viber.Step-3-1"]: "Перейдіть до ${1} і введіть свій номер телефону.",
    ["Viber.Step-3-2"]:
      "Ви отримаєте повідомлення Viber з кодом, який потрібно ввести в поле введення.",
    ["Viber.Step-4"]: "Створіть акаунт бота.",
    ["Viber.Step-4-1"]:
      "Заповніть наведену нижче форму, прийміть Загальні положення та умови та натисніть ${1}.",
    ["Viber.Step-4-2"]:
      "Це новий аккаунт Viber, пов’язаний з вашим номером телефону (як адміністратора), де працюватиме бот.",
    ["Viber.Step-5"]: "Після створення аккаунту ви отримаєте приватний токен.",
    ["Viber.Step-5-1"]: "Скопіюйте свій токен і введіть його нижче:",
    ["Viber.Step-6"]:
      "Viber дозволяє налаштувати вітальне повідомлення для вашого профілю бота, яке відображатиметься користувачам під час першої взаємодії з вашим ботом.",
    ["Greeting Message"]: "Вітальне повідомлення",
    ["Create Bot Account"]: "Create Bot Account",
    ["Viber Private Token"]: "Viber Private Token",
    // канал Telegram
    ["Telegram.Step-1"]: "Увійдіть у свій акаунт Telegram.",
    ["Telegram.Step-1-1"]:
      "Якщо у вас немає аккаунту Telegram, зареєструйтеся на сторінці ${1}",
    ["Telegram.Step-2"]:
      "Після входу знайдіть @BotFather за допомогою параметра пошуку, як показано нижче. Виберіть бота BotFather і введіть ${1} (або натисніть кнопку START). Якщо @BotFather вже є серед ваших контактів, ви можете безпосередньо перейти до кроку 3.",
    ["Telegram.Step-3"]: "Створити бота:",
    ["Telegram.Step-3-1"]:
      "Надішліть ${1} до BotFather і введіть ім’я для нового бота, який буде створено.",
    ["Telegram.Step-3-2"]:
      "Укажіть ім’я користувача, яке закінчується на ${1}, напр. sample_bot або SampleBot.",
    ["Telegram.Step-3-3"]: "Скопіюйте токен доступу з підтвердження Telegram.",
    ["Telegram.Step-4"]: "Вставте токен в поле введення нижче.",
    ["Telegram API Token"]: "Telegram API Token",
    // канал WhatsApp
    ["API Key"]: "Ключ API",
    ["Whatsapp.Header"]:
      "Щоб отримати доступ до WhatsApp Business API через нашу вбудовану форму реєстрації, дотримуйтесь цього посібника.",
    ["Whatsapp.Step-1"]: "Необхідні умови.",
    ["Whatsapp.Step-1-1"]: "Перед реєстрацією переконайтеся, що у вас є дійсний номер телефону. Перевірте, чи відповідає ваш номер телефону наведеним ${1} критеріям.",
    ["Whatsapp.Step-1-2"]: "Для телефонних номерів із увімкненою системою IVR, IVR потрібно тимчасово вимкнути під час процесу реєстрації.",
    ["Whatsapp.Step-2-1"]: "Перейдіть за посиланням на форму реєстрації ${1}, щоб заповнити заявку на обліковий запис WhatsApp Business.",
    ["Whatsapp.Step-2-2"]: "Вам потрібно буде вказати свою адресу електронної пошти (яку не можна підключити до активного облікового запису 360dialog), своє ім’я, назву вашої компанії та пароль, які використовуватимуться під час входу в 360 Client Hub.",
    ["Whatsapp.Step-2-3"]: "Після натискання кнопки ${1} ви будете перенаправлені на вбудовану сторінку реєстрації.",
    ["Whatsapp.Step-3-1"]: "Завершіть вбудовану реєстрацію.",
    ["Whatsapp.Step-3-2"]: "Застереження: не тримайте вбудоване спливаюче вікно реєстрації відкритим більше 1 години, інакше сеанс закінчиться. Перш ніж почати, переконайтеся, що у вас є всі передумови з кроку 1.",
    ["Whatsapp.Step-3-3"]: "Вам потрібно буде увійти у свій мета-обліковий запис, щоб рухатися далі. Важливо, щоб цей мета-обліковий запис мав доступ адміністратора до Meta Business Manager, який керуватиме цим обліковим записом WhatsApp.",
    ["Whatsapp.Step-3-4"]: "Укажіть номер телефону вашої компанії нижче.",
    ["Whatsapp.Step-3-5"]: "Щойно вбудована реєстрація буде завершена, ми негайно розгорнемо клієнт WhatsApp Business API. Ви автоматично ввійдете в 360 Client Hub, де зможете створити свій ключ API.",
    ["Whatsapp.Step-4"]: "Згенеруйте ключ API і переконайтеся, що ви зберігаєте його в захищеному місці.",
    ["Whatsapp.Step-4-1"]: "Скопіюйте ключ API у поле введення нижче.",
    ["Whatsapp.Step-5"]: "Заповніть профіль бізнес-менеджера.",
    ["Whatsapp.Step-5-1"]: "Щоб залишатися на зв’язку з API, додайте свою інформацію в розділ бізнес-інформації налаштувань бізнес-менеджера. Дізнайтеся, як це зробити ${1}.",
    // канал Facebook
    ["Facebook.Header"]:
      "Щоб налаштувати Facebook Messenger як канал, розробникам потрібно буде створити програму на Facebook, увімкнути інтеграцію вебхука для платформи для надсилання та отримання повідомлень, а також підписатися на програму на сторінку Facebook.",
    ["Facebook.Step-1"]: "Створіть програму.",
    ["Facebook.Step-1-1"]: "Увійдіть до ${1} і перейдіть до ${2}.",
    ["Facebook.Step-1-2"]:
      "Натисніть кнопку ${1}, а потім виберіть тип програми ${2}.",
    ["Facebook.Step-1-3"]:
      "Введіть усі відповідні відомості для вашої програми та натисніть ${1}.",
    ["Facebook.Step-1-4"]:
      "Ваш додаток буде створено, і ви будете перенаправлені на сторінку налаштування програми.",
    ["Facebook.Step-1-5"]:
      "Виберіть ${1} та натисніть кнопку ${2}, щоб продовжити. Ви будете перенаправлені на сторінку налаштувань.",
    ["Facebook.Step-2"]: "Отримати детальну інформацію про сторінку.",
    ["Facebook.Step-2-1"]:
      "Зв’яжіть сторінку з програмою та створіть ${1} з розділу Генерування токенів. Якщо у вас немає сторінки, ви можете створити сторінку у Facebook ${2}.",
    ["Facebook.Step-2-2"]:
      "Скопіюйте цей Page Access Token до сторінки та введіть його нижче.",
    ["Facebook.Step-2-3"]:
      "Перейдіть до головного меню налаштувань, а потім виберіть Основні налаштування, зафіксуйте ${1} і ${2} і введіть їх нижче.",
    ["Facebook.Step-2-4"]:
      "Зі свого аккаунту Facebook перейдіть на сторінку Facebook, яку ви вибрали вище. Знайдіть меню ${1}.",
    ["Facebook.Step-2-5"]: "Скопіюйте ${1} і введіть його нижче.",
    ["Facebook.Step-3"]: "Налаштування Webhook.",
    ["Facebook.Step-3-1"]:
      "${1}, щоб почати процес налаштування вебхука. Натисніть перемикач у верхньому правому куті, щоб активувати канал. Після активації каналу інтеграція вебхука вмикається автоматично.",
    ["Facebook.Step-3-2"]:
      "Перейдіть до меню налаштувань Messenger і прокрутіть униз до ${1}. У розділі Webhooks виберіть сторінку, яку ви використовували для створення маркера сторінки, і натисніть ${2}. Перевірте такі поля підписки: ${3}, ${4}, ${5}, ${6}.\nНатисніть кнопку ${7}.",
    ["Facebook.Step-4"]: "Надішліть додаток Facebook на затвердження.",
    ["Facebook.Step-4-1"]:
      "Після схвалення ви можете ввімкнути канал Facebook Messenger для свого бота. Щоб дізнатися більше про те, як надіслати програму, відвідайте сторінку ${1}.",
    ["Facebook developer portal"]: "Facebook developer portal",
    ["My Apps"]: "My Apps",
    ["Create App"]: "Create App",
    ["Business"]: "Business",
    ["Set up"]: "Set up",
    ["Page Access Token"]: "Page Access Token",
    ["App ID"]: "App ID",
    ["App Secret"]: "App Secret",
    ["About"]: "About",
    ["Page ID"]: "Page ID",
    ["Webhooks"]: "Webhooks",
    ["Add Callback URL"]: "Add Callback URL",
    ["Add subscriptions"]: "Add subscriptions",
    ["Callback URL"]: "Callback URL",
    ["Verify Token"]: "Verify Token",
    ["Enable this channel"]: "Увімкніть цей канал",
    ["Webhooks section"]: "Webhooks section",
    ["Add Subscriptions"]: "Add Subscriptions",
    // google drive
    ["GoogleDrive.Header-1"]:
      "Підключивши свій Google Диск до бота, ваш досвід роботи з роботом значно покращиться. Наприклад, ваш бот зможе використовувати деякі дані або файли безпосередньо з вашого Google Диска, а також зберігати дані або файли, отримані від клієнтів. Використання Google Диск значно спростить вашу роботу, оскільки ви матимете прямий доступ до читання та редагування збережених даних у будь-який момент без втручання в код бота.",
    ["GoogleDrive.Header-2"]:
      "Наведені нижче інструкції допоможуть вам надати спільний доступ до файлів і папок із вашого Google Диска.",
    ["GoogleDrive.Step-1"]: "Увійти в ${1}",
    ["GoogleDrive.Step-1-1"]:
      "Якщо у вас немає аккаунту Google, створіть його за адресою ${1}",
    ["GoogleDrive.Step-2"]: "Відкрийте ${1} тут ${2}",
    ["GoogleDrive.Step-2-1"]:
      "Ви також можете отримати доступ до свого Диска Google із ${1}, натиснувши кнопку Google Apps.",
    ["GoogleDrive.Step-3"]: "Поділіться файлами або папками зі своїм ботом.",
    ["GoogleDrive.Step-3-1"]:
      "На своєму Google Диску виберіть файл або папку, викликайте підменю та виберіть ${1}.",
    ["GoogleDrive.Step-3-2"]:
      "Поділіться доступом будь-яким із можливих способів.\n${1}. Надати доступ до певного облікового запису: скопіюйте наступний обліковий запис у поле введення у вікні спільного доступу та надішліть запрошення.",
    ["GoogleDrive.Step-3-3"]:
      "${1}. Отримайте посилання для спільного доступу: у розділі ${2} змініть статус ${3} на ${4} та натисніть Готово.",
    ["GoogleDrive.Step-3-4"]:
      "Зауважте, що ви можете контролювати доступ: якщо бот читатиме лише дані з Google Диска, тоді достатньо ролі ${1}, а якщо бот також зберігатиме дані на Google Диску, то роль ${2} буде вимагається. Ви можете будь-коли змінити роль у налаштуваннях спільного доступу.",
    ["GoogleDrive.Step-4"]:
      "Налаштуйте завантаження даних у файл або папку в редакторі бота. Додаткову інформацію про це можна знайти в шаблонах редактора ботів (наприклад, дрібниці, шаблони замовити їжу) або в нашому довідковому центрі.",
    ["GoogleDrive.Step-4-1"]:
      "Кожного разу, коли ви вставляєте посилання на файл або папку в редакторі ботів, воно з’являтиметься у списку нижче, щоб ви могли переглянути їх усі в одному місці.",
    ["GoogleDrive.Step-4-2"]:
      "Попередження: деякі файли або папки, які використовуються в боті, недоступні. Щоб вирішити проблему, перевірте на Диску Google, чи налаштовано спільний доступ до файлів або папок, а в редакторі ботів перевірте, чи правильне вбудоване посилання.",
    ["Google Drive Folder"]: "Папка Google Диска",
    ["Google Drive Folder" + Case.Genitive]: "Папкою Google Диска",
    ["Google Drive"]: "Google Диск",
    ["Add Link"]: "Додати посилання…",
    ["Copy link"]: "Copy link",
    ["Get link"]: "Get link",
    ["Send"]: "Send",
    ["Share"]: "Share",
    ["Google homepage"]: "Домашня сторінка Google",
    ["Account"]: "Обліковий запис",
    ["First way"]: "Перший спосіб",
    ["Second way"]: "Другий спосіб",
    ["Restricted"]: "Restricted",
    ["Anyone with the link"]: "Anyone with the link",
    ["List is empty"]: "Список порожній",
    ["there are no files or folders used in a bot"]:
      "у боті не використовуються файли чи папки",
    ["Viewer"]: "Viewer",
    // google calendar
    ["GoogleCalendar.Header-1"]:
      "Підключення вашого календаря Google до бота значно покращить роботу з роботом. Наприклад, це може дозволити вашому боту створювати події та завдання прямо у вашому календарі Google. Це також полегшить вашу роботу, оскільки ви матимете прямий доступ до перегляду та редагування календаря в будь-який час без втручання в код бота.",
    ["GoogleCalendar.Header-2"]:
      "Наведені нижче інструкції допоможуть вам поділитися календарем Google з ботом.",
    ["GoogleCalendar.Step-1"]: "Увійти в ${1}",
    ["GoogleCalendar.Step-1-1"]:
      "Якщо у вас немає аккаунту Google, створіть його за адресою ${1}",
    ["GoogleCalendar.Step-2"]: "Відкрийте ${1} тут ${2}",
    ["GoogleCalendar.Step-2-1"]:
      "Ви також можете отримати доступ до свого Календаря Google із ${1}, натиснувши кнопку Google apps.",
    ["GoogleCalendar.Step-3"]: "Поділіться ${1} зі своїм ботом.",
    ["GoogleCalendar.Step-3-1"]:
      "У своєму Google Календарі створіть новий календар або виберіть наявний.",
    ["GoogleCalendar.Step-3-2"]:
      "Визначте параметри свого календаря та виберіть ${1}.",
    ["GoogleCalendar.Step-3-3"]:
      "У налаштуваннях календаря перейдіть до розділу ${1}, натисніть кнопку ${2}, скопіюйте наступний обліковий запис у поле введення у вікні спільного доступу та надішліть запрошення.",
    ["GoogleCalendar.Step-3-4"]:
      "Будь ласка, зверніть увагу на налаштування доступу: якщо бот повинен читати лише дані з календаря Google, тоді достатньо ${1}, але якщо він повинен зберігати дані в календарі Google, то потрібно вибрати ${2}.\nВи можете будь-коли змінити права доступу в налаштуваннях календаря. Дізнайтеся більше про це ${3}.",
    ["GoogleCalendar.Step-4"]:
      "Налаштуйте зв'язок з календарем у редакторі бота. Спочатку отримайте посилання на свій календар у налаштуваннях календаря в розділі ${1}, натиснувши кнопку ${2}.",
    ["GoogleCalendar.Step-4-1"]:
      "Щоразу, коли ви вставляєте посилання на календар у редакторі ботів, воно з’являтиметься у списку нижче, щоб ви могли переглянути їх усі в одному місці.",
    ["GoogleCalendar.Step-4-2"]:
      "Попередження: деякі календарі, які використовуються в боті, недоступні. Щоб вирішити проблему, перевірте у своєму Календарі Google, чи налаштовано спільний доступ до календаря, а в редакторі ботів перевірте, чи правильне вбудоване посилання.",
    ["Google Calendar"]: "Google Календар",
    ["Google Calendar" + Case.Genitive]: "Google Календарем",
    ["Settings and sharing"]: "Settings and sharing",
    ["Share with specific people"]: "Share with specific people",
    ["Add people"]: "Add people",
    ["Make changes and manage sharing"]: "Make changes and manage sharing",
    ["Access permissions for events"]: "Access permissions for events",
    ["Get shareable link"]: "Get shareable link",
    ["See all event details"]: "See all event details",
    ["there are no calendars used in a bot"]:
      "в боті не використовуються календарі",
    // google sheets
    ["GoogleSheets.Header-1"]:
      "Підключивши електронні таблиці Google до бота, ваш досвід роботи з ботом значно покращиться. Наприклад, ваш бот зможе завантажувати дані безпосередньо з вашої електронної таблиці Google, а також завантажувати нові дані в режимі реального часу. Використання Google Таблиць значно полегшить вашу роботу, оскільки ви матимете прямий доступ до читання та редагування збережених даних у будь-який момент без втручання в код бота.",
    ["GoogleSheets.Header-2"]:
      "Наведені нижче інструкції допоможуть вам поділитися таблицями Google.",
    ["GoogleSheets.Step-1"]: "Увійти в ${1}",
    ["GoogleSheets.Step-1-1"]:
      "Якщо у вас немає аккаунту Google, створіть його за адресою ${1}",
    ["GoogleSheets.Step-2"]: "Відкрийте ${1} тут ${2}",
    ["GoogleSheets.Step-2-1"]:
      "Ви також можете отримати доступ до своїх таблиць Google із ${1}, натиснувши кнопку Google apps.",
    ["GoogleSheets.Step-3"]: "Поділіться ${1} зі своїм ботом.",
    ["GoogleSheets.Step-3-1"]:
      "У Google Таблицях відкрийте електронну таблицю та натисніть кнопку ${1}.",
    ["GoogleSheets.Step-3-2"]:
      "Поділіться доступом будь-яким із можливих способів.\n${1}. Надати доступ до певного облікового запису: скопіюйте наступний обліковий запис у поле введення у вікні спільного доступу та надішліть запрошення.",
    ["GoogleSheets.Step-3-3"]:
      "${1}. Отримайте посилання для спільного доступу: у розділі ${2} змініть статус ${3} на ${4} та натисніть Готово.",
    ["GoogleSheets.Step-3-4"]:
      "Зауважте, що ви можете контролювати доступ: якщо бот читатиме лише дані з електронної таблиці Google, тоді достатньо ролі ${1}, а якщо бот також зберігатиме дані в електронній таблиці Google, тоді роль ${2} буде вимагається. Ви можете будь-коли змінити роль у налаштуваннях спільного доступу.",
    ["GoogleSheets.Step-4"]:
      "Налаштуйте завантаження даних в електронну таблицю в редакторі бота. Додаткову інформацію про це можна знайти в шаблонах редактора ботів (наприклад, дрібниці, шаблони замовити їжу) або в нашому довідковому центрі.",
    ["GoogleSheets.Step-4-1"]:
      "Щоразу, коли ви вставляєте посилання на електронну таблицю в редакторі ботів, воно з’являтиметься в списку нижче, щоб ви могли переглянути їх усі в одному місці.",
    ["Google Sheets"]: "Google Таблиці",
    ["Google Spreadsheet"]: "Таблиця Google",
    ["Google Spreadsheet" + Case.Instrumental]: "Google Таблицею",
    ["there are no spreadsheets used in a bot"]:
      "у боті не використовуються електронні таблиці",
    // live chat
    ["LiveChat.Header"]:
      "Інтеграція LiveChat з ботом дозволяє вам додавати живі комунікації з агентами, тому обслуговування клієнтів значно покращується. Наступна інтеграція вимагає, щоб у вас був обліковий запис LiveChat.",
    ["LiveChat.Header-1"]: "Отримання облікового запису LiveChat за допомогою QuData",
    ["LiveChat.Header-2"]: "У LiveChat всі партнери QuData отримують розширену безкоштовну 60-денну пробну версію та знижку 10% на перший платіж.\nЩоб скористатися цими перевагами, натисніть кнопку Отримати свій обліковий запис LiveChat нижче, і ви отримаєте електронний лист із запрошенням. Дотримуйтесь інструкцій в електронному листі, щоб завершити реєстрацію в LiveChat.",
    ["LiveChat.Header-3"]: "Отримайте свій обліковий запис ${1}LiveChat",
    ["LiveChat.Header-3-1"]: "Запрошення надіслано. Будь ласка, перевірте свою електронну пошту.",
    ["LiveChat.Step-1"]: "Увійти в ${1}",
    ["LiveChat.Step-1-1"]:
      "Якщо у вас немає облікового запису LiveChat, зареєструйтеся на сторінці ${1}",
    ["LiveChat.Step-2"]: "Надайте свій LiveChat Organization ID.",
    ["LiveChat.Step-2-1"]:
      "У ${1} перейдіть до ${2} і скопіюйте ${3} у поле введення нижче.",
    ["LiveChat.Step-3"]: "Створіть додаток.",
    ["LiveChat.Step-3-1"]: "У ${1} перейдіть до ${2} і створіть новий додаток.",
    ["LiveChat.Step-4"]: "Створіть блок авторизації:",
    ["LiveChat.Step-4-1"]: "У своїй програмі перейдіть до розділу ${1}.",
    ["LiveChat.Step-4-2"]: "У блоці ${1} натисніть кнопку ${2}.",
    ["LiveChat.Step-4-3"]:
      "У вікні ${1} виберіть параметр ${2} і натисніть кнопку ${3}.",
    ["LiveChat.Step-4-4"]:
      "Після цього ви перейдете до налаштувань ${1}. Будь ласка, не залишайте цю сторінку, доки не закінчите ${2} цієї інструкції.",
    ["LiveChat.Step-5"]: "Налаштуйте авторизацію додатка:",
    ["LiveChat.Step-5-1"]:
      "У конфігурації ${1} знайдіть ${2} і скопіюйте його в поле введення нижче.",
    ["LiveChat.Step-5-2"]:
      "У конфігурації ${1} знайдіть ${2} і скопіюйте його в поле введення нижче.",
    ["LiveChat.Step-5-3"]:
      "Ви не можете відновити оригінальний Client Secret, тому зберігайте його в безпечному місці.",
    ["LiveChat.Step-5-4"]:
      "Будьте обережні, ви не зможете побачити Client Secret після перезавантаження сторінки. У цьому випадку вам доведеться згенерувати та надати нам новий секрет (він скине старий).",
    ["LiveChat.Step-5-5"]:
      "Перейдіть до розділу ${1} і додайте до списку такі URL-адреси за допомогою кнопки ${2}:",
    ["LiveChat.Step-5-6"]:
      "Перейдіть до розділу ${1} і виберіть область доступу ${2}. Натисніть кнопку ${3} для цього розділу",
    ["LiveChat.Step-5-7"]:
      "У верхній частині сторінки ${1} натисніть кнопку ${2}, щоб підготуватися до розгортання.",
    ["LiveChat.Step-5-8"]:
      "${1}. Натисніть кнопку ${2} нижче, щоб почати швидкий автоматизований процес. Зверніть увагу, що під час процесу вас можуть попросити увійти в Live Chat.",
    ["LiveChat.Step-6"]: "Налаштуйте блок Webhook:",
    ["LiveChat.Step-6-1"]:
      "У своїй програмі перейдіть до розділу ${1}, знайдіть блок ${2} та натисніть кнопку ${3}.",
    ["LiveChat.Step-6-2"]:
      "Скопіюйте наведений нижче ${1} та введіть його у відповідне поле в конфігурації ${2}.",
    ["LiveChat.Step-6-3"]:
      "Надайте нам ${1}. Натисніть кнопку ${2} і скопіюйте секретний ключ у поле введення нижче.",
    ["LiveChat.Step-6-4"]:
      "У розділі ${1} виберіть ${2}. Для цієї події виберіть параметри: ${3}, ${4}.",
    ["LiveChat.Step-6-5"]: "Натисніть кнопку ${1}.",
    ["LiveChat.Step-7"]: "Натисніть кнопку ${1} і дотримуйтесь інструкцій у вікні LiveChat, щоб створити код доступу.",
    ["LiveChat Developers Console"]: "LiveChat Developers Console",
    ["My Profile"]: "My Profile",
    ["Organization ID"]: "Organization ID",
    ["Building Blocks"]: "Building Blocks",
    ["App Authorization"]: "App Authorization",
    ["Get started"]: "Get started",
    ["Creating the OAuth2.1 Client"]: "Creating the OAuth2.1 Client",
    ["Server-side app"]: "Server-side app",
    ["Continue"]: "Continue",
    ["Client Id"]: "Client Id",
    ["Client Secret"]: "Client Secret",
    ["Redirect URI whitelist"]: "Redirect URI whitelist",
    ["Add"]: "Add",
    ["App scopes and API access"]: "App scopes and API access",
    ["Select All"]: "Select All",
    ["Chat Webhooks"]: "Chat Webhooks",
    ["Webhook URL"]: "Webhook URL",
    ["Secret Key"]: "Secret Key",
    ["Generate"]: "Generate",
    ["Webhook triggers"]: "Webhook triggers",
    ["author type"]: "author type",
    ["agent"]: "agent",
    ["Allow access to your App"]: "Надайте доступ до вашої програми",
    ["Allow Access"]: "Дозволити доступ",
    ["Done, access allowed"]: "Готово, доступ дозволено.",
    ["Access denied. Please double check the steps above"]:
      "Доступ заборонено. Перевірте наведені вище кроки та спробуйте ще раз, щоб надати доступ.",
    //permissions
    ["Roles"]: "Ролі",
    ["Assign"]: "Призначити",
    ["Select Bots"]: "Вибрати ботів",
    ["Select Roles"]: "Вибрати Ролі",
    ["Update Roles"]: "Оновити ролі",
    ["Add new roles"]: "Додати нові",
    ["Account Deleting"]: "Видалення акааунту",
    ["Account Deleting 1"]: "Ви впевнені, що хочете видалити обліковий запис ${1}?",
    ["Account Deleting 2"]: "Майте на увазі, що при видаленні облікового запису будуть ${1}",
    ["Account Deleting B"]: "стерті всі дані та всі його чатботи.",
    ["Delete Anyway"]: "Видалити аккаунт",
    ["Information"]: "Інформація",
    ["Available to users"]: "Доступно користувачам",
    ["Author"]: "Автор",
    ["Are you sure"]: "Ви дійсно бажаєте вимкнути бота?",
    ["Main text about disable bot"]: `Користувачі не матимуть змоги запускати бота після переведення його на технічне обслуговування.`,
    // tuto
    ["Onboarding"]: "Ознайомлення",
    ["Registration"]: "Реєстрація",
    ["Steps"]: "Кроків",
    ["Create a bot from the template"]: "Створити бота з шаблону",
    ["Customize a design"]: "Налаштувати дизайн",
    ["View Analytics"]: "Переглянути аналітику",
    ["Deploy a bot"]: "Впровадити бота",
    ["The tutorial completed"]: "Це керівництво завершено",
    ["Setups viewed"]: "З налаштуваннями ознайомлені",
    ["Registration completed"]: "Реєстрація завершена",
    ["Bot is created"]: "Бот створено",
    ["Onboarding completed"]: "Ознайомлення завершено!",
    ["Tutover1_1"]: "Щоб завершити реєстрацію, будь ласка, заповніть дану форму. Так Ви допоможете QuData ефективніше покращувати продукт та користувальницький досвід.",
    ["Tutover2_1"]: "Натиснувши на готовий шаблон бота, Ви відкриєте його в редакторі, який дозволяє дослідити усі компоненті бота зсередини.",
    ["Tutover2_2"]: "По кнопці Грати Ви запустите чат з ботом і зможете одразу провзаємодіяти з ним.",
    ["Tutover2_3"]: "По кнопці Клонувати Ви створите власний клон бота, який вже зможете редагувати в редакторі ботів.",
    ["Tutover2_4"]: "Для початку, спробуйте поспілкуватися з готовими шаблонами ботів. Оберіть шаблон, який Вам довподоби, та натисніть Грати, щоб запустити чат із ним.",
    ["Tutover2_5"]: "Створіть першого власного бота на базі готового шаблону, натиснувши Клонувати. Потім Ви зможете модифікувати та налаштувати створеного бота за власним смаком, або обрати інший шаблон відповідно до своїх потреб.",
    ["Click anywhere"]: "Натисніть будь-де аби продовжити…",
    ["Tutover3_1"]: "Перш ніж змінити зовнішній вигляд чату, запустіть чат з ботом, щоб спостерігати зміни в реальному часі.",
    ["Tutover3_2"]: "Змініть будь-які налаштування вашого боту за власним смаком. Нові налаштування одразу збережуться та відобразяться у вікні чату.",
    // Assistant Settings
    ["GPT Settings"]: "Налаштування GPT",
    // Assistant GPT Settings
    ["GPT Assistant Settings"]: "Налаштування помічника",
    ["GPT System Message"]: "Системне повідомлення",
    ["GPT System Placeholder"]: "Ви бот-помічник компанії. Ви відповідаєте на запитання користувачів...",
    ["GPT System Hint"]: "Системне повідомлення визначає ідентичність та поведінку бота. Докладно описуйте те, як Ви хочете, щоб бот поводився, як відповідав і ким представлявся.",
    ["GPT Prompt Message"]: "Підказка",
    ["GPT Prompt Placeholder"]: "Обробити такий текст користувача: ...",
    ["GPT Prompt Hint"]: "Підказка - це деяка передмова до кожного повідомлення, яке вказує на те, як реагувати на текст користувача. Як правило використовується у комбінації із системним повідомленням. Наприклад, Системне повідомлення: \"Ти бот помічник, який відповідає на відгуки. Кожен відгук має формат Відгук: Текст відгуку\". У такому разі в підказці може бути частина \"Відгук: \", тим самим уточнюючи для ChatGPT формат введення.",
    ["GPT Use model data"]: "Використовуйте лише дані з джерел",
    ["GPT Use model data Hint"]: "Перемикач обмежує базу знань робота лише тими даними, які завантажені як джерела. При невеликій кількості джерел бот занадто часто може відповідати, що він нічого не знає.",
    ["GPT No doc answer"]: "Текст відповіді, якщо відповідних джерел не знайдено",
    ["GPT No doc answer Placeholder"]: "Вибачте! На жаль, я не знаю відповіді на ваше запитання.",
    ["GPT No doc answer Hint"]: "Якщо у джерелах не було знайдено відповіді, то використовуватиметься перефразований текст поля \"Я не знаю\".",


    ["GPT Use google"]: "Використовувати гугл для перевірки та виправлення посилань бота",
    ["GPT Use google Hint"]: "Якщо бот дає користувачеві посилання, то перед відправкою вона буде перевірена і, якщо це можливо, вона буде виправлена.",

    ["GPT Site name"]: "Використовувати лише посилання з сайту:",
    ["GPT Site name Placeholder"]: "Наприклад: qudata.com",
    ["GPT Site name Hint"]: "Під час перевірки посилання будуть використовуватися лише дані із вказаного сайту.",


    ["GPT No doc answer strong"]: "Рівень обмежень у використанні джерел.",
    ["GPT No doc answer strong Hint"]: "Рівень обмеження знань робота. Чим більше значення, тим сильнішим буде обмеження у відповіді робота.",
    ["GPT Match threshold"]: "Поріг відбору відповідних джерел.",
    ["GPT Match threshold Hint"]: "Поріг відбору джерел призначений, щоб відсіювати джерела, які відповідають питанню користувача. Чим більший поріг, тим менша кількість і більше відповідні джерела буде обрано. Важливо пам'ятати, що при надто великому порозі відповідні джерела також можуть бути відкинуті через не відповідність розміру джерела та питання користувача.",
    ["GPT Documents count"]: "Максимальна кількість джерел у відповіді бота.",
    ["GPT Documents count Hint"]: "Для більш коректної роботи та оптимізації роботи робота ми не використовуємо всі джерела, які ви завантажили в бот. Чим більше джерел використовується, тим точніше бот може відповідати на запитання користувача, але при цьому він витрачатиме більше токенів.",
    // Model GPT Settings
    ["GPT Chat History"]: "Довжина історія чату",
    ["GPT Chat History Hint"]: "Кількість повідомлень, які пам'ятає помічник.",
    ["GPT Model Settings"]: "Налаштування моделі GPT",
    ["GPT Sources Settings"]: "Налаштування джерел GPT",
    ["GPT Model"]: "Модель GPT",
    ["GPT Model Hint"]: "ID моделі для використання.",
    ["gpt-3.5-turbo"]: "GPT 3.5 Turbo",
    ["gpt-3.5-turbo-0301"]: "GPT 3.5 Turbo-0301",
    ["gpt-3.5-turbo-0613"]: "GPT 3.5 Turbo-0613",
    ["gpt-3.5-turbo-16k"]: "GPT 3.5 Turbo 16k",
    ["gpt-3.5-turbo-16k-0613"]: "GPT 3.5 Turbo 16k-0613",
    ["gpt-4"]: "GPT 4 (бета, повільніше)",
    ["gpt-4-0613"]: "GPT 4-0613 (бета, повільніше)",
    ["gpt-4-32k"]: "GPT 4 32k (бета, повільніше)",
    ["gpt-4-32k-0613"]: "GPT 4 32k-0613 (бета, повільніше)",
    ["GPT Temperature"]: "Температура",
    ["GPT Temperature Hint"]: "Яку температуру вибірки використовувати, від 0 до 1. Вищі значення, як-от 0,8, зроблять результат більш випадковим, тоді як нижчі значення, як-от 0,2, зроблять його більш зосередженим і детермінованим. \nЗагалом ми рекомендуємо змінювати це або top_p, але не обидва.",
    ["GPT Top p"]: "Вибирайте токени з упевненістю більш ніж",
    ["GPT Top p Hint"]: "Альтернатива вибірці з температурою, яка називається нуклеусною вибіркою, де модель розглядає результати токенів із масою ймовірності top_p. Таким чином, 0,1 означає, що враховуються лише токени, що становлять 10% верхньої маси ймовірності. \nЗагалом ми рекомендуємо змінювати цю або температуру, але не обидва.",
    ["GPT Max tokens"]: "Максимальна кількість токенів на запит",
    ["GPT Max tokens Hint"]: "Максимальна кількість токенів для генерації під час завершення чату. \nЗагальна довжина вхідних маркерів і згенерованих маркерів обмежена довжиною контексту моделі.",
    ["GPT Presence penalty"]: "Штраф за присутність",
    ["GPT Presence penalty Hint"]: "Число від -2,0 до 2,0. Позитивні значення штрафують нові маркери залежно від того, чи з’явилися вони в тексті до цього моменту, збільшуючи ймовірність того, що модель обговорюватиме нові теми.",
    ["GPT Frequency penalty"]: "Штраф за частоту",
    ["GPT Frequency penalty Hint"]: "Число від -2,0 до 2,0. Позитивні значення штрафують нові лексеми на основі їх наявної частоти в тексті на даний момент, зменшуючи ймовірність повторення моделлю того самого рядка дослівно.",
    ["GPT Number generations"]: "Кількість генерацій",
    ["GPT Number generations Hint"]: "Скільки варіантів завершення чату для кожного вхідного повідомлення.",
    // Module ChatGPT
    ["ChatGPT.Step-1-1"]: "Зареєструйтеся за #link# і створіть свій обліковий запис.",
    ["ChatGPT.Step-1-2"]: "Перейдіть до меню свого профілю та виберіть \"Переглянути ключі API\".",
    ["ChatGPT.Step-1-3"]: "Створіть новий ключ. Натисніть кнопку \"Створити новий секретний ключ\".",
    ["ChatGPT.Step-1-4"]: "Скопіюйте його та вставте в поле нижче.",
    ["ChatGPT.Step-1-4-1"]: "Скопіюйте його та вставте в поле вище.",
    ["How to get the ChatGPT API key"]: "Як отримати ключ API ChatGPT.",
    ["How will users use your assistant?"]: "Як користувачі використовуватимуть вашого помічника?",
    ["Choose your assistant persona"]: "Виберіть особистість свого помічника.",
    ["Describe your assistant persona"]: "Опишіть свого помічника.",
    ["Insert your ChatGPT key"]: "Вставте ключ ChatGPT.",
    ["Insert your ChatGPT key ..."]: "При введенні API ключа, Ви можете використовувати можливість ChatGPT безкоштовно. Без використання ключа у Вас буде лише 30000 безкоштовних токенів, після чого Вам необхідно буде купити передплату.",
    ["This template has no parameter settings"]: "Цей шаблон не має налаштувань параметрів.",
    ["This description will not ..."]: "(Цей опис не братиме участі у створенні персонажа бота.)",
    ["Create a new assistant with ..."]: "Створіть нового помічника з власною базою знань і помічником. Ви зможете змінити ці параметри пізніше.",
    ["Assistant description"]: "Опис помічника",
    // Sources
    ["New Source"]: "Нове Джерело",
    ["Sources Library"]: "Бібліотека Джерел",
    ["Source settings"]: "Налаштування джерела",
    ["Add Text source"]: "Текстове джерело",
    ["Add Link source"]: "Джерело Посилання",
    ["Add sources from Site"]: "Джерела з Сайту",
    ["Add sources from File"]: "Джерела з Файлу",
    ["Source tag"]: "Тег джерела",
    ["Source text"]: "Текст джерела",
    ["Save source"]: "Зберегти джерело",
    ["tokens have been used:"]: "Було використано жетонів: ",
    ["N tokens have been used"]: "Було використано ${1} жетонів.",
    ["N tokens will be used"]: "Буде використано ${1} жетонів.",
    ["N tokens will be used and more"]: "Кількість використаних токенів стане відомо після обробки джерела. Буде використано мінімум ${1} жетонів.",
    ["Received"]: "Отримано",
    ["Success"]: "Успіх",
    ["Process"]: "Обробка",
    ["Parsing"]: "Розбір",
    ["File"]: "Файл",
    ["Sitemap"]: "Карта сайту",
    ["Link"]: "Посилання",
    ["Delete Source"]: "Видалити Джерело",
    ["After deleting this source ..."]: "Після видалення цього джерела ви більше не зможете ним користуватися.",
    ["The source was successfully deleted"]: "Джерело успішно видалено",
    ["Source link"]: "Посилання на джерело",
    ["Tag"]: "Тег",
    ["Type"]: "Тип",
    ["Update At"]: "Оновлено",
    ["Actions"]: "Перелік дій",
    ["Show"]: "Показати",
    ["Page"]: "Сторінка",
    ["of"]: "з",
    ["N"]: "№",

    // Templates
    ["URL"]: "Посилання",
    ["Company"]: "Назва компанії",
    ["A general knowledge assistant for your company with multiple products, sources, teams, websites, documents, and more."]: "Помічник із загальної інформації для вашої компанії з багатьма продуктами, джерелами, командами, веб-сайтами, документами тощо.",

    ["Expert on your content"]: "Експерт із вашого контенту",
    ["An expert on your own data. Could be your business, a set of products, or internal knowledge for your team."]: "Експерт власних даних. Це може бути ваш бізнес, набір продуктів або внутрішні знання вашої команди.",

    ["Assistants"]: "Помічники",
    ["Assistants in various business areas."]: "Помічники в різних сферах бізнесу.",

    ["Manager"]: "Менеджер",
    ["Manager on your site."]: "Менеджер на вашому сайті.",

    ["Business plan Generator"]: "Генератор бізнес планів",
    ["Business plan generator. Will create a comprehensive and organized plan including detailed market analysis, target audience, marketing strategies, financial forecasts and any other important aspects."]: "Генератор бізнес планів. Створить комплексний та організований план, що включає детальний аналіз ринку, цільову аудиторію, маркетингові стратегії, фінансові прогнози та будь-які інші важливі аспекти.",


    ["Product Manager"]: "Менеджер по продукції",
    ["Will write the product requirements document in the role of the primary product manager."]: "Напише документ із вимогами до продукту в ролі основного менеджера з продукту.",

    ["IT Expert"]: "IT експерт",
    ["Helps to solve technical problems of users."]: "Допомагає вирішити технічні проблеми користувачів.",

    ["Code assistant"]: "Помічник коду",
    ["Helps users write, debug, and optimize code for different programming languages ​​and platforms."]: "Допомагає користувачам писати, налагоджувати та оптимізувати код для різних мов програмування та платформ",

    ["Science Explainer"]: "Пояснення науки",
    ["The bot explains scientific concepts, theories and phenomena in a fun and accessible way."]: "Бот весело та доступно пояснює наукові концепції, теорії та явища.",

    ["Marketing"]: "Маркетинг",
    ["Various bots useful in the field of marketing."]: "Різні боти, корисні в сфері маркетингу.",

    ["PlanBot Marketer"]: "Маркетолог плану",
    ["Create a marketing campaign plan"]: "Створіть план маркетингової кампанії.",


    ["E-commerce SEO expert"]: "SEO експерт електронної комерції",
    ["Product descriptions for marketplaces. "]: "Описи товарів для маркетплейсів.",


    ["Bot Promo Planner"]: "Планувальник акцій",
    ["The bot will help you think over an advertising campaign for your target audience."]: "Бот допоможе вам продумати рекламну кампанію для цільової аудиторії.",

    // Sources Hints

    ["About Text Source"]: "Джерело типу Текст - це одиночне джерело, що складається з введеного Вами тексту.",

    ["About File Source"]: "Джерело типу Файл - це безліч джерел, створюваних із файлу. Файл повинен мати розширення .csv і включати стовпець під назвою text. Крім цього файл може мати стовпець link - якщо у рядку описано джерело Посилання або стовпець question, якщо ми завантажуємо файл типу FAQ.",

    ["About Link Source"]: "Джерело типу Посилання - це одиночне джерело, яке включає дані окремо сторінки сайту.",

    ["About Sitemap Source"]: "Джерело типу Карта сайту – це безліч джерел, які будуть взяті з Вашого сайту.",

    ["About Tag in Source"]: "Для ідентифікації джерела можна встановити тег.",

    ["Refresh source"]: "Повторно обробіть джерело",
    ["Edit source"]: "Редагувати джерело",
    ["Remove source"]: "Видалити джерело",

    ["Sources Success Hint"]: "Ваше джерело успішно оброблено і використовуватиметься в чат-боті.",
    ["Sources Received Hint"]: "Ваше джерело успішно завантажено на наш сервер.",
    ["Sources Process Hint"]: "Ваше джерело обробляється і скоро буде доступне для використання в чат-боті.",
    ["Sources Parsing Hint"]: "Дані Вашого джерела читаються із зовнішнього ресурсу.",

    ["GPT Bot Card Info"]: "Це бот ChatGPT",
    ["Show only GPT Bots"]: "Відображати лише GPT-ботів",

    ["Bot type"]: "Тип ботів",
    ["All bots"]: "Всі боти",
    ["ChatGPT Bots"]: "Боти ChatGPT",
    ["Editor Bots"]: "Боти редактора",

    ["Sources create error"]: "Помилка створення джерел.",
    ["Source exists"]: "Джерело вже існує.",

    ["Get links"]: "Отримати посилання",
    ["Please, enter link"]: "Будь ласка, введіть посилання",
    ["Please, choose the file"]: "Будь ласка, виберіть файл",
    ["Please click Get Links and select links."]: "«Будь ласка, натисніть \"Отримати посилання\" та виберіть посилання.",
  },
};

export default uk;
