
import React from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Slash } from "react-feather";
import useBots from "../../hooks/useBots";
import { useTranslation } from "../../hooks/useLocalization";
import useMessage from "../../hooks/useMessage";
import logger from "../../utils/logger";

const ArchiveBot = ({ bot }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { t } = useTranslation();
  const { updateBot } = useBots();
  const { showMessage, showMessageWithDebug } = useMessage();
  const { id } = bot;
  const values = { status: "maintenance" };


  const ModalDelbot = (props) => {
    const deleteBot = async () => {
      try {
        await updateBot(id, values);
        showMessage({ save: "" });
      } catch (error) {
        logger.error(error);
        const { message = t("Something went wrong"), debug } = error;
        showMessageWithDebug({ error: message, debug });
      }
      setModalShow(!modalShow)
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-flex flex-column justify-content-center">
          <button className="btn-close" onClick={() => setModalShow(!modalShow)}></button><br />
          <h3>{t("Are you sure")}</h3>
          <p>{t("Main text about disable bot")}</p>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-around">
          <Button className="ps-5 pe-5" onClick={deleteBot}>
            {t("Maintenance")}
          </Button>
          <Button className="ps-5 pe-5" onClick={() => setModalShow(!modalShow)}>
            {t("Cancel")}
          </Button>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>{t("Status")} {t("Maintenance")}</Tooltip>}>
        <Button variant="light" className="m-1 p-1" onClick={() => setModalShow(true)} disabled={bot.status === "maintenance"}>
          <Slash />
        </Button>
      </OverlayTrigger>
      <ModalDelbot show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default ArchiveBot;