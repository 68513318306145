const copyText = (value) => {
  const text =
    typeof value === "string"
      ? value
      : value?.target?.value || value?.target?.innerHTML || "";
  const element = document.createElement("input");
  try {
    document.body.appendChild(element);
    element.value = text;
    element.select();
    return document.execCommand("copy");
  } catch (error) {
    // not allowed
  } finally {
    document.body.removeChild(element);
  }
};

export default copyText;
