import React from "react";
import { Helmet } from "react-helmet-async";
import useBots from "../../../hooks/useBots";
import QDLoader from "../../../components/QDLoader";
import GPTSettings from "../../../components/bots/gptsettings/GPTSettings";
import { Container } from "react-bootstrap";
import Header from "./Header";
import { useTranslation } from "../../../hooks/useLocalization";

const GPTSettingsPage = () => {
  const { t } = useTranslation();
  const { isInitialized } = useBots();
  return (
    <React.Fragment>
      <Helmet title={t("GPT Settings")} />
      <Container fluid className="p-0">
        <Header title={t("GPT Settings")} />
        {isInitialized ? <GPTSettings /> : <QDLoader />}
      </Container>
    </React.Fragment>
  );
};

export default GPTSettingsPage;
