import React from "react";
import { Helmet } from "react-helmet-async";
import useBots from "../../../hooks/useBots";
import QDLoader from "../../../components/QDLoader";
import NewGPTSource from "../../../components/bots/gptsettings/NewGPTSource";
import { Container } from "react-bootstrap";
import Header from "./Header";
import { useTranslation } from "../../../hooks/useLocalization";

const NewGPTSourcePage = () => {
    const { t } = useTranslation();
    const { isInitialized } = useBots();
    return (
        <React.Fragment>
            <Helmet title={t("New Source")} />
            <Container fluid className="p-0">
                <Header title={t("New Source")} />
                {isInitialized ? <NewGPTSource /> : <QDLoader />}
            </Container>
        </React.Fragment>
    );
};

export default NewGPTSourcePage;
