const scrollIntoView = (elementId) => {
  const element =
    typeof elementId === "string"
      ? window.document.getElementById(elementId)
      : elementId;
  if (element) {
    setTimeout(() => element.scrollIntoView({ block: "center" }));
  }
};

const scrollTo = (elementId) => {
  const element =
    typeof elementId === "string"
      ? window.document.getElementById(elementId)
      : elementId;
  if (element) {
    window.scrollTo({
      top:
        element.offsetTop - ((window.innerHeight - element.clientHeight) >> 1),
      behavior: "instant",
    });
  }
};

export { scrollIntoView, scrollTo };
