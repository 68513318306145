import React, { useCallback, useMemo, useState } from "react";
import { Button, Card, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { PlusCircle } from "react-feather";
import chatgpt from "../../../assets/img/brands/chatgpt.svg";
import { useTranslation } from "../../../hooks/useLocalization";

import useAnalytics from "../../../hooks/useAnalytics";

const BotNewGPTCard = ({ handlers }) => {
  const { t } = useTranslation();
  const { onCreate } = handlers || {};
  const { CONSOLE_EVENTS } = useAnalytics();
  const hover = useHoverState();
  const className = useMemo(
    () => `text-secondary p-3 ${Object.keys(handlers).length > 5 ? "ps-2 pe-2" : ""}`,
    [handlers]
  );
  return (
    <Card
      className="flex-fill text-center bot-card"
      {...hover.body}
      onClick={async () => {
        onCreate();
        window.gtag('event', "bots_new_gpt_bot")
        await CONSOLE_EVENTS.EV_CreateGPT.send({})
      }}
    >
      <Card.Body className="pb-0 bot-card-text">
        <div className="d-flex align-items-center bot-card-header h-100">
          <div className="flex-grow-1">
            <PlusCircle size="6rem" className="mb-2" />
            <h3 className="mb-2 text-truncate-2">{t("ChatGPT")}</h3>
          </div>
        </div>
      </Card.Body>
      <hr className="m-0" />
      <Card.Body className="p-0">
        <Row xs="auto" className="bot-card-footer text-end justify-content-center" >
          <OverlayTrigger placement="bottom"
            overlay={<Tooltip>{t("Create with ChatGPT")}</Tooltip>}
          >
            <Button variant="" className={className} >
              <img alt="chatgpt" src={chatgpt} width="24" height="24" />
            </Button>
          </OverlayTrigger>
        </Row>
      </Card.Body>
    </Card>
  );
};

const useHoverState = () => {
  const [state, setState] = useState({});
  const hoverSome = useCallback(
    (key) => ({
      onMouseEnter: () => setState({ [key]: true }),
      onMouseLeave: () => setState({ [key]: false }),
      onMouseDown: (event) => {
        event.preventDefault();
        setState({ [key]: false });
      },
    }),
    [setState]
  );
  return {
    state,
    body: hoverSome.bind(null, "body")(),
  };
};

export default BotNewGPTCard;
