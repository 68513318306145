import React, { useCallback, useState } from "react";
import ContextMenu from "../components/ContextMenu";

const initialState = {};

const ContextMenuContext = React.createContext(initialState);

function ContextMenuProvider({ children }) {
  const [contextMenu, setContextMenu] = useState({
    show: false,
    header: null,
    items: null,
  });

  const showContextMenu = useCallback(
    ({ header, items }) =>
      setContextMenu({ ...contextMenu, header, items, show: true }),
    [contextMenu, setContextMenu]
  );

  return (
    <ContextMenuContext.Provider
      value={{
        showContextMenu,
      }}
    >
      <ContextMenu
        {...contextMenu}
        onClose={() => setContextMenu({ ...contextMenu, show: false })}
      />
      {children}
    </ContextMenuContext.Provider>
  );
}

export { ContextMenuProvider, ContextMenuContext };
