import React, { useCallback, useContext, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import Slot from "./Slot";
import Loading from "./Loading";
import Scrollbar from "../../Scrollbar";
// import { RunBotContext, RunBotDebugContext } from "qubot";
const RunBotContext = null;
const RunBotDebugContext = null;

const Slots = ({ id }) => {
  const {
    getData,
    selectedSlot,
    allSlots = [],
  } = useContext(RunBotDebugContext);
  const { state } = useContext(RunBotContext);
  const isLoading = !allSlots?.length;

  useEffect(() => getData({ slots: ["*"] }), [id, getData]);

  const getSlotData = useCallback(
    async (index) => {
      await getData({ slots: [allSlots[index].name] });
    },
    [allSlots, getData]
  );

  useEffect(() => {
    (async () => {
      if (allSlots) {
        const index = allSlots.indexOf(
          allSlots.find(({ name }) => name === selectedSlot?.name)
        );
        if (index > -1) {
          await getSlotData(index);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Slots</Card.Title>
      </Card.Header>
      <div className="t-card-slots-container ps-dark">
        <Scrollbar>
          <Card.Body className="p-0">
            {(isLoading && <Loading />) || (
              <Accordion flush className="mb-3">
                {(allSlots || []).map((slot, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header
                      onMouseDown={async (event) => {
                        const { target } = event;
                        if (target.classList?.contains("collapsed")) {
                          await getSlotData(index);
                        }
                      }}
                    >
                      {slot.name}
                    </Accordion.Header>
                    <Accordion.Body>
                      <Slot slot={slot} />
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            )}
          </Card.Body>
        </Scrollbar>
      </div>
    </Card>
  );
};

export default Slots;
