import React, { useEffect, useReducer } from "react";
import api from "../../net/api";
import useAuth from "../../hooks/useAuth";
import * as CMD from "../sources/reducer";
import logger from "../../utils/logger";
import { useTranslation } from "../../hooks/useLocalization";
import useBots from "../../hooks/useBots";
import {
  Link,
  Link2,
  FileText,
  Type,
} from "react-feather";

import useMessage from "../../hooks/useMessage";

import Thread from "../../utils/thread";

const SOURCE_TYPES = {
  TEXT: {
    title: "Text", description: "Add Text source", icon: <Type style={{ transform: 'scale(1.2)', marginRight: '.2em' }} />, value: 0, disabled: false
  },
  LINK: {
    title: "Link", description: "Add Link source", icon: <Link style={{ transform: 'scale(1.2)', marginRight: '.2em' }} />, value: 1, disabled: false
  },
  SITEMAP: {
    title: "Sitemap", description: "Add sources from Site", icon: <Link2 style={{ transform: 'scale(1.2)', marginRight: '.2em' }} />, value: 2, disabled: false
  },
  FILE: {
    title: "File", description: "Add sources from File", icon: <FileText style={{ transform: 'scale(1.2)', marginRight: '.2em' }} />, value: 3, disabled: false
  },
  getTitle: (name) =>
    (name === SOURCE_TYPES.TEXT.value && SOURCE_TYPES.TEXT.title) ||
    (name === SOURCE_TYPES.LINK.value && SOURCE_TYPES.LINK.title) ||
    (name === SOURCE_TYPES.SITEMAP.value && SOURCE_TYPES.SITEMAP.title) ||
    (name === SOURCE_TYPES.FILE.value && SOURCE_TYPES.FILE.title) || 'Unknown',
  getTypes: () => [SOURCE_TYPES.TEXT, SOURCE_TYPES.FILE, SOURCE_TYPES.LINK, SOURCE_TYPES.SITEMAP,]
};

const SOURCE_STATUS = {
  RECEIVED: -1,
  SUCCESS: 0,
  PROCESS: 1,
  ERROR: 2,
  PARSING: 3,
  getTitle: (name) =>
    (name === SOURCE_STATUS.RECEIVED && "Received") ||
    (name === SOURCE_STATUS.SUCCESS && "Success") ||
    (name === SOURCE_STATUS.PROCESS && "Process") ||
    (name === SOURCE_STATUS.ERROR && "Error") ||
    (name === SOURCE_STATUS.PARSING && "Parsing") ||
    'Unknown',
}

const COLUMNS = {
  ORDER: {
    Header: "Order",
  },
  CHECK: {
    Header: "Check",
  },
  TITLE: {
    Header: 'Tag',
    accessor: "title",
  },
  DESCRIPTION: {
    Header: 'Description',
    accessor: "data.text",
  },
  TYPE: {
    Header: 'Type',
    accessor: "type",
  },
  STATUS: {
    Header: 'Status',
    accessor: "status",
  },
  CREATE_AT: {
    Header: 'Create At',
    accessor: "createAt",
  },
  UPDATE_AT: {
    Header: 'Update At',
    accessor: "updateAt",
  },
  ACTIONS: {
    Header: "Actions",
    accessor: 'id'
  },
}

const SourcesContext = React.createContext(CMD.initialState);
const MainThread = new Thread(4000);

function SourcesProvider({ children }) {
  const { t } = useTranslation();
  const { activeBot } = useBots()
  const { showMessage } = useMessage();
  const { isInitialized, user, secureAPIRequest } = useAuth();
  const [state, dispatch] = useReducer(CMD.SourcesReducer, CMD.initialState);
  const tableColumns = [COLUMNS.ORDER, COLUMNS.TITLE, COLUMNS.DESCRIPTION, COLUMNS.TYPE, COLUMNS.STATUS, COLUMNS.UPDATE_AT, COLUMNS.ACTIONS]
  // Parse respose
  // ------------------------
  const parseResponse = async (url, response) => {

    const { data } = response;
    if (!data) {
      throw new Error(t("No data after loading"));
    }

    const { sources: responseSources, debug } = data;
    if (!Array.isArray(responseSources)) {
      throw new Error(t("Unsupported response data format"));
    }

    let sources;
    const parsedSources = responseSources
      .sort(({ title: a }, { title: b }) => (b - a));

    switch (url) {
      case api.SOURCE.FETCH: case api.SOURCE.UPDATE: case api.SOURCE.CREATE: {
        sources = parsedSources;
        return { sources, debug };
      }

      default:
        return null;
    }
  };

  // Restore Session
  // ------------------------
  useEffect(() => {
    (async () => {

      if (!(isInitialized && user && activeBot)) {
        // waiting for auth
        return;
      }

      try {
        const { sources } = await fetchSources();
        dispatch({
          type: CMD.INITIALIZE,
          payload: {
            sources,
            tableColumns,
            loadingError: null,
          },
        });

      } catch (e) {
        logger.error(e);
        dispatch({
          type: CMD.INITIALIZE,
          payload: {
            sources: [],
            tableColumns,
            loadingError: e,
          },
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, user, activeBot]);


  const hasFetch = (sources) => {
    let res = sources.find((source) => (
      !(source.status === SOURCE_STATUS.SUCCESS
        || source.status === SOURCE_STATUS.ERROR)
    ))
    return (res !== undefined)
  }

  // Thread tasks
  // ------------------------
  useEffect(() => {
    if (!(isInitialized && user)) {
      // waiting for auth
      return;
    }
    MainThread.handler = isInitialized
      ? async () => {
        try {
          if (state.sources.length > 0 && hasFetch(state.sources)) {
            const { sources } = await fetchSources();
            let tokens = 0

            sources.forEach((item) => {
              let source = state.sources.find(source => source.id === item.id)
              if (item.status === SOURCE_STATUS.SUCCESS && source.status !== SOURCE_STATUS.SUCCESS) {
                const { length } = item
                tokens += length
              }
            })
            dispatch({
              type: CMD.INITIALIZE,
              payload: {
                sources,
                tableColumns,
                loadingError: null,
              },
            });

            if (tokens > 0) {
              showMessage({ save: t("tokens have been used:") + tokens })
            }
          }

        } catch (e) {
          //logger.error(e);
        }
      }
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, state, activeBot]);

  // Rest
  // ------------------------
  const fetchSources = async () => {
    const { uid } = user;
    const { id: bid } = activeBot;
    const response = await secureAPIRequest(api.SOURCE.FETCH, { bot: { id: bid }, user: { uid: uid } });
    const { sources } = await parseResponse(api.SOURCE.FETCH, response);
    return { sources };
  };

  const deleteSource = async (id) => {
    const { uid } = user;
    const { id: bid } = activeBot;
    const response = await secureAPIRequest(api.SOURCE.UPDATE, { bot: { id: bid }, user: { uid: uid }, sources: [{ id: id, delete: true }] });
    const parsedResponse = await parseResponse(api.SOURCE.UPDATE, response);
    const { sources, debug } = parsedResponse;
    const { logs: loadingError } = debug;
    dispatch({
      type: CMD.DELETE,
      payload: {
        sources: [{ id: id }],
        tableColumns,
        loadingError,
      },
    });
    return { sources };
  }

  const createSource = async (source) => {
    const { uid } = user;
    const { id: bid } = activeBot;
    source.timestamp = Date.now()
    let request = { 'bot': { id: bid }, 'user': { uid: uid }, 'sources': [{ ...source }] };
    const response = await secureAPIRequest(api.SOURCE.CREATE, request);
    const parsedResponse = await parseResponse(api.SOURCE.CREATE, response);
    const { sources, debug } = parsedResponse;
    const { logs: loadingError } = debug;
    dispatch({
      type: CMD.APPEND,
      payload: {
        sources: sources,
        tableColumns,
        loadingError,
      },
    });
    return { sources, debug };
  }
  const createSources = async (source_array) => {
    const { uid } = user;
    const { id: bid } = activeBot;
    for (let i = 0; i < source_array.length; i++)
      source_array[i].timestamp = Date.now()
    let request = { 'bot': { id: bid }, 'user': { uid: uid }, 'sources': source_array };
    const response = await secureAPIRequest(api.SOURCE.CREATE, request);
    const parsedResponse = await parseResponse(api.SOURCE.CREATE, response);
    const { sources, debug } = parsedResponse;
    const { logs: loadingError } = debug;
    dispatch({
      type: CMD.APPEND,
      payload: {
        sources: sources,
        tableColumns,
        loadingError,
      },
    });
    return { sources, debug };
  }

  const updateEmbeddings = async (id) => {
    let source = state.sources.find(source => source.id === id)
    if (source) {
      source.status = -1;
      const { sources } = await updateSource(source)
      return { sources };
    }
  }

  const updateSource = async (source) => {
    const { uid } = user;
    const { id: bid } = activeBot;
    const response = await secureAPIRequest(api.SOURCE.UPDATE, { bot: { id: bid }, user: { uid: uid }, sources: [{ ...source }] });
    const parsedResponse = await parseResponse(api.SOURCE.UPDATE, response);
    const { sources, debug } = parsedResponse;
    const { logs: loadingError } = debug;
    dispatch({
      type: CMD.UPDATE,
      payload: {
        sources: sources,
        tableColumns,
        loadingError,
      },
    });

    return { sources };
  }

  const getSitemap = async (link) => {
    const response = await secureAPIRequest(api.PARSING.SITE, { link: link }, false);
    const { links, error } = (response) ? response.data : { error: t("Connection error") }
    return { links, error }
  }

  return (
    <SourcesContext.Provider
      value={{
        ...state,
        tableColumns,
        fetchSources,
        createSource,
        createSources,
        updateSource,
        deleteSource,
        updateEmbeddings,
        getSitemap,
      }}
    >
      {children}
    </SourcesContext.Provider>
  );
}

export {
  SourcesProvider,
  SourcesContext,
  SOURCE_TYPES,
  SOURCE_STATUS
};
