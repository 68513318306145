import { Badge } from "react-bootstrap";

const FileBadge = ({ bg, className, pill, text }) => (
  <Badge
    bg={bg}
    className={className}
    pill={pill}
    style={{ pointerEvents: "none" }}
  >
    {text}
  </Badge>
);

export default FileBadge;
