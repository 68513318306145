import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import useBots from "../../hooks/useBots";
import useLocationContext from "../../hooks/useLocationContext";
import Wordpress from "../../modals/Wordpress";

function BotsGuard({ path, redirect, children }) {
  const { isInitialized, isAuthenticated } = useAuth();
  const {
    isInitialized: isInitializedBots,
    activeBot,
    accessBotLevels,
  } = useBots();
  const navigate = useNavigate();
  const location = useLocation();
  const [paramsParsed, setParamsParsed] = useState();
  const [showWordpress, setShowWordpress] = useState();
  const { getSearchParam, deleteSearchParam } = useLocationContext();

  useEffect(() => {
    const { pathname } = location;
    if (isInitialized && isAuthenticated && isInitializedBots) {
      if (!paramsParsed) {
        setParamsParsed(true);
        const [from, plan] = getSearchParam(["from", "plan"]);
        if (from) {
          setShowWordpress(from?.toLowerCase() === "wp");
        }
        if (plan) {
          deleteSearchParam("plan");
          return navigate(`/account/pricing#${plan}`);
        }
      }

      if (pathname.indexOf(`/${path}`) === 0) {
        const levels = accessBotLevels(activeBot);
        const subroute = pathname
          .substr(pathname.indexOf(path) + path.length)
          .replace(/\//g, "");
        if (
          !(typeof activeBot?.id === "string" && levels[subroute]) &&
          redirect
        ) {
          return navigate(
            redirect.startsWith("/") ? redirect : `/${path}/${redirect}`,
            { replace: true }
          );
        }
      }
    }
  }, [
    path,
    redirect,
    isInitialized,
    isAuthenticated,
    isInitializedBots,
    accessBotLevels,
    location,
    activeBot,
    navigate,
    paramsParsed,
    setParamsParsed,
    getSearchParam,
    deleteSearchParam,
  ]);

  return (
    <React.Fragment>
      {children}
      <Wordpress
        open={showWordpress}
        setOpen={(value) => setShowWordpress(value)}
      />
    </React.Fragment>
  );
}

export default BotsGuard;
