import React from "react";
import { Modal } from "react-bootstrap";

const ZoomVideo = ({ children, splash }) => {

  const ModalVideo = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          {children}
        </Modal.Body>
      </Modal>
    )
  }

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      {splash ? (
        <>
          <div className="zoom-image mb-2 c-pointer" onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
            <img alt="video" src={splash} className="m-3 ms-0 mw-100" />
          </div>
          <ModalVideo show={modalShow} onHide={() => setModalShow(false)} />
        </>
      ) : (
        <div className="d-flex justify-content-center mb-4">
          {children}
        </div>
      )}
    </>
  );
}

export default ZoomVideo;
