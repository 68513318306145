import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import ForgotPassword from "../../components/auth/ForgotPassword";
import { Link } from "react-router-dom";
import { useTranslation } from "../../hooks/useLocalization";

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  return (
    <React.Fragment>
      <Helmet title={t("Reset Password")} />
      <div className="text-center mt-4">
        {!submitted && (
          <h1 className="h2">{t("Enter your email to reset your password")}</h1>
        )}
        <p className="lead">
          {/* Need an account first?
        <Link to="/auth/sign-up"> Sign up here</Link> */}
          {t("Remember your password?")}
          <Link to="/auth"> {t("Login here")}</Link>
        </p>
      </div>

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <ForgotPassword onSubmit={setSubmitted} />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
