import { Button, Col, Table } from "react-bootstrap";
import { useTranslation } from "../../../../../hooks/useLocalization";

const LinkEmpty = ({ text }) => {
  const { t } = useTranslation();
  return (
    <div className="text-muted">
      <i>
        <span className="lead">{t("List is empty")}</span>
        <br />
        <span>({text})</span>
      </i>
    </div>
  );
};

const LinkOpen = ({ name, url }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex">
      <Col>
        <span className="lead">{name}</span>
        <br />
        <span>{url}</span>
      </Col>
      <Col md="auto" className="align-self-center">
        <Button size="lg" onClick={() => window.open(url, "_blank")}>
          {t("Open")}
        </Button>
      </Col>
    </div>
  );
};

const TBodyItem = ({ element, error }) => {
  const className = error ? "border-danger border-2" : "";
  return (
    <tr className={className}>
      <td className={className}>{element}</td>
    </tr>
  );
};

const ChannelLinks = ({ links, emptyMessage }) => {
  return (
    <Table className="w-100" bordered>
      <tbody>
        {links?.length ? (
          links.map((link, i) => (
            <TBodyItem
              key={i}
              error={link?.error}
              element={<LinkOpen {...link} />}
            />
          ))
        ) : (
          <TBodyItem element={<LinkEmpty text={emptyMessage} />} />
        )}
      </tbody>
    </Table>
  );
};

export default ChannelLinks;
