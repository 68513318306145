import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { WordpressConfigure } from "../components/bots/channels/bodies/Wordpress";
import Scrollbar from "../components/Scrollbar";
import { useTranslation } from "../hooks/useLocalization";
import useLocationContext from "../hooks/useLocationContext";

import useAnalytics from "../hooks/useAnalytics";

const WordpressModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const { deleteSearchParam } = useLocationContext();
  const { CONSOLE_EVENTS } = useAnalytics();

  const onClose = async () => {
    await CONSOLE_EVENTS.EV_Wordpress.send({ action: 'close' });
    deleteSearchParam("from");
    setOpen(false);
  };
  // const [copyBridge, setOnCopy] = useState({ onSubmit: null });
  return (
    <Modal show={open} onHide={onClose} size="lg" enforceFocus={false}>
      <Helmet title={t("Wordpress")} />
      <Modal.Header closeButton>{t("Connect Wordpress")}</Modal.Header>
      <div className="modal-body-container ps-dark">
        <Scrollbar>
          <Modal.Body className="m-4">
            <WordpressConfigure
            // setOnCopy={(onSubmit) => setOnCopy({ onSubmit })}
            />
          </Modal.Body>
        </Scrollbar>
      </div>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}> {t("Close")}
        </Button>
        {/* <Button variant="primary"
          onClick={() => {
            copyBridge.onSubmit();
            onClose();
          }}
        > {t("Copy & Close")}
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default WordpressModal;
