import axios from "axios";
import logger from "../utils/logger";
import socket from "./socket";

// Provider
// ------------------------
class provider {
  static #sockets = {};

  // Http POST request
  static async httpPOST(server, method, data = {}, headers = {}, options = {}) {
    if (!server) {
      logger.warn(`[axios] Invalid server (${server})`);
      return;
    }

    if (!method) {
      logger.warn(`[axios] Invalid method (${method})`);
      return;
    }

    let { timeout } = options || {};
    if (!(timeout > 0)) {
      timeout = 15000;
    }

    logger.net(`[axios] ${method} (${timeout})`);
    const url = `${provider.httpURL(server)}${method}/`;
    return await axios.post(url, data, { headers, timeout });
  }

  // Socket send data
  static async socketSend(id, method, data = {}, options = {}) {
    return new Promise((resolve) => {
      const socketIns = provider.#sockets[id];
      if (!socketIns) {
        logger.warn(`[socket] Invalid socket-id (${id})`);
        return;
      }

      if (!method) {
        logger.warn(`[socket] ${method} (Invalid method)`);
        return;
      }

      let { timeout } = options || {};
      if (!(timeout > 0)) {
        timeout = 5000;
      }

      const task = socketIns.send({
        data: { ...data, request: method },
        callback: (data) => resolve({ data }),
        timeout,
      });

      if (task) {
        logger.net(
          `%c[socket-${id}] [task/${task.id}] ${method} (${timeout})`,
          "color: blue"
        );
      }
    });
  }

  static createSocket(server) {
    if (!server) {
      logger.warn(`[socket] Invalid server (${server})`);
      return -1;
    }

    const url = provider.socketURL(server);
    const socketIns = new socket(url);
    const { id } = socketIns;
    provider.#sockets[id] = socketIns;
    logger.net(`[socket-${id}] ${url}`);
    return id;
  }

  static enableSocket(id, enabled, handlers) {
    const socketIns = provider.#sockets[id];
    if (!socketIns) {
      return;
    }

    if (enabled) {
      socketIns.connect({ handlers });
    } else {
      socketIns.clean();
      socketIns.disconnect({ handlers });
    }
  }

  static isSocketConnecting(id) {
    const socketIns = provider.#sockets[id];
    return socketIns?.isConnecting;
  }

  static isSocketConnected(id) {
    const socketIns = provider.#sockets[id];
    return socketIns?.isConnected;
  }

  static httpURL(value) {
    if (typeof value !== "string") {
      return value;
    }
    const { protocol } = window.location || {};
    if (!(value.startsWith("http://") || value.startsWith("https://"))) {
      value = `${protocol}//${value}`;
    }
    if (!value.endsWith("/")) {
      value = `${value}/`;
    }
    return value;
  }

  static socketURL(value) {
    if (typeof value !== "string") {
      return value;
    }
    const { protocol } = window.location || {};
    if (!(value.startsWith("ws://") || value.startsWith("wss://"))) {
      value = `${protocol === "https:" ? "wss:" : "ws:"}//${value}`;
    }
    if (!value.endsWith("/")) {
      value = `${value}/`;
    }
    return value;
  }
}

export default provider;
