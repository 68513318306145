import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { store } from "./redux/store";

import "./localization";
import routes from "./routes";

import { AuthProvider } from "./contexts/QDContext";
import { LocationProvider } from "./contexts/LocationContext";
import { UIProvider } from "./contexts/UIContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { ContextMenuProvider } from "./contexts/ContextMenuContext";
import { NotificationProvider } from "./contexts/NotificationContext";
import { OffcanvasProvider } from "./contexts/OffcanvasContext";
import ChartJsDefaults from "./utils/ChartJsDefaults";
import { useTranslation } from "./hooks/useLocalization";
import { TutorialProvider } from "./contexts/TutorialContext";
import { config } from "./config";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = () => {
  const { t } = useTranslation();
  const content = useRoutes(routes);
  const google_clientId = config.social.google.clientId;
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`%s - ${t("Qudata Console")}`}
        defaultTitle={t("Qudata Console")}
      />
      <Provider store={store}>
        <GoogleOAuthProvider clientId={google_clientId}>
          <LocationProvider>
            <ThemeProvider>
              <ContextMenuProvider>
                <UIProvider>
                  <SidebarProvider>
                    <LayoutProvider>
                      <ChartJsDefaults />
                      <AuthProvider>
                        <TutorialProvider>
                          <OffcanvasProvider>
                            <NotificationProvider>
                              {content}
                            </NotificationProvider>
                          </OffcanvasProvider>
                        </TutorialProvider>
                      </AuthProvider>
                    </LayoutProvider>
                  </SidebarProvider>
                </UIProvider>
              </ContextMenuProvider>
            </ThemeProvider>
          </LocationProvider>
        </GoogleOAuthProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
