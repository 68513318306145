import React from "react";
import { BOT_MODULE } from "../../../../contexts/bots/BotsContext";
import { useTranslation } from "../../../../hooks/useLocalization";
import { ChatGPTIcon } from "../bodies/ChatGPT";
import ChannelCard from "./ChannelCard";

const ChannelChatGPTCard = (props) => {
  const { t } = useTranslation();
  const { channelName, channel, handlers } = props;
  const { url, validated } = channel;
  return (
    <ChannelCard
      {...props}
      name={t(BOT_MODULE.title(channelName))}
      icon={ChatGPTIcon}
      handlers={{
        ...handlers,
        onLink: url && validated ? handlers.onLink : null,
      }}
    />
  );
};

export default ChannelChatGPTCard;
