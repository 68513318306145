import { Button } from "react-bootstrap";
import FileBadge from "./FileBadge";
import { REGEXP_CSS, REGEXP_IMG, REGEXP_YML } from "../../../utils/files";
import {
  BOT_FILE_OPTION,
  BOT_FILE_STATUS,
} from "../../../contexts/bots/BotsContext";

const getColumnClassName = (tableColumns, column) => {
  const breakpoint = tableColumns.find(
    ({ accessor }) => accessor === column.id
  )?.breakpoint;
  return breakpoint ? `d-none d-${breakpoint}-table-cell` : "";
};

const getColumnStyle = (tableColumns, column) => {
  return {
    width: column.id === "path" ? "50%" : void 0,
  };
};

const getRowStyle = (row) => {
  return { cursor: "pointer" };
};

const getOptionVariant = (option) =>
  (option === BOT_FILE_OPTION.MAIN && "success") ||
  (option === BOT_FILE_OPTION.ICON && "warning") ||
  (option === BOT_FILE_OPTION.STYLE && "info");

const getStatusVariant = (status) =>
  (status === BOT_FILE_STATUS.CREATE && "success") ||
  (status === BOT_FILE_STATUS.UPDATE && "primary") ||
  (status === BOT_FILE_STATUS.REMOVE && "danger");

// const getStatusColor = (status) =>
//   (status === BOT_FILE_STATUS.CREATE && `var(--bs-${getStatusVariant(status)})`) ||
//   (status === BOT_FILE_STATUS.UPDATE && `var(--bs-${getStatusVariant(status)})`) ||
//   (status === BOT_FILE_STATUS.REMOVE && `var(--bs-${getStatusVariant(status)})`);

const getOptionByFile = (path) => {
  return (
    (REGEXP_CSS.test(path) && BOT_FILE_OPTION.STYLE) ||
    (REGEXP_YML.test(path) && BOT_FILE_OPTION.MAIN) ||
    (REGEXP_IMG.test(path) && BOT_FILE_OPTION.ICON)
  );
};

const getOptionContextMenuProps = ({ cell, onClick }) => ({
  header: cell.row.values.path,
  items: [
    {
      as: (
        <Button
          variant={`outline-${getOptionVariant(
            getOptionByFile(cell.row.values.path)
          )} w-100`}
        >
          <span className="me-2">Set as</span>
          {FileBadge(
            getBadgeRendererProps({
              value: getOptionByFile(cell.row.values.path),
              column: { id: "option" },
            })
          )}
        </Button>
      ),
      onClick,
    },
  ],
});

const isColumnRenderedAsBadge = (column) =>
  column.id === "option" || column.id === "status";

const getBadgeRendererProps = (cell) => {
  const props =
    cell.column.id === "option"
      ? {
          bg: getOptionVariant(cell.value),
          pill: true,
        }
      : {
          bg: getStatusVariant(cell.value),
          className: `badge-${getStatusVariant(cell.value)}`,
        };
  return { text: cell.value, ...props };
};

const getTableData = ({ localFilesStatus }) => {
  return localFilesStatus
    ? localFilesStatus
        .sort(
          (a, b) =>
            REGEXP_YML.test(b.source?.fullPath) -
            REGEXP_YML.test(a.source?.fullPath)
        )
        .map(({ source: file, size, mtime, option, status }) => ({
          option,
          path: file?.fullPath,
          size: file?.isDirectory
            ? "directory"
            : typeof size === "number"
            ? size
            : "-",
          date: mtime
            ? mtime.toLocaleString("en-US", {
                time: "short",
                date: "short",
              })
            : "-",
          status,
        }))
    : [];
};

export {
  getColumnClassName,
  getColumnStyle,
  getRowStyle,
  getOptionVariant,
  getStatusVariant,
  getOptionByFile,
  getOptionContextMenuProps,
  isColumnRenderedAsBadge,
  getBadgeRendererProps,
  getTableData,
};
