import React, { useCallback, useMemo } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DollarSign } from "react-feather";
import { BillingPlan } from "../../contexts/billing/BillingContext";
import useBilling from "../../hooks/useBilling";
import { useTranslation } from "../../hooks/useLocalization";
import useMessage from "../../hooks/useMessage";
import logger from "../../utils/logger";
import { getAmountRendererProps, getPeriodRendererProps, getStatusRendererProps, PaymentAmount, PaymentPeriod, PaymentStatus } from "../payments/utils";
import ColumnSortingTable from "../tables/ColumnSortingTable";

const ShowPayments = ({ account: acc }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [payments, setPayments] = React.useState([]);
  const { t, tt } = useTranslation();
  const { showMessageWithDebug } = useMessage();

  const {
    fetchPayments,
    getProduct,
    getSubscriptionByProduct,
  } = useBilling();

  const onClickHandler = async () => {
    try {
      const history = await fetchPayments(acc.uid)
      setPayments(history.payments)
      setModalShow(!modalShow);
    } catch (error) {
      logger.error(error);
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    }
  }

  const ModalPayments = (props) => {
    const headers = useMemo(() => ["period", "amount", "status"], []);

    const getPaymentCurrency = useCallback(
      (payment) =>
        (payment.products || [])
          .map((segment) => getProduct(segment))
          .reduce(
            (currency, product) => currency || product?.price?.currency,
            null
          ),
      []
    );

    const calcPaymentAmount = useCallback(
      (payment) =>
        (payment.products || [])
          .map((segment) => getProduct(segment))
          .reduce((amount, product) => amount + (product?.price?.value || 0), 0),
      []
    );

    const getProductDestination = useCallback(
      (product) => {
        const subscription = getSubscriptionByProduct(product?.productID);
        return subscription
          ? tt("X subscription", t(BillingPlan.title(subscription.id)))
          : t("Third-party services");
      },
      []
    );

    const getPaymentAmountDetails = useCallback(
      (payment) =>
        (payment.products || [])
          .map((segment) => getProduct(segment))
          .map(
            (product) => ({
              name: getProductDestination(product),
              ...product?.price,
            }),
            0
          ) || 0,
      [getProductDestination]
    );

    const MIN_ROWS = 5;
    const rows = useMemo(
      () =>
        payments
          .map((payment) => {
            const { time, endTime, status } = payment;
            return {
              period: { time, endTime },
              amount: {
                value: calcPaymentAmount(payment),
                currency: getPaymentCurrency(payment),
                details: getPaymentAmountDetails(payment),
              },
              status: { value: status },
            };
          })
          .concat(
            new Array(Math.max(MIN_ROWS - payments.length, 0)).fill(
              Object.assign({}, ...headers.map((key) => ({ [key]: "" })))
            )
          ),
      [
        headers,
        getPaymentCurrency,
        calcPaymentAmount,
        getPaymentAmountDetails,
      ]
    );

    const tableColumns = headers.map((name) => ({
      Header: t(
        typeof name === "string" ? name[0].toUpperCase() + name.substr(1) : name
      ),
      accessor: name,
    }));

    const tableData = rows;

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-flex flex-column justify-content-center">
          <button className="btn-close" onClick={() => setModalShow(!modalShow)}></button>
          <h3>Payments history</h3>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-around">
          <ColumnSortingTable
            columns={tableColumns}
            data={tableData}
            getCellRenderer={(cell) =>
              (cell.column.id === "period" && PaymentPeriod) ||
              (cell.column.id === "amount" && PaymentAmount) ||
              (cell.column.id === "status" && PaymentStatus)
            }
            getCellRendererProps={(cell) =>
              (cell.column.id === "period" &&
                getPeriodRendererProps(cell)) ||
              (cell.column.id === "amount" &&
                getAmountRendererProps(cell)) ||
              (cell.column.id === "status" && getStatusRendererProps(cell))
            }
          />
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>{t("Payments")}</Tooltip>}>
        <Button variant="light" className="m-1 p-1" onClick={onClickHandler}>
          <DollarSign />
        </Button>
      </OverlayTrigger>
      <ModalPayments show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default ShowPayments;