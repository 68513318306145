import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "react-bootstrap";
import { useTranslation } from "../../hooks/useLocalization";
import useMessage from "../../hooks/useMessage";

function SignXDeleteConfirm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deleteAccount } = useAuth();
  const { showMessageWithDebug } = useMessage();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const verify = async () => {
      try {
        await deleteAccount();
        setTimeout(() => navigate("/auth"), 500);
      } catch (error) {
        const { message = t("Something went wrong"), errorMessage } = error;
        setErrors({ submit: message });
        showMessageWithDebug({ error: errorMessage });
      }
    };
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {errors.submit && (
        <Alert variant="danger">
          <div className="alert-message d-flex align-items-center">
            <FontAwesomeIcon
              icon={faTimesCircle}
              size="3x"
              className="text-danger flex-shrink-0 me-3"
            />
            <div>{errors.submit}</div>
          </div>
        </Alert>
      )}
      <div className="submitting-icon">
        {!errors.submit && (
          <FontAwesomeIcon
            spin
            icon={faTrashAlt}
            size="6x"
            className="text-primary"
          />
        )}
      </div>
    </>
  );
}

export default SignXDeleteConfirm;
