import { useEffect, useState } from "react";
import Thread from "../../utils/thread";
import NavbarLanguages from "../navbar/NavbarLanguages";

const MainThread = new Thread(100);

const AuthLanguages = () => {
  const [style, setStyle] = useState({ display: "none" });
  useEffect(() => {
    MainThread.handler = async () =>
      setStyle({ display: "block", top: "calc(5px + 0.2rem)", right: "65px" });
    return () => (MainThread.handler = null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setStyle]);
  return <NavbarLanguages className="position-absolute p-2" style={style} />;
};

export default AuthLanguages;
