import { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useTranslation } from "../../../../../hooks/useLocalization";

const ChannelInputState = ({ channel, fields, isSubmitting }) => {
  const { t } = useTranslation();
  const { validated } = channel || {};
  const someNotFilled = fields?.some(
    (field) =>
      !(
        channel &&
        channel[field] &&
        (!Array.isArray(channel[field]) || channel[field].length)
      )
  );
  const [hidden, setHidden] = useState(false);
  useEffect(() => setHidden(false), [isSubmitting, setHidden]);
  return !(validated || isSubmitting || someNotFilled) ? (
    <Alert hidden={hidden} variant="danger" className="mt-4">
      <div className="alert-message">
        <h4 className="alert-heading">{t("Validation failed")}</h4>
        <p>{t("Please make sure you copy the correct values")}</p>
        <hr />
        <Button
          variant="light"
          className="me-1"
          onClick={() => setHidden(true)}
        >
          {t("Okay")}
        </Button>
      </div>
    </Alert>
  ) : null;
};

export default ChannelInputState;
