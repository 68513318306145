const isExperimental = () => {
  const params = new URLSearchParams(window.location.search);
  const experimental = !!params.get("experimental");
  // NOTE: В данный момент только включение без возможности отключить
  // return experimental;
  return (
    window.Qudata_experimental || (window.Qudata_experimental = experimental)
  );
};

const atDevhost = () => {
  // return false;
  const { host } = window.location;
  return (
    host === "localhost:3000" ||
    host === "dev.console.qudata.com" ||
    host === "beta.console.qudata.com" ||
    isExperimental()
  );
};

const atIFrame = () => {
  return window.parent?.location !== window.location;
};

export { atDevhost, isExperimental, atIFrame };
