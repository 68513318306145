import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import ResetPassword from "../../components/auth/ResetPassword";
import { Link } from "react-router-dom";
import { useTranslation } from "../../hooks/useLocalization";

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet title={t("Reset Password")} />
      <div className="text-center mt-4">
        <h1 className="h2">{t("Reset your password")}</h1>
        <p className="lead">
          {t("Remember your password?")}
          <Link to="/auth"> {t("Login here")}</Link>
        </p>
      </div>

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <ResetPassword />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
