/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from "react";
import { useTranslation } from "../../../hooks/useLocalization";
import { Languages } from "../../../languages/languages";
import NavbarLanguages from "../../navbar/NavbarLanguages";

const NavListItemLanguage = ({ depth = 0, icon: Icon }) => {
  const { i18n, t } = useTranslation();
  const selectedLanguage = Languages[i18n.language] || Languages.en;
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(
    (e) => {
      e.preventDefault();
      setOpen(!open);
    },
    [setOpen, open]
  );

  return (
    <li className={`position-relative sidebar-item ${open ? "active" : ""}`}>
      <NavbarLanguages className="position-absolute top-0 end-0" hideToggle align="start" open={open} setOpen={setOpen} />
      <a rel="noreferrer" href="/" activeclassname="active"
        className={`sidebar-link d-flex align-items-center ${open ? "" : "collapsed"}`}
        data-bs-toggle="collapse" aria-expanded={open ? "true" : "false"}
        onMouseUp={(event) => event.stopPropagation()}
        onClick={handleToggle}
      >
        {Icon && <Icon className="feather align-middle" />}{" "}
        <span className="align-middle d-inline-block text-truncate" style={{ maxWidth: "150px" }}
          depth={depth} >
          {t(selectedLanguage?.name)}
        </span>
      </a>
    </li>
  );
};

export default NavListItemLanguage;
