import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import { useTranslation } from "../../hooks/useLocalization";

function ResetPassword() {
  const { t, tt } = useTranslation();
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const emailRef = useRef();
  useEffect(() => emailRef.current?.focus(), [emailRef]);
  return (
    <Formik
      enableReinitialize
      initialValues={{ password: "", confirmPassword: "", submit: false }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(6, tt("Must be at least N characters", 6))
          .max(255)
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,255}$/,
            t("Password must contain")
          ),
        // .required("Password is required"),
        confirmPassword: Yup.string()
          // .required("Password is required")
          .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              t("Both password need to be the same")
            ),
          }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const isAuthenticated = await resetPassword(values.password);
          navigate(isAuthenticated ? "/dashboard" : "/auth");
        } catch (error) {
          const { message = t("Something went wrong") } = error;
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            {/* <Form.Label htmlFor="password">Password</Form.Label> */}
            <Form.Control
              required
              id="password"
              type="password"
              name="password"
              autoComplete="new-password"
              placeholder={t("Password")}
              ref={emailRef}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            {/* <Form.Label htmlFor="confirm-password">Confirm password</Form.Label> */}
            <Form.Control
              required
              id="confirm-password"
              type="password"
              name="confirmPassword"
              autoComplete="new-password"
              placeholder={t("Confirm password")}
              isInvalid={Boolean(
                touched.confirmPassword && errors.confirmPassword
              )}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.confirmPassword && (
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="text-center mt-3 w-100 d-grid">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {t("Reset Password")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ResetPassword;
