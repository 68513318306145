import { useState } from "react";
import dropbox from "../../../../assets/img/brands/dropbox.svg";
import screen_1 from "../../../../assets/img/screenshots/channels/screen_dropbox_1.png";
import screen_2 from "../../../../assets/img/screenshots/channels/screen_dropbox_2.png";
// import screen_3 from "../../../../assets/img/screenshots/channels/screen_dropbox_3.png";
import screen_4 from "../../../../assets/img/screenshots/channels/screen_dropbox_4.png";
import screen_3_1 from "../../../../assets/img/screenshots/channels/screen_dropbox_3_1.png";
import screen_3_2 from "../../../../assets/img/screenshots/channels/screen_dropbox_3_2.png";
import screen_5_1 from "../../../../assets/img/screenshots/channels/screen_dropbox_5_1.png";
import screen_5_2 from "../../../../assets/img/screenshots/channels/screen_dropbox_5_2.png";
import screen_5_3 from "../../../../assets/img/screenshots/channels/screen_dropbox_5_3.png";
import { BOT_CHANNEL } from "../../../../contexts/bots/BotsContext";
import useBots from "../../../../hooks/useBots";
import { useTranslation } from "../../../../hooks/useLocalization";
import useMessage from "../../../../hooks/useMessage";
import { Accordion } from "../../../accordion/Accordion";
import ZoomImage from "../../../ZoomImage";
import ChannelInput from "./submit/ChannelInput";
import ChannelInputState from "./submit/ChannelInputState";
import ChannelState from "./submit/ChannelState";
import { Button } from "react-bootstrap";

const DropboxIcon = ({ className }) => (
  <img
    src={dropbox || ""}
    alt=""
    width={20}
    height={20}
    className={className}
  />
);

const DropboxLogin = () => {
  const { tt } = useTranslation();
  return (
    <>
      {tt(
        "Dropbox.Step-1-1",
        <a
          href="https://www.dropbox.com/login"
          alt="dropbox"
          target="_blank"
          rel="noreferrer"
        >
          https://www.dropbox.com/login
        </a>
      )}
    </>
  );
};

const DropboxCreateApp = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      <ul>
        <li>
          {tt(
            "Dropbox.Step-2-1",
            <a
              href="https://www.dropbox.com/developers/apps"
              alt="dropbox"
              target="_blank"
              rel="noreferrer"
            >
              https://www.dropbox.com/developers/apps
            </a>
          )}
        </li>
        <li>
          {tt("Dropbox.Step-2-2", <b>{t("Create app")}</b>)}
          <ZoomImage>
            <img alt="screen_1" src={screen_1} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
        <li>
          {tt("Dropbox.Step-2-3", <b>{t("Full Dropbox")}</b>)}
          <ZoomImage>
            <img alt="screen_2" src={screen_2} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
      </ul>
    </>
  );
};

const DropboxSetPermissions = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      <p>{tt("Dropbox.Step-3", <b>{t("Permissions")}</b>, <b>{t("Submit")}</b>)}</p>
      <div className="d-flex">
        <ZoomImage>
          <img alt="screen_2" src={screen_3_1} className="m-3 ms-0 mw-100" />
        </ZoomImage>
        <ZoomImage>
          <img alt="screen_2" src={screen_3_2} className="m-3 ms-0 mw-100" />
        </ZoomImage>
      </div>
    </>
  )
}

const DropboxSetKeys = ({ id, channel, levels }) => {
  const { t, tt } = useTranslation();
  const { updateBot } = useBots();
  const { app_key, app_secret } = channel || {};
  const [isSubmitting, setSubmitting] = useState();
  const { /*showMessage, */ showMessageWithDebug } = useMessage();

  const onSubmit = async (props) => {
    try {
      setSubmitting(true);
      await updateBot(id, {
        workflows: [
          { ...channel, name: BOT_CHANNEL.DROPBOX, ...props },
        ],
      });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setSubmitting(false);
    }
  };

  const onChange__app_key = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit({ app_key: value, app_secret });
    }
  };

  const onChange__app_secret = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit({ app_key, app_secret: value });
    }
  };

  return (
    <>
      <p>{tt("Dropbox.Step-4", <b>{t("Settings")}</b>, <b>{t("App key")}</b>, <b>{t("App secret")}</b>)}</p>
      <ZoomImage>
        <img alt="screen_2" src={screen_4} className="m-3 ms-0 mw-100" />
      </ZoomImage>
      <ChannelInput
        strict
        title={t("App key")}
        field="app_key"
        channel={channel}
        disabled={!levels.manage}
        isSubmitting={isSubmitting}
        onChange={onChange__app_key}
      />
      <ChannelInput
        strict
        title={t("App secret")}
        field="app_secret"
        channel={channel}
        disabled={!levels.manage}
        isSubmitting={isSubmitting}
        onChange={onChange__app_secret}
      />
    </>
  )
}

const DropboxAPIToken = ({ id, channel, levels }) => {
  // limits
  // OAuth 2 access token (138)
  const { t, tt } = useTranslation();
  const { app_key, app_secret } = channel || {};
  const { updateBot } = useBots();
  const [isSubmitting, setSubmitting] = useState();
  const { /*showMessage, */ showMessageWithDebug } = useMessage();
  const onSubmit = async (value) => {
    try {
      setSubmitting(true);
      await updateBot(id, {
        workflows: [{ ...channel, name: BOT_CHANNEL.DROPBOX, token: value }],
      });
      // showMessage({ save: "" });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setSubmitting(false);
    }
  };
  const onChange = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit(value);
    }
  };

  const openDropBox = () => {
    const url = `https://www.dropbox.com/oauth2/authorize?response_type=code&client_id=${app_key}&token_access_type=offline`;
    window.open(url, "_blank");
  };

  const onGetCode = () => {
    if (app_key && app_secret) openDropBox();
    else showMessageWithDebug({ error: t("Finish Step 4 to continue") });
  }

  return (
    <>
      <div className="d-flex">
        <ZoomImage>
          <img alt="screen_3" src={screen_5_1} className="m-3 ms-0 mw-100" />
        </ZoomImage>
        <ZoomImage>
          <img alt="screen_3" src={screen_5_2} className="m-3 ms-0 mw-100" />
        </ZoomImage>
        <ZoomImage>
          <img alt="screen_3" src={screen_5_3} className="m-3 ms-0 mw-100" />
        </ZoomImage>
      </div>
      <Button
        onClick={onGetCode}
        className="btn-primary">
        {t("Get code")}
      </Button>
      <p className="mt-3">{tt("Dropbox.Step-5-1", <b>{t("Access Code")}</b>)}</p>
      <ChannelInput
        strict
        title={t("Access Code")}
        field="token"
        channel={channel}
        disabled={!levels.manage}
        isSubmitting={isSubmitting}
        onChange={onChange}
      />
      <ChannelInputState
        fields={["token"]}
        channel={channel}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

const DropboxConfigure = ({ channel, levels, bot, handlers }) => {
  const { t, tt } = useTranslation();
  const { id } = bot || {};
  return (
    <>
      <Accordion defaultActiveKey="0" className="accordion-steps no-animation">
        <Accordion.Item eventKey="0" className="bg-white">
          <Accordion.Header>{tt("Step N", 1)}</Accordion.Header>
          <Accordion.Body>
            <p>{t("Dropbox.Step-1")}</p>
            <DropboxLogin />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{tt("Step N", 2)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Dropbox.Step-2")}</p>
            <DropboxCreateApp />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{tt("Step N", 3)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <DropboxSetPermissions />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{tt("Step N", 4)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <DropboxSetKeys id={id} channel={channel} levels={levels} />
            <ChannelState channel={channel} handlers={handlers} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>{tt("Step N", 5)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{tt("Dropbox.Step-5")}</p>
            <DropboxAPIToken id={id} channel={channel} levels={levels} />
            <ChannelState channel={channel} handlers={handlers} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const DropboxGetHandlers = ({ url, validated, handlers }) => {
  if (validated && url) {
    handlers = {
      ...handlers,
      onLink: () => {
        window.open(url, "_blank");
      },
    };
  }
  return handlers;
};

export { DropboxIcon, DropboxConfigure, DropboxGetHandlers };
