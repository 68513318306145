import { useCallback, useContext } from "react";
import NotyfContext from "../contexts/NotyfContext";
import { useTranslation } from "./useLocalization";
import useOffcanvas from "./useOffcanvas";

const defaultMessage = {
  save: "Your changes have been successfully saved",
  info: "Note",
  error: "Something went wrong",
};

const useMessage = () => {
  const { t } = useTranslation();
  const notyf = useContext(NotyfContext);
  const { offcanvasData, setOffcanvasData } = useOffcanvas();

  const showMessage = useCallback(
    ({ save, info, error, onClick }) => {
      let message = null;
      if (notyf) {
        if (typeof save === "string") {
          message = notyf.open({
            type: "default",
            message: t(save || defaultMessage.save),
            duration: 5000,
            ripple: true,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          });
        } else if (typeof info === "string") {
          message = notyf.open({
            type: "success",
            message: t(info || defaultMessage.info),
            duration: 15000,
            ripple: true,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          });
        } else if (typeof error === "string") {
          message = notyf.open({
            type: "danger",
            message: t(error || defaultMessage.error),
            duration: 15000,
            ripple: true,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          });
        }
        if (message && onClick) {
          message.on("click", onClick);
        }
      }

      return message;
    },
    [notyf, t]
  );

  const showMessageWithDebug = useCallback(
    ({ save, info, error, debug }) => {
      const withDebug = (message) => {
        return debug
          ? `${t(message)} ${t("Click on the message to see More Details")}.`
          : t(message);
      };
      if (debug) {
        const message = showMessage({
          save:
            typeof save === "string" && withDebug(save || defaultMessage.save),
          info:
            typeof info === "string" && withDebug(info || defaultMessage.info),
          error:
            typeof error === "string" &&
            withDebug(error || defaultMessage.error),
          onClick: () => {
            setOffcanvasData({ title: t("More details"), debug });
            notyf.dismiss(message);
          },
        });
      } else {
        showMessage({ save, info, error });
      }
    },
    [notyf, showMessage, setOffcanvasData, t]
  );

  const updateMessageDebug = useCallback(
    ({ debug, fallback }) => {
      if (offcanvasData?.debug) {
        if (debug) {
          setOffcanvasData({ title: t("More details"), debug });
        } else {
          setOffcanvasData({
            title: t("More details"),
            debug: {
              logs: fallback ? [fallback] : [],
            },
          });
        }
        return true;
      }
    },
    [offcanvasData, setOffcanvasData, t]
  );

  const dismissMessage = useCallback(
    (message) => {
      if (notyf && message) {
        try {
          notyf.dismiss(message);
        } catch (error) { }
      }
    },
    [notyf]
  );

  const dismissAllMessages = useCallback(() => {
    if (notyf) {
      try {
        notyf.dismissAll();
      } catch (error) { }
    }
  }, [notyf]);

  return {
    showMessage,
    showMessageWithDebug,
    updateMessageDebug,
    dismissMessage,
    dismissAllMessages,
  };
};

export default useMessage;
