import { Col, Row } from "react-bootstrap";
import { useTranslation } from "../../../hooks/useLocalization";
import BreadcrumbHeader from "../BreadcrumbHeader";

const Header = () => {
  const { t } = useTranslation();
  return (
    <Row className="mb-2 mb-xl-3">
      <Col xs="auto" /*className="d-none d-sm-block"*/>
        <BreadcrumbHeader title={t("Setup")} />
      </Col>
    </Row>
  );
};

export default Header;
