import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";
import { uniqueArray } from "../utils/data";
import AntiSpam from "../utils/spam";
import AdminUsersTable from "./tables/AdminUsersTable";

const antiSpam = new AntiSpam();

const AdminUsers = () => {
  const { t } = useTranslation();
  const [usersData, setUsersData] = useState([]);
  const { activityReport, isInitialized } = useAuth();

  const refresh = async () => {
    antiSpam.action(async () => {
      try {
        let users = await activityReport();
        setUsersData(users || []);
      } catch (error) {
      } finally {
      }
    });
  }

  useEffect(() => {
    if (isInitialized) refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized])

  const headers = useMemo(
    () =>
      uniqueArray(
        ["Users", "Name", "Company", "Actions"]
      ),
    []
  );

  const tableColumns = headers.map((name) => {
    let accessor;
    switch (name) {
      case "Users": accessor = "email"; break;
      case "Name": accessor = "displayName"; break;
      case "Company": accessor = "companyName"; break;
      default:
    }
    return {
      Header: t(name),
      accessor
    }
  });

  const tableData = usersData.map((val) =>
    Object.assign(
      {},
      ...Object.keys(val).map((key) => ({
        [key]:
          ["name", "bundle", "status"].indexOf(key) < 0 &&
            val[key] &&
            typeof val[key] === "object"
            ? JSON.stringify(val[key])
            : val[key],
      }))
    )
  )

  return (
    <>
      <AdminUsersTable columns={tableColumns} data={tableData} wheelPropagation={true} refresh={refresh} />
    </>
  )
}

export default AdminUsers;