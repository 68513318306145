import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Col, Collapse, Row } from "react-bootstrap";
import { ChevronDown, ChevronUp } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BillingPlan,
  PLAN_STATUS,
} from "../../contexts/billing/BillingContext";
import { CURRENCY_SYMBOLS } from "../../contexts/billing/utils";
import useMessage from "../../hooks/useMessage";
import useBilling from "../../hooks/useBilling";
import AntiSpam from "../../utils/spam";
import PlanCard from "./cards/PlanCard";
import Features from "./Features";
import useLocationContext from "../../hooks/useLocationContext";
import { useTranslation } from "../../hooks/useLocalization";
import { useLocaleDateString } from "../../utils/data";
import { Case } from "../../languages/languages";

const antiSpam = new AntiSpam();

const Pricing = () => {
  const { t, tc, tt } = useTranslation();
  const localeDateString = useLocaleDateString();
  const location = useLocation();
  const navigate = useNavigate();
  const { clearRedirect, applyRedirect } = useLocationContext();
  const planIndex = location.hash?.substr(1) | 0;
  const {
    isInitialized,
    isProbilled,
    plan,
    paymentsPending,
    hasCancelablePlan,
    getSubscriptionProduct,
    getSubscription,
    upgradePlan,
    changePlan,
    changePlanUndo,
    cancelAllPlans,
    refreshSubscriptions,
    refreshProducts,
  } = useBilling();
  const { showMessage, showMessageWithDebug } = useMessage();
  const [open, setOpen] = useState(false);
  const featuredIndex = planIndex > 0 ? planIndex : -1;

  const refresh = async () => {
    antiSpam.action(async () => {
      await refreshSubscriptions();
      await refreshProducts();
    });
  };

  useEffect(() => {
    return () => clearRedirect("billing");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitialized) refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, isProbilled]);

  const planFlags = useCallback(
    (id) => {
      const index = BillingPlan.index(id);
      const subscription = getSubscription(id);
      const { status } = subscription || {};
      const product = getSubscriptionProduct(id);
      const isFree = !id;
      const isFreeNoPlan = isFree && !plan;
      const isFreeNoCancelPlan = isFree && !hasCancelablePlan();
      const isCurrent = plan?.id === id;
      const isDowngrade = isFree || plan?.index > index;
      const isPlanPurchased = !!status;
      const isPlanCanceled = status === PLAN_STATUS.CANCELED;
      const isFeatured = featuredIndex === index && !plan;
      const isLoading = !isProbilled;
      const isNextPlan =
        !(isCurrent || isFreeNoPlan) && (isPlanPurchased || isFreeNoCancelPlan);
      const isNextPlanDetails = isNextPlan && subscription;
      const isPurchaseDisabled =
        isCurrent ||
        isFreeNoPlan ||
        isFreeNoCancelPlan ||
        isPlanPurchased ||
        isLoading;
      const isHasCoupon =
        !isPurchaseDisabled && product.coupon?.price?.value > 0;
      return {
        isFree,
        isFreeNoPlan,
        isFreeNoCancelPlan,
        isCurrent,
        isDowngrade,
        isPlanPurchased,
        isPlanCanceled,
        isFeatured,
        isLoading,
        isNextPlan,
        isNextPlanDetails,
        isHasCoupon,
        isPurchaseDisabled,
      };
    },
    [
      plan,
      featuredIndex,
      isProbilled,
      getSubscriptionProduct,
      getSubscription,
      hasCancelablePlan,
    ]
  );

  const planCommonFlags = useCallback(
    (list) => ({
      isSomeNextPlan: list.some(({ id }) => {
        const { isNextPlan } = planFlags(id);
        return isNextPlan;
      }),
      isSomeHasCoupon: list.some(({ id }) => {
        const { isHasCoupon } = planFlags(id);
        return isHasCoupon;
      }),
      isNextPlanDetails: list.find(({ id }) => {
        const { isNextPlanDetails } = planFlags(id);
        return isNextPlanDetails;
      }),
    }),
    [planFlags]
  );

  const list = useMemo(
    () => [
      {
        id: null,
        includes: [
          { text: t("Visual editor") },
          { text: t("Chatbot templates") },
          { text: tt("Communication channels", 2) },
          { text: tt("N Message Limit", tt("K", 10)) },
          { text: t("Basic analytics") },
          { text: t("ChatGPT integration"), bage: 'NEW' },
        ],
      },
      {
        id: BillingPlan.START_UP,
        includes: [
          { text: t("Visual editor") },
          { text: t("Chatbot templates") },
          { text: t("All communication channels") },
          { text: tt("N Message Limit", tt("K", 50)) },
          { text: t("Basic analytics") },
          { text: t("ChatGPT integration"), bage: 'NEW' },
          { text: t("API integrations") },
        ],
      },
      {
        id: BillingPlan.PREMIUM,
        includes: [
          { text: t("Visual editor") },
          { text: t("Chatbot templates") },
          { text: t("All communication channels") },
          { text: tt("N Message Limit", tt("K", 250)) },
          { text: t("Advanced analytics") },
          { text: t("ChatGPT integration"), bage: 'NEW' },
          { text: t("API integrations") },
        ],
      },
      {
        id: BillingPlan.ENTERPRISE,
        includes: [
          { text: t("Visual editor") },
          { text: t("Chatbot templates") },
          { text: t("All communication channels") },
          { text: tt("N Message Limit", tt("M", 1)) },
          { text: t("Advanced analytics") },
          { text: t("ChatGPT integration"), bage: 'NEW' },
          { text: t("API integrations") },
          { text: t("Voice chatbot") },
        ],
      },
    ],
    [t, tt]
  );

  const commonFlags = useMemo(
    () => planCommonFlags(list),
    [planCommonFlags, list]
  );

  const planCard = useCallback(
    (id) => {
      const {
        isFree,
        isFreeNoPlan,
        isCurrent,
        isDowngrade,
        isPlanPurchased,
        isPlanCanceled,
        isFeatured,
        isLoading,
        isNextPlan,
        isHasCoupon,
        isPurchaseDisabled,
      } = planFlags(id);
      const { isSomeNextPlan, isSomeHasCoupon } = commonFlags;
      const { time, endTime } = getSubscription(id) || {};
      const product = getSubscriptionProduct(id);
      const price = product.price?.value || (id ? "?" : 0);
      const couponPrice = (isHasCoupon && product.coupon?.price?.value) || 0;
      const currencySymbol = CURRENCY_SYMBOLS[price?.currency || "USD"];
      const extendedHeader = isSomeHasCoupon;
      const extendedBody = isSomeNextPlan || isSomeHasCoupon;
      const textWillExpire = (isCurrent || isFreeNoPlan) && isSomeNextPlan && (
        <span className="text-success">
          {tt("will expire on", localeDateString(endTime))}
        </span>
      );
      const textWillActive = isNextPlan && (
        <span>
          {tt("will be activated", localeDateString(time || plan?.endTime))}
        </span>
      );
      const textCoupon =
        couponPrice > 0 &&
        tt("Get off for the first month", `${currencySymbol}${couponPrice}`);
      return {
        pro: !!id,
        loading: isLoading,
        featured: isFeatured,
        selected: isCurrent && !isFree,
        purchased: isPlanPurchased,
        canceled: isPlanCanceled,
        title: t(BillingPlan.title(id)),
        price,
        couponPrice,
        currencySymbol,
        extendedHeader,
        extendedBody,
        tryPlan: async () => {
          try {
            if (isDowngrade) {
              if (isNextPlan) {
                await changePlanUndo();
                showMessage({ save: t("The plan change canceled") });
              } else {
                await changePlan(id);
                showMessage({ save: t("Plan change is scheduled") });
              }
            } else if (await upgradePlan(id)) {
              showMessage({
                save: t("The plan has been successfully upgraded"),
              });
              applyRedirect("billing");
            }
          } catch (error) {
            const { message = t("Something went wrong"), debug } = error;
            showMessageWithDebug({ error: message, debug });
          }
        },
        tryPlanText:
          isCurrent || isFreeNoPlan
            ? t("Your current plan")
            : isNextPlan
              ? t("Your next plan")
              : isDowngrade
                ? t("Change plan")
                : t("Upgrade"),
        tryPlanTextHover: isNextPlan && t("Undo"),
        tryPlanDescription: textWillExpire || textWillActive || textCoupon,
        tryPlanOutline: isCurrent || isFreeNoPlan || isDowngrade || isNextPlan,
        tryPlanVariant: isPurchaseDisabled
          ? isCurrent
            ? "success"
            : isNextPlan
              ? "primary"
              : ""
          : "primary",
        tryPlanDisabled: isPurchaseDisabled && (!isDowngrade || isFreeNoPlan),
      };
    },
    [
      plan,
      upgradePlan,
      changePlan,
      changePlanUndo,
      getSubscriptionProduct,
      getSubscription,
      commonFlags,
      planFlags,
      showMessage,
      showMessageWithDebug,
      applyRedirect,
      t,
      tt,
      localeDateString,
    ]
  );

  const planCards = useCallback(
    (list) => {
      const commonFlags = planCommonFlags(list);
      return list.map((value) => ({
        ...value,
        ...planCard(value.id, commonFlags),
      }));
    },
    [planCommonFlags, planCard]
  );

  const cards = useMemo(() => planCards(list), [planCards, list]);

  const unsubscribe = useCallback(async () => {
    try {
      await cancelAllPlans();
      showMessage({ save: t("You have successfully unsubscribed") });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    }
  }, [cancelAllPlans, showMessage, showMessageWithDebug, t]);

  const nextSubscription = plan && commonFlags.isNextPlanDetails;

  const renderInfo = nextSubscription ? (
    <>
      {tt(
        "Your plan will change",
        t(plan.title),
        t(BillingPlan.title(nextSubscription.id)),
        localeDateString(nextSubscription.time || plan.endTime),
        <a
          href="/"
          alt="undo"
          rel="noreferrer"
          onClick={async (e) => {
            e.preventDefault();
            try {
              await changePlanUndo();
              showMessage({ save: t("The plan change canceled") });
            } catch (error) {
              const { message = t("Something went wrong"), debug } = error;
              showMessageWithDebug({ error: message, debug });
            }
          }}
        >
          {t("here")}
        </a>
      )}
    </>
  ) : plan ? (
    tt("You’re subscribed", t(plan.title))
  ) : (
    t("Flexible plans")
  );

  const renderPendingInfo = paymentsPending?.length > 0 && (
    <span className="text-danger">
      {tt(
        "You have been invoiced",
        <u>
          <a
            href="/account/payments"
            alt="payment history"
            rel="noreferrer"
            className="text-danger"
            onClick={async (e) => {
              e.preventDefault();
              navigate("/account/payments");
            }}
          >
            {tc("payment history", Case.Genitive)}
          </a>
        </u>
      )}
    </span>
  );

  const renderUnsubscribe = hasCancelablePlan() && (
    <>
      {tt(
        "If you wish to unsubscribe",
        <a
          href="/"
          alt="unsubscribe"
          rel="noreferrer"
          onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            await unsubscribe();
          }}
        >
          {t("here")}
        </a>
      )}
    </>
  );

  return (
    <>
      <div className="packages-prices">
        <h1 className="text-center">{t("Packages & Prices")}</h1>
        <p className="lead text-center mb-4">
          {renderInfo}
          {
            <>
              <br />
              {renderPendingInfo}
            </>
          }
        </p>
      </div>
      <Row className="py-4">
        {cards.map((value, index) => (
          <Col className="mb-3" key={index}>
            <PlanCard {...value} />
          </Col>
        ))}
      </Row>
      <Row className="my-4">
        <Col>
          <hr />
        </Col>
        <Col md="auto">
          <div
            className="text-center cursor-pointer"
            onClick={() => setOpen(!open)}
            aria-controls="collapse-features"
            aria-expanded={open}
          >
            {open ? (
              <ChevronUp className="me-2" size={28} />
            ) : (
              <ChevronDown className="me-2" size={28} />
            )}
            <span className="h2 align-middle">
              {open ? t("Hide all features") : t("Compare all features")}
            </span>
          </div>
        </Col>
        <Col>
          <hr />
        </Col>
      </Row>
      <Collapse in={open}>
        <div id="collapse-features">
          <Card className="plans-card">
            <Features />
          </Card>
        </div>
      </Collapse>
      {renderUnsubscribe}
    </>
  );
};

export default Pricing;
