const TOKEN = {
  CHANNEL: "Qubot-Token-Channel",
  CLIENT: "Qubot-Token-Client",
  AUTH: "Qubot-Token-Auth",
};

const getTokens = ({ channelToken, accessToken, authToken }) => {
  const tokens = {
    [TOKEN.CHANNEL]: channelToken,
    [TOKEN.CLIENT]: accessToken,
    [TOKEN.AUTH]: authToken,
  };
  for (let token in tokens) {
    if (!tokens[token]) {
      delete tokens[token];
    }
  }
  return tokens;
};

const hasTokens = (value) =>
  value && typeof value === "object"
    ? Object.values(TOKEN).some((key) => typeof value[key] === "string")
    : false;

export { TOKEN, getTokens, hasTokens };
