import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Carousel,
  Col,
  FloatingLabel,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import useBots from "../../../hooks/useBots";
import useTemplates from "../../../hooks/useTemplates";
import * as Yup from "yup";
import useMessage from "../../../hooks/useMessage";
import useClipboard from "../../../hooks/useClipboard";
import logger from "../../../utils/logger";
import { useTranslation } from "../../../hooks/useLocalization";
import { ChatGPTConfigureSimple } from "../channels/bodies/ChatGPT"

import useAnalytics from "../../../hooks/useAnalytics";


const CreateGPTBot = ({
  page = 0,
  name = "",
  description = "",
  template = 0,
  template_type = "",
  template_params = {},
  key = "",
  setOnSubmit,
  onComplete,
  validateName,
}) => {
  const { t, tt } = useTranslation();
  const submitRef = useRef();
  const { onCopyEvent } = useClipboard("Bot bundle");
  const { showMessageWithDebug } = useMessage();
  const { createBot, generateBundle } = useBots();

  const { CONSOLE_EVENTS } = useAnalytics();
  // const [required, setRequired] = useState({});

  const [activeTemplate, setActiveTemplate] = useState('')
  const { groups, templates, fields } = useTemplates()


  useEffect(() => {
    if (setOnSubmit) {
      setOnSubmit(() =>
        submitRef.current?.dispatchEvent(new MouseEvent("click"))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FormStep1 = ({ errors, handleBlur, handleChange, touched, values }) => {
    const nameRef = useRef();

    useEffect(() => {
      nameRef.current?.focus()
    }, [nameRef]);

    return <>
      <Card className="m-1 shadow-none">
        <Card.Subtitle className="mb-3 text-center">{t('Create a new assistant with ...')}</Card.Subtitle>
        <Row>
          <Col xl md="6">
            <Form.Group className="mb-3">
              <FloatingLabel label={`${t("Bot name")} *`}>
                <Form.Control
                  required
                  id="name"
                  type="text"
                  name="name"
                  ref={nameRef}
                  placeholder={`${t("Bot name")} *`}
                  value={values.name}
                  isInvalid={Boolean(touched.name && errors.name)}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    validateName((values.name.length > 0) && !Boolean(touched.name && errors.name));
                    handleChange(e);
                  }}
                />
                {!!touched.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>{t("Bundle is generated automatically")}</Tooltip>
              }
            >
              <Form.Group className="mb-3">
                <FloatingLabel label={t("Bot bundle")}>
                  <Form.Control
                    readOnly
                    id="bundle"
                    type="text"
                    name="bundle"
                    placeholder={t("Bot bundle")}
                    className="cursor-copy"
                    value={generateBundle(values.name) || ""}
                    onClick={onCopyEvent}
                  />
                </FloatingLabel>
              </Form.Group>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>
          <Form.Group className="mb-3">
            <FloatingLabel label={t("Description")}>
              <Form.Control
                as="textarea"
                id="description"
                type="text"
                name="description"
                placeholder={t("Description")}
                value={values.description}
                isInvalid={Boolean(touched.description && errors.description)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.description && (
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Card.Subtitle className="mt-1 text-center">{t('This description will not ...')}</Card.Subtitle>
      </Card>
    </>
  }

  const FormStep2 = ({ errors, handleBlur, handleChange, touched, values }) => {

    return <>
      <Card className="m-1 shadow-none">
        <Card.Subtitle className="mb-3 text-center">{t('How will users use your assistant?')}</Card.Subtitle>
        <div className="d-flex flex-row flex-wrap">

          {groups.map((item, ind) => {
            return <Form.Label htmlFor={"t_" + item.value} style={{ flex: "1 1 33%", minWidth: "200px" }} key={ind}>
              <Card className="p-3 m-1 shadow-none" style={{ height: "100%", borderStype: "solid", borderWidth: "1px", borderColor: "#cccccc" }}>
                <div className="d-flex flex-row justify-content-between">
                  <Card.Title>{t(item.title)}</Card.Title>
                  <Form.Check
                    type={"radio"}
                    name={"template"}
                    value={item.value}
                    id={'t_' + item.value}
                    checked={+values.template === item.value}
                    onBlur={handleBlur}
                    onChange={async (e) => {
                      await CONSOLE_EVENTS.EV_CreateGPT.send({ action: 'change_template', params: { value: item.value } })
                      setActiveTemplate('');
                      handleChange(e);
                    }}
                  ></Form.Check>
                </div>
                <Card.Body>{t(item.description)}</Card.Body>
              </Card>
            </Form.Label>
          })}

        </div>
      </Card >
    </>
  }

  const FormStep3 = ({ errors, handleBlur, handleChange, touched, values }) => {
    return <>
      <Card className="m-1 shadow-none">
        <Card.Subtitle className="mb-3 text-center">{t('Choose your assistant persona')}</Card.Subtitle>
        <div className="d-flex flex-row flex-wrap">

          {templates.map((item, ind) => {
            if (item.for === +values.template) {
              if (activeTemplate === '') {
                setActiveTemplate(item.value)
              }
              return <Form.Label htmlFor={"t_" + item.value} style={{ flex: "1 1 33%", minWidth: "200px" }} key={ind}>
                <Card className="p-3 m-1 shadow-none" style={{ height: "100%", borderStype: "solid", borderWidth: "1px", borderColor: "#cccccc" }}>
                  <div className="d-flex flex-row justify-content-between">
                    <Card.Title>{t(item.title)}</Card.Title>
                    <Form.Check
                      type={"radio"}
                      name={"template_type"}
                      value={item.value}
                      id={"t_" + item.value}
                      checked={activeTemplate === item.value}
                      onBlur={handleBlur}
                      onChange={async (e) => {
                        await CONSOLE_EVENTS.EV_CreateGPT.send({ action: 'change_template_type', params: { value: item.value } })
                        setActiveTemplate(item.value);
                        handleChange(e);
                      }}
                    ></Form.Check>
                  </div>
                  <Card.Body>{t(item.description)}</Card.Body>
                </Card>
              </Form.Label>
            }
            return <div key={ind}></div>
          })}

        </div>
      </Card >
    </>
  }

  const FormStep4 = ({ errors, handleBlur, handleChange, touched, values }) => {

    const getFieldOnType = ({ item }) => {
      if (item.type === 'text') {
        return <Form.Control
          id={'template_params.' + item.param}
          type={item.type}
          name={'template_params.' + item.param}
          placeholder={`${t(item.title)}`}
          value={values['template_params'][item.param]}
          isInvalid={Boolean(touched[item.param] && errors[item.param])}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      }
      else if (item.type === 'textarea') {
        return <Form.Control
          as={item.type}
          id={'template_params.' + item.param}
          type='text'
          name={'template_params.' + item.param}
          placeholder={`${t(item.title)}`}
          value={values['template_params'][item.param]}
          isInvalid={Boolean(touched[item.param] && errors[item.param])}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      }
    }

    if (fields)
      return <>
        <Card className="m-1 shadow-none">
          <Card.Subtitle className="mb-3 text-center">{t('Describe your assistant persona')}</Card.Subtitle>
          {
            fields.map((item, ind) => {
              if (item.for === activeTemplate)
                return <Row key={ind}>
                  <Form.Group className="mb-3">
                    <FloatingLabel label={`${t(item.title)}`}>
                      {getFieldOnType({ item })}
                    </FloatingLabel >
                  </Form.Group>
                </Row>
              return <div key={ind}></div>
              // !!touched[item.param] && (
              //   <Form.Control.Feedback type="invalid">
              //     {errors[item.param]}
              //   </Form.Control.Feedback>
              // )
            })
          }
        </Card >
      </>
    else
      return <>
        <Card className="m-1 shadow-none">
          <Card.Subtitle className="mb-3 text-center">{t('This template has no parameter settings')}.</Card.Subtitle>
        </Card >
      </>
  }

  const FormStep5 = ({ errors, handleBlur, handleChange, touched, values }) => {
    return <>
      <Card className="m-1 shadow-none">
        <Card.Subtitle className="mb-3 text-center">{t('Insert your ChatGPT key')}</Card.Subtitle>
        <Row>
          <Form.Group className="mb-3">
            <FloatingLabel label={`${t("API Key")} *`}>
              <Form.Control
                id="key"
                type="text"
                name="key"
                placeholder={`${t("API Key")} *`}
                value={values.key}
                isInvalid={Boolean(touched.key && errors.key)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Form.Group>
        </Row>
        <Accordion>
          <Accordion.Item eventKey="-1" className="bg-white">
            <Accordion.Header>{t("How to get the ChatGPT API key")}</Accordion.Header>
            <Accordion.Body>
              <ChatGPTConfigureSimple />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Card.Subtitle className="mt-3 text-center">{t('Insert your ChatGPT key ...')}</Card.Subtitle>
      </Card>
    </>
  }

  const RetCarusel = ({ page, errors, handleBlur, handleChange, touched, values }) => {
    return <>
      <Carousel slide controls={false} indicators={false} activeIndex={page} keyboard={false} interval={0} >
        <Carousel.Item>
          {(page < 2) ? <FormStep1 errors={errors} handleBlur={handleBlur} handleChange={handleChange} touched={touched} values={values} /> : ''}
        </Carousel.Item>
        <Carousel.Item>
          {(page >= 0 && page < 3) ? <FormStep2 errors={errors} handleBlur={handleBlur} handleChange={handleChange} touched={touched} values={values} /> : ''}
        </Carousel.Item>
        <Carousel.Item>
          {(page >= 1 && page < 4) ? <FormStep3 errors={errors} handleBlur={handleBlur} handleChange={handleChange} touched={touched} values={values} /> : ''}
        </Carousel.Item>
        <Carousel.Item>
          {(page >= 2 && page < 5) ? <FormStep4 errors={errors} handleBlur={handleBlur} handleChange={handleChange} touched={touched} values={values} /> : ''}
        </Carousel.Item>
        <Carousel.Item>
          {(page >= 3) ? <FormStep5 errors={errors} handleBlur={handleBlur} handleChange={handleChange} touched={touched} values={values} /> : ''}
        </Carousel.Item>
      </Carousel>
    </>
  }

  const GetSystem = (template) => {
    const selected_template = templates.find((item) => (item.value === activeTemplate))
    if (selected_template) {
      let params = template.template_params
      let prompt = selected_template.prompt
      selected_template.fields.forEach((field, i) => {
        let text = (params[field.param] !== undefined) ? params[field.param] : field.default
        prompt = prompt.replace('{' + field.param + '}', text)
      });
      return prompt
    }
    return ''
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ name, description, template, template_type, template_params, key }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .matches(
              /^[aA-zZ\s0-9_()]+$/g,
              t("Must contain only Latin characters")
            )
            .min(5, tt("Must be at least N characters", 5))
            .max(150)
          ,
          template: Yup.string().max(255),
          template_param: Yup.string().max(255),
          description: Yup.string().max(255),
          key: Yup.string().max(255),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          const key = values.key;
          const basic = {
            name: values.name,
            description: values.description,
            bundle: generateBundle(values.name)
          };
          ({ template } = {
            template: { ...values },
          });
          delete (template.key)
          delete (template.name)
          delete (template.description)
          try {
            //scrollIntoView("name");
            const bot = await createBot({ ...basic, bot_type: "gpt", modules: [{ name: 'chat_gpt', api_key: key, system: GetSystem(template) }] }); //  
            // showMessage({ save: "Bot successfully created" });
            onComplete && onComplete(bot);
            await CONSOLE_EVENTS.EV_CreateGPT.send({ action: 'complete', params: { bid: bot.id } });
          } catch (error) {
            logger.error(error);
            const { message = t("Something went wrong"), debug } = error;
            showMessageWithDebug({ error: message, debug });
            setErrors({ submit: message });
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, }) => {
          validateName(((values.name.length >= 5) && !Boolean(errors.name)))
          return (
            <Form onSubmit={handleSubmit}>
              <RetCarusel errors={errors} handleBlur={handleBlur} handleChange={handleChange} touched={touched} values={values} page={page} />
              <div className="text-center w-100 d-grid">
                <Button hidden type="submit" variant="primary" ref={submitRef} disabled={isSubmitting}>
                  {t("Create Bot")}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

export default CreateGPTBot;
