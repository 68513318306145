import { useCallback, useMemo } from "react";
import {
  Bell
} from "react-feather";
import { NOTIFICATION_STATUS } from "../../contexts/NotificationContext";
import { useTranslation } from "../../hooks/useLocalization";
import useNotifications from "../../hooks/useNotifications";
import NavbarDropdown from "./NavbarDropdown";

const NavbarNotifications = ({ tutorials }) => {
  const { notifications } = useNotifications();
  const { t } = useTranslation();
  const showBadge = useMemo(
    () =>
      notifications?.filter(({ status }) => status !== NOTIFICATION_STATUS.CLICKED)
        .length + tutorials.length ? 1 : "",
    [notifications, tutorials]
  );
  // const showBadge = useMemo(
  //   () => {
  //     let total = 0;
  //     let completed = 0;
  //     tutorials.forEach((item) => {
  //       total += item.totalSteps;
  //       completed += item.completedSteps;
  //     })
  //     return total - completed;
  //   }, [tutorials]
  // )
  const onToggle = useCallback(() => {
    notifications?.forEach(
      ({ onView }) => typeof onView === "function" && onView()
    );
  }, [notifications]);
  return (
    <NavbarDropdown
      tutorials={tutorials}
      notifications={notifications}
      header={
        notifications?.length
          ? t("Notifications")
          : t("You have no notifications")
      }
      icon={Bell}
      showBadge={showBadge}
      className="ms-auto cursor-arrow"
      onToggle={onToggle}
    >
    </NavbarDropdown>
  );
};

export default NavbarNotifications;
