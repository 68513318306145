import { useContext } from "react";
import {
  Accordion as AccordionBootstrap,
  AccordionContext,
} from "react-bootstrap";

const Accordion = Object.assign(
  (props) => {
    const { children } = props;
    return <AccordionBootstrap {...props}>{children}</AccordionBootstrap>;
  },
  {
    Button: AccordionBootstrap.Button,
    Collapse: AccordionBootstrap.Collapse,
    Item: (props) => {
      const accordion = useContext(AccordionContext);
      const { activeEventKey } = accordion || {};
      const currentIsExpanded = props.eventKey === activeEventKey;
      return (
        <AccordionBootstrap.Item
          {...props}
          className={currentIsExpanded ? "active" : ""}
          onClickCapture={(event) =>
            event.target.classList?.contains("accordion-button") &&
            currentIsExpanded &&
            event.stopPropagation()
          }
        />
      );
    },
    Header: AccordionBootstrap.Header,
    Body: AccordionBootstrap.Body,
  }
);

export { Accordion };
