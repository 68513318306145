import React from "react";
import useSidebar from "../../hooks/useSidebar";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { Badge, Spinner } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import Scrollbar from "../Scrollbar";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "../../hooks/useLocalization";
import SidebarNavList from "./SidebarNavList";
import useBots from "../../hooks/useBots";
import { ArrowLeft } from "react-feather";
import { isMobile } from "../../utils/screen";

import useAnalytics from "../../hooks/useAnalytics";

const Sidebar = ({ items, showFooter }) => {
  const { activeBot } = useBots();
  const { t } = useTranslation();
  const { sidebarProps, isOpen, setIsOpen } = useSidebar();
  const { isLoading, plan, paymentsPending } = sidebarProps;
  const bannerPaymentsPending = !isLoading && paymentsPending;
  const bannerUpgradePlan = !(isLoading || plan || bannerPaymentsPending);
  const location = useLocation();
  const { pathname } = location;
  const { CONSOLE_EVENTS } = useAnalytics();

  const dashboardView = matchPath(
    { path: "/dashboard/bots", end: true },
    pathname
  );

  const onNavLinkClick = () => {
    if (isMobile() && !isOpen) {
      setIsOpen(true);
    }
  };

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <a className="sidebar-brand" href="/">
          <Logo /> <span className="align-middle me-2">Qudata</span>
          {isLoading ? (
            <Spinner animation="border" variant="info" size="sm" className="ms-1" />
          ) : (
            plan && <Badge bg="info">{t(plan.title)}</Badge>
          )}
        </a>
        {bannerPaymentsPending && (
          <NavLink to="/account/payments" className="sidebar-upgrade"
            onClick={async () => {
              onNavLinkClick();
              await CONSOLE_EVENTS.EV_Locate.send({ params: { onmobile: isMobile(), href: "/account/payments" } });
            }}
          >
            <Badge bg="danger" className="w-100 text-wrap p-2 mb-2 rounded-0">
              {t("Payment pending")}
            </Badge>
          </NavLink>
        )}
        {bannerUpgradePlan && (
          <NavLink to="/account/pricing" className="sidebar-upgrade"
            onClick={async () => {
              onNavLinkClick();
              await CONSOLE_EVENTS.EV_Locate.send({ params: { onmobile: isMobile(), href: "/account/pricing" } });
            }}
          >
            <Badge bg="danger" className="w-100 text-wrap p-2 mb-2 rounded-0">
              {t("Upgrade your subscription")}
            </Badge>
          </NavLink>
        )}
        <SidebarNavList
          pages={[
            {
              bot: activeBot,
              kind: "head",
              href: "/dashboard/bots#clear",
              icon: !dashboardView && ArrowLeft,
              title: t("All Bots"),
            },
          ]}
          depth={0}
        />
        <Scrollbar>
          <div className="h-0">
            <SidebarNav items={items} />
          </div>
        </Scrollbar>
        {!!showFooter && <SidebarFooter />}
      </div>
    </nav>
  );
};

export default Sidebar;
