import React, { useEffect } from "react";
import useBots from "../../hooks/useBots";
import useAnalytics from "../../hooks/useAnalytics";
import useExtensions from "../../hooks/useExtensions";
import useQubot from "../../hooks/useQubot";
import useUI from "../../hooks/useUI";
import useAuth from "../../hooks/useAuth";
import useTutorial from "../../hooks/useTutorial";

const QubotEnv = ({ children }) => {
  const { useOption } = useUI();
  const option = useOption("editor", {}, "/bots/editor");
  const useAuthAPI = useAuth();
  const useBotsAPI = useBots();
  const useTutorialAPI = useTutorial();
  const useAnalyticsAPI = useAnalytics();
  const useExtensionsAPI = useExtensions();
  const qubot = useQubot();

  useEffect(() => {
    qubot?.use({
      useOption: option,
      useAuth: useAuthAPI,
      useBots: useBotsAPI,
      useAnalytics: useAnalyticsAPI,
      useExtensions: useExtensionsAPI,
      useTutorial: useTutorialAPI,
    });
    if (useAuthAPI.user !== null) qubot?.create();
  }, [
    qubot,
    option,
    useAuthAPI,
    useBotsAPI,
    useAnalyticsAPI,
    useExtensionsAPI,
    useTutorialAPI,
  ]);

  return <>{children}</>;
};

export default QubotEnv;
