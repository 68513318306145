import React, { useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";
import useBots from "../../hooks/useBots";
import QDLoader from "../../components/QDLoader";
import Channels from "../../components/bots/channels/Channels";
import BreadcrumbHeader from "./BreadcrumbHeader";
import { BOT } from "../../contexts/bots/BotsContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "../../hooks/useLocalization";
import { useHistory } from "../../hooks/useHistory";

const ChannelsPage = () => {
  const { t } = useTranslation();
  const { isInitialized } = useBots();
  const hitory = useHistory();
  const navigate = useNavigate();
  const location = useLocation();
  const channelName = location.hash?.substr(1) || null;
  const setChannelName = useCallback(
    (value) => {
      if (value !== channelName) {
        hitory.push(window.location);
        navigate(`/bots/channels${value ? "#" + value : ""}`, {
          replace: true,
        });
      }
    },
    [channelName, navigate, hitory]
  );
  const channelTitle = (channelName) ? t(BOT.title(channelName)) : null;
  return (
    <React.Fragment>
      <Helmet title={channelTitle || t("Integrations")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col xs="auto" /*className="d-none d-sm-block"*/>
            <BreadcrumbHeader
              title={t("Integrations")}
              onTitle={() => setChannelName(null)}
              subtitle={channelTitle}
            />
          </Col>
        </Row>
        {isInitialized ? (
          <Channels channelName={channelName} setChannelName={setChannelName} />
        ) : (
          <QDLoader />
        )}
      </Container>
    </React.Fragment>
  );
};

export default ChannelsPage;
