import React, { useCallback, useEffect, useReducer } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import logger from "../utils/logger";

const initialState = {
  searchParams: new URLSearchParams(),
  redirectPath: {},
};

const INITIALIZE = "INITIALIZE";
const SEARCH_PARAMS = "SEARCH_PARAMS";
const REDIRECT_PATH = "REDIRECT_PATH";

const LocationReducer = (state, action) => {
  // logger.dev("[LocationReducer]", action.type);
  const { searchParams, redirectPath, isInitialized } = action.payload || {};
  switch (action.type) {
    case INITIALIZE:
      return { isInitialized, searchParams };
    case SEARCH_PARAMS:
      return { ...state, searchParams };
    case REDIRECT_PATH:
      return { ...state, redirectPath };
    default:
      logger.dev(`[LocationReducer] invalid action ${action}`);
  }
  return state;
};

const LocationContext = React.createContext(initialState);

function LocationProvider({ children }) {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(LocationReducer, initialState);
  const { isInitialized, searchParams } = state;
  let [windowSearchParams] = useSearchParams();

  // Restore Session
  // ------------------------
  useEffect(() => {
    dispatch({
      type: INITIALIZE,
      payload: {
        isInitialized: true,
        searchParams: windowSearchParams,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Validate location search
  // ------------------------
  const validateParams = useCallback(() => {
    let valid = true;
    // 1. exists
    for (let param of searchParams) {
      const [name, value] = param;
      if (!(windowSearchParams.has(name) && searchParams.get(name) === value)) {
        valid = false;
        break;
      }
    }
    // 1. not exists
    for (let param of windowSearchParams) {
      const [name, value] = param;
      if (!(searchParams.has(name) && searchParams.get(name) === value)) {
        valid = false;
        break;
      }
    }
    if (!valid) {
      const { pathname, hash } = window.location;
      navigate(`${pathname}?${searchParams}${hash}`, { replace: true });
    }
  }, [searchParams, windowSearchParams, navigate]);

  // Update location search
  // ------------------------
  useEffect(() => {
    if (isInitialized) validateParams();
  }, [isInitialized, validateParams]);

  const hasSearchParam = useCallback(
    (name) => searchParams.has(name),
    [searchParams]
  );

  const getSearchParam = useCallback(
    (name) =>
      Array.isArray(name)
        ? name.map((name) => searchParams.get(name))
        : searchParams.get(name),
    [searchParams]
  );

  const setSearchParam = useCallback(
    (name, value) => {
      // logger.dev("[LocationReducer] setSearchParam", { name, value });
      if (searchParams.get(name) === value) {
        return value;
      }
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(name, value);
      dispatch({
        type: SEARCH_PARAMS,
        payload: {
          searchParams: newSearchParams,
        },
      });
      return value;
    },
    [searchParams]
  );

  const deleteSearchParam = useCallback(
    (name) => {
      // logger.dev("[LocationReducer] deleteSearchParam", { name });
      const names = (Array.isArray(name) ? name : [name]).filter((name) =>
        searchParams.has(name)
      );
      if (!names.length) {
        return false;
      }
      const newSearchParams = new URLSearchParams(searchParams);
      names.forEach((name) => newSearchParams.delete(name));
      dispatch({
        type: SEARCH_PARAMS,
        payload: {
          searchParams: newSearchParams,
        },
      });
      return true;
    },
    [searchParams]
  );

  const setRedirect = useCallback(
    (name, value) => {
      // logger.dev("[LocationReducer] setRedirect", { name, value });
      const { pathname, hash } = window.location;
      value ||= { pathname, hash };
      dispatch({
        type: REDIRECT_PATH,
        payload: {
          redirectPath: { ...state.redirectPath, [name]: value },
        },
      });
      return value;
    },
    [state.redirectPath]
  );

  const clearRedirect = useCallback(
    (name) => {
      // logger.dev("[LocationReducer] clearRedirect", name);
      dispatch({
        type: REDIRECT_PATH,
        payload: {
          redirectPath: { ...state.redirectPath, [name]: null },
        },
      });
    },
    [state.redirectPath]
  );

  const applyRedirect = useCallback(
    (name, options) => {
      // logger.dev("[LocationReducer] applyRedirect", name);
      const { clear } = options || {};
      const { pathname, hash = "" } =
        (state.redirectPath && state.redirectPath[name]) || {};
      if (pathname) {
        navigate(`${pathname}?${searchParams}${hash}`);
        if (clear) clearRedirect(name);
        return true;
      }
      return false;
    },
    [state.redirectPath, navigate, searchParams, clearRedirect]
  );

  return (
    <LocationContext.Provider
      value={{
        ...state,

        hasSearchParam,
        getSearchParam,
        setSearchParam,
        deleteSearchParam,

        setRedirect,
        clearRedirect,
        applyRedirect,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
}

export { LocationProvider, LocationContext };
