import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import { useTable, useSortBy } from "react-table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

// Create a default prop getter
const defaultPropGetter = () => ({});

const FilesTable = ({
  columns,
  data,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getHeaderProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  getCellRenderer = defaultPropGetter,
  getCellRendererProps = defaultPropGetter,
}) => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      width: 150,
      maxWidth: 300,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useSortBy
      // useFlexLayout
    );

  return (
    <Table
      hover
      {...getTableProps()}
      style={{ width: "100%", tableLayout: "fixed" }}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                  column.getSortByToggleProps(),
                  getColumnProps(column),
                  getHeaderProps(column),
                ])}
              >
                {column.render("Header")}
                {!column.disableSortBy && (
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} className="ms-2" />
                    )}
                  </span>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps([getCellProps(cell)])}>
                  {cell.render(
                    getCellRenderer(cell) || "Cell",
                    getCellRendererProps(cell)
                  )}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default FilesTable;
