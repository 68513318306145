import React from "react";
import { BOT_CHANNEL } from "../../../../contexts/bots/BotsContext";
import { useTranslation } from "../../../../hooks/useLocalization";
import { WhatsappIcon } from "../bodies/Whatsapp";
import ChannelCard from "./ChannelCard";

const ChannelWhatsappCard = (props) => {
  const { t } = useTranslation();
  const { channelName, handlers } = props;
  return (
    <ChannelCard
      {...props}
      name={t(BOT_CHANNEL.title(channelName))}
      icon={WhatsappIcon}
      handlers={handlers}
    />
  );
};

export default ChannelWhatsappCard;