import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "react-bootstrap";
import { useTranslation } from "../../hooks/useLocalization";

function SignUpVerify() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { verifyEmail } = useAuth();
  const [errors, setErrors] = useState({});
  const { fast } = useParams();

  useEffect(() => {
    const verify = async () => {
      try {
        await verifyEmail();
        setTimeout(() => fast ? navigate("/auth/quick-sign-up") : navigate("/auth/sign-up-complete"), 500);
      } catch (error) {
        const { message = t("Something went wrong") } = error;
        setErrors({ submit: message });
      }
    };
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {errors.submit && (
        <Alert variant="danger">
          <div className="alert-message d-flex align-items-center">
            <FontAwesomeIcon
              icon={faTimesCircle}
              size="3x"
              className="text-danger flex-shrink-0 me-3"
            />
            <div>{errors.submit}</div>
          </div>
        </Alert>
      )}
      <div className="submitting-icon">
        {!errors.submit && (
          <FontAwesomeIcon
            spin
            icon={faAt}
            size="6x"
            className="text-primary"
          />
        )}
      </div>
    </>
  );
}

export default SignUpVerify;
