import React from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { X } from "react-feather";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "../../hooks/useLocalization";
import useMessage from "../../hooks/useMessage";

const DeleteAcc = ({ account: acc, refresh }) => {
  const { t, tt } = useTranslation();
  const [modalShow, setModalShow] = React.useState(false);

  const onCLickHandler = () => {
    setModalShow(!modalShow);
  }

  const ModalDelAcc = (props) => {
    const { deleteAccount } = useAuth();
    const { showMessageWithDebug, showMessage } = useMessage();

    const deleteAcc = async () => {
      const { uid } = acc;
      let req = {
        user: { uid },
      }

      try {
        await deleteAccount(req);
        showMessage({ save: "" })
        refresh();
        setModalShow(!modalShow);
      } catch (error) {
        const { message = t("Something went wrong"), debug } = error;
        showMessageWithDebug({ error: message, debug });
      }
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-flex justify-content-center flex-column">
          <button className="btn-close" onClick={() => setModalShow(!modalShow)}></button>
          <h3>{t("Account Deleting")}</h3>
          <p className="mb-0 mt-1">{tt("Account Deleting 1",
            <b>{acc.email}</b>
          )}</p>
          <p>{tt("Account Deleting 2", <b>{t("Account Deleting B")}</b>)}</p>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-around">
          <Button className="ps-5 pe-5 btn-danger" onClick={deleteAcc}>
            {t("Delete Anyway")}
          </Button>
          <Button className="ps-5 pe-5" onClick={() => setModalShow(!modalShow)}>
            {t("Cancel")}
          </Button>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>{t("Delete")} {t("Account")}</Tooltip>}>
        <Button variant="light" className="m-1 p-1" onClick={onCLickHandler}>
          <X />
        </Button>
      </OverlayTrigger>
      <ModalDelAcc show={modalShow} onHide={() => setModalShow(false)} />
    </>

  )
}

export default DeleteAcc;