import { useCallback, useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";

const ContextMenu = ({ show, header, items, onClose }) => {
  const menuRef = useRef();
  const [pos, setPos] = useState({ x: 0, y: 0 });

  const isContains = useCallback(
    (e) => menuRef.current?.contains(e.target),
    [menuRef]
  );

  const handleEscape = useCallback(
    (e) => {
      if (e.key === "Escape") {
        onClose();
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [onClose]
  );

  useEffect(() => {
    window.removeEventListener("keydown", handleEscape, { capture: true });
    if (show) {
      window.addEventListener("keydown", handleEscape, { capture: true });
    }
    window.onmousedown = window.onmousewheel = (e) => {
      if (show && !isContains(e)) {
        onClose();
      }
    };
    window.onmouseup = (e) => {
      if (!isContains(e)) {
        setPos({ x: e.pageX, y: e.pageY });
      }
    };
    return () => {
      window.removeEventListener("keydown", handleEscape, { capture: true });
      window.onmousedown = window.onmousewheel = window.onmouseup = null;
    };
  }, [show, isContains, onClose, handleEscape]);

  return (
    <Dropdown.Menu
      ref={menuRef}
      show={show}
      style={{
        left: Math.min(pos.x - window.scrollX, window.innerWidth - 270),
        top: Math.min(pos.y - window.scrollY, window.innerHeight - 110),
        zIndex: 1987 - 1,
        position: "fixed",
      }}
    >
      {header && (
        <Dropdown.Header
          className="d-inline-block text-truncate"
          style={{ maxWidth: "250px" }}
        >
          {header}
        </Dropdown.Header>
      )}
      {items &&
        items.map((item, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => {
              item?.onClick();
              onClose();
            }}
          >
            {item.as || item.text}
          </Dropdown.Item>
        ))}
    </Dropdown.Menu>
  );
};

export default ContextMenu;
