import {
  REGEXP_CSS,
  REGEXP_IMG,
  REGEXP_IMG_A,
  REGEXP_OPTION_ICON,
  REGEXP_OPTION_MAIN,
  REGEXP_OPTION_STYLE,
  REGEXP_YML,
} from "../../utils/files";
import { BOT_FILE_OPTION, BOT_FILE_STATUS } from "./BotsContext";

const botById = (bots, botId) => bots && bots.find(({ id }) => id === botId);

const parseLocalFiles = (bot, sBot, localFiles) => {
  const { setupFiles } = sBot || {};
  const files = bot?.files || [];
  const status = localFiles
    // idle
    .filter((localFile) => compareFile(findFile(files, localFile), localFile))
    .map((localFile) => ({
      ...localFile,
      option: findSetupFile(setupFiles, localFile.source?.fullPath)?.option,
      status: BOT_FILE_STATUS.IDLE,
    }))
    // update
    .concat(
      localFiles
        .filter(
          (localFile) =>
            findFile(files, localFile) &&
            !compareFile(findFile(files, localFile), localFile)
        )
        .map((localFile) => ({
          ...localFile,
          option: findSetupFile(setupFiles, localFile.source?.fullPath)?.option,
          status: BOT_FILE_STATUS.UPDATE,
        }))
    )
    // create
    .concat(
      localFiles
        .filter((localFile) => !findFile(files, localFile))
        .map((localFile) => ({
          ...localFile,
          option: findSetupFile(setupFiles, localFile.source?.fullPath)?.option,
          status: BOT_FILE_STATUS.CREATE,
        }))
    )
    // remove
    .concat(
      files
        .filter((file) => !findLocalFile(localFiles, file))
        .map((file) => ({
          source: { fullPath: file.path },
          status: BOT_FILE_STATUS.REMOVE,
        }))
    );

  // config.yml
  if (!status.find(({ option }) => option === BOT_FILE_OPTION.MAIN)) {
    const sFile =
      status.find(({ source }) => REGEXP_OPTION_MAIN.test(source?.name)) ||
      status.find(({ source }) => REGEXP_YML.test(source?.name));
    if (sFile) {
      sFile.option = BOT_FILE_OPTION.MAIN;
    }
  }

  // icon.png
  if (!status.find(({ option }) => option === BOT_FILE_OPTION.ICON)) {
    const sFile =
      status.find(({ source }) => REGEXP_OPTION_ICON.test(source?.name)) ||
      status.find(({ source }) => REGEXP_IMG_A.test(source?.name)) ||
      status.find(({ source }) => REGEXP_IMG.test(source?.name));
    if (sFile) {
      sFile.option = BOT_FILE_OPTION.ICON;
    }
  }

  // style.css
  if (!status.find(({ option }) => option === BOT_FILE_OPTION.MAIN)) {
    const sFile =
      status.find(({ source }) => REGEXP_OPTION_STYLE.test(source?.name)) ||
      status.find(({ source }) => REGEXP_CSS.test(source?.name));
    if (sFile) {
      sFile.option = BOT_FILE_OPTION.MAIN;
    }
  }

  // option changed
  status
    .filter(({ status }) => status === BOT_FILE_STATUS.IDLE)
    .forEach((fileStatus) => {
      if (!findFileOpt(files, fileStatus.option, fileStatus.source?.fullPath)) {
        if (fileStatus.option) {
          fileStatus.status = BOT_FILE_STATUS.UPDATE;
        }
      }
    });

  return status;
};

const compareLocalFiles = (firstFilesStatus, secondFilesStatus) => {
  if (firstFilesStatus === secondFilesStatus) return true;
  if (!!firstFilesStatus !== !!secondFilesStatus) return false;
  if (firstFilesStatus?.length !== secondFilesStatus?.length) return false;
  return !(secondFilesStatus || []).find(
    (file) =>
      !compareLocalFile(
        file,
        (firstFilesStatus || []).find(
          ({ source }) => source?.fullPath === file.source?.fullPath
        )
      )
  );
};

const compareLocalFile = (firstStatus, secondStatus) => {
  if (firstStatus === secondStatus) return true;
  if (!!firstStatus !== !!secondStatus) return false;
  return (
    firstStatus?.status === secondStatus?.status &&
    firstStatus?.option === secondStatus?.option &&
    firstStatus?.mtime?.getTime() === secondStatus?.mtime?.getTime() &&
    firstStatus?.source?.fullPath === secondStatus.source?.fullPath
  );
};

const findFile = (files, localFile) => {
  return (files || []).find((file) => file.path === localFile.source?.fullPath);
};

const findFileOpt = (files, option, path) => {
  return (files || []).find(
    (file) =>
      (!path || file.path === path) &&
      (option ? file.type === option : !file.type)
  );
};

const findLocalFile = (localFiles, file) => {
  return (localFiles || []).find(
    ({ source }) => source?.fullPath === file.path
  );
};

const compareFile = (file, localFile) => {
  return file && file.mtime === localFile.mtime?.getTime();
};

// const compareLocalFile = (localFile, file) => {
//   return localFile && localFile.mtime?.getTime() === file.mtime;
// };

const getBotFileSetup = (settings, path) => {
  const { setupFiles } = settings;
  const file = findSetupFile(setupFiles, path);
  if (file) {
    return { setupFiles, file };
  }
  return createBotFileSetup(settings, path);
};

const createBotFileSetup = (settings, path) => {
  let { setupFiles } = settings;
  if (!setupFiles) {
    setupFiles = settings.setupFiles = [];
  }
  const file = { path };
  setupFiles.push(file);
  return { setupFiles, file };
};

const findSetupFile = (setupFiles, path) => {
  return (setupFiles || []).find((file) => file.path === path);
};

export {
  botById,
  parseLocalFiles,
  compareLocalFiles,
  compareLocalFile,
  findFile,
  findFileOpt,
  findLocalFile,
  compareFile,
  getBotFileSetup,
  createBotFileSetup,
  findSetupFile,
};
