import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, ListGroup } from "react-bootstrap";
import { Moon, Play, RefreshCw, Search } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import Loading from "./Loading";
import Scrollbar from "../../Scrollbar";

// import { RunBotContext, RunBotDebugContext } from "qubot";
const RunBotContext = null;
const RunBotDebugContext = null;

const States = ({
  id,
  requestLocalFiles,
  updateRevision,
  testingRevision,
  localDirectory,
  hasPermissions,
  readdirError,
  updateError,
  getParam,
  setParam,
  setBotParam,
}) => {
  const { state, runState, lastState } = useContext(RunBotContext);
  const { getData, debugState } = useContext(RunBotDebugContext);
  const [userSelected, setUserSelected] = useState(null);

  const states = debugState[id]?.states || [];
  const curState = state[id]?.state || "";
  const isLoading = !states?.length;

  const afterUpdate = getParam("update");
  const afterUpdateReload = afterUpdate === "reload";
  const afterUpdateWarning =
    afterUpdateReload && (!hasPermissions || readdirError);
  const afterUpdateError = afterUpdateReload && updateError;

  useEffect(() => {
    (async () => {
      if (
        id &&
        curState &&
        updateRevision !== testingRevision &&
        afterUpdateReload
      ) {
        await runState({ state: curState });
        setBotParam(id, { testingRevision: updateRevision }, updateRevision);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, curState, updateRevision, testingRevision, afterUpdateReload]);

  useEffect(() => getData({ states: ["*"] }), [id, getData]);

  const findCurrentStateIndex = useCallback(
    (arr, field) =>
      arr.indexOf(
        curState
          ? arr.find(({ name }) => name === curState)
          : arr.find((item) => item[field])
      ),
    [curState]
  );

  const active = findCurrentStateIndex(states, "active");
  const runFromState = userSelected !== null ? userSelected : active;
  useEffect(() => setUserSelected(null), [state, setUserSelected]);

  const runInitHandler = async () => {
    await runState({});
    setBotParam(id, { testingRevision: updateRevision }, updateRevision);
  };

  const runContinueHandler = async () => {
    await lastState({});
  };

  const runFromStateHandler = async (index) => {
    await runState({
      state: states[index !== void 0 ? index : runFromState].name,
    });
  };

  // если state спит, то на старте проскроливать до него
  // скролирование списка со states всё таки нужно
  //   пока человек не мотнёт колесом мышки, а взводить обратно при кликах на
  //   restart, continue, list-item
  // беджи слотов
  //   в протоколе лимит (опрашивать на старте и при смене стейта)
  //   плавное блымание (сортировка последних сверху, если он выделен то скролировать список наверх)
  // видеть куски yml по текущему состоянию
  //   https://www.npmjs.com/package/js-yaml
  // под мобилкой бьется окно чат бота (в Client из-за resizable или явно сломали)
  // подбешивают анимации бота при возврате в окно testing (как минимум скролирование)

  return (
    <Card>
      <Card.Header>
        <Card.Title className="mb-0">
          {isLoading && "States"}
          <InputGroup
            className="input-group-testing"
            hidden={true /*isLoading*/}
          >
            <Form.Control
              disabled
              placeholder="Find the states..."
              aria-label="Search"
            />
            <Button variant="">
              <Search className="feather" />
            </Button>
          </InputGroup>
        </Card.Title>
      </Card.Header>

      <Card.Body className="p-0">
        <div className="t-card-states-container ps-dark">
          {states && (
            <Scrollbar>
              {(isLoading && <Loading />) || (
                <ListGroup as="ul" variant="flush" className="mb-3">
                  {states.map((state, index) => {
                    const stateActive = index === active && curState;
                    const stateToContinue = index === active && !curState;
                    const stateSelected = index === userSelected;
                    return (
                      <ListGroup.Item
                        as="li"
                        key={index}
                        active={stateActive}
                        variant={stateSelected ? "secondary" : void 0}
                        className="cursor-pointer"
                        onClick={() => {
                          setUserSelected(index);
                          runFromStateHandler(index);
                        }}
                      >
                        {state.name}
                        <Play
                          hidden
                          className="feather text-success float-end play"
                        />
                        <Moon
                          hidden={!stateToContinue}
                          className="feather text-warning float-end sleep"
                        />
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              )}
            </Scrollbar>
          )}
        </div>
        <div className="states-footer p-4" hidden={isLoading}>
          {localDirectory && (
            <div className="d-flex gap-1">
              <Form.Check
                type="switch"
                label="Upload updates"
                className={`auto-updates-switcher ${
                  afterUpdateError
                    ? "auto-updates-switcher-danger"
                    : afterUpdateWarning
                    ? "auto-updates-switcher-warning"
                    : ""
                }`}
                id="auto-updates-switcher"
                checked={afterUpdateReload}
                onChange={() =>
                  setParam("update", afterUpdateReload ? null : "reload")
                }
              />
              {afterUpdateWarning && (
                <a
                  rel="noreferrer"
                  href="/dashboard/bots"
                  className="text-muted text-decoration-none"
                  onClick={async (e) => {
                    e.preventDefault();
                    await requestLocalFiles(id);
                  }}
                >
                  (<FontAwesomeIcon icon={faWrench} fixedWidth />)
                </a>
              )}
            </div>
          )}
          <div className="ms-auto">
            <Button
              size="sm"
              className="me-2"
              variant="outline-primary"
              onClick={() => runInitHandler()}
            >
              <RefreshCw className="feather" />
              <span>Restart</span>
            </Button>
            <Button
              size="sm"
              variant="success"
              hidden={!!curState}
              onClick={() => runContinueHandler()}
            >
              <Play className="feather" />
              <span>Continue</span>
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default States;
