import { OverlayTrigger, Popover } from "react-bootstrap";
import { Info } from "react-feather";
import { useNavigate } from "react-router-dom";
import { PAYMENTS_STATUS } from "../../contexts/billing/BillingContext";
import { useTranslation } from "../../hooks/useLocalization";
import { useLocaleDateString } from "../../utils/data";

const getPrice = (value, currency, translation) => {
  const { t, tts } = translation;
  return tts(`N ${currency}`, value) || `${value} ${t(currency)}`;
};

const PaymentPeriod = ({ cell, className }) => {
  const localeDateString = useLocaleDateString();
  const { time, endTime } = cell.value;
  const text =
    time && endTime
      ? localeDateString(time) + " - " + localeDateString(endTime)
      : "";
  return <span className={className}>{text}</span>;
};

const PaymentUpdatedAt = ({ cell, className }) => {
  const localeDateString = useLocaleDateString();
  const { value } = cell;
  const text = value ? localeDateString(value) : "";
  return <span className={className}>{text}</span>;
};

const getPeriodRendererProps = (cell) => {
  const props = {};
  return { cell, ...props };
};

const renderDestination = (value, index) => {
  return Array.isArray(value)
    ? value.map((n, i) => <span key={index * 100 + i}>{n}</span>)
    : value;
}

const PaymentPurpose = ({ cell, className }) => {
  const { value } = cell;
  return (
    <div className="w-100">
      {renderDestination(value.name, 0)}
    </div>
  )
}

const PaymentAmount = ({ cell, className }) => {
  const translation = useTranslation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { value, currency, details } = cell.value;
  const { values } = cell.row;
  const text =
    values?.status?.value === PAYMENTS_STATUS.SCHEDULED
      ? "-"
      : value
        ? getPrice(value?.toFixed(2), currency, translation)
        : "";
  return details ? (
    <OverlayTrigger
      rootClose
      placement="left"
      trigger="click"
      overlay={
        <Popover>
          {/* <Popover.Header as="h3">Details</Popover.Header> */}
          <Popover.Body>
            {details.map((value, index) => (
              <span key={index}>
                {renderDestination(value.name, index)} -{" "}
                {getPrice(value.value?.toFixed(2), value.currency, translation)}
                <br />
              </span>
            ))}
            <a href="/" onClick={(e) => { e.preventDefault(); navigate("/account/traffic") }}>{t("More details")}</a>
          </Popover.Body>
        </Popover>
      }
    >
      <div className="w-100 cursor-help">
        <span className={className}>{text}</span>
        {text && <Info className="feather float-end text-info" />}
      </div>
    </OverlayTrigger>
  ) : (
    <div className="w-100">
      <span className={className}>{text}</span>
    </div>
  );
};

const getAmountRendererProps = (cell) => {
  const props = {};
  return { cell, ...props };
};

const PaymentStatus = ({ cell, className }) => {
  const { t, tt } = useTranslation();
  const localeDateString = useLocaleDateString();
  const { values } = cell.row;
  const time = values.period?.time;
  const details = values.amount?.details;
  const status = values.status?.value;
  const payInvoice = values.status?.payInvoice;
  const text = status
    ? (status === PAYMENTS_STATUS.PENDING && (
      <>
        {t("Payment pending")}
        {typeof payInvoice === "function" && (
          <a
            href="/"
            alt="undo"
            rel="noreferrer"
            className="float-end"
            onClick={async (e) => {
              e.preventDefault();
              payInvoice();
            }}
          >
            {t("Pay now")}
          </a>
        )}
      </>
    )) ||
    (status === PAYMENTS_STATUS.PROCESSING && t("Payment confirmation")) ||
    (status === PAYMENTS_STATUS.COMPLETED && (
      <>
        {t("Payment")}
        {details && (
          <span>
            {" "}
            (
            {details.map((value, index) => (
              <span key={index}>
                {renderDestination(value.name, index)}
                {index < details.length - 1 && ", "}
              </span>
            ))}
            )
          </span>
        )}
      </>
    )) ||
    (status === PAYMENTS_STATUS.SCHEDULED &&
      tt("Estimated billing date", time ? localeDateString(time) : ""))
    : "";
  return <span className={className}>{text}</span>;
};

const getStatusRendererProps = (cell) => {
  const { column } = cell || {};
  const status = cell?.value?.value || "";
  const isStatus = column.id === "status";
  const isStatusPending = isStatus && status === PAYMENTS_STATUS.PENDING;
  const className = (isStatusPending && "text-danger font-weight-bold") || "";
  const props = cell.column.id === "status" ? { className } : {};
  return { cell, ...props };
};

export {
  getPrice,
  PaymentPeriod,
  PaymentAmount,
  PaymentStatus,
  PaymentPurpose,
  PaymentUpdatedAt,
  getPeriodRendererProps,
  getAmountRendererProps,
  getStatusRendererProps,
};
