import { useCallback } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import MyBots from "../../components/bots/MyBots";
import QDLoader from "../../components/QDLoader";
import Scrollbar from "../../components/Scrollbar";
import useAuth from "../../hooks/useAuth";
import useBots from "../../hooks/useBots";
import { useTranslation } from "../../hooks/useLocalization";
import {
  BOT_FILTER,
  getBotFilter,
  SORT_BY,
} from "../../pages/dashboards/Bots/utils";

import useAnalytics from "../../hooks/useAnalytics";

const TemplatesModal = ({ open, setOpen, title, filter = BOT_FILTER.TEMPLATE, handlers, }) => {
  const { CONSOLE_EVENTS } = useAnalytics();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { isInitialized } = useBots();
  const isReady = isInitialized && !!filter;
  const onClose = async () => {
    setOpen(false);
    await CONSOLE_EVENTS.EV_Templates.send({ action: 'close' })
  }
  const filterBot = useCallback(
    (bot, filter) => getBotFilter(bot, filter, user) === filter,
    [user]
  );
  title ||= t("Templates");

  if (open) {
    let wrap = document.getElementById("qubot-wrapper");
    let open = document.getElementById("qubot-open");

    if (!wrap || !open) return

    wrap.classList.remove("qubot-show");
    wrap.classList.add("qubot-hide");

    open.classList.remove("qubot-hide");
    open.classList.add("qubot-show");
  }

  return (
    <Modal show={open} onHide={onClose} size="xl" enforceFocus={false}>
      <Helmet title={title} />
      <Modal.Header closeButton>{title}</Modal.Header>
      <div className="modal-body-container ps-dark">
        <Scrollbar>
          <Modal.Body className="p-4.5 bg-body">
            <Helmet title={title} />
            <Container fluid className="p-0">
              {isReady ? (
                <MyBots
                  filter={filter}
                  filterBot={filterBot}
                  sortBy={SORT_BY.NAME}
                  handlers={handlers}
                />
              ) : (
                <QDLoader />
              )}
            </Container>
          </Modal.Body>
        </Scrollbar>
      </div>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TemplatesModal;
