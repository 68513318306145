import Chart from "react-apexcharts";
import { Dropdown } from "react-bootstrap";
import { FILTERS } from "../../../../contexts/bots/filters";
import { useTranslation } from "../../../../hooks/useLocalization";
import ReportCard from "./ReportCard";

const ReportEventsCard = ({
  report,
  proPlan,
  isLoading,
  filters,
  eventNames,
  onSelectEventName,
}) => {
  const { t, tt } = useTranslation();
  return (
    <ReportCard
      variant="warning"
      helpText={t("The number of times the selected event occurred")}
      proPlan={proPlan}
      childrenHeader={
        <Dropdown className="d-inline">
          {t("Events")}
          <Dropdown.Toggle variant="light" className="bg-white shadow-sm ms-2">
            <span>{filters[FILTERS.EVENT_NAME] || t("No event selected")}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {eventNames?.length ? (
              eventNames.map(({ name }, index) => (
                <Dropdown.Item
                  key={index}
                  active={name === filters[FILTERS.EVENT_NAME]}
                  onClick={() => onSelectEventName(name)}
                >
                  {name}
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.Item disabled>{t("Empty")}</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      }
      childrenFooter={<span>{tt("Total N", report.total)}</span>}
      isLoading={isLoading}
    >
      <div className="chart w-100">
        <Chart
          options={report.options}
          series={report.data}
          type="area"
          height="200"
        />
      </div>
    </ReportCard>
  );
};

export default ReportEventsCard;
