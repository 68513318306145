import React from "react";
import { Navbar } from "react-bootstrap";
import useSidebar from "../../hooks/useSidebar";

// const notifications = [
//   {
//     type: "important",
//     title: "Update completed",
//     description: "Restart server 12 to complete the update.",
//     time: "2h ago",
//   },
//   {
//     type: "default",
//     title: "Lorem ipsum",
//     description: "Aliquam ex eros, imperdiet vulputate hendrerit et.",
//     time: "6h ago",
//   },
//   {
//     type: "login",
//     title: "Login from 192.186.1.1",
//     description: "",
//     time: "6h ago",
//   },
//   {
//     type: "request",
//     title: "New connection",
//     description: "Anna accepted your request.",
//     time: "12h ago",
//   },
// ];

// const messages = [
//   {
//     name: "Ashley Briggs",
//     avatar: avatar5,
//     description: "Nam pretium turpis et arcu. Duis arcu tortor.",
//     time: "15m ago",
//   },
//   {
//     name: "Chris Wood",
//     avatar: avatar1,
//     description: "Curabitur ligula sapien euismod vitae.",
//     time: "2h ago",
//   },
//   {
//     name: "Stacie Hall",
//     avatar: avatar4,
//     description: "Pellentesque auctor neque nec urna.",
//     time: "4h ago",
//   },
//   {
//     name: "Bertha Martin",
//     avatar: avatar3,
//     description: "Aenean tellus metus, bibendum sed, posuere ac, mattis non.",
//     time: "5h ago",
//   },
// ];

const NavbarComponent = () => {
  // const { t } = useTranslation();
  const { isOpen, setIsOpen } = useSidebar();
  // const { notifications } = useNotifications();
  // const [showWordpress, setShowWordpress] = useState();
  return (
    <>
      <Navbar
        variant="light"
        expand
        className="navbar-bg justify-content-start"
      >
        <span
          className="sidebar-toggle d-flex"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <i className="hamburger align-self-center">
            <div className="sidebar-toggle-area" />
          </i>
        </span>

        {/* <Form inline="true" className="d-none d-sm-inline-block">
          <InputGroup hidden className="input-group-navbar">
            <Form.Control
              disabled
              placeholder={t("Search")}
              aria-label="Search"
            />
            <Button variant="">
              <Search className="feather" />
            </Button>
          </InputGroup>
        </Form> */}

        {/* <Navbar.Collapse>
          <Nav className="navbar-align"> */}
        {/* <NavbarDropdown
            header="New Messages"
            footer="Show all messages"
            icon={MessageCircle}
            count={messages.length}
            showBadge
          >
            {messages.map((item, key) => {
              return (
                <NavbarDropdownItem
                  key={key}
                  icon={
                    <img
                      className="avatar img-fluid rounded-circle"
                      src={item.avatar}
                      alt={item.name}
                    />
                  }
                  title={item.name}
                  description={item.description}
                  time={item.time}
                  spacing
                />
              );
            })}
          </NavbarDropdown> */}

        {/* <a
              target="_blank"
              rel="noreferrer"
              href="/"
              className="nav-link nav-icon me-2"
              onClick={(e) => {
                e.preventDefault();
                setShowWordpress(true);
              }}
            >
              <img alt="Wordpress" src={wordpress} width="20" height="20" />
            </a> */}

        {/* <NavbarDropdown
              header={
                notifications.length
                  ? "Notifications"
                  : "You have no notifications"
              }
              footer={notifications.length > 5 && "Show all notifications"}
              icon={Bell}
              // icon={notifications.length ? Bell : BellOff}
              // count={notifications.length > 0 && notifications.length}
              showBadge={
                notifications.filter(({ type }) => type === "important").length
              }
            >
              {notifications.map((item, key) => {
                let icon = <Bell size={18} className="text-warning" />;

                if (item.type === "important") {
                  icon = <AlertCircle size={18} className="text-danger" />;
                }

                if (item.type === "login") {
                  icon = <Home size={18} className="text-primary" />;
                }

                if (item.type === "request") {
                  icon = <UserPlus size={18} className="text-success" />;
                }

                return (
                  <NavbarDropdownItem
                    key={key}
                    icon={icon}
                    title={item.title}
                    description={item.description}
                    time={item.time}
                    href={item.href}
                  />
                );
              })}
            </NavbarDropdown> */}

        {/* <a
              target="_blank"
              rel="noreferrer"
              href={provider.httpURL(config.links?.help)}
              className="nav-link nav-icon me-2"
            >
              <HelpCircle className="feather" />
            </a> */}

        {/* <NavbarLanguages /> */}
        {/* <NavbarUser /> */}
        {/* </Nav>
        </Navbar.Collapse> */}
      </Navbar>
      {/* <Wordpress
        open={showWordpress}
        setOpen={(value) => setShowWordpress(value)}
      /> */}
    </>
  );
};

export default NavbarComponent;
