import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import CreateGPTBot from "../../components/bots/gpt_create/CreateGPTBot";
import Scrollbar from "../../components/Scrollbar";
import { useTranslation } from "../../hooks/useLocalization";

import StepedProgress from "../../components/StepedProgress";
import useAnalytics from "../../hooks/useAnalytics";


const CreateGPTBotModal = ({ bot, open, setOpen, onComplete }) => {
  const { t } = useTranslation();
  const { name, description, isUpdating } = bot || {};
  const [createBotBridge, setOnSubmit] = useState({ onSubmit: null });
  const { CONSOLE_EVENTS } = useAnalytics();
  const [page, setPage] = useState(0);
  const [nameIsValid, validateName] = useState(false);
  const onClose = async () => {
    setOpen(false);
    await CONSOLE_EVENTS.EV_CreateGPT.send({ action: 'close' })
  }
  const page_count = 5

  const getNextButton = () => {
    if (page + 1 < page_count)
      return <Button disabled={!!isUpdating || !nameIsValid} variant="primary"
        onClick={async () => {
          if (page < page_count) {
            setPage(page + 1);
            await CONSOLE_EVENTS.EV_CreateGPT.send({ action: 'step_' + (page + 2) })
          }
        }}
      >
        {t("Next")}
      </Button>
    else
      return (
        <>
          <Button disabled={!!isUpdating} variant="primary"
            onClick={() => {
              createBotBridge.onSubmit();
              window.gtag('event', "bots_new_gpt_bot_create")
            }}
          >
            {t("Create Bot")}
          </Button>
        </>);
  };

  return (
    <Modal show={open} onHide={() => { onClose(); setPage(0); validateName(false); }} size="lg" enforceFocus={false}>
      <Helmet title={t("New Assistant")} />
      <Modal.Header closeButton>{t("New Assistant")}</Modal.Header>
      <StepedProgress activeKey={page} max={page_count} />
      <div className="modal-body-container ps-dark">
        <Scrollbar>
          <Modal.Body className="m-4 mt-0 mb-0">
            <CreateGPTBot
              page={page}
              name={name}
              validateName={validateName}
              description={description}
              setOnSubmit={(onSubmit) => setOnSubmit({ onSubmit })}
              onComplete={(bot) => {
                setOpen(false);
                onComplete && onComplete(bot);
              }}
            />
          </Modal.Body>
        </Scrollbar>
      </div>
      <Modal.Footer>
        <Button disabled={!!isUpdating} variant="secondary"
          onClick={async () => {
            if (page <= 0) {
              onClose(); setPage(0);
              validateName(false);
            } else {
              setPage(page - 1);
              await CONSOLE_EVENTS.EV_CreateGPT.send({ action: 'step_' + (page + 2) });
            }
          }}
        >
          {t("Back")}
        </Button>

        <Button disabled={!!isUpdating || page <= 0 || page >= page_count - 1} variant="light"
          onClick={async () => {
            if (page < page_count) {
              setPage(page + 1);
              await CONSOLE_EVENTS.EV_CreateGPT.send({ action: 'skip_step_' + (page + 2) })
            }
          }}
        >
          {t("Skip")}
        </Button>
        {getNextButton()}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateGPTBotModal;
