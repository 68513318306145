import i18n from "i18next";
import { initReactI18next } from "./hooks/useLocalization";

import en from "./languages/lang_en";
import uk from "./languages/lang_uk";

const resources = {
  en: { ...en },
  uk: { ...uk },
};

const language = (window.navigator.language || "en").split("-")[0];
const storedLanguage = window.localStorage.getItem("language");
const lng =
  (storedLanguage === "ua" ? "uk" : storedLanguage) || // Исправление ошибки локализации
  (language === "ru" ? "uk" : language); // Русский военный корабль - иди нахуй!

i18n.use(initReactI18next).init({
  lng,
  resources,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
