import { ProgressBar } from "react-bootstrap";
import { useTranslation } from "../hooks/useLocalization";


const StepedProgress = ({ activeKey, max, min = 0 }) => {
    const { tt } = useTranslation();
    return <>
        <ProgressBar
            default
            label={tt('Step N', activeKey + 1)}
            variant={`${activeKey < max ? "primary" : "success"}`}
            max={max} min={min} now={activeKey + 1}
            style={{ borderRadius: 0 }}
        >
        </ProgressBar>
    </>
};

export default StepedProgress;
