import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Save, X } from "react-feather";
// import { RunBotDebugContext } from "qubot";
const RunBotDebugContext = null;

const Slot = ({ slot }) => {
  const { setData, selectedSlot } = useContext(RunBotDebugContext);
  const textAreaRef = useRef(null);
  const [text, setText] = useState(textAreaRef.current?.value);

  const slotName = slot?.name;
  const selectedSlotName = selectedSlot?.name || "";
  const selectedSlotValue = selectedSlot?.value || "";

  useEffect(() => {
    if (textAreaRef.current && slotName === selectedSlotName) {
      const value = JSON.stringify(selectedSlotValue, null, "\t");
      textAreaRef.current.value = value;
      setText(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSlotValue]);

  const saveHandler = async (clear) => {
    await setData({
      slots: [
        {
          name: selectedSlotName,
          value: clear ? "" : JSON.parse(text),
        },
      ],
    });
  };

  const selectedThis = slotName === selectedSlotName;
  const theSameValue = text === JSON.stringify(selectedSlotValue, null, "\t");
  const isSelectedEmptyValue = selectedSlotValue === "";

  let isTextInvalid = false;
  try {
    JSON.parse(text || null);
  } catch (e) {
    isTextInvalid = true;
  }

  return (
    <>
      <div className="mb-3">
        <Form.Control
          as="textarea"
          type="text"
          rows="3"
          placeholder="Waiting for value..."
          ref={textAreaRef}
          onChange={(e) => setText(e.target.value)}
          isInvalid={isTextInvalid}
        />
      </div>
      <div className="bg-white">
        <Button
          size="sm"
          className="me-2 mb-2"
          variant="outline-danger"
          onClick={() => saveHandler(true)}
          disabled={!selectedThis || isSelectedEmptyValue}
        >
          <X className="feather" />
          <span>Clear</span>
        </Button>
        <Button
          size="sm"
          className="float-end me-2 mb-2"
          variant={
            selectedThis && !theSameValue
              ? "outline-primary"
              : "outline-secondary"
          }
          onClick={() => saveHandler()}
          disabled={!selectedThis || theSameValue || isTextInvalid}
        >
          <Save className="feather" />
          <span>Save changes</span>
        </Button>
      </div>
    </>
  );
};

export default Slot;
