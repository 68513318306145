import React from "react";
import { useTable, useSortBy } from "react-table";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import Scrollbar from "../Scrollbar";
import useAuth from "../../hooks/useAuth";
import ShowPayments from "../permissions/ShowPayments";
import DeleteAcc from "../permissions/DeleteAcc";
import EditUserRoles from "../permissions/EditUserRoles";
import api from "../../net/api";

const defaultPropGetter = () => ({});
const defaultRendererGetter = () => null;

const AdminUsersTable = ({
  refresh,
  id,
  columns,
  data,
  wheelPropagation,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getHeaderProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  getCellRenderer = defaultRendererGetter,
  getCellRendererProps = defaultPropGetter,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  const { checkPermission, checkRole } = useAuth();

  const showPermissions = (user) => {
    return (
      <>
        {checkPermission(api.PERMISSION.ACC_UPDATE) && <EditUserRoles account={user} />}
        {<ShowPayments account={user} />}
        {checkRole(api.ROLES.SUPER_ADMIN) && <DeleteAcc account={user} refresh={refresh} />}
      </>
    )
  }

  return (
    <div className="ps-dark ps-overflow-hidden">
      <Scrollbar wheelPropagation={wheelPropagation}>
        <Table bordered {...getTableProps()} id={id}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                style={{ borderTop: 0 }}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      column.getSortByToggleProps(),
                      getColumnProps(column),
                      getHeaderProps(column),
                    ])}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} className="ms-2" />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps(getRowProps(row))} key={index}>
                  {row.cells.map((cell) => {
                    if (cell.column.Header === "Actions") {
                      return (
                        <td {...cell.getCellProps([getCellProps(cell)])}>
                          {showPermissions(row.original)}
                        </td>
                      )
                    } else {
                      return (
                        <td {...cell.getCellProps([getCellProps(cell)])}>
                          {cell.render(
                            getCellRenderer(cell) || "Cell",
                            getCellRendererProps(cell)
                          )}
                        </td>
                      )
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Scrollbar>
    </div>
  );
};

export default AdminUsersTable;