import { useEffect, useMemo, useCallback, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  OverlayTrigger,
  ProgressBar,
  Row,
  Tooltip,
} from "react-bootstrap";
import { RefreshCw, RotateCw, ShoppingBag } from "react-feather";
import { useNavigate } from "react-router-dom";
import useBilling from "../../hooks/useBilling";
import RefreshAlert from "../../components/bots/RefreshAlert";
import ColumnSortingTable from "../tables/ColumnSortingTable";
import AntiSpam from "../../utils/spam";
import {
  getStatusRendererProps,
  PaymentStatus,
  PaymentAmount,
  getAmountRendererProps,
  PaymentPeriod,
  getPeriodRendererProps,
  getPrice,
} from "./utils";
import useMessage from "../../hooks/useMessage";
import { useTranslation } from "../../hooks/useLocalization";
import { BillingPlan } from "../../contexts/billing/BillingContext";

const antiSpam = new AntiSpam();

const Payments = () => {
  const { t, tt, tts } = useTranslation();
  const navigate = useNavigate();
  const {
    isInitialized,
    isProbilled,
    payments,
    paymentsPending,
    settings,
    isLoading,
    loadingError,
    refreshPayments,
    setBillingSettings,
    getSubscriptionByProduct,
    payInvoice,
    getProduct,
  } = useBilling();
  const { showMessageWithDebug } = useMessage();
  const [isPaying, setPaying] = useState();
  const [autoBilling, setAutoBilling] = useState(settings.autoBilling);

  const getPaymentCurrency = useCallback(
    (payment) =>
      (payment.products || [])
        .map((segment) => getProduct(segment))
        .reduce(
          (currency, product) => currency || product?.price?.currency,
          null
        ),
    [getProduct]
  );

  const calcPaymentAmount = useCallback(
    (payment) =>
      (payment.products || [])
        .map((segment) => getProduct(segment))
        .reduce((amount, product) => amount + (product?.price?.value || 0), 0),
    [getProduct]
  );

  const getProductDestination = useCallback(
    (product) => {
      const subscription = getSubscriptionByProduct(product?.productID);
      return subscription
        ? tt("X subscription", t(BillingPlan.title(subscription.id)))
        : t("Third-party services");
    },
    [getSubscriptionByProduct, t, tt]
  );

  const getPaymentAmountDetails = useCallback(
    (payment) =>
      (payment.products || [])
        .map((segment) => getProduct(segment))
        .map(
          (product) => ({
            name: getProductDestination(product),
            ...product?.price,
          }),
          0
        ) || 0,
    [getProduct, getProductDestination]
  );

  const payAmount = useMemo(
    () => paymentsPending?.reduce((p, c) => p + calcPaymentAmount(c), 0) || 0,
    [paymentsPending, calcPaymentAmount]
  );

  const payCurrency = useMemo(
    () => paymentsPending?.reduce((p, c) => getPaymentCurrency(c), 0) || null,
    [paymentsPending, getPaymentCurrency]
  );

  const refresh = async () => {
    antiSpam.action(async () => {
      await refreshPayments();
      setAutoBilling(settings.autoBilling);
    });
  };

  useEffect(() => {
    if (isInitialized) refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, isProbilled]);

  const onChangeAutoBilling = async (event) => {
    try {
      const { target } = event;
      const autoBilling = !!target?.checked;
      setAutoBilling(autoBilling);
      await setBillingSettings({ autoBilling });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    }
  };

  const onClickPay = async (event) => {
    try {
      setPaying(true);
      await payInvoice();
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setPaying(false);
    }
  };

  const headers = useMemo(() => ["period", "amount", "status"], []);

  const MIN_ROWS = 5;
  const rows = useMemo(
    () =>
      payments
        .map((payment) => {
          const { time, endTime, status } = payment;
          return {
            period: { time, endTime },
            amount: {
              value: calcPaymentAmount(payment),
              currency: getPaymentCurrency(payment),
              details: getPaymentAmountDetails(payment),
            },
            status: { value: status, payInvoice },
          };
        })
        .concat(
          new Array(Math.max(MIN_ROWS - payments.length, 0)).fill(
            Object.assign({}, ...headers.map((key) => ({ [key]: "" })))
          )
        ),
    [
      payments,
      headers,
      payInvoice,
      getPaymentCurrency,
      calcPaymentAmount,
      getPaymentAmountDetails,
    ]
  );

  const tableColumns = headers.map((name) => ({
    Header: t(
      typeof name === "string" ? name[0].toUpperCase() + name.substr(1) : name
    ),
    accessor: name,
  }));

  const tableData = rows;

  return (
    <>
      <Card className="flex-fill mb-4">
        <Card.Body className="payments-header">
          <Row className="mt-n1">
            <Col className="d-flex gap-2 align-items-center">
              <Button
                variant="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/account/pricing");
                }}
              >
                <ShoppingBag className="feather me-2" />
                {t("Manage subscription")}
              </Button>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    {autoBilling
                      ? t("Auto billing is active")
                      : t("Auto billing deactivated")}
                  </Tooltip>
                }
              >
                <Form.Check
                  type="switch"
                  id="enabled"
                  className="ms-2"
                  label={t("Auto billing")}
                  checked={autoBilling}
                  onChange={async (event) => {
                    event.stopPropagation();
                    await onChangeAutoBilling(event);
                  }}
                />
              </OverlayTrigger>
              <nav className="ms-auto" />
              <Button
                variant="outline-primary"
                className="shadow-sm"
                disabled={isLoading}
                onClick={refresh}
              >
                {isLoading ? (
                  <RotateCw className="feather fa-spin" />
                ) : (
                  <RefreshCw className="feather" />
                )}
                <span className="ms-2">{t("Refresh")}</span>
              </Button>
            </Col>
          </Row>
          <ProgressBar
            className={`position-absolute mt-2 ${isLoading ? "" : "opacity-0"}`}
            striped
            animated
            variant="primary"
            now={isLoading ? 90 : 0}
          />
        </Card.Body>
      </Card>
      {loadingError && (
        <Col md="12" className="mb-4">
          <RefreshAlert title="Data" error={loadingError} onRetry={refresh} />
        </Col>
      )}
      <Container fluid className="p-0">
        <Row>
          <Col md="12">
            <Card>
              <ColumnSortingTable
                isHidden={false}
                columns={tableColumns}
                data={tableData}
                getCellRenderer={(cell) =>
                  (cell.column.id === "period" && PaymentPeriod) ||
                  (cell.column.id === "amount" && PaymentAmount) ||
                  (cell.column.id === "status" && PaymentStatus)
                }
                getCellRendererProps={(cell) =>
                  (cell.column.id === "period" &&
                    getPeriodRendererProps(cell)) ||
                  (cell.column.id === "amount" &&
                    getAmountRendererProps(cell)) ||
                  (cell.column.id === "status" && getStatusRendererProps(cell))
                }
              />
              {payAmount > 0 && (
                <Button
                  size="lg"
                  variant="primary"
                  className="my-4 mx-auto"
                  onClick={async () => await onClickPay()}
                >
                  {isPaying && <RotateCw className="feather fa-spin me-2" />}
                  {tt(
                    "Pay on invoice",
                    getPrice(payAmount, payCurrency, { t, tts })
                  )}
                </Button>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Payments;
