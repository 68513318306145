import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const ZoomImage = ({ children }) => {
  return (
    <div className="zoom-image mb-2">
      <Zoom>{children}</Zoom>
    </div>
  );
};

export default ZoomImage;
