import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, ProgressBar } from "react-bootstrap";
import { RefreshCw, RotateCw } from "react-feather";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "../hooks/useLocalization";
import { uniqueArray } from "../utils/data";
import logger from "../utils/logger";
import AntiSpam from "../utils/spam";
import ColumnSortingTable from "./tables/ColumnSortingTable";

const antiSpam = new AntiSpam();

const ActivityReport = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const { activityReport, isInitialized } = useAuth();

  const refresh = async () => {
    antiSpam.action(async () => {
      try {
        setLoading(true);
        const users = await activityReport();
        setUsersData(users || []);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    if (isInitialized) refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized])

  const headers = useMemo(
    () =>
      uniqueArray(
        ["firstName", "lastName", "email", "phone", "companyName"].concat(
          usersData.reduce(
            (prev, item) => prev.concat(Object.keys(item).filter((key) => key !== "uid" && key !== "license")),
            []
          )
        )
      ),
    [usersData]
  );

  const tableColumns = headers.map((name) => ({
    Header: t(name),
    accessor: name,
  }));

  const tableData = usersData.map((val) =>
    Object.assign(
      {},
      ...Object.keys(val).map((key) => ({
        [key]:
          ["firstName", "lastName", "email", "phone", "companyName"].indexOf(key) < 0 &&
            val[key] &&
            typeof val[key] === "object"
            ? JSON.stringify(val[key])
            : key === "websiteURL"
              ? val[key].replace(/\//g, `/\n`)
              : val[key],
      }))
    )
  );

  return (
    <>
      <Card className="wordpress-header">
        <Card.Header className="d-flex align-items-center">
          <Card.Title tag="h5" className="mb-0 flex-1">
            {t("Activity Report")}
          </Card.Title>
          <Button
            variant="outline-primary"
            className="shadow-sm me-2"
            onClick={downloadCSV}
          >{t("Download CSV")}
          </Button>
          <Button
            variant="outline-primary"
            className="shadow-sm"
            disabled={isLoading}
            onClick={refresh}
          >
            {isLoading ? (
              <RotateCw className="feather fa-spin" />
            ) : (
              <RefreshCw className="feather" />
            )}
            <span className="ms-2">{t("Refresh")}</span>
          </Button>
        </Card.Header>
        <ProgressBar
          className={`my-2 mx-3 ${isLoading ? "" : "opacity-0"}`}
          striped
          animated
          variant="primary"
          now={isLoading ? 90 : 0}
        />
      </Card>
      <Card>
        <ColumnSortingTable isHidden={true} columns={tableColumns} data={tableData} wheelPropagation={true} id="activity-report-table" />
      </Card>
    </>
  )
}

const downloadCSV = () => {
  let CSV = tableToCSV("activity-report-table");
  if (!CSV) return
  let temp_link = document.createElement('a');
  temp_link.download = "UsersActivityReport.csv";
  let universalBOM = "\uFEFF";
  temp_link.href = 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + CSV);
  temp_link.style.display = "none";
  document.body.appendChild(temp_link);
  temp_link.click();
  document.body.removeChild(temp_link);
}

const tableToCSV = (elementID) => {
  let csv_data = [];
  let element = document.getElementById(elementID)

  if (!element) {
    logger.error(`Element not found (${elementID})`)
    return null
  }

  let rows = element.getElementsByTagName('tr');

  for (let i = 0; i < rows.length; i++) {
    let cols = rows[i].querySelectorAll('td,th');
    let csvrow = [];

    for (let j = 0; j < cols.length; j++) {
      let data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
      data = data.replace(/"/g, '""');
      csvrow.push(data);
    }

    csv_data.push(csvrow.join(","));
  }
  csv_data = csv_data.join('\n');
  return "sep=,\n" + csv_data;
}

export default ActivityReport;
