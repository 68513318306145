import Chart from "react-apexcharts";
import { useTranslation } from "../../../../hooks/useLocalization";
import ReportCard from "./ReportCard";

const ReportConversionCard = ({ report, proPlan, isLoading }) => {
  const { t, tt } = useTranslation();
  return (
    <ReportCard
      variant="warning"
      helpText={t("Aggregated event conversions for")}
      proPlan={proPlan}
      childrenHeader={<>{t("Conversion")}</>}
      childrenFooter={
        <span className="opacity-0">{tt("Total N", report.total)}</span>
      }
      isLoading={isLoading}
    >
      <div className="chart w-100">
        <Chart
          options={report.options}
          series={report.data}
          type="bar"
          height="200"
        />
      </div>
    </ReportCard>
  );
};

export default ReportConversionCard;
