import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  OverlayTrigger,
  Tooltip,
  Row,
  Form,
  Spinner,
  Col,
} from "react-bootstrap";
import { Activity, ExternalLink } from "react-feather";
import { useTranslation } from "../../../../hooks/useLocalization";
import ChannelDisableModal from "../../../../modals/bots/ChannelDisable";
import ProLock from "../../../pricing/ProLock";

const ChannelCard = ({
  loading,
  enabling,
  proPlan,
  icon: Icon,
  name,
  channel,
  handlers,
}) => {
  const { t } = useTranslation();
  const { enabled: defaultEnabled, validated } = channel || {};
  const { onSelect, onLink, onEnable } = handlers || {};
  const [enabled, setEnabled] = useState(defaultEnabled);
  const hover = useHoverState();
  const enableRef = useRef();
  const enableBlocked = proPlan && validated;
  const [disableModal, setDisableModal] = useState();

  useEffect(() => {
    setEnabled(defaultEnabled);
    if (enableRef.current) {
      enableRef.current.checked = defaultEnabled;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultEnabled]);

  const renderEnable = (lock) => (
    <Button
      variant=""
      className={`p-2 ${enabling ? "cursor-progress" : ""}`}
      {...hover.enable}
      disabled={!validated}
      onClickCapture={(event) => {
        if (enabling) {
          event.preventDefault();
          event.stopPropagation();
        }
      }}
      onClick={async (event) => {
        event.stopPropagation();
        const target = enableRef.current;
        const checked = !!target?.checked;
        const enabled = !checked;
        if (!enabled) {
          setDisableModal(true);
          return;
        }
        enableRef.current.checked = enabled;
        await onEnable(enabled);
        setEnabled(enabled);
      }}
    >
      {lock ? (
        <ProLock proPlan={proPlan} selected={hover.state.body} type="switch" />
      ) : (
        <Form.Check
          ref={enableRef}
          type="switch"
          id="enabled"
          className="ps-0 pe-none"
          defaultChecked={enabled}
        />
      )}
    </Button>
  );

  return (
    <>
      <Card
        className={`channel-card flex-fill text-center ${(validated || name === "Wordpress") ? "channel-card-selected" : ""
          }`}
        {...hover.body}
        onClick={() => !loading && onSelect()}
      >
        <Card.Body className="channel-card-text pb-0">
          <div className="channel-card-header d-flex">
            <Col>
              <Row xs="auto" className="w-100 align-items-center">
                <Icon className="channel-card-logo mb-2" />
                <span className="mb-2 text-truncate-1 lead ps-1">
                  {name || t("Unknown")}
                </span>
              </Row>
            </Col>
          </div>
          <div className="channel-card-body">
            <div className="mb-0">
              {loading && (
                <Spinner
                  animation="border"
                  variant="secondary"
                  className="mt-4"
                  size="sm"
                />
              )}
            </div>
          </div>
        </Card.Body>
        {!loading && (
          <>
            <hr className="m-0" />
            <Card.Body className="p-0">
              <Row
                xs="auto"
                className="channel-card-footer align-items-center justify-content-center"
              >
                {!(enableBlocked || validated) && (
                  <span className="text-truncate-1 text-primary">
                    {t("Click to configure")}
                  </span>
                )}
                {onEnable &&
                  (enableBlocked ? (
                    renderEnable(true)
                  ) : (
                    <OverlayTrigger
                      show={hover.state.enable}
                      placement="bottom"
                      overlay={
                        <Tooltip>
                          {enabled
                            ? t("Channel is active")
                            : t("Channel deactivated")}
                        </Tooltip>
                      }
                    >
                      {renderEnable()}
                    </OverlayTrigger>
                  ))}
                {onLink && (
                  <OverlayTrigger
                    show={hover.state.link}
                    placement="bottom"
                    overlay={<Tooltip>{t("Open")}</Tooltip>}
                  >
                    <Button
                      variant=""
                      className={`text-secondary p-2 ${enabled ? "" : "opacity-25"
                        }`}
                      disabled={!enabled}
                      {...hover.link}
                      onClick={(e) => {
                        e.stopPropagation();
                        onLink();
                      }}
                    >
                      <ExternalLink className="feather w-100 h-100" />
                    </Button>
                  </OverlayTrigger>
                )}
                {validated && !(onEnable || onLink) && (
                  <Button disabled variant="" className="text-secondary">
                    <Activity className="feather" />
                  </Button>
                )}
              </Row>
            </Card.Body>
          </>
        )}
      </Card>
      <ChannelDisableModal
        channelName={channel.name}
        open={disableModal}
        setOpen={setDisableModal}
        onSubmit={async (disable) => {
          if (disable) {
            const enabled = !disable;
            enableRef.current.checked = enabled;
            await onEnable(enabled);
            setEnabled(enabled);
          }
        }}
      />
    </>
  );
};

const useHoverState = () => {
  const [state, setState] = useState({});
  const hoverSome = useCallback(
    (key) => ({
      onMouseEnter: () => setState({ [key]: true }),
      onMouseLeave: () => setState({ [key]: false }),
      onMouseDown: (event) => {
        event.preventDefault();
        setState({ [key]: false });
      },
    }),
    [setState]
  );
  return {
    state,
    body: hoverSome.bind(null, "body")(),
    link: hoverSome.bind(null, "link")(),
    enable: hoverSome.bind(null, "enable")(),
  };
};

export default ChannelCard;
