import React, { forwardRef, useCallback, useMemo, useState } from "react";
import {
  Button,
  Card,
  OverlayTrigger,
  Popover,
  Tooltip,
  Row,
  Badge,
} from "react-bootstrap";
import {
  Download,
  RotateCw,
  PieChart,
  Settings,
  Grid,
  Database,
  HardDrive,
  Package,
  Copy,
  Play,
} from "react-feather";
import IconBot from "./BotIcon";
import useClipboard from "../../../hooks/useClipboard";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "../../../hooks/useLocalization";
import useTutorial from "../../../hooks/useTutorial";

import { ChatGPTIcon } from "../../bots/gptsettings/icons/ChatGPTIcon";

import useAnalytics from "../../../hooks/useAnalytics";

const BotCard = forwardRef(({
  id,
  name,
  kind,
  description,
  iconSrc,
  author,
  bundle,
  bot_type,
  isDownload,
  selected,
  statusIcon: StatusIcon,
  statusText,
  statusVariant,
  search,
  matchSearch,
  showInfo,
  showKind,
  handlers,
  isAboutUs,
}, ref) => {
  const {
    showTuto2_1,
    showTuto2_2,
    showTuto2_3,
    setTutoSTorage,
    tutoStorage,
    setProp,
    updateStep,
    tutorials,
    setTutorials,
    tuto_2_3,
    checkTuto2Complete,
  } = useTutorial();
  const { t } = useTranslation();
  const {
    onSelect,
    onFiles,
    onDownload,
    onClone,
    onPlay,
    onManage,
    onChannels,
    onReports,
    onData,
  } = handlers || {};

  const hover = useHoverState();
  const { onCopyEvent } = useClipboard("Bot bundle");
  const { CONSOLE_EVENTS } = useAnalytics();

  const renderSearch = (value) => {
    if (search && typeof value === "string") {
      const [from, to] = matchSearch(search, value) || [];
      if (from > -1 && to > -1) {
        return (
          <>
            {value.substring(0, from)}
            <mark>{value.substring(from, to)}</mark>
            {value.substr(to)}
          </>
        );
      }
    }
    return value;
  };

  const infoText = useMemo(
    () =>
      `${t("By")} ${author?.displayName || "unknown"}\n${statusText}\n${bundle || id
      }`,
    [author?.displayName, statusText, bundle, id, t]
  );

  const infoMark = useMemo(
    () =>
      search &&
      [author?.displayName, bundle, id].some((value) => {
        const [from, to] = matchSearch(search, value) || [];
        return from > -1 && to > -1;
      }),
    [search, matchSearch, author?.displayName, bundle, id]
  );

  const className = useMemo(
    () =>
      `text-secondary p-3 ${Object.keys(handlers).length > 5 ? "ps-2 pe-2" : ""
      }`,
    [handlers]
  );

  const tutover1 = (
    <Popover className="tuto-popover">
      <Popover.Header as="h3" className="tuto-popover-header">1.</Popover.Header>
      <Popover.Body>
        {t("Tutover2_1")}
      </Popover.Body>
    </Popover>
  );

  const tutover2 = (
    <Popover className="tuto-popover">
      <Popover.Header as="h3" className="tuto-popover-header">2.</Popover.Header>
      <Popover.Body>
        {t("Tutover2_3")}
      </Popover.Body>
    </Popover>
  );

  const tutover3 = (
    <Popover className="tuto-popover">
      <Popover.Header as="h3" className="tuto-popover-header">3.</Popover.Header>
      <Popover.Body>
        {t("Tutover2_2")}
      </Popover.Body>
      <Popover.Header className="tuto-popover-footer">{t("Click anywhere")}</Popover.Header>
    </Popover>
  );

  const tutover4 = (
    <Popover className="tuto-popover">
      <Popover.Header as="h3" className="tuto-popover-header">1.</Popover.Header>
      <Popover.Body>
        {t("Tutover2_4")}
      </Popover.Body>
    </Popover>
  );

  const tutover5 = (
    <Popover className="tuto-popover">
      <Popover.Header as="h3" className="tuto-popover-header">1.</Popover.Header>
      <Popover.Body>
        {t("Tutover2_5")}
      </Popover.Body>
    </Popover>
  );

  return (
    <Card
      className={`flex-fill text-center bot-card ${selected ? "bot-card-selected" : ""
        }`}
      {...hover.body}
      onClick={async () => {
        onSelect();
        await CONSOLE_EVENTS.EV_SelectBot.send({ params: { bid: id, bot_type: bot_type || 'default' } })
      }}
    >
      <div className="position-absolute w-100 text-end bot-card-info d-flex justify-content-between">
        {showInfo && (
          <>
            {(bot_type === 'gpt') ?
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    {t('GPT Bot Card Info')}
                  </Tooltip>
                }
              >
                <div
                  className="info-toggle-area cursor-help d-flex justify-content-center align-items-center"
                  style={{ width: '40px', height: '40px', top: '3px', left: '0px', right: '' }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <ChatGPTIcon /> </div>
              </OverlayTrigger> :
              ' '}
            <OverlayTrigger
              show={hover.state.info}
              placement="bottom"
              overlay={
                <Tooltip>
                  {infoText.split("\n").map((line, index) => (
                    <p key={index} className="m-0">
                      {line}
                    </p>
                  ))}
                </Tooltip>
              }
            >
              <div
                className="info-toggle-area cursor-copy d-flex justify-content-center align-items-center"
                style={{ width: '40px', height: '40px' }}
                {...hover.info}
                onClick={(e) => {
                  e.stopPropagation();
                  onCopyEvent(bundle || id);
                }}
              >
                {infoMark ? (
                  <mark>
                    <FontAwesomeIcon icon={faInfo} />
                  </mark>
                ) : (
                  <FontAwesomeIcon icon={faInfo} />
                )}
              </div>
            </OverlayTrigger>
          </>
        )}
      </div>
      <Card.Body className="pb-0 bot-card-text">
        <div className="bot-card-header d-flex align-items-start pt-2">
          <div className="flex-grow-1 mb-1">
            <IconBot kind={kind} src={iconSrc} className="mb-2" />
            <OverlayTrigger
              show={showTuto2_1 && isAboutUs}
              placement="right"
              overlay={tutover1}
            >
              <h3 className="mb-2 text-truncate-2 text-break">
                {renderSearch(name)}
              </h3>
            </OverlayTrigger>
            {/* <p>by {renderSearch(author?.displayName)}</p> */}
          </div>
        </div>
        <div className="bot-card-body">
          <div className="mb-0">
            <p className="text-truncate-4">{description}</p>
            {/* <Badge bg="" className={`badge-soft-${statusVariant} me-1`}>
              <StatusIcon className="me-1" size={12} />
              {statusText}
            </Badge> */}
            {/* <div className="mb-n1">
              <Badge
                bg=""
                className="badge-soft-secondary me-2 mt-1 cursor-copy"
                {...hover.badges}
                onClick={(e) => {
                  e.stopPropagation();
                  onCopyEvent(e);
                }}
              >
                {renderSearch(bundle || id)}
              </Badge>
            </div> */}
          </div>
        </div>
      </Card.Body>
      <hr className="m-0" />
      <Card.Body className="p-0">
        <Row
          xs="auto"
          className="bot-card-footer text-end justify-content-center"
        >
          {onClone && (
            <>
              <OverlayTrigger
                show={showTuto2_1 && isAboutUs}
                placement="left"
                overlay={tutover2}
              >
                <div className="p-0"></div>
              </OverlayTrigger>
              <OverlayTrigger
                show={showTuto2_3 && isAboutUs}
                placement="left"
                overlay={tutover5}
              >
                <div className="p-0"></div>
              </OverlayTrigger>
              <OverlayTrigger
                show={hover.state.clone}
                placement="bottom"
                overlay={<Tooltip>{t("Clone")}</Tooltip>}
              >
                <Button
                  variant=""
                  className={className}
                  {...hover.clone}
                  onClick={async (e) => {
                    e.stopPropagation();
                    onClone({ id, kind, name, description });
                    await CONSOLE_EVENTS.EV_CloneBot.send({ params: { bid: id } })
                  }}
                >
                  <Copy className="feather w-100 h-100" />
                </Button>
              </OverlayTrigger>
            </>
          )}
          {onManage && (
            <OverlayTrigger
              show={hover.state.manage}
              placement="bottom"
              overlay={<Tooltip>{t("General Settings")}</Tooltip>}
            >
              <Button
                variant=""
                className={className}
                {...hover.manage}
                onClick={async (e) => {
                  await CONSOLE_EVENTS.EV_Locate.send({ params: { href: '/bots/settings' } })
                  e.stopPropagation();
                  onManage(e);
                }}
              >
                <Settings className="feather w-100 h-100" />
              </Button>
            </OverlayTrigger>
          )}
          {onFiles && (
            <OverlayTrigger
              show={hover.state.edit}
              placement="bottom"
              overlay={<Tooltip>{t("Backup/Restore")}</Tooltip>}
            >
              <Button
                variant=""
                className={className}
                {...hover.edit}
                onClick={(e) => {
                  e.stopPropagation();
                  onFiles(e);
                }}
              >
                <HardDrive className="feather w-100 h-100" />
              </Button>
            </OverlayTrigger>
          )}
          {onDownload && (
            <OverlayTrigger
              placement="bottom"
              show={hover.state.download && !isDownload}
              overlay={<Tooltip>{t("Download Archive")}</Tooltip>}
            >
              <Button
                variant=""
                className={className}
                disabled={isDownload}
                {...hover.download}
                onClick={(e) => {
                  e.stopPropagation();
                  onDownload(e);
                }}
              >
                {isDownload ? (
                  <RotateCw className="feather fa-spin w-100 h-100" />
                ) : (
                  <Download className="feather w-100 h-100" />
                )}
              </Button>
            </OverlayTrigger>
          )}
          {onChannels && (
            <OverlayTrigger
              show={hover.state.channels}
              placement="bottom"
              overlay={<Tooltip>{t("Integrations")}</Tooltip>}
            >
              <Button
                variant=""
                className={className}
                {...hover.channels}
                onClick={async (e) => {
                  await CONSOLE_EVENTS.EV_Locate.send({ params: { href: '/bots/channels' } })
                  e.stopPropagation();
                  onChannels(e);
                }}
              >
                <Grid className="feather w-100 h-100" />
              </Button>
            </OverlayTrigger>
          )}
          {onData && (
            <OverlayTrigger
              show={hover.state.data}
              placement="bottom"
              overlay={<Tooltip>{t("Storage")}</Tooltip>}
            >
              <Button
                variant=""
                className={className}
                {...hover.data}
                onClick={async (e) => {
                  await CONSOLE_EVENTS.EV_Locate.send({ params: { href: '/bots/data' } })
                  e.stopPropagation();
                  onData(e);
                }}
              >
                <Database className="feather w-100 h-100" />
              </Button>
            </OverlayTrigger>
          )}
          {onReports && (
            <OverlayTrigger
              show={hover.state.reports}
              placement="bottom"
              overlay={<Tooltip>{t("Analytics")}</Tooltip>}
            >
              <Button
                variant=""
                className={className}
                {...hover.reports}
                onClick={async (e) => {
                  await CONSOLE_EVENTS.EV_Locate.send({ params: { href: '/bots/analytics' } })
                  e.stopPropagation();
                  onReports(e);
                }}
              >
                <PieChart className="feather w-100 h-100" />
              </Button>
            </OverlayTrigger>
          )}
          {onPlay && (
            <>
              <OverlayTrigger
                show={hover.state.play}
                placement="bottom"
                overlay={<Tooltip>{t("Play")}</Tooltip>}
              >
                <Button
                  variant=""
                  className={className}
                  {...hover.play}
                  onClick={async (e) => {
                    if (!tutoStorage.tuto_2._2) {
                      tutoStorage.tuto_2._2 = true;
                      setProp("tutorial", tutoStorage)
                      setTutoSTorage(tutoStorage);
                      updateStep(1, 2, "from BotCard");
                      tutorials[1].onClick = tuto_2_3;
                      setTutorials([...tutorials])
                      checkTuto2Complete();
                    }
                    e.stopPropagation();
                    onPlay({ id, kind, name, description });
                    await CONSOLE_EVENTS.EV_RunBot.send({ params: { bid: id } })
                  }}
                >
                  <Play className="feather w-100 h-100" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                show={showTuto2_1 && isAboutUs}
                placement="right"
                overlay={tutover3}
              >
                <div className="p-0"></div>
              </OverlayTrigger>
              <OverlayTrigger
                show={showTuto2_2 && isAboutUs}
                placement="right"
                overlay={tutover4}
              >
                <div className="p-0"></div>
              </OverlayTrigger>
            </>
          )}
        </Row>
      </Card.Body>
      {showKind && (
        <Badge bg="" className="badge-soft-danger pt-1">
          <Package className="me-1" size={12} />
          {kind.charAt(0).toUpperCase() + kind.substring(1)}
        </Badge>
      )}
    </Card>
  );
});

const useHoverState = () => {
  const [state, setState] = useState({});
  const hoverSome = useCallback(
    (key) => ({
      onMouseEnter: () => setState({ [key]: true }),
      onMouseLeave: () => setState({ [key]: false }),
      onMouseDown: (event) => {
        event.preventDefault();
        setState({ [key]: false });
      },
    }),
    [setState]
  );
  return {
    state,
    body: hoverSome.bind(null, "body")(),
    edit: hoverSome.bind(null, "edit")(),
    clone: hoverSome.bind(null, "clone")(),
    play: hoverSome.bind(null, "play")(),
    manage: hoverSome.bind(null, "manage")(),
    download: hoverSome.bind(null, "download")(),
    channels: hoverSome.bind(null, "channels")(),
    reports: hoverSome.bind(null, "reports")(),
    data: hoverSome.bind(null, "data")(),
    badges: hoverSome.bind(null, "badges")(),
    info: hoverSome.bind(null, "info")(),
  };
};

export default BotCard;
