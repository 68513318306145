import { Dropdown, Nav } from "react-bootstrap";

const Navigation = ({ activeKey, items, onSelect }) => (
  <>
    <Nav
      variant="pills"
      className="flex-row navigation"
      activeKey={activeKey}
      onSelect={(eventKey) => onSelect(eventKey)}
    >
      {items.map(({ name, value }, index) => (
        <Nav.Item key={index}>
          <Nav.Link eventKey={value}>{name}</Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
    <Dropdown className="navigation-dropdown">
      <Dropdown.Toggle>
        {items.find(({ value }) => value === activeKey)?.name}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {items.map(({ name, value }, index) => (
          <Dropdown.Item
            key={index}
            active={value === activeKey}
            onClick={() => onSelect(value)}
          >
            {name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  </>
);

export default Navigation;
