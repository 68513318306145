import { Button, Col, Row } from "react-bootstrap";
import { Monitor } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../hooks/useLocalization";

const DesktopOnly = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Row className="text-center h-100 me-0">
      <Col md="12" xl className="align-self-center">
        <div className="mt-5 m-auto">
          <h4>
            <Monitor className="mb-2" size={64} />
            <br />
            Available on larger screens
          </h4>
          <Button
            variant="secondary"
            className="mt-1"
            onClick={() => navigate("/")}
          >
            {t("Back")}
          </Button>
        </div>
      </Col>
    </Row>
  );
};
export default DesktopOnly;
