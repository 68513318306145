import React from "react";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import LandingLayout from "./layouts/Landing";

// Guards
import EntryGuard from "./components/guards/EntryGuard";
import AuthGuard from "./components/guards/AuthGuard";
import RoutesGuard from "./components/guards/RoutesGuard";

// Landing
import Landing from "./pages/landing/Landing";

// Dashboards
import Bots from "./pages/dashboards/Bots";

// Pages
import Settings from "./pages/pages/Settings";
import Pricing from "./pages/pages/Pricing";
import Payments from "./pages/pages/Payments";
import TrafficPage from "./pages/pages/Traffic";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import SignUpVerify from "./pages/auth/SignUpVerify";
import SignUpComplete from "./pages/auth/SignUpComplete";
import SignUpFast from "./pages/auth/SignUpFast";
import SignXDeleteConfirm from "./pages/auth/SignXDeleteConfirm";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";

// Bots
import GeneralSettings from "./pages/bots/GeneralSettings";
import ImportExport from "./pages/bots/ImportExport";
import DeleteBot from "./pages/bots/DeleteBot";
import Testing from "./pages/bots/testing/Testing";
import Editor from "./pages/bots/editor/Editor";
import Setup from "./pages/bots/setup/Setup";
import Channels from "./pages/bots/Channels";
import GPTSettings from "./pages/bots/gptsettings/GPTSettings";
import NewGPTSource from "./pages/bots/gptsettings/NewGPTSource";
import GPTSources from "./pages/bots/gptsettings/GPTSources";
import Analytics from "./pages/bots/analytics/Analytics";
import BotData from "./pages/bots/data/BotData";

const routes = [
  {
    path: "/",
    element: (
      <EntryGuard>
        <AuthGuard>
          <RoutesGuard path="/" redirect="dashboard">
            <LandingLayout />
          </RoutesGuard>
        </AuthGuard>
      </EntryGuard>
    ),
    children: [
      {
        path: "",
        element: <Landing />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <EntryGuard>
        <AuthGuard>
          <RoutesGuard path="dashboard" redirect="bots">
            <DashboardLayout />
          </RoutesGuard>
        </AuthGuard>
      </EntryGuard>
    ),
    children: [
      // {
      //   path: "default",
      //   element: <Default />,
      // },
      {
        path: "bots",
        element: <Bots />,
      },
      // {
      //   path: "analytics",
      //   element: <Analytics />,
      // },
      // {
      //   path: "saas",
      //   element: <SaaS />,
      // },
      // {
      //   path: "social",
      //   element: <Social />,
      // },
      // {
      //   path: "crypto",
      //   element: <Crypto />,
      // },
    ],
  },
  {
    path: "account",
    element: (
      <EntryGuard>
        <AuthGuard>
          <RoutesGuard path="account" redirect="settings">
            <DashboardLayout />
          </RoutesGuard>
        </AuthGuard>
      </EntryGuard>
    ),
    children: [
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "payments",
        element: <Payments />,
      },
      {
        path: "traffic",
        element: <TrafficPage />,
      },
    ],
  },
  {
    path: "bots",
    element: (
      <EntryGuard>
        <AuthGuard>
          <RoutesGuard path="bots" redirect="editor">
            <DashboardLayout />
          </RoutesGuard>
        </AuthGuard>
      </EntryGuard>
    ),
    children: [
      {
        path: "settings",
        element: <GeneralSettings />,
      },
      {
        path: "files",
        element: <ImportExport />,
      },
      {
        path: "delete",
        element: <DeleteBot />,
      },
      {
        path: "testing",
        element: <Testing />,
      },
      {
        path: "editor",
        element: <Editor />,
      },
      {
        path: "setup",
        element: <Setup />,
      },
      {
        path: "channels",
        element: <Channels />,
      },
      {
        path: "new_source",
        element: <NewGPTSource />,
      },
      {
        path: "sources",
        element: <GPTSources />,
      },
      {
        path: "gptsettings",
        element: <GPTSettings />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "data",
        element: <BotData />,
      },
    ],
  },
  {
    path: "auth",
    element: (
      <EntryGuard>
        <RoutesGuard path="auth" redirect="sign-in">
          <AuthLayout />
        </RoutesGuard>
      </EntryGuard>
    ),
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "sign-up-verify",
        element: <SignUpVerify />,
      },
      {
        path: "sign-up-verify/:fast",
        element: <SignUpVerify />,
      },
      {
        path: "sign-up-complete",
        element: <SignUpComplete />,
      },
      {
        path: "quick-sign-up",
        element: <SignUpFast />,
      },
      {
        path: "sign-xdelete-confirm",
        element: <SignXDeleteConfirm />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
