import React, { useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form, Row, Col, FloatingLabel } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";

import avatar from "../../assets/img/avatars/no-avatar.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import useMessage from "../../hooks/useMessage";
import { useTranslation } from "../../hooks/useLocalization";

function SignXProfile({ dashboard }) {
  const { t } = useTranslation();
  const { user, updateProfile } = useAuth();
  const { showMessage } = useMessage();
  const nameRef = useRef();
  useEffect(() => nameRef.current?.focus(), [nameRef]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        displayName: user?.displayName || "",
      }}
      validationSchema={Yup.object().shape({
        displayName: Yup.string().max(255),
        //.required("Display name is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const { displayName } = values;
          await updateProfile({ displayName });
          if (dashboard) {
            showMessage({ save: "" });
          }
        } catch (error) {
          const { message = t("Something went wrong") } = error;
          if (dashboard) {
            showMessage({ error: message });
          }
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {/* {errors.submit && (
            <Alert variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )} */}
          <Row>
            <Col>
              <div className="text-center">
                <img
                  alt="avatar"
                  src={avatar}
                  className="rounded-circle img-responsive mt-2"
                  width="128"
                  height="128"
                />
                <div className="mt-2 mb-3">
                  <Button variant="primary" disabled>
                    <FontAwesomeIcon icon={faUpload} /> {t("Upload")}
                  </Button>
                </div>
              </div>
              <Form.Group className="mb-3">
                <FloatingLabel label={`${t("Display name")} *`}>
                  <Form.Control
                    required
                    type="text"
                    id="displayName"
                    name="displayName"
                    placeholder={`${t("Display name")} *`}
                    ref={nameRef}
                    value={values.displayName}
                    isInvalid={Boolean(
                      touched.displayName && errors.displayName
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.displayName && (
                    <Form.Control.Feedback type="invalid">
                      {errors.displayName}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Button type="submit" variant="primary" disabled={isSubmitting}>
            {t("Save changes")}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default SignXProfile;
