import React from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";

import QDLoader from "../../../components/QDLoader";
import BotData from "../../../components/bots/data/BotData";
import Header from "./Header";
import useBots from "../../../hooks/useBots";
import { useTranslation } from "../../../hooks/useLocalization";

const BotDataPage = () => {
  const { t } = useTranslation();
  const { isInitialized } = useBots();
  return (
    <React.Fragment>
      <Helmet title={t("Storage")} />
      <Container fluid className="p-0">
        {isInitialized && <Header />}
        {isInitialized ? <BotData /> : <QDLoader />}
      </Container>
    </React.Fragment>
  );
};

export default BotDataPage;
