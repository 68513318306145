import { atDevhost } from "../system/system";
import logger from "../utils/logger";
import provider from "./provider";
import { getTokens } from "./token";

// Server method
// ------------------------
class ServerMethod {
  constructor(method, extension = null, version = "v1") {
    var ins = new window.String(method);
    ins.version = version;
    ins.extension = extension;
    ins.toString = () => method;
    return ins;
  }
}

// API
// ------------------------
class api {
  // Methods: Auth
  static AUTH = {
    GET: "account", // Получить информацию о профиле
    LOGIN: "login", // Авторизироваться
    SOCIAL: "social", // Авторизироваться в социальной сети
    UPDATE: "account_update", // Обновить информацию с профиле
    REGISTER: "signup", // Зарегистрироваться
    REGISTER_CONFIRM: "account_verify", // Выполнить проверку email с authToken
    REGISTER_FINALIZE: "account_complete", // Завершить регистрацию с дополнительной информацией о пользователе
    PASSWORD_FORGOT: "account_forgot", // Запустить процесс восстановления пароля через email
    PASSWORD_RESTORE: "account_restore", // Установить новый пароль с authToken
    DELETE: "account_unnecessary", // Запросить удаление пользователя
    DELETE_CONFIRM: "account_delete", // Удалить пользователя
    FETCH: "accounts_fetch", // Получить публичную информацию о пользователях
    VERIFY_FAST: "account_verify_fast", // Выполнить проверку email с authToken
    REGISTER_FAST: "account_complete_fast", // Завершить быструю регистрацию
    LICENSE: "license_update",
    ROLES: "roles_fetch",
    PERMISSIONS: "permissions_fetch",
  };

  // Methods: Bot
  static BOT = {
    PING: "ping",
    RUN_STATE: "run_state",
    LAST_STATE: "last_state",
    USER_ACTION: "user_action",
    RUN_ACTION: "run_action",
    GET_DATA: "get_data",
    SET_DATA: "set_data",
  };

  // Methods: Bot files
  static FILES = {
    GET: "assets", // Получить файл
  };

  // Methods: Bot manage
  static MANAGE = {
    FETCH: "bots_fetch", // Получить список всех доступных ботов
    CLONE: "bots_clone", // Склонировать существующих ботов
    CREATE: "bots_create", // Создать новых ботов
    UPDATE: "bots_update", // Обновить существующих ботов
    DOWNLOAD: "bots_download", // Скачать бота zip файлом
    LICENSE: "livechat_license", // Получить лицензию для LiveChat
    TEMPLATES_FETCH: "templates_fetch", // Получить темплейты GPT
  };

  // Methods: Bot manage
  static SOURCE = {
    FETCH: "sources_fetch", // Получить список всех доступных источников
    CREATE: "sources_create", // Создать новый источник
    UPDATE: "sources_update", // Обновить существующий источник
  };


  // Methods: Permissions
  static ROLES = {
    SUPER_ADMIN: "Super Admin",
    ADMIN: "Admin",
    QA: "QA Engineer",
    ACC_MANAGER: "Account Manager",
  }

  // Methods: Permissions
  static PERMISSION = {
    FETCH: "bots_fetch", // Получить список всех доступных ботов
    UPDATE: "bots_update", // Обновить существующих ботов
    UPDATE_STATUS: "bots_update_status", // Обновить статус ботовname: bots_fetch
    DELETE: "bots_delete",
    DISABLE: "bots_disable",
    ACC_FETCH: "accounts_fetch",
    ACC_UPDATE: "accounts_update",
    ACC_BILLING_FETCH: "accounts_billing_fetch",
  }

  // Methods: Bot analytics
  static REPORTS = {
    ADD_USERS: "addUsers", // Добавить пользователей
    ADD_EVENTS: "addEvents", // Добавить события
    SESSIONS: "getSessions", // Получить сессии
    CHANNELS: "getChannels", // Получить список каналов
    UNIQUE_USERS: "getUniqueUsers", // Получить уникальных пользователей
    EVENT_PROPS: "getEvents", // Получить детали события
    EVENT_NAMES: "getEventParams", // Получить список доступных событий
    EVENTS_CONVERSION: "getEventsConversion", // Получить конверсию всех событий
    SESSIONS_CHANNEL: "getSessionsPerChannel", // Получить сессии по всем каналам
    ADD_CONSOLE_EVENTS: "addConsoleEvents", // Добавить событие консоли
  };

  // Extensions: Bot
  static DATA = {
    GET: new ServerMethod("select", "qudata_save"), // Получить данные бота
    SET: new ServerMethod("insert", "qudata_save"), // Отправить данные бота
  };

  // Extensions: LiveChat
  static LIVE_CHAT = {
    ACCESS_TOKEN: new ServerMethod("auth", "livechat"), // Получить access_token для LiveChat
  };

  // Extensions: Parsing
  static PARSING = {
    SITE: new ServerMethod("site", "parsing"), // Получить sitemap сайта
  };

  // Billing: Bot billing
  static BILLING = {
    FETCH: "billing_fetch", // Получить SUBSCRIPTIONS, PRODUCTS, FEATURES
    SUBSCRIPTIONS: "subscriptions_fetch", // Получить список активных подписок
    SUBSCRIPTION_CHANGE: "subscription_change", // Изменить подписку
    SUBSCRIPTION_UNDO: "subscription_change_undo", // Отменить изменение подписки
    UNSUBSCRIBE: "subscription_cancel", // Отписаться
    PRODUCTS: "products_fetch", // Получить список продуктов для покупки
    COUPONS: "coupons_fetch", // Получить список купонов
    FEATURES: "features_fetch", // Получить состояние фич, доступных пользователю
    NOTIFICATIONS: "notifications_fetch", // Получить список уведомлений
    NOTIFICATIONS_UPDATE: "notifications_update", // Отправить результат взаимодействия с уведомлениями
    PAYMENTS: "payments_fetch", // Получить настройки и историю платежей
    PAYMENTS_TUNE: "payments_update", // Обновить настройки платежей
    CHECKOUT: "billing_checkout", // Выполнить покупку товаров
    DELETE_USER: "delete_user", // Почистить данные пользователя (для тестинга)
    ENCRYPT: "encrypt_payload", // Зашифровать данные для checkout
  };

  // Methods: Common
  static COMMON = {
    TOKENS: "headers", // Отправить заголовки авторизации
  };

  // Language
  static #language = "en";

  // Routes
  static #routes = null;
  static #tokens = {};
  static #initialized = false;
  static #authenticated = false;

  // Request
  static async request(method, data) {
    const route = api.route(method);
    if (!route) {
      logger.warn(`[api] Invalid method (${method})`);
      return;
    }

    let lang = api.#language;
    let { server, socketID } = route;
    data = { ...data, lang };

    if (atDevhost()) {
      data.redirect = `${window.location.protocol}//${window.location.host}`;
    }

    if (typeof method === "object") {
      const { version, extension } = method;
      if (typeof version === "string") {
        server = server.replace(`\${version}`, version);
        data = { ...data, version };
      }
      if (typeof extension === "string") {
        server = server.replace(`\${extension}`, extension);
        data = { ...data, extension };
      }
    }

    const timeout =
      method === api.MANAGE.CREATE || method === api.MANAGE.UPDATE
        ? 15000
        : method === api.PARSING.SITE
          ? 600000
          : void 0;
    const options = { timeout };

    if (
      provider.isSocketConnecting(socketID) ||
      provider.isSocketConnected(socketID)
    ) {
      return await provider.socketSend(socketID, method, data, options);
    }

    const headers = api.tokens();
    return await provider.httpPOST(server, method, data, headers, options);
  }

  // File source
  static fileSrc(filePath, botId) {
    const method = api.FILES.GET;
    const route = api.route(method);
    if (!route) {
      logger.warn(`[api] Invalid method (${method})`);
      return "";
    }
    const { server } = route;
    const url = provider.httpURL(server);
    return `${url}${botId}/${method}/${filePath}`;
  }

  // API config
  static configure(routes) {
    logger.net(`[api] routes=`, routes);

    api.#routes?.forEach(({ socketID }) => {
      api.enableSocket(socketID, false);
    });

    api.#routes = routes;
  }

  // API config tokens
  static configureToken(tokens) {
    if (Object.keys(tokens).some((t) => api.#tokens[t] !== tokens[t])) {
      api.#tokens = { ...api.#tokens, ...tokens };
      logger.net(`[api] tokens=`, api.tokens());
      api.#routes?.forEach(({ socketID }) => {
        api.sendTokens(socketID);
      });
    }
  }

  // API language
  static setLanguage(language) {
    api.#language = language;
  }

  // API auth ready
  static initialized(value) {
    if (api.#initialized === value) {
      return;
    }

    api.#routes
      .slice(0, 1)
      .forEach(({ socketID }) => api.enableSocket(socketID, value));
    api.#initialized = value;
  }

  // API bots ready
  static authenticated(value) {
    if (!api.#initialized || api.#authenticated === value) {
      return;
    }

    api.#routes
      .slice(1)
      .forEach(({ socketID }) => api.enableSocket(socketID, value));
    api.#authenticated = value;
  }

  // API enable socket & send tokens
  static enableSocket(socketID, value) {
    provider.enableSocket(socketID, value, {
      onopen: () => api.sendTokens(socketID),
    });
  }

  // API send tokens
  static sendTokens(socketID) {
    if (
      provider.isSocketConnecting(socketID) ||
      provider.isSocketConnected(socketID)
    ) {
      const tokens = api.tokens();
      if (!Object.keys(tokens).length) return; // No tokens yet
      provider.socketSend(socketID, api.COMMON.TOKENS, tokens);
    }
  }

  // API tokens
  static tokens() {
    return getTokens(api.#tokens);
  }

  // API tools
  static route(method) {
    return api.#routes?.find(({ methods }) => methods.indexOf(method) > -1);
  }

  // API ready
  static isReady() {
    return api.#initialized && api.#routes;
  }

  // API reset
  static reset() {
    api.initialized(false);
    api.authenticated(false);
  }

  static singleton() {
    if (typeof window.Qudata_api === "function") {
      window.Qudata_api.authenticated(false);
      window.Qudata_api.initialized(false);
    }
    return (window.Qudata_api = api);
  }
}

export default api = api.singleton();
