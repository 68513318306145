import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";

import useBots from "../../hooks/useBots";
import QDLoader from "../../components/QDLoader";
import ImportExport from "../../components/bots/ImportExport";
import BreadcrumbHeader from "./BreadcrumbHeader";
import { useTranslation } from "../../hooks/useLocalization";

const ImportExportPage = () => {
  const { t } = useTranslation();
  const { isInitialized } = useBots();
  return (
    <React.Fragment>
      <Helmet title={t("Backup/Restore")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col xs="auto" /*className="d-none d-sm-block"*/>
            <BreadcrumbHeader title={t("Backup/Restore")} />
          </Col>
        </Row>
        {isInitialized ? <ImportExport /> : <QDLoader />}
      </Container>
    </React.Fragment>
  );
};

export default ImportExportPage;
