import { useState } from "react";
import viber from "../../../../assets/img/brands/viber.svg";
import screen_1 from "../../../../assets/img/screenshots/channels/screen_viber_1.png";
import screen_2 from "../../../../assets/img/screenshots/channels/screen_viber_2.png";
import screen_3 from "../../../../assets/img/screenshots/channels/screen_viber_3.png";
import screen_4 from "../../../../assets/img/screenshots/channels/screen_viber_4.png";
import { BOT_CHANNEL } from "../../../../contexts/bots/BotsContext";
import useBots from "../../../../hooks/useBots";
import { useTranslation } from "../../../../hooks/useLocalization";
import useMessage from "../../../../hooks/useMessage";
import { Accordion } from "../../../accordion/Accordion";
import ZoomImage from "../../../ZoomImage";
import ZoomVideo from "../../../ZoomVideo";
import ChannelInput from "./submit/ChannelInput";
import ChannelInputState from "./submit/ChannelInputState";
import ChannelState from "./submit/ChannelState";

const ViberIcon = ({ className }) => (
  <img src={viber || ""} alt="" width={20} height={20} className={className} />
);

const ViberDownload = () => {
  return (
    <a
      href="https://www.viber.com/download"
      alt="viber"
      target="_blank"
      rel="noreferrer"
    >
      https://www.viber.com/download
    </a>
  );
};

const ViberCreateAccout = () => {
  return (
    <>
      <a
        href="https://account.viber.com/en/create-account"
        alt="viber"
        target="_blank"
        rel="noreferrer"
      >
        https://account.viber.com/en/create-account
      </a>
      <ZoomImage>
        <img alt="screen_1" src={screen_1} className="m-3 ms-0 mw-100" />
      </ZoomImage>
    </>
  );
};

const ViberLogin = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      {tt(
        "Viber.Step-3-1",
        <a
          href="https://partners.viber.com"
          alt="viber"
          target="_blank"
          rel="noreferrer"
        >
          https://partners.viber.com
        </a>
      )}
      <ZoomImage>
        <img alt="screen_2" src={screen_2} className="m-3 ms-0 mw-100" />
      </ZoomImage>
      <p>{t("Viber.Step-3-2")}</p>
    </>
  );
};

const ViberCreateBot = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      {tt("Viber.Step-4-1", <b>{t("Create Bot Account")}</b>)}
      <p>{t("Viber.Step-4-2")}</p>
      <ZoomImage>
        <img alt="screen_3" src={screen_3} className="m-3 ms-0 mw-100" />
      </ZoomImage>
    </>
  );
};

const ViberAPIToken = ({ id, channel, levels }) => {
  // limits
  // Viber Private Token (50)
  const { t } = useTranslation();
  const { greeting_message } = channel || {};
  const { updateBot } = useBots();
  const [isSubmitting, setSubmitting] = useState();
  const { /*showMessage, */ showMessageWithDebug } = useMessage();
  const onSubmit = async (value) => {
    try {
      setSubmitting(true);
      await updateBot(id, {
        channels: [
          {
            ...channel,
            name: BOT_CHANNEL.VIBER,
            token: value,
            greeting_message,
          },
        ],
      });
      // showMessage({ save: "" });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setSubmitting(false);
    }
  };
  const onChange = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit(value);
    }
  };
  return (
    <>
      <ZoomImage>
        <img alt="screen_4" src={screen_4} className="m-3 ms-0 mw-100" />
      </ZoomImage>
      {t("Viber.Step-5-1")}
      <ChannelInput
        strict
        title={t("Viber Private Token")}
        field="token"
        channel={channel}
        disabled={!levels.manage}
        isSubmitting={isSubmitting}
        onChange={onChange}
      />
      <ChannelInputState
        fields={["token"]}
        channel={channel}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

const ViberGreetingMessage = ({ id, channel, levels }) => {
  const { t } = useTranslation();
  const { token } = channel || {};
  const { updateBot } = useBots();
  const [isSubmitting, setSubmitting] = useState();
  const { /*showMessage, */ showMessageWithDebug } = useMessage();
  const onSubmit = async (value) => {
    try {
      setSubmitting(true);
      await updateBot(id, {
        channels: [
          {
            ...channel,
            name: BOT_CHANNEL.VIBER,
            token,
            greeting_message: value,
          },
        ],
      });
      // showMessage({ save: "" });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setSubmitting(false);
    }
  };
  const onChange = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit(value);
    }
  };
  return (
    <>
      <ChannelInput
        optional
        title={t("Greeting Message")}
        field="greeting_message"
        limit={500}
        channel={channel}
        disabled={!levels.manage}
        isSubmitting={isSubmitting}
        onChange={onChange}
      />
    </>
  );
};

const ViberConfigure = ({ channel, levels, bot, handlers }) => {
  const { t, tt } = useTranslation();
  const { id } = bot || {};
  return (
    <>
      <ZoomVideo>
        <iframe width="50%" height="415" src="https://www.youtube.com/embed/XLQFACK8vdQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </ZoomVideo>
      <Accordion defaultActiveKey="0" className="accordion-steps no-animation">
        <Accordion.Item eventKey="0" className="bg-white">
          <Accordion.Header>{tt("Step N", 1)}</Accordion.Header>
          <Accordion.Body>
            <p>{t("Viber.Step-1")}</p>
            <ViberDownload />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{tt("Step N", 2)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Viber.Step-2")}</p>
            <ViberCreateAccout />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{tt("Step N", 3)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Viber.Step-3")}</p>
            <ViberLogin />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{tt("Step N", 4)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Viber.Step-4")}</p>
            <ViberCreateBot />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>{tt("Step N", 5)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Viber.Step-5")}</p>
            <ViberAPIToken id={id} channel={channel} levels={levels} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>{tt("Step N", 6)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Viber.Step-6")}</p>
            <ViberGreetingMessage id={id} channel={channel} levels={levels} />
            <ChannelState channel={channel} handlers={handlers} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const ViberGetHandlers = ({ url, validated, handlers }) => {
  if (validated && url) {
    handlers = {
      ...handlers,
      onLink: () => {
        window.open(url, "_blank");
      },
    };
  }
  return handlers;
};

export { ViberIcon, ViberConfigure, ViberGetHandlers };
