import Chart from "react-apexcharts";
import { useTranslation } from "../../../../hooks/useLocalization";
import ReportCard from "./ReportCard";

const ReportSessionsCard = ({ report, proPlan, isLoading }) => {
  const { t, tt } = useTranslation();
  return (
    <ReportCard
      variant="primary"
      helpText={t("The total number of bot launches")}
      proPlan={proPlan}
      childrenHeader={<>{t("Sessions")}</>}
      childrenFooter={<span>{tt("Total N", report.total)}</span>}
      isLoading={isLoading}
    >
      <div className="chart w-100">
        <Chart
          options={report.options}
          series={report.data}
          type="area"
          height="200"
        />
      </div>
    </ReportCard>
  );
};

export default ReportSessionsCard;
