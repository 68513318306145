import QuBotDashboardEnv from "../qubot/QuBotDashboardEnv";

const useQubot = () => {
  return (
    window.QuBotDashboardEnvIns ||
    (QuBotDashboardEnv.isCodeLoaded()
      ? (window.QuBotDashboardEnvIns = new QuBotDashboardEnv())
      : null)
  );
};

export default useQubot;
