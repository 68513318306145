import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import messenger from "../../../../assets/img/brands/facebook-messenger.svg";
import screen_1 from "../../../../assets/img/screenshots/channels/screen_facebook_1_1.png";
import screen_2 from "../../../../assets/img/screenshots/channels/screen_facebook_1_2.png";
import screen_3 from "../../../../assets/img/screenshots/channels/screen_facebook_1_3.png";
import screen_4 from "../../../../assets/img/screenshots/channels/screen_facebook_2.png";
import screen_5 from "../../../../assets/img/screenshots/channels/screen_facebook_3_1.png";
import screen_6 from "../../../../assets/img/screenshots/channels/screen_facebook_3_2.png";
import screen_7 from "../../../../assets/img/screenshots/channels/screen_facebook_4.png";
import screen_8 from "../../../../assets/img/screenshots/channels/screen_facebook_5_1.png";
import screen_9 from "../../../../assets/img/screenshots/channels/screen_facebook_5_2.png";
import screen_10 from "../../../../assets/img/screenshots/channels/screen_facebook_6.png";
import screen_11 from "../../../../assets/img/screenshots/channels/screen_facebook_7_1.png";
import screen_12 from "../../../../assets/img/screenshots/channels/screen_facebook_7_2.png";
import screen_13 from "../../../../assets/img/screenshots/channels/screen_facebook_8.png";
import { BOT_CHANNEL } from "../../../../contexts/bots/BotsContext";
import useBots from "../../../../hooks/useBots";
import { useTranslation } from "../../../../hooks/useLocalization";
import useMessage from "../../../../hooks/useMessage";
import { Accordion } from "../../../accordion/Accordion";
import ZoomImage from "../../../ZoomImage";
import ZoomVideo from "../../../ZoomVideo";
import ChannelInput from "./submit/ChannelInput";
import ChannelInputState from "./submit/ChannelInputState";
import ChannelState from "./submit/ChannelState";

const FacebookIcon = ({ className }) => (
  <img
    src={messenger || ""}
    alt=""
    width={20}
    height={20}
    className={className}
  />
);

const FacebookCreateApp = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      <ul>
        <li>
          {tt(
            "Facebook.Step-1-1",
            <a
              href="https://developers.facebook.com"
              alt="facebook"
              target="_blank"
              rel="noreferrer"
            >
              {t("Facebook developer portal")}
            </a>,
            <b>{t("My Apps")}</b>
          )}
          <ZoomImage>
            <img alt="screen_1" src={screen_1} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
        <li>
          {tt(
            "Facebook.Step-1-2",
            <b>{t("Create App")}</b>,
            <b>{t("Business")}</b>
          )}
          <ZoomImage>
            <img alt="screen_2" src={screen_2} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
        <li>
          {tt("Facebook.Step-1-3", <b>{t("Create App")}</b>)}
          <ZoomImage>
            <img
              alt="screen_3"
              src={screen_3}
              className="m-3 ms-0 mb-0 mw-100"
            />
          </ZoomImage>
          <p className="mb-3">{t("Facebook.Step-1-4")}</p>
        </li>
        <li>
          {tt(
            "Facebook.Step-1-5",
            <b>{t("Messenger")}</b>,
            <b>{t("Set up")}</b>
          )}
          <ZoomImage>
            <img alt="screen_4" src={screen_4} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
      </ul>
    </>
  );
};

const FacebookPageDetails = ({ id, channel, levels }) => {
  // limits
  // Page Access Token (128-255)
  // App ID(15)
  // App Secret(30-32)
  // Page ID(15)
  const { t, tt } = useTranslation();
  const { page_access_token, app_id, app_secret, page_id } = channel || {};
  const { updateBot } = useBots();
  const [isSubmitting, setSubmitting] = useState();
  const { /*showMessage, */ showMessageWithDebug } = useMessage();
  const onSubmit = async (props) => {
    try {
      setSubmitting(true);
      await updateBot(id, {
        channels: [{ ...channel, name: BOT_CHANNEL.FACEBOOK, ...props }],
      });
      // showMessage({ save: "" });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setSubmitting(false);
    }
  };
  const onChange__page_access_token = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit({ page_access_token: value, app_id, app_secret, page_id });
    }
  };
  const onChange__app_id = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit({ page_access_token, app_id: value, app_secret, page_id });
    }
  };
  const onChange__app_secret = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit({ page_access_token, app_id, app_secret: value, page_id });
    }
  };
  const onChange__page_id = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit({ page_access_token, app_id, app_secret, page_id: value });
    }
  };
  return (
    <>
      <ul>
        <li>
          {tt(
            "Facebook.Step-2-1",
            <b>{t("Page Access Token")}</b>,
            <a
              href="https://www.facebook.com/pages/create"
              alt="create"
              target="_blank"
              rel="noreferrer"
            >
              {t("here")}
            </a>
          )}
          <Row>
            <ZoomImage>
              <img alt="screen_5" src={screen_5} className="m-3 ms-0 mw-100" />
            </ZoomImage>
            <ZoomImage>
              <img alt="screen_6" src={screen_6} className="m-3 ms-0 mw-100" />
            </ZoomImage>
          </Row>
          <p>{t("Facebook.Step-2-2")}</p>
          <ChannelInput
            strict
            title={t("Page Access Token")}
            field="page_access_token"
            channel={channel}
            disabled={!levels.manage}
            isSubmitting={isSubmitting}
            onChange={onChange__page_access_token}
          />
        </li>
        <li>
          {tt(
            "Facebook.Step-2-3",
            <b>{t("App ID")}</b>,
            <b>{t("App Secret")}</b>
          )}
          <ZoomImage>
            <img alt="screen_7" src={screen_7} className="m-3 ms-0 mw-100" />
          </ZoomImage>
          <Form>
            <Row>
              <Col md={6}>
                <ChannelInput
                  strict
                  title={t("App ID")}
                  field="app_id"
                  channel={channel}
                  disabled={!levels.manage}
                  isSubmitting={isSubmitting}
                  onChange={onChange__app_id}
                />
              </Col>
              <Col md={6}>
                <ChannelInput
                  strict
                  title={t("App Secret")}
                  field="app_secret"
                  channel={channel}
                  disabled={!levels.manage}
                  isSubmitting={isSubmitting}
                  onChange={onChange__app_secret}
                />
              </Col>
            </Row>
          </Form>
        </li>
        <li>
          {tt("Facebook.Step-2-4", <b>{t("About")}</b>)}
          <Row>
            <ZoomImage>
              <img alt="screen_8" src={screen_8} className="m-3 ms-0 mw-100" />
            </ZoomImage>
            <ZoomImage>
              <img alt="screen_9" src={screen_9} className="m-3 ms-0 mw-100" />
            </ZoomImage>
          </Row>
          {tt("Facebook.Step-2-5", <b>{t("Page ID")}</b>)}
          <ChannelInput
            strict
            title={t("Page ID")}
            field="page_id"
            channel={channel}
            disabled={!levels.manage}
            isSubmitting={isSubmitting}
            onChange={onChange__page_id}
          />
        </li>
      </ul>
      <ChannelInputState
        fields={["page_access_token", "app_id", "app_secret", "page_id"]}
        channel={channel}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

const FacebookSetupWebhook = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      <ul>
        <li>
          {tt("Facebook.Step-3-1", <b>{t("Enable this channel")}</b>)}
          <ZoomImage>
            <img alt="screen_10" src={screen_10} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
        <li>
          {tt(
            "Facebook.Step-3-2",
            <b>{t("Webhooks section")}</b>,
            <b>{t("Add subscriptions")}</b>,
            <span className="bg-light">{t("messages")}</span>,
            <span className="bg-light">{t("messaging_postbacks")}</span>,
            <span className="bg-light">{t("message_deliveries")}</span>,
            <span className="bg-light">{t("message_reads")}</span>,
            <b>{t("Save")}</b>
          )}
          <Row>
            <ZoomImage>
              <img
                alt="screen_11"
                src={screen_11}
                className="m-3 ms-0 mw-100"
              />
            </ZoomImage>
            <ZoomImage>
              <img
                alt="screen_12"
                src={screen_12}
                className="m-3 ms-0 mw-100"
              />
            </ZoomImage>
          </Row>
        </li>
      </ul>
    </>
  );
};

const FacebookSubmitApp = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      <ZoomImage>
        <img alt="screen_13" src={screen_13} className="m-3 ms-0 mw-100" />
      </ZoomImage>
      {tt(
        "Facebook.Step-4-1",
        <a
          href="https://developers.facebook.com/docs/messenger-platform/app-review#submission"
          alt="submission"
          target="_blank"
          rel="noreferrer"
        >
          {t("here")}
        </a>
      )}
    </>
  );
};

const FacebookConfigure = ({ channel, levels, bot, handlers }) => {
  const { t, tt } = useTranslation();
  const { id } = bot || {};
  return (
    <>
      <ZoomVideo>
        <iframe width="50%" height="415" src="https://www.youtube.com/embed/kvQvPEDGn5k" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </ZoomVideo>
      <p>{t("Facebook.Header")}</p>
      <Accordion defaultActiveKey="0" className="accordion-steps no-animation">
        <Accordion.Item eventKey="0" className="bg-white">
          <Accordion.Header>{tt("Step N", 1)}</Accordion.Header>
          <Accordion.Body>
            <p>{t("Facebook.Step-1")}</p>
            <FacebookCreateApp />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{tt("Step N", 2)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Facebook.Step-2")}</p>
            <FacebookPageDetails id={id} channel={channel} levels={levels} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{tt("Step N", 3)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Facebook.Step-3")}</p>
            <FacebookSetupWebhook />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{tt("Step N", 4)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Facebook.Step-4")}</p>
            <FacebookSubmitApp />
            <ChannelState channel={channel} handlers={handlers} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const FacebookGetHandlers = ({ url, validated, handlers }) => {
  if (validated && url) {
    handlers = {
      ...handlers,
      onLink: () => {
        window.open(url, "_blank");
      },
    };
  }
  return handlers;
};

export { FacebookIcon, FacebookConfigure, FacebookGetHandlers };
