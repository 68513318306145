/*eslint no-new-func: "off"*/

import api from "../net/api";
import provider from "../net/provider";
import { config } from "../config";

/******************************************************************************
 *                        Dashboard qubot environement
 *
 *                                                   (c) 2021 qudata.com, steps
 *******************************************************************************/
class QuBotDashboardQBEnv {
  #uiAPI = {};
  #useAPI = {};

  constructor(dhEnv, { type, cid }) {
    this.dhEnv = dhEnv;
    this.type = type;
    this.cid = cid;
    this.isToken = false;
  }

  /**************************************************************************
   */
  static isCodeLoaded() {
    return typeof this.libClass() === "function";
  }

  /**************************************************************************
   */
  static libClass() {
    return new Function("return QuBotManager;")();
  }

  /**************************************************************************
   * UI api
   */
  async ui(value) {
    this.#uiAPI = { ...this.#uiAPI, ...value };
  }

  /**************************************************************************
   * Use api
   */
  async use(value) {
    this.#useAPI = { ...this.#useAPI, ...value };
  }

  /**************************************************************************
   * Create bot environement
   */
  async create() {
    // logger.dev(`[QuBotDashboardQBEnv] create`);
    this.lib = new (QuBotDashboardQBEnv.libClass())(this);
    // const route = api.route(api.BOT.RUN_STATE);
    // let { server } = route;

    // const { useBots } = this.#useAPI;
    // const { activeBot } = useBots;
    // const { id } = activeBot;
    const { useAuth } = this.#useAPI;
    const { user, checkRole } = useAuth;

    this.lib.editPermission = checkRole(api.ROLES.SUPER_ADMIN) || checkRole(api.ROLES.ADMIN);

    const { channelToken, routes } = config;
    const server = routes.ws.bot;
    const host = new URL(provider.socketURL(server)).host;

    let params = {
      actions: "remote",
      host: host,
      lang: "en",
      channelToken: channelToken,
      uid: user.uid,
    };
    return this.lib.create(params);
  }

  /**************************************************************************
   * Save bot
   */
  async saveBot(props) {
    return await this.dhEnv.saveBot(props);
  }

  /**************************************************************************
   * Load bot
   */
  async loadBot(props) {
    return await this.dhEnv.loadBot(props);
  }

  /**************************************************************************
   * Load setup
   */
  async loadSetup(props) {
    return await this.dhEnv.loadSetup(props);
  }

  /**************************************************************************
   * Save setup
   */
  async saveSetup(props) {
    return await this.dhEnv.saveSetup(props);
  }

  /**************************************************************************
   * Sending data to storage.
   * This data will be supplemented with time and date and added to the general list.
   *
   * @param {*} storage - storage location ('wp', 'qudata', 'sheets')
   * @param {*} ownerID - owner ID (for WP, the same value for all clients)
   * @param {*} botID   - bot ID; generated in QuBotMyBots
   * @param {*} key     - data key to filter by
   * @param {*} value   - data string
   */
  async send(props) {
    // logger.dev("[QuBotDashboardQBEnv] send", props);
    const { useExtensions } = this.#useAPI;
    const { setData } = useExtensions;
    const { time, botID, uid, value } = props;
    await setData({ time, botID, uid, value });
  }

  /**************************************************************************
   * Send analytics request
   */
  async sendAnalytics(props) {
    // logger.dev("[QuBotDashboardQBEnv] sendAnalytics", props);
    const { useAnalytics } = this.#useAPI;
    const { addReports } = useAnalytics;
    await addReports(props);
  }

  /**************************************************************************
   * Run action request
   */
  async runAction({ action, params }) {
    // logger.dev("[QuBotDashboardQBEnv] runAction", props);
    const { useAuth, useBots } = this.#useAPI;
    const { user, secureAPIRequestRaw } = useAuth;
    const { activeBot } = useBots;
    const { uid } = user;
    const { id } = activeBot;
    const response = await secureAPIRequestRaw(api.BOT.RUN_ACTION, {
      user: { uid },
      bot: { id },
      action,
      params,
    });
    return response.data;
  }
}

export default QuBotDashboardQBEnv;
