import React, { useEffect, useReducer } from "react";
import api from "../../net/api";
import useAuth from "../../hooks/useAuth";
import * as CMD from "./reducer";
import useBots from "../../hooks/useBots";
import { createInterval, FILTERS } from "./filters";
import { BOT_CHANNEL } from "./BotsContext";

const INITIALIZE = "INITIALIZE";

const initialState = {
  isInitialized: false,
};

const ExtensionsReducer = (state, action) => {
  // logger.dev("[ExtensionsReducer]", action.type, action.payload);
  const { isInitialized } = action.payload || {};

  switch (action.type) {
    case INITIALIZE:
      return { ...state, isInitialized };
    default:
      return state;
  }
};

const ExtensionsContext = React.createContext(CMD.initialState);

function ExtensionsProvider({ children }) {
  const { activeBot } = useBots();
  const { isInitialized, isAuthenticated, secureAPIRequest } = useAuth();
  const [state, dispatch] = useReducer(ExtensionsReducer, initialState);

  // Actualize Session
  // ------------------------
  useEffect(() => {
    (async () => {
      dispatch({
        type: INITIALIZE,
        payload: {
          isInitialized: isInitialized && isAuthenticated,
        },
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, isAuthenticated]);

  // Data get
  // ------------------------
  const getData = async (filters) => {
    return await buildRequest(api.DATA.GET, {}, filters);
  };

  // Data set
  // ------------------------
  const setData = async (props) => {
    return await secureAPIRequest(api.DATA.SET, { rows: [props] });
  };

  // Get Token
  // ------------------------
  const getToken = async (channel, props) => {
    if (channel === BOT_CHANNEL.LIVE_CHAT) {
      return await secureAPIRequest(api.LIVE_CHAT.ACCESS_TOKEN, props);
    }
  };

  const buildRequest = async (
    request,
    params,
    filters,
    include = {
      [FILTERS.INTERVAL]: true,
    }
  ) => {
    const response = await secureAPIRequest(
      request,
      buildRequestParams(params, filters, { ...include, bid: true })
    );
    const reportsValue = await parseResponse(request, response);
    return reportsValue;
  };

  const buildRequestParams = (params, filters = {}, include = {}) => {
    const requestFilters = [];

    if (include.bid) {
      requestFilters.push({
        field: "bid",
        value: activeBot?.id || "", //bid_0
      });
    }

    if (include[FILTERS.INTERVAL]) {
      let [from, to] = createInterval(
        filters[FILTERS.INTERVAL],
        filters[FILTERS.INTERVAL_VALUES]
      );
      requestFilters.push({
        type: "interval",
        from: from.toISOString(),
        to: to.toISOString(),
      });
      const DAY = 24 * 60 * 60 * 1000;
      const group = to.getTime() - from.getTime() > DAY ? "day" : "hour";
      params.group = group;
      // setFilters({ [FILTERS.GROUP]: group });
    }

    if (include[FILTERS.EVENT_NAME]) {
      const value = filters[FILTERS.EVENT_NAME];
      params.unique = true;
      requestFilters.push({
        field: "name",
        value,
      });
    }

    params.filters = requestFilters;
    return params;
  };

  const parseResponse = async (url, response) => {
    // logger.dev("[ExtensionsContext] parseResponse", url, response);

    const { data } = response;
    if (!data) {
      throw new Error(`No data after loading`);
    }

    const { rows } = data;
    if (!Array.isArray(rows)) {
      throw new Error(`Unsupported response data format`);
    }

    switch (url) {
      case api.DATA.GET:
        return rows;
      default:
        return null;
    }
  };

  return (
    <ExtensionsContext.Provider
      value={{
        ...state,

        getData,
        setData,
        getToken,
      }}
    >
      {children}
    </ExtensionsContext.Provider>
  );
}

export { ExtensionsProvider, ExtensionsContext };
