import { useState } from "react";
import { Card, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { HelpCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import ProLock from "../../../pricing/ProLock";

const ReportCard = ({
  variant,
  helpText,
  proPlan,
  children,
  childrenHeader,
  childrenFooter,
  isLoading,
}) => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">
          {childrenHeader}
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{helpText}</Tooltip>}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="/"
              onClick={(e) => e.preventDefault()}
              className="nav-link nav-icon ms-2"
            >
              <HelpCircle className="feather" />
            </a>
          </OverlayTrigger>
          {isLoading ? (
            <Spinner
              animation="border"
              variant={variant}
              size="sm"
              className="float-end"
            />
          ) : (
            proPlan && (
              <ProLock
                proPlan={proPlan}
                className="p-2 float-end"
                selected={hover}
              />
            )
          )}
        </Card.Title>
      </Card.Header>
      <div
        className={proPlan ? "chart-locked cursor-pointer" : ""}
        onMouseEnter={() => setHover(true)}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseOut={() => setHover(false)}
        onClick={() => {
          if (proPlan) {
            navigate(`/account/pricing#${proPlan.index}`);
          }
        }}
      >
        <Card.Body className="p-0">{children}</Card.Body>
        <Card.Body>{childrenFooter}</Card.Body>
      </div>
    </Card>
  );
};

export default ReportCard;
