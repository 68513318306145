import { Col, Row } from "react-bootstrap";

const QDLoader = () => (
  <Row className="text-center h-100 me-0">
    <Col md="12" xl className="align-self-center">
      <div className="qd-loader mt-5 m-auto">
        <div className="inner one" />
        <div className="inner two" />
        <div className="inner three" />
      </div>
    </Col>
  </Row>
);

export default QDLoader;
