import { useEffect, useMemo, useRef, useState } from "react";
import {
  FloatingLabel,
  Form,
  OverlayTrigger,
  ProgressBar,
  Tooltip,
} from "react-bootstrap";
import { CheckCircle, Save, XCircle } from "react-feather";
import { useTranslation } from "../../../../../hooks/useLocalization";

const ChannelInput = ({
  strict,
  optional,
  limit = 255,
  title,
  channel,
  field,
  disabled,
  isSubmitting,
  onChange,
}) => {
  const { t, tt } = useTranslation();
  const { validated, errors } = channel || {};
  const error = useMemo(() => errors && errors[field], [errors, field]);
  const value = channel && channel[field];
  const placeholder = strict
    ? `${title}*`
    : optional
      ? `${title} (${t("optional")})`
      : title;

  const ref = useRef();
  const valueValid = !!value;

  const [formSuccess, setFormSuccess] = useState();
  const [formError, setFormError] = useState();
  const [limitReached, setLimitReached] = useState();
  const [checkFilled, setCheckFilled] = useState();
  const [checkSuccess, setCheckSuccess] = useState();
  const [checkError, setCheckError] = useState();
  const [checkUnsaved, setCheckUnsaved] = useState();
  const [renderCheck, setRenderCheck] = useState();
  const [renderProgress, setRenderProgress] = useState();

  // const [formEvent, setFormEvent] = useState();

  const udpateState = (newValue) => {
    const refValue = ref.current?.value;
    const currentValue = typeof newValue === "string" ? newValue : refValue;
    const unsavedChanges = (currentValue || "") !== (value || "");
    const limitReached = !isSubmitting && (currentValue?.length || 0) > limit;

    const formFilled = !!currentValue;
    const formSuccess =
      !!(currentValue && !unsavedChanges) && validated && !isSubmitting;
    const formError =
      (!currentValue && !unsavedChanges && !optional) ||
      (!validated && error && !isSubmitting) ||
      limitReached;
    setFormSuccess(formSuccess);
    setFormError(formError);

    const checkFilled = formFilled && !(formSuccess || formError);
    const checkSuccess = formSuccess && valueValid;
    const checkError = formError && valueValid;
    const checkUnsaved = unsavedChanges;
    setCheckFilled(checkFilled);
    setCheckSuccess(checkSuccess);
    setCheckError(checkError);
    setCheckUnsaved(checkUnsaved);
    setLimitReached(limitReached);
    setRenderCheck(
      !isSubmitting &&
      (checkFilled || checkSuccess || checkError || checkUnsaved)
    );
    setRenderProgress(isSubmitting && unsavedChanges);
  };

  const sendValue = (newValue) => {
    const limitReached = (newValue?.length || 0) > limit;
    if (newValue !== value && !limitReached) {
      onChange({ target: { value: newValue } });
      udpateState(newValue);
    }
  };

  const onFormChange = (event) => {
    udpateState(event.target?.value);
  };

  const onFormPaste = (event) => {
    sendValue(event.target?.value);
  };

  const onFormKeyDown = (event) => {
    if (event.keyCode === 13) {
      sendValue(ref.current?.value);
    }
  };

  const onClickSave = () => {
    sendValue(ref.current?.value);
  };

  useEffect(udpateState, [
    isSubmitting,
    validated,
    valueValid,
    optional,
    limit,
    value,
    error,
  ]);

  return (
    <Form.Group className="channel-input mb-3 mt-3">
      <FloatingLabel label={placeholder}>
        <Form.Control
          ref={ref}
          as="input"
          type="text"
          disabled={disabled}
          isValid={formSuccess}
          isInvalid={formError}
          placeholder={placeholder}
          defaultValue={value || ""}
          onChange={onFormChange}
          onPaste={(event) => setTimeout(onFormPaste, 0, event)}
          onKeyDown={onFormKeyDown}
        />
        {renderCheck && (
          <Form.Control.Feedback type="invalid">
            {limitReached
              ? tt("Value must be at most N characters", limit)
              : error?.errorMessage}
          </Form.Control.Feedback>
        )}
        {renderCheck && (
          <div className="channel-input-check">
            {checkUnsaved ? (
              limitReached ? (
                <Save className="text-muted" />
              ) : (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{t("Save changes")}</Tooltip>}
                >
                  <Save
                    className="text-primary cursor-pointer"
                    onClick={onClickSave}
                  />
                </OverlayTrigger>
              )
            ) : checkSuccess ? (
              <CheckCircle className="text-success" />
            ) : checkError ? (
              <XCircle className="text-danger" />
            ) : checkFilled ? (
              <CheckCircle />
            ) : null}
          </div>
        )}
      </FloatingLabel>
      <ProgressBar
        className={`mt-2 ${renderProgress ? "" : "opacity-0"}`}
        striped
        animated
        variant="primary"
        now={isSubmitting ? 90 : 0}
      />
    </Form.Group>
  );
};

export default ChannelInput;
