import { useCallback, useState } from "react";
import { Form, ProgressBar } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "../../../../../hooks/useLocalization";

const ChannelSelect = ({
  limit,
  options,
  selected,
  placeholder,
  isDisabled,
  isSubmitting,
  isLimitReached,
  isCreateBlocked,
  onChange,
}) => {
  const { tt } = useTranslation();
  const [isCreating, setCreating] = useState();
  const onCreateOption = useCallback(
    async (value) => {
      try {
        setCreating(true);
        await onChange([...selected, { value, label: value }]);
      } catch (e) {
        // nothing to do
      } finally {
        setCreating(false);
      }
    },
    [setCreating, onChange, selected]
  );
  return (
    <>
      <CreatableSelect
        id="domains"
        isDisabled={isCreating || isDisabled}
        className={`react-select-container mb-2 z-3 ${
          isCreateBlocked(selected) ? "react-select-pro-lock" : ""
        }`}
        classNamePrefix="react-select"
        placeholder={placeholder}
        options={options}
        value={selected}
        onChange={onChange}
        onCreateOption={onCreateOption}
        isMulti
      />
      <Form.Control.Feedback
        type="invalid"
        className={isLimitReached(selected) && "d-block"}
      >
        {tt("Value must be at most N characters", limit)}
      </Form.Control.Feedback>
      <ProgressBar
        className={`mt-2 mb-1 ${isSubmitting ? "" : "opacity-0"}`}
        striped
        animated
        variant="primary"
        now={isSubmitting ? 90 : 0}
      />
    </>
  );
};

export default ChannelSelect;
