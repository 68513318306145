import React from "react";
import { Helmet } from "react-helmet-async";
import useBots from "../../../hooks/useBots";
import QDLoader from "../../../components/QDLoader";
import GPTSources from "../../../components/bots/gptsettings/GPTSources";
import { Container } from "react-bootstrap";
import Header from "./Header";
import { useTranslation } from "../../../hooks/useLocalization";

const GPTSourcesPage = () => {
    const { t } = useTranslation();
    const { isInitialized } = useBots();
    return (
        <React.Fragment>
            <Helmet title={t("Sources Library")} />
            <Container fluid className="p-0">
                <Header title={t("Sources Library")} />
                {isInitialized ? <GPTSources /> : <QDLoader />}
            </Container>
        </React.Fragment>
    );
};

export default GPTSourcesPage;
