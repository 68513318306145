import { Badge, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Check, Info, Minus } from "react-feather";
import { BillingPlan } from "../../contexts/billing/BillingContext";
import { useTranslation } from "../../hooks/useLocalization";

const Features = () => {
  const { t, tt } = useTranslation();

  const feature = (value) => {
    if (typeof value === "object") {
      const { children, tooltip } = value;
      if (tooltip) {
        return (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{tooltip}</Tooltip>}
          >
            <div
              className="cursor-help"
              style={{
                textDecorationLine: "underline",
                textDecorationStyle: "dotted",
                textUnderlineOffset: "5px",
              }}
            >
              {children}
            </div>
          </OverlayTrigger>
        );
      }
      return children;
    }
    return value ? (
      <Check className="feather text-primary" />
    ) : (
      <Minus className="feather text-muted" />
    );
  };

  const featuresHeader = (name) => (
    <tr className="border-0">
      <td className="plans-col">
        <b>
          <big>{name.toUpperCase()}</big>
        </b>
      </td>
      <td />
      <td />
      <td />
      <td />
    </tr>
  );

  const getBage = (title) => {
    if (title.length > 0)
      return <>
        <div className="btn btn-primary" style={{ marginLeft: '8px', paddingTop: "0", paddingBottom: "0", cursor: 'default', transform: 'scale(0.80)' }}>
          {title}
        </div>
      </>
  }

  const featuresList = (name, a, b, c, d, info, bage = '') => (
    <tr className="border-0">
      <td className="plans-col d-flex justify-content-between">
        <span className="d-flex">{name}{getBage(bage)}</span>
        {info &&
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip >{info}</Tooltip>}
          >
            <Info className="cursor-help" />
          </OverlayTrigger>
        }
      </td>
      <td className="text-center">{feature(a)}</td>
      <td className="text-center">{feature(b)}</td>
      <td className="text-center">{feature(c)}</td>
      <td className="text-center">{feature(d)}</td>
    </tr>
  );
  return (
    <Table bordered className="my-0">
      <thead>
        <tr className="border-top-0">
          <th className="plans-row">{t("Plans")}</th>
          <th>
            {t(BillingPlan.title(BillingPlan.FREE))}
            {/* <Badge className="badge-outline-primary rounded-pill ms-2">
            $0
          </Badge> */}
          </th>
          <th>
            {t(BillingPlan.title(BillingPlan.START_UP))}
            <Badge className="badge-outline-primary rounded-pill ms-2">
              $19
            </Badge>
          </th>
          <th>
            {t(BillingPlan.title(BillingPlan.PREMIUM))}
            <Badge className="badge-outline-primary rounded-pill ms-2">
              $49
            </Badge>
          </th>
          <th>
            {t(BillingPlan.title(BillingPlan.ENTERPRISE))}
            <Badge className="badge-outline-primary rounded-pill ms-2">
              $99
            </Badge>
          </th>
        </tr>
      </thead>
      <tbody>
        {featuresHeader(t("Development"))}
        {featuresList(t("Visual chatbot editor"), 1, 1, 1, 1)}
        {featuresList(t("Chatbot templates"), 1, 1, 1, 1)}
        {featuresList(t("Basic NLU"), 1, 1, 1, 1)}
        {featuresList(t("Advanced NLU"), 0, 0, 1, 1)}
        {featuresList(t("NLU custom models"), 0, 0, 0, 1)}
        {featuresList(t("Voice chatbot"), 0, 0, 0, 1, t("Voice chatbot Info"))}
        {featuresList(t("ChatGPT integration"), 1, 1, 1, 1, t("ChatGPT integration Info"), 'NEW')}
        {featuresList(t("Multiple languages setup"), 1, 1, 1, 1)}
        {featuresList(t("Interface customization"), 1, 1, 1, 1)}
        {featuresList(tt("Optional links"), 0, 1, 1, 1, t("Optional links Info"))}
        {featuresHeader(t("Communication and channels"))}
        {featuresList(t("Web widget"), 1, 1, 1, 1)}
        {featuresList(
          t("Messengers"),
          {
            children: <>1</>,
            tooltip: t("Free plan includes"),
          },
          1,
          1,
          1,
          t("Free plan includes")
        )}
        {featuresList(t("Facebook Messenger"), 1, 1, 1, 1)}
        {featuresList(t("Viber"), 1, 1, 1, 1)}
        {featuresList(t("Telegram"), 1, 1, 1, 1)}
        {featuresList(t("WhatsApp"), 0, 1, 1, 1, t("Whatsapp Info"))}
        {featuresList(t("LiveChat"), 0, 1, 1, 1, tt("LiveChat Info", <b style={{ color: "yellow" }}>{t("Special Offer")}</b>))}
        {featuresHeader(t("Analytics"))}
        {featuresList(
          t("Chatbot Analytics"),
          {
            children: <>{t("Basic")}</>,
          },
          {
            children: <>{t("Basic")}</>,
          },
          {
            children: <>{t("Advanced")}</>,
            tooltip: t("Advanced Analytics provides"),
          },
          {
            children: <>{t("Advanced")}</>,
            tooltip: t("Advanced Analytics provides"),
          },
          t("Advanced Analytics provides"),
        )}
        {featuresList(t("Google Analytics Integration"), 0, 1, 1, 1)}
        {featuresHeader(t("Data storage"))}
        {featuresList(t("Google Calendar Integration"), 0, 1, 1, 1)}
        {featuresList(t("Google Sheets Integration"), 0, 1, 1, 1)}
        {featuresList(
          t("QuData storage"),
          {
            children: <>{tt("N days", 14)}</>,
            tooltip: t("Store the information about"),
          },
          {
            children: <>{t("1 month")}</>,
            tooltip: t("Store the information about"),
          },
          {
            children: <>{tt("N months", 6)}</>,
            tooltip: t("Store the information about"),
          },
          {
            children: <>{t("Unlimited")}</>,
            tooltip: t("Store the information about"),
          },
          t("Store the information about"),
        )}
        {featuresHeader(t("Special options"))}
        {featuresList(t("Bots Hosting"), 1, 1, 1, 1)}
        {featuresList(
          t("Number of domains"),
          {
            children: <>1</>,
          },
          {
            children: <>3</>,
          },
          {
            children: <>5</>,
          },
          {
            children: <>{t("Unlimited")}</>,
          }
        )}
        {featuresList(
          t("Message Limit"),
          {
            children: <>{tt("/mo", tt("K", 10))}</>,
            tooltip: t("The number of exchanged messages"),
          },
          {
            children: <>{tt("/mo", tt("K", 50))}</>,
            tooltip: t("The number of exchanged messages"),
          },
          {
            children: <>{tt("/mo", tt("K", 250))}</>,
            tooltip: t("The number of exchanged messages"),
          },
          {
            children: <>{tt("/mo", tt("M", 1))}</>,
            tooltip: t("The number of exchanged messages"),
          },
          t("The number of exchanged messages"),
        )}
        {featuresList(t("QuBot - Chatbot Builder"), 1, 1, 1, 1)}
        {featuresList(t("WordPress plugin"), 1, 1, 1, 1)}
        {featuresList(t("3rd party apps integration"), 0, 1, 1, 1, t("3rd party apps integration Info"))}
        {featuresList(t("Page targeting"), 1, 1, 1, 1, t("Page targeting Info"))}
        {featuresList(
          t("Chatbot testing"),
          0,
          0,
          {
            children: <>{t("On request")}</>,
            tooltip: t("Appreciate the opportunity"),
          },
          {
            children: <>{t("On request")}</>,
            tooltip: t("Appreciate the opportunity"),
          },
          t("Appreciate the opportunity"),
        )}
        {featuresList(
          t("Support"),
          {
            children: <>{t("E-mail")}</>,
          },
          {
            children: <>{t("E-mail")}</>,
          },
          {
            children: <>{t("E-mail")}</>,
          },
          {
            children: <>{t("Messengers")}</>,
          }
        )}
      </tbody>
    </Table>
  );
};

export default Features;
