import React, { useCallback, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Row,
  Dropdown,
  InputGroup,
  Form,
  Card,
  ProgressBar,
} from "react-bootstrap";


import { Clock, Layers, RefreshCw, RotateCw, Search, X, Codepen } from "react-feather";
import Navigation from "../../../components/Navigation";
import { BOT_CATEGORY_LIST } from "../../../contexts/bots/BotsContext";
import useAuth from "../../../hooks/useAuth";
import useBots from "../../../hooks/useBots";
import { useTranslation } from "../../../hooks/useLocalization";
import { isExperimental } from "../../../system/system";
import { BOT_FILTER, SORT_BY, BOTS_TYPE } from "./utils";

import { ChatGPTIcon } from "../../../components/bots/gptsettings/icons/ChatGPTIcon";

const Header = ({
  filter,
  setFilter,
  sortBy,
  setSortBy,
  search,
  setSearch,
  setBotType,
  botType,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { refreshBots, isLoading } = useBots();
  const searchRef = useRef();

  const focusSearch = () => {
    searchRef.current?.focus();
    searchRef.current?.select();
  };

  const updateSearch = (event) => {
    const text = event.target.value || "";
    setSearch(text);
  };

  const cancelSearch = () => {
    setSearch("");
  };

  const handleKeyboard = useCallback((e) => {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (e.ctrlKey && keyCode === 70) {
      focusSearch();
      e.preventDefault();
    }
    if (e.key === "Escape") {
      cancelSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.removeEventListener("keydown", handleKeyboard, { capture: true });
    window.addEventListener("keydown", handleKeyboard, { capture: true });
    return () => {
      window.removeEventListener("keydown", handleKeyboard, { capture: true });
    };
  }, [handleKeyboard]);

  useEffect(() => {
    if (user) {
      refreshBots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const navItems = [
    { name: t(BOT_FILTER.title(BOT_FILTER.ALL)), value: BOT_FILTER.MY },
  ].concat(
    isExperimental()
      ? [
        {
          name: t(BOT_FILTER.title(BOT_FILTER.SHARED)),
          value: BOT_FILTER.SHARED,
        },
      ]
      : []
  );

  const sortItems = [
    { name: t("By name"), value: SORT_BY.NAME },
    { name: t("Recently"), value: SORT_BY.RECENT },
  ];

  const botsTypeItems = [
    { name: t("All bots"), value: BOTS_TYPE.ALL },
    { name: t("ChatGPT Bots"), value: BOTS_TYPE.GPT },
    { name: t("Editor Bots"), value: BOTS_TYPE.EDITOR },
  ];

  return (
    <div>
      <Card className="flex-fill mb-4">
        <Card.Body className="my-bots-header">
          <Row className="mt-n1">
            <Col className="d-flex gap-2 align-items-center">
              <Dropdown hidden>
                <Dropdown.Toggle
                  variant="light"
                  className="dropdown-category d-inline bg-white shadow-sm"
                >
                  <Layers className="feather align-middle me-1" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {BOT_CATEGORY_LIST.map((category, index) => (
                    <Dropdown.Item key={index} value={category}>
                      {category}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <hr hidden />
              <Navigation
                items={navItems}
                activeKey={filter}
                onSelect={setFilter}
              />
              <InputGroup className="input-group-my-bots">
                <Form.Control
                  ref={searchRef}
                  value={search}
                  placeholder={t("Search")}
                  onChange={updateSearch}
                />
                <Button variant="" onClick={focusSearch}>
                  {(search?.length && (
                    <X className="feather" onClick={cancelSearch} />
                  )) || <Search className="feather" />}
                </Button>
              </InputGroup>
              <nav className="ms-auto" />
              <span>{t("Bot type")}</span>
              <Dropdown className="d-inline">
                <Dropdown.Toggle
                  variant="light"
                  className="dropdown-sortBy bg-white shadow-sm"
                >
                  {botType === BOTS_TYPE.GPT && (
                    <ChatGPTIcon className="feather align-middle me-1" />
                  )}
                  {botType === BOTS_TYPE.EDITOR && (
                    <Codepen className="feather align-middle me-1" />
                  )}
                  <span>
                    {botsTypeItems.find(({ value }) => value === botType)?.name}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {botsTypeItems.map(({ name, value }, index) => (
                    <Dropdown.Item key={index} onClick={() => setBotType(value)}>
                      {name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <span>{t("Sort")}</span>
              <Dropdown className="d-inline">
                <Dropdown.Toggle
                  variant="light"
                  className="dropdown-sortBy bg-white shadow-sm"
                >
                  {sortBy === SORT_BY.RECENT && (
                    <Clock className="feather align-middle me-1" />
                  )}
                  <span>
                    {sortItems.find(({ value }) => value === sortBy)?.name}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {sortItems.map(({ name, value }, index) => (
                    <Dropdown.Item key={index} onClick={() => setSortBy(value)}>
                      {name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Button
                variant="outline-primary"
                className="shadow-sm"
                disabled={isLoading}
                onClick={refreshBots}
              >
                {isLoading ? (
                  <RotateCw className="feather fa-spin" />
                ) : (
                  <RefreshCw className="feather" />
                )}
                <span className="ms-2">{t("Refresh")}</span>
              </Button>
            </Col>
          </Row>
          <ProgressBar
            className={`position-absolute mt-2 ${isLoading ? "" : "opacity-0"}`}
            striped
            animated
            variant="primary"
            now={isLoading ? 90 : 0}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default Header;
