/*eslint no-template-curly-in-string: "off"*/
/*eslint no-useless-computed-key: "off"*/
import { Case, Names } from "./languages";

const en = {
  translation: {
    // языки
    [Names.en]: Names.en,
    [Names.ua]: Names.ua,
    // валюта
    ["USD"]: "USD",
    ["N USD"]: "${1} USD",
    // коды ошибок
    ["errorCode_0"]: "Unknown error.",
    ["errorCode_1"]: "Function not specified in this api version.",
    ["errorCode_2"]: "Asset not exists.",
    ["errorCode_3"]: "Wrong request data.",
    ["errorCode_4"]: "Bot not exists.",
    ["errorCode_5"]: "Login or password incorrect.",
    ["errorCode_6"]: "User already exists.",
    ["errorCode_7"]: "Permission denied.",
    ["errorCode_8"]: "State not exists.",
    ["errorCode_9"]: "Button not exists in current state.",
    ["errorCode_10"]: "Input not exists in current state.",
    ["errorCode_11"]: "Unknown user action.",
    ["errorCode_12"]:
      "Your account has not been confirmed. The new confirmation letter has been sent to your email address.",
    ["errorCode_13"]: "Error in bot config.",
    ["errorCode_14"]: "Bot already exists.",
    ["errorCode_15"]: "Verify accessToken failed.",
    ["errorCode_16"]: "Channel disabled.",
    // счетчики
    ["K"]: "${1}K",
    ["M"]: "${1}M",
    ["N days"]: "${1} days",
    ["1 month"]: "1 month",
    ["N months"]: "${1} months",
    ["/mo"]: "${1}/mo",
    ["Price /mo"]: "${1}${2}/mo",
    ["Regular Price /mo"]: "Regular price: ${1}${2}/mo",
    // главный экран
    ["Qudata"]: "Qudata",
    ["Qudata Console"]: "Qudata Console",
    ["Search"]: "Search…",
    ["Bots not found"]: "No bots were found for ${1}.",
    ["All Bots"]: "All Bots",
    ["Shared Bots"]: "Shared Bots",
    ["Templates"]: "Templates",
    ["Examples"]: "Examples",
    ["Unknown Bots"]: "Unknown Bots",
    ["My Bots"]: "My Bots",
    ["By name"]: "By name",
    ["Recently"]: "Recently",
    ["Sort"]: "Sort",
    ["Refresh"]: "Refresh",
    ["Bots Dashboard"]: "Bots Dashboard",
    ["Failed to download bot"]: "Failed to download bot",
    ["Under review"]: "Under review",
    ["Published"]: "Published",
    ["Maintenance"]: "Maintenance",
    ["In development"]: "In development",
    ["Woops"]: "Woops",
    ["Clear"]: "Clear",
    ["Refresh failed"]:
      "Failed to load ${1}. Check your network settings and ${2}.",
    ["Try Again"]: "Try Again",
    ["Blank bot"]: "Blank bot",
    ["New Assistant"]: "New Assistant",
    ["Create with ChatGPT"]: "Create bot with ChatGPT support",
    ["Connect Wordpress"]: "Connect to Wordpress plugin",
    ["By"]: "By",
    ["Clone"]: "Clone",
    ["General Settings"]: "General Settings",
    ["Backup/Restore"]: "Backup/Restore",
    ["Download Archive"]: "Download Archive",
    ["Channels"]: "Channels",
    ["Storage"]: "Storage",
    ["Analytics"]: "Analytics",
    ["Play"]: "Play",
    ["Management"]: "Management",
    ["Delete Bot"]: "Delete Bot",
    ["Testing"]: "Testing",
    ["Editor"]: "Editor",
    ["Edit"]: "Edit",
    ["Setup"]: "Setup",
    ["Payments"]: "Payments",
    ["Payments history"]: "Payments history",
    ["Traffic tracking"]: "Traffic tracking",
    ["Help"]: "Help",
    ["Logout"]: "Logout",
    ["Language"]: "Language",
    // подписки
    ["Subscription"]: "Subscription",
    ["X subscription"]: "${1} subscription",
    ["Third-party services"]: "Third-party services",
    ["Something went wrong"]: "Something went wrong",
    ["Manage subscription"]: "Manage subscription",
    ["Auto billing"]: "Auto billing",
    ["Auto billing is active"]: "Auto billing is active",
    ["Auto billing deactivated"]: "Auto billing deactivated",
    ["Pay on invoice"]: "Pay ${1} on invoice",
    ["Period"]: "Period",
    ["Amount"]: "Amount",
    ["Status"]: "Status",
    ["Payment"]: "Payment",
    ["Payment pending"]: "Payment pending",
    ["Payment confirmation"]: "Payment confirmation",
    ["Completed"]: "Completed",
    ["Estimated billing date"]: "Estimated billing date ${1}",
    ["Packages & Prices"]: "Packages & Prices",
    ["Hide all features"]: "Hide all features",
    ["Compare all features"]: "Compare all features",
    ["If you wish to unsubscribe"]:
      "If you wish to unsubscribe, click ${1}.\nPlease, mind that once you unsubscribe, some features may no longer be available.",
    ["here"]: "here",
    ["You have been invoiced"]:
      "You have been invoiced for the subscription. Please, check your ${1} and email.",
    ["payment history"]: "payment history",
    ["payment history" + Case.Genitive]: "payment history",
    ["Flexible plans"]: "Flexible plans you’ve been looking for.",
    ["You’re subscribed"]: "You’re subscribed to ${1} plan",
    ["Free"]: "Free",
    ["Start-Up"]: "Start-Up",
    ["Premium"]: "Premium",
    ["Enterprise"]: "Enterprise",
    ["The plan change canceled"]: "The plan change canceled",
    ["Your plan will change"]:
      "Your plan will change from ${1} to ${2} on ${3}. If you want to retain the ${1}, click ${4} to undo.",
    ["You have successfully unsubscribed"]:
      "You have successfully unsubscribed",
    ["Your current plan"]: "Your current plan",
    ["Your next plan"]: "Your next plan",
    ["Change plan"]: "Change plan",
    ["Upgrade"]: "Upgrade",
    ["Undo"]: "Undo",
    ["Plan change is scheduled"]: "Plan change is scheduled",
    ["The plan has been successfully upgraded"]:
      "The plan has been successfully upgraded",
    ["Get off for the first month"]: "Get ${1} off for the first month",
    ["will expire on"]: "will expire on ${1}",
    ["will be activated"]: "will be activated on ${1}",
    ["Visual editor"]: "Visual editor",
    ["Chatbot templates"]: "Chatbot templates",
    ["Communication channels"]: "${1} communication channels",
    ["N Message Limit"]: "${1}/mo Message Limit",
    ["Basic analytics"]: "Basic analytics",
    ["Basic NLU"]: "Basic NLU",
    ["All communication channels"]: "All communication channels",
    ["Standard integrations"]: "Standard integrations",
    ["Advanced analytics"]: "Advanced analytics",
    ["Advanced NLU"]: "Advanced NLU",
    ["Extra integrations"]: "Extra integrations",
    ["API integrations"]: "Extra integrations",
    ["ChatGPT integration"]: "ChatGPT integration",
    ["NLU customisation"]: "NLU customisation",
    ["Voice chatbot"]: "Voice chatbot",
    ["Voice chatbot Info"]: "Voice mode in the chatbot is available for customers who order chatbot development by the QuData team.",
    ["ChatGPT integration Info"]: "ChatGPT integration involves connecting the client's existing ChatGPT account.",
    ["Plans"]: "Plans",
    ["Development"]: "Development",
    ["Visual chatbot editor"]: "Visual chatbot editor",
    ["NLU custom models"]: "NLU custom models",
    ["Multiple languages setup"]: "Multiple languages setup",
    ["Interface customization"]: "Interface customization",
    ["Optional links"]: "Optional links",
    ["Optional links Info"]: "“Powered By” or Qudata links are optional for paid plans and can be disabled.",
    ["Communication and channels"]: "Communication and channels",
    ["Web widget"]: "Web widget",
    ["Messengers"]: "Messengers",
    ["Free plan includes"]:
      "Free plan includes a web widget and 1 messenger channel.",
    ["Facebook Messenger"]: "Facebook Messenger",
    ["Chatbot Analytics"]: "Chatbot Analytics",
    ["Basic"]: "Basic",
    ["Advanced"]: "Advanced",
    ["Advanced Analytics provides"]:
      "Advanced Analytics provides extended data storage capacity and custom events creation.",
    ["Google Analytics Integration"]: "Google Analytics Integration",
    ["Data storage"]: "Data storage",
    ["Google Calendar Integration"]: "Google Calendar Integration",
    ["Google Sheets Integration"]: "Google Sheets Integration",
    ["QuData storage"]: "QuData storage",
    ["Store the information about"]:
      "Store the information about chats history for a certain period of time according to the chosen plan.",
    ["Unlimited"]: "Unlimited",
    ["Special options"]: "Special options",
    ["Bots Hosting"]: "Bots Hosting",
    ["Number of domains"]: "Number of domains",
    ["Message Limit"]: "Message Limit",
    ["The number of exchanged messages"]:
      "The number of exchanged messages between the chatbot and customers is limited  according to the chosen plan (1 conversation can consist of several messages).",
    ["QuBot - Chatbot Builder"]: "QuBot - Chatbot Builder",
    ["WordPress plugin"]: "WordPress plugin",
    ["3rd party apps integration"]: "3rd party apps integration",
    ["3rd party apps integration Info"]: "3rd party apps integration requires additional expenses for any plan.",
    ["Page targeting"]: "Page targeting",
    ["Page targeting Info"]: "Add chatbot to individual pages of your website.",
    ["Chatbot testing"]: "Chatbot testing",
    ["On request"]: "On request",
    ["Appreciate the opportunity"]:
      "Appreciate the opportunity of getting your chatbots tested by our well-experienced team.",
    ["Support"]: "Support",
    ["E-mail"]: "E-mail",
    ["Upgrade your subscription"]: "Upgrade your subscription!",
    ["Pay now"]: "Pay now",
    ["purpose"]: "Payments porpuse",
    ["period"]: "Charged period",
    ["updated"]: "Updated",
    ["Bot files updated"]: "Bot files updated",
    ["amount"]: "Amount",
    ["Messages"]: "Messages",
    ["MessagesTooltip"]: "The number of exchanged messages between the chatbot and customers is limited accorfing to the chosen plan (1 conversationcan consist of several messages).",
    ["Messages total: N"]: "Messages total: ${1}",
    ["Free messages: N"]: "Free messages: ${1}",
    ["Paid messages: N"]: "Paid messages: ${1}",
    ["Current plan"]: "Your current plan ${1} includes ${2}/mo messages. Exceeding the message limit is charged $0.001/message.\nYou can increase the message limit by upgrading your plan ${3}.",
    // логин
    ["Welcome back"]: "Welcome back, ${1}",
    ["Sign In"]: "Sign In",
    ["Sign in"]: "Sign in",
    ["Sign in to your account"]: "Sign in to your account",
    ["Sign in to your account to continue"]:
      "Sign in to your account to continue",
    ["Your account has not been confirmed"]:
      "Your account has not been confirmed. Please check your email.",
    ["Must be a valid email"]: "Must be a valid email",
    ["Email"]: "Email",
    ["Enter your email"]: "Enter your email",
    ["Password"]: "Password",
    ["Enter your password"]: "Enter your password",
    ["Remember me next time"]: "Remember me next time",
    ["No Account?"]: "No Account?",
    ["Forgot password?"]: "Forgot password?",
    ["Login with Google"]: "Login with Google",
    ["Login with Facebook"]: "Login with Facebook",
    ["Sign up with Google"]: "Sign up with Google",
    ["Sign up with Facebook"]: "Sign up with Facebook",
    ["Login or password incorrect."]: "Login or password incorrect.",
    // регистрация
    ["Sign Up"]: "Sign Up",
    ["Sign up"]: "Sign up",
    ["Already have an account?"]: "Already have an account?",
    ["Login here"]: "Login here",
    ["You’re sign up!"]:
      "You’re sign up! Please check your email for the confirmation message we’ve just sent you.",
    ["Must be at least N characters"]: "Must be at least ${1} characters",
    ["Must contain only Latin characters"]: "Must contain only Latin characters, numbers, spaces, and _ ( ) symbols",
    ["Password must contain"]:
      "Must contain at least one numeric digit, one uppercase and one lowercase letter",
    ["Both password need to be the same"]: "Both password need to be the same",
    ["First name"]: "First name",
    ["Last name"]: "Last name",
    ["Email address"]: "Email address",
    ["Confirm password"]: "Confirm password",
    ["By clicking the Sign Up button"]:
      "By clicking the Sign Up button, you agree to our ${1}, and ${2}",
    ["Terms & Conditions"]: "Terms & Conditions",
    ["Terms & Conditions" + Case.Instrumental]: "Terms & Conditions",
    ["Privacy Policy"]: "Privacy Policy",
    ["Privacy Policy" + Case.Instrumental]: "Privacy Policy",
    ["Email confirmation"]: "Email confirmation",
    ["Please wait for the operation to complete"]:
      "Please wait for the operation to complete",
    ["Please, fill in the form to complete the registration"]:
      "Please, fill in the form to complete the registration",
    ["Company name"]: "Company name",
    ["Business website URL"]: "Business website URL",
    ["Number of employees"]: "Number of employees",
    ["Industry"]: "Industry",
    ["Your position"]: "Your position",
    ["Phone number"]: "Phone number",
    ["Save changes"]: "Save changes",
    ["Complete"]: "Complete",
    ["Please check your email"]: "Please check your email",
    ["Reset Password"]: "Reset Password",
    ["We have sent you an email with further instructions"]:
      "We have sent you an email with further instructions. Please follow the instructions to change your password.",
    ["Email Address"]: "Email Address",
    ["Enter your email to reset your password"]:
      "Enter your email to reset your password",
    ["Remember your password?"]: "Remember your password?",
    ["Upload"]: "Upload",
    ["Display name"]: "Display name",
    ["Your changes have been successfully saved"]:
      "Your changes have been successfully saved",
    ["Note"]: "Note",
    ["Click on the message to see More Details"]:
      "Click on the message to see More Details",
    ["More details"]: "More details",
    ["Connection timeout"]: "Connection timeout",
    ["Reset your password"]: "Reset your password.",
    ["After deleting your account"]:
      "After deleting your account, you will not be able to sign in with this email until you register again",
    ["Confirm deletion"]: "Confirm deletion",
    // 404, 500 Error
    ["Error"]: "Error",
    ["Page not found"]: "Page not found.",
    ["The page you are looking for might have been removed"]:
      "The page you are looking for might have been removed.",
    ["Return to website"]: "Return to website",
    ["Internal server error"]: "Internal server error.",
    ["The server encountered something unexpected"]:
      "The server encountered something unexpected that didn't allow it to complete the request.",
    // профиль
    ["Account Settings"]: "Account Settings",
    ["Profile"]: "Profile",
    ["Details"]: "Details",
    ["Billing"]: "Billing",
    ["Clear billing"]: "Clear billing",
    ["User Profile"]: "User Profile",
    ["Additional Details"]: "Additional Details",
    ["Billing Information"]: "Biling Information",
    ["Change Password"]: "Change Password",
    ["Delete Account"]: "Delete Account",
    ["Settings"]: "Settings",
    ["Payment history has been deleted"]: "Payment history has been deleted.",
    ["This feature is only available for test"]:
      "This feature is only available for test.",
    ["Clear payment history"]: "Clear payment history",
    ["Activity Report"]: "Activity Report",
    ["Download CSV"]: "Download CSV",
    ["Admin Panel"]: "Admin Panel",
    // модальные окна
    ["Close"]: "Close",
    ["Create"]: "Create",
    ["Delete"]: "Delete",
    ["Rename"]: "Rename",
    ["Clone Bot"]: "Clone Bot",
    ["Create Bot"]: "Create Bot",
    ["After deleting this bot"]:
      "After deleting this bot, you will no longer be able to use it.",
    ["The bot was successfully deleted"]: "The bot was successfully deleted",
    ["Rename Bot"]: "Rename Bot",
    // хранилище
    ["Today"]: "Today",
    ["Yesterday"]: "Yesterday",
    ["Last Week"]: "Last Week",
    ["Last Month"]: "Last Month",
    ["Custom"]: "Custom",
    ["not serializable"]: "not serializable",
    ["Date"]: "Date",
    ["Time"]: "Time",
    ["Kind"]: "Kind",
    // аналитика
    ["Sessions"]: "Sessions",
    ["Unique Users"]: "Unique Users",
    ["Conversion"]: "Conversion",
    ["Event X"]: "Event ${1}",
    ["Sessions Per Channel"]: "Sessions Per Channel",
    ["Reports"]: "Reports",
    ["Events"]: "Events",
    ["Total N"]: "Total: ${1}",
    ["The number of sessions for"]:
      "The number of sessions for the selected period by channel.",
    ["Aggregated event conversions for"]:
      "Aggregated event conversions for the selected period. It's calculated by: Conversions = Number of events / Number of sessions * 100%.",
    ["The number of times the selected event occurred"]:
      "The number of times the selected event occurred. Any custom event has to be embedded in the bot code in order to appear in the list of target events.",
    ["No event selected"]: "No event selected",
    ["Empty"]: "Empty",
    ["The total number of bot launches"]: "The total number of bot launches.",
    ["The total number of unique bot launches"]:
      "The total number of unique bot launches.",
    ["Add Filter"]: "Add Filter…",
    // настройки бота
    ["General info"]: "General info",
    ["Name"]: "Name",
    ["Bot name"]: "Bot name",
    ["Bot name is required"]: "Bot name is required",
    ["The data is up to date"]: "The data is up to date",
    ["Bot bundle"]: "Bot bundle",
    ["Bundle is generated automatically"]:
      "Bundle is generated automatically from company domain and bot name.",
    ["Description"]: "Description",
    ["Default language"]: "Default language",
    ["Bot status"]: "Bot status",
    ["Assistant"]: "Assistant",
    ["Bot kind"]: "Bot kind",
    ["User"]: "User",
    ["Init"]: "Init",
    ["Template"]: "Template",
    ["Example"]: "Example",
    // уведомления
    ["Account details required"]: "Account details required",
    ["Please fill in all fields in the form"]:
      "Please fill in all fields in the form.",
    ["Notifications"]: "Notifications",
    ["You have no notifications"]: "You have no notifications",
    ["Reminder"]: "Reminder",
    ["notification.next-subscription-3"]:
      "Your next subscription billing is due in 3 days.",
    ["notification.next-subscription-2"]:
      "Your next subscription billing is due in 2 days.",
    ["notification.next-subscription-1"]:
      "Your next subscription billing is due in 1 day.",
    ["notification.invoice"]:
      "You have been invoiced for the subscription. Please, check your payment history and email.",
    ["notification.invoice-asap"]:
      "You have been invoiced for the subscription. Please, check your payment history and email.",
    ["notification.subscription-canceled"]:
      "Sorry, your subscription has been canceled due to non-payment. Your current plan has been changed to Free.",
    ["notification.subscription-renewed"]:
      "Congratulations! Your subscription has been successfully renewed.",
    // сеть
    ["Connection error"]: "Connection error",
    ["No data after loading"]: "No data after loading",
    ["Unsupported response data format"]: "Unsupported response data format",
    // разное
    ["Text"]: "Text",
    ["copied"]: "copied",
    // каналы
    ["Integrations"]: "Integrations",
    ["Workflow"]: "Workflow",
    ["Wordpress"]: "Wordpress",
    ["WebChat"]: "WebChat",
    ["Messenger"]: "Messenger",
    ["Zendesk"]: "Zendesk",
    ["Dropbox"]: "Dropbox",
    ["Viber"]: "Viber",
    ["Telegram"]: "Telegram",
    ["WhatsApp"]: "WhatsApp",
    ["Whatsapp Info"]: "WhatsApp doesn’t include Facebook fees.",
    ["LiveChat"]: "LiveChat",
    ["LiveChat Info"]: "LiveChat integration requires LiveChat account registration and additional expenses.\n${1}\nSubscribe to our plan and create a LiveChat account with extended trial up to 60 days as well as 10% off first payment.",
    ["Special Offer"]: "Special Offer!",
    ["Unknown"]: "Unknown",
    ["Channel is active"]: "Channel is active",
    ["Channel deactivated"]: "Channel deactivated",
    ["Open"]: "Open",
    ["Click to configure"]: "Click to configure",
    ["Back"]: "Back",
    ["Next"]: "Next",
    ["Skip"]: "Skip",
    ["Pro"]: "Pro",
    ["Step N"]: "Step ${1}",
    ["That’s it!"]: "That’s it!",
    ["This was all the steps"]:
      "This was all the steps for setting up the channel. Don't forget to make sure the channel is activated.",
    ["This was all the step..."]:
      "This was all the steps for setting up the channel. Don't forget to make sure the",
    ["...channel is activated"]:
      " channel is activated",
    ["To activate your chatbot, click..."]: "To activate your chatbot, click General settings on the left navigation panel.\nIn the Bot status section, select Published from the list and save the changes.",
    ["optional"]: "optional",
    ["Validation failed"]: "Validation failed.",
    ["Okay"]: "Okay",
    ["Please make sure you copy the correct values"]:
      "Please make sure you copy the correct values and try again. To fix this, follow the instructions in this step.",
    ["Copy"]: "Copy",
    ["Value must be at most N characters"]:
      "Value must be at most ${1} characters",
    ["Deactivate the channel"]: "Deactivate the channel",
    ["Deactivate"]: "Deactivate",
    ["Cancel"]: "Cancel",
    ["Are you sure that you want to deactivate the X"]:
      "Are you sure that you want to deactivate the ${1} channel? In this case it will stop functioning. To maintain the correct work of the channel, keep it active.",
    ["While channel is deactivated..."]:
      "While channel is deactivated, the Bot widget won't work. Switch the channel to Active, before placing the widget link on your website.",
    ["Please follow the previous steps"]: "Please follow the previous steps",
    // канал Web
    ["Web.Header"]: "How to deploy your chatbot on a webpage.",
    ["Web.Step-1"]: "Provide domain names where the bot will be hosted.",
    ["Web.Step-2"]: "Embed the Bot widget on your site:",
    ["Web.Step-2-1"]: "Copy the following code to clipboard.",
    ["Web.Step-2-2"]:
      "Open your webpage and access the HTML. Scroll down to the bottom and paste it.",
    ["Web.Step-2-3"]:
      "Reload the page.\nThe bot will appear as a button in the bottom right.",
    ["Add Domain"]: "Add Domain…",
    ["Upgrade your subscription to add more domain names"]:
      "Upgrade your subscription to add more domain names.",
    ["HTML code"]: "HTML code",
    // канал Wordpress
    ["Wordpress.Header"]:
      "If you use WordPress, еxtend your experience! Try QuBot plugin, a chatbot builder with a variety of ready-to-use templates of chatbots for any purpose your business may have.",
    ["Wordpress.Step-1"]:
      "In case you are already using the plugin, follow the instructions below to connect your bot created with QuBot to this console to see bot statistics and enable multiple channels.",
    ["Wordpress.Step-1-1"]: "Copy the access token to clipboard.",
    ["Wordpress.Step-2"]: "Paste the access token in the WordPress plugin:",
    ["Wordpress.Step-2-1"]: "Open your bot in the “QuBot” Wordpress plugin.",
    ["Wordpress.Step-2-2"]: "Go to Launch settings as shown below.",
    ["Wordpress.Step-2-3"]: "Paste the token in the ID field.",
    ["Wordpress.Step-2-4"]:
      "Then you can work on your bot both in the plugin and in this console.",
    ["Access Token"]: "Access Token",
    ["QuBot – Chatbot Builder with Templates"]:
      "QuBot – Chatbot Builder with Templates",
    ["QuData"]: "QuData",
    ["Get Plugin"]: "Get Plugin",
    // канал Zendesk
    ["Zendesk.Step-1"]: "Provide your Zendesk account.",
    ["Zendesk.Step-1-1"]:
      "Register at ${1} and create your account (trial or sponsored Zendesk Support account). Provide the URL to your account into the input field below (e.g. https://myaccountname.zendesk.com).",
    ["Zendesk.Step-1-2"]:
      "Trial access only lasts for 14-days, make sure that when the trial period ends, your subscription plan at Zendesk meets the minimal requirements for the Chat API to work:",
    ["Zendesk.Step-1-3"]: "Zendesk Chat: Enterprise subscription",
    ["Zendesk.Step-1-4"]:
      "Zendesk Support Suite: Essential or higher subscription",
    ["Zendesk.Step-2"]: "Create an administrator.",
    ["Zendesk.Step-2-1"]:
      "In the main menu, create a new user (Administrator) to act on behalf of the bot. You can also use the registration profile.",
    ["Zendesk.Step-3"]: "Create an API token for the administrator.",
    ["Zendesk.Step-3-1"]:
      "In the admin account, navigate to the Settings, then go to the ${1}.",
    ["Zendesk.Step-3-2"]:
      "In the Admin Center, navigate to the ${1}. In the Settings tab, enable the Token access.",
    ["Zendesk.Step-3-3"]:
      "Click on the ${1} button, copy the code from the ${2} field and ${3} the changes.",
    ["Zendesk.Step-4"]: "Provide the API token.",
    ["Account URL"]: "Account URL",
    ["Admin Center"]: "Admin Center",
    ["Apps and integrations > Zendesk API"]:
      "Apps and integrations > Zendesk API",
    ["Add API token"]: "Add API token",
    ["API token"]: "API token",
    ["Save"]: "Save",
    // канал Dropbox
    ["Dropbox.Step-1"]: "Log in to your Dropbox account.",
    ["Dropbox.Step-1-1"]:
      "If you don't have a Dropbox account, sign up at ${1}",
    ["Dropbox.Step-2"]: "Create an app:",
    ["Dropbox.Step-2-1"]:
      "On the developer portal, navigate to the App Console at ${1}",
    ["Dropbox.Step-2-2"]: "In your App console, press the ${1} button.",
    ["Dropbox.Step-2-3"]:
      "Choose the type of access ${1}, name your app, accept the Terms and Conditions and create an app.",
    ["Dropbox.Step-3"]: "In your app, navigate to the ${1} tab, select the following checkboxes: files.metadata.write, files.content.write, files.content.read, sharing.write. Then click the ${2} button.",
    ["Dropbox.Step-4"]: "In your app, navigate to the ${1} tab, copy the ${2} and ${3} and paste it below.",
    ["Dropbox.Step-5"]: "Click the ${1} button and follow the instructions in the dropbox window to generate an access code.",
    ["Dropbox.Step-5-1"]: "Once you receive the ${1}, enter it below.",
    ["Permissions"]: "Permissions",
    ["Submit"]: "Submit",
    ["App key"]: "App key",
    ["App secret"]: "App secret",
    ["Get code"]: "Get code",
    ["Access Code"]: "Access Code",
    ["Create app"]: "Create app",
    ["Full Dropbox"]: "Full Dropbox",
    ["OAuth 2 access token"]: "OAuth 2 access token",
    ["Finish Step 4 to continue"]: "Finish Step 4 to continue.",
    // канал Viber
    ["Viber.Step-1"]: "Download the official Viber app for your phone:",
    ["Viber.Step-2"]: "Create a Viber Public Account:",
    ["Viber.Step-3"]: "Log in to the Viber Admin Panel.",
    ["Viber.Step-3-1"]: "Go to ${1} and enter your phone number.",
    ["Viber.Step-3-2"]:
      "You will receive a Viber message with a code, which you need to enter in the input field.",
    ["Viber.Step-4"]: "Create a bot account.",
    ["Viber.Step-4-1"]:
      "Fill out the following form, accept the Terms and Conditions and then click ${1}.",
    ["Viber.Step-4-2"]:
      "This is a new Viber account associated with your phone number (as an administrator) where the bot will operate.",
    ["Viber.Step-5"]:
      "After your account is created you will receive a private token.",
    ["Viber.Step-5-1"]: "Copy your token and enter it below:",
    ["Viber.Step-6"]:
      "Viber allows you to customize the greeting message for your bot profile that is shown to users the first time they interact with your bot.",
    ["Greeting Message"]: "Greeting Message",
    ["Create Bot Account"]: "Create Bot Account",
    ["Viber Private Token"]: "Viber Private Token",
    // канал Telegram
    ["Telegram.Step-1"]: "Log in to your Telegram account.",
    ["Telegram.Step-1-1"]:
      "If you don’t have a Telegram account, sign up at ${1}",
    ["Telegram.Step-2"]:
      "Once signed in, search for @BotFather from the Search option as shown below.Select BotFather bot and type ${1} (or click on START button). If @BotFather is already among your contacts, you may proceed to Step 3 directly.",
    ["Telegram.Step-3"]: "Create a bot:",
    ["Telegram.Step-3-1"]:
      "Send ${1} to the BotFather, and enter a name for the new bot to be created.",
    ["Telegram.Step-3-2"]:
      "Provide the username that ends with ${1}, e.g. sample_bot or SampleBot.",
    ["Telegram.Step-3-3"]:
      "Copy the access token from the Telegram’s confirmation message.",
    ["Telegram.Step-4"]: "Paste the token into the input field below.",
    ["Telegram API Token"]: "Telegram API Token",
    // канал WhatsApp
    ["API Key"]: "API Key",
    ["Whatsapp.Header"]:
      "To get WhatsApp Business API Access through our embedded signup  form, follow this guide.",
    ["Whatsapp.Step-1"]: "Prerequisites.",
    ["Whatsapp.Step-1-1"]: "Before signing up, make sure you have a valid phone number.Check if your phone number meets the criteria ${1}.",
    ["Whatsapp.Step-1-2"]: "For phone numbers with an IVR system enabled, the IVR needs to be temporarily disabled during the registration process.",
    ["Whatsapp.Step-2-1"]: "Follow our signup form link ${1} to fill out the WhatsApp Business Account application.",
    ["Whatsapp.Step-2-2"]: "You will need to provide your email address (which can't be connected to an active 360dialog account), your name, your company name, and the password that will be used in the 360 Client Hub login.",
    ["Whatsapp.Step-2-3"]: "After clicking the ${1} button, you will be redirected to the embedded signup page.",
    ["Whatsapp.Step-3-1"]: "Complete the embedded signup.",
    ["Whatsapp.Step-3-2"]: "Precaution: do not keep the embedded signup pop-up open for more than 1 hour, otherwise the session will expire. Please ensure that you have all the prerequisites from Step 1 ready before starting.",
    ["Whatsapp.Step-3-3"]: "You will need to log in to your Meta account to move forward. It's important that this Meta account has admin access to the Meta Business Manager that will manage this WhatsApp account.",
    ["Whatsapp.Step-3-4"]: "Provide the phone number for your business below.",
    ["Whatsapp.Step-3-5"]: "Once the embedded signup is completed, we will immediately deploy the WhatsApp Business API Client. You will be automatically logged into 360 Client Hub, where you can generate your API Key.",
    ["Whatsapp.Step-4"]: "Generate an API Key and make sure you store it in a secure location.",
    ["Whatsapp.Step-4-1"]: "Copy the API Key into the input field below.",
    ["Whatsapp.Step-5"]: "Complete your business manager profile.",
    ["Whatsapp.Step-5-1"]: "To keep connected to the API, you should add your information in the business info section of business manager settings. Learn how to do it ${1}.",
    // канал Facebook
    ["Facebook.Header"]:
      "To set up Facebook Messenger as a channel, developers will need to create an app on Facebook, enable webhook integration for the platform to send and receive messages, and subscribe the app to a Facebook page.",
    ["Facebook.Step-1"]: "Create an App.",
    ["Facebook.Step-1-1"]: "Login to the ${1} and go to ${2}.",
    ["Facebook.Step-1-2"]:
      "Click the ${1} button, then select an app type ${2}.",
    ["Facebook.Step-1-3"]:
      "Enter all the relevant details for your app and click ${1}.",
    ["Facebook.Step-1-4"]:
      "Your App will be created and you will be redirected to the App setup page.",
    ["Facebook.Step-1-5"]:
      "Choose ${1} and click the ${2} button to continue. You will be redirected to the Settings page.",
    ["Facebook.Step-2"]: "Get Page Details.",
    ["Facebook.Step-2-1"]:
      "Associate a page with the app and generate the ${1} from the Token Generation section. If you do not have a page, you can create a Facebook page ${2}.",
    ["Facebook.Step-2-2"]: "Copy this Page Access Token and enter it below.",
    ["Facebook.Step-2-3"]:
      "Navigate to the Main Settings menu then select Basic Settings, capture the ${1} and ${2} and enter it below.",
    ["Facebook.Step-2-4"]:
      "From your Facebook account, navigate to the Facebook page you have selected above. Locate the ${1} menu.",
    ["Facebook.Step-2-5"]: "Copy ${1} and enter it below.",
    ["Facebook.Step-3"]: "Setup Webhook.",
    ["Facebook.Step-3-1"]:
      "${1} to start the webhook setup process. Click the switch in the top right to activate the channel. Once the channel is activated, the webhook integration is enabled automatically.",
    ["Facebook.Step-3-2"]:
      "Navigate to the Messenger Settings menu and scroll down to the ${1}. Under the Webhooks section, select the page you have used to generate the page token and click on ${2}. Check the following subscription fields: ${3}, ${4}, ${5}, ${6}.\n${7} the changes.",
    ["Facebook.Step-4"]: "Submit the Facebook app for approval.",
    ["Facebook.Step-4-1"]:
      "Once approved, you can enable the Facebook Messenger channel for your bot. For more info on how to submit the app, visit ${1}.",
    ["Facebook developer portal"]: "Facebook developer portal",
    ["My Apps"]: "My Apps",
    ["Create App"]: "Create App",
    ["Business"]: "Business",
    ["Set up"]: "Set up",
    ["Page Access Token"]: "Page Access Token",
    ["App ID"]: "App ID",
    ["App Secret"]: "App Secret",
    ["About"]: "About",
    ["Page ID"]: "Page ID",
    ["Webhooks"]: "Webhooks",
    ["Add Callback URL"]: "Add Callback URL",
    ["Add subscriptions"]: "Add subscriptions",
    ["Callback URL"]: "Callback URL",
    ["Verify Token"]: "Verify Token",
    ["Enable this channel"]: "Enable this channel",
    ["Webhooks section"]: "Webhooks section",
    ["Add Subscriptions"]: "Add Subscriptions",
    // google drive
    ["GoogleDrive.Header-1"]:
      "By connecting your Google Drive to the bot, your bot experience will improve significantly. For example, your bot will be able to use some data or files directly from your Google Drive, as well as save data or files received from clients. Using Google Drive will make your work much easier, because you will have direct access to read and edit the saved data at any time without interfering with the bot code.",
    ["GoogleDrive.Header-2"]:
      "The instructions below will help you to share files and folders from your Google Drive.",
    ["GoogleDrive.Step-1"]: "Log in to ${1}",
    ["GoogleDrive.Step-1-1"]:
      "If you don’t have a Google account, create it at ${1}",
    ["GoogleDrive.Step-2"]: "Open your ${1} here ${2}",
    ["GoogleDrive.Step-2-1"]:
      "You can also access your Google Drive from the ${1} by clicking the Google apps button.",
    ["GoogleDrive.Step-3"]: "Share files or folders with your bot.",
    ["GoogleDrive.Step-3-1"]:
      "In your Google Drive, choose a file or folder, call the submenu and select ${1}.",
    ["GoogleDrive.Step-3-2"]:
      "Share the access in any of the possible ways.\n${1}. Share with a specific account: copy the following account into the input field of the sharing window and send the invitation.",
    ["GoogleDrive.Step-3-3"]:
      "${1}. Get shareable link: in the ${2} section change status ${3} to ${4}, and click Done.",
    ["GoogleDrive.Step-3-4"]:
      "Please note that you can control the access: if the bot will only read data from Google Drive, then the ${1} role is enough, and if the bot will also save data to Google Drive, then the ${2} role is required. You can change the role at any time in the sharing settings.",
    ["GoogleDrive.Step-4"]:
      "Set up data loading to a file or folder in the bot editor. Additional information about this can be found in the bot editor templates (e.g. Trivia, Order Food templates) or in our help center.",
    ["GoogleDrive.Step-4-1"]:
      "Every time you embed a link to a file or folder in the bot editor, it will appear in the list below so you can view them all in one place.",
    ["GoogleDrive.Step-4-2"]:
      "Warning: Some files or folders used in the bot are not available. To fix the issue, on your Google Drive, check if the file or folder sharing is set up, and, in the bot editor, check if the embedded link is correct.",
    ["Google Drive Folder"]: "Google Drive Folder",
    ["Google Drive Folder" + Case.Genitive]: "Google Drive Folder",
    ["Google Drive"]: "Google Drive",
    ["Add Link"]: "Add Link…",
    ["Copy link"]: "Copy link",
    ["Get link"]: "Get link",
    ["Send"]: "Send",
    ["Share"]: "Share",
    ["Google homepage"]: "Google homepage",
    ["Account"]: "Account",
    ["First way"]: "First way",
    ["Second way"]: "Second way",
    ["Restricted"]: "Restricted",
    ["Anyone with the link"]: "Anyone with the link",
    ["List is empty"]: "List is empty",
    ["there are no files or folders used in a bot"]:
      "there are no files or folders used in a bot",
    ["Viewer"]: "Viewer",
    // google calendar
    ["GoogleCalendar.Header-1"]:
      "Connecting your Google calendar to the bot, will improve your bot experience significantly. For example, it may enable your bot to create events and tasks right in your Google calendar. It will also facilitate your work as you will have direct access to view and edit the calendar at any time without interfering with the bot code.",
    ["GoogleCalendar.Header-2"]:
      "The instructions below will help you to share the Google calendar with the bot.",
    ["GoogleCalendar.Step-1"]: "Log in to ${1}",
    ["GoogleCalendar.Step-1-1"]:
      "If you don’t have a Google account, create it at ${1}",
    ["GoogleCalendar.Step-2"]: "Open your ${1} here ${2}",
    ["GoogleCalendar.Step-2-1"]:
      "You can also access your Google Calendar from the ${1} by clicking the Google apps button.",
    ["GoogleCalendar.Step-3"]: "Share the ${1} with your bot.",
    ["GoogleCalendar.Step-3-1"]:
      "In your Google Calendar, create a new calendar or select the existing one.",
    ["GoogleCalendar.Step-3-2"]:
      "Call the options for your calendar and select ${1}.",
    ["GoogleCalendar.Step-3-3"]:
      "In the calendar settings, navigate to the ${1} section, click the ${2} button, copy the following account into the input field of the sharing window and send the invitation.",
    ["GoogleCalendar.Step-3-4"]:
      "Please, mind the access settings: if the bot should only read data from Google calendar, then ${1} suffice, but if it has to save data to Google calendar, then select ${2} is required.\nYou can change the access permissions at any time in the calendar settings. Learn more about it ${3}.",
    ["GoogleCalendar.Step-4"]:
      "Set up connection with the calendar in the bot editor. First, get a link to your calendar in the calendar settings, under ${1} section, by clicking the ${2} button.",
    ["GoogleCalendar.Step-4-1"]:
      "Every time you embed a link to a calendar in the bot editor, it will appear in the list below so you can view them all in one place.",
    ["GoogleCalendar.Step-4-2"]:
      "Warning: Some calendars used in the bot are not available. To fix the issue, on your Google Calendar, check if the calendar sharing is set up, and, in the bot editor, check if the embedded link is correct.",
    ["Google Calendar"]: "Google Calendar",
    ["Google Calendar" + Case.Genitive]: "Google Calendar",
    ["Settings and sharing"]: "Settings and sharing",
    ["Share with specific people"]: "Share with specific people",
    ["Add people"]: "Add people",
    ["Make changes and manage sharing"]: "Make changes and manage sharing",
    ["Access permissions for events"]: "Access permissions for events",
    ["Get shareable link"]: "Get shareable link",
    ["See all event details"]: "See all event details",
    ["there are no calendars used in a bot"]:
      "there are no calendars used in a bot",
    // google sheets
    ["GoogleSheets.Header-1"]:
      "By connecting your Google spreadsheets to the bot, your bot experience will improve significantly. For example, your bot will be able to download data directly from your Google spreadsheet, as well as upload new data in real time. Using Google Sheets will make your work much easier, because you will have direct access to read and edit the saved data at any time without interfering with the bot code.",
    ["GoogleSheets.Header-2"]:
      "The instructions below will help you to share Google spreadsheets.",
    ["GoogleSheets.Step-1"]: "Log in to ${1}",
    ["GoogleSheets.Step-1-1"]:
      "If you don’t have a Google account, create it at ${1}",
    ["GoogleSheets.Step-2"]: "Open your ${1} here ${2}",
    ["GoogleSheets.Step-2-1"]:
      "You can also access your Google Sheets from the ${1} by clicking the Google apps button.",
    ["GoogleSheets.Step-3"]: "Share the ${1} with your bot.",
    ["GoogleSheets.Step-3-1"]:
      "In your Google Sheets, open a spreadsheet and click the ${1} button.",
    ["GoogleSheets.Step-3-2"]:
      "Share the access in any of the possible ways.\n${1}. Share with a specific account: copy the following account into the input field of the sharing window and send the invitation.",
    ["GoogleSheets.Step-3-3"]:
      "${1}. Get shareable link: in the ${2} section change status ${3} to ${4}, and click Done.",
    ["GoogleSheets.Step-3-4"]:
      "Please note that you can control the access: if the bot will only read data from Google spreadsheet, then the ${1} role is enough, and if the bot will also save data to Google spreadsheet, then the ${2} role is required. You can change the role at any time in the sharing settings.",
    ["GoogleSheets.Step-4"]:
      "Set up data loading to a spreadsheet in the bot editor. Additional information about this can be found in the bot editor templates (e.g. Trivia, Order Food templates) or in our help center.",
    ["GoogleSheets.Step-4-1"]:
      "Every time you embed a link to a spreadsheet in the bot editor, it will appear in the list below so you can view them all in one place.",
    ["Google Sheets"]: "Google Sheets",
    ["Google Spreadsheet"]: "Google Spreadsheet",
    ["Google Spreadsheet" + Case.Instrumental]: "Google Spreadsheet",
    ["there are no spreadsheets used in a bot"]:
      "there are no spreadsheets used in a bot",
    // live chat
    ["LiveChat.Header"]:
      "Integrating LiveChat with a bot allows you to add live communication with the agents, so your customer service improves significantly. The following integration requires you to have a LiveChat account.",
    ["LiveChat.Header-1"]: "Getting a LiveChat account with QuData",
    ["LiveChat.Header-2"]: "In LiveChat, all QuData partners get an extended free 60-day trial and 10% off first payment.\nTo enjoy these benefits, click on the Get your LiveChat account button below, and you will receive an invitation email. Follow the instructions in the email to complete the LiveChat registration.",
    ["LiveChat.Header-3"]: "Get your ${1}LiveChat account",
    ["LiveChat.Header-3-1"]: "Invitation sent. Please, check your email.",
    ["LiveChat.Step-1"]: "Log in to ${1}",
    ["LiveChat.Step-1-1"]:
      "If you don’t have a LiveChat account, sign up at ${1}",
    ["LiveChat.Step-2"]: "Provide your LiveChat Organization ID.",
    ["LiveChat.Step-2-1"]:
      "In the ${1}, go to ${2} and copy the ${3} into the input field below.",
    ["LiveChat.Step-3"]: "Create an App.",
    ["LiveChat.Step-3-1"]: "In the ${1}, go to ${2} and create a new app.",
    ["LiveChat.Step-4"]: "Create an Authorization Block:",
    ["LiveChat.Step-4-1"]: "In your app, navigate to the ${1} section.",
    ["LiveChat.Step-4-2"]: "In the ${1} block, click the ${2} button.",
    ["LiveChat.Step-4-3"]:
      "In the ${1} window, select the ${2} option, and click the ${3} button.",
    ["LiveChat.Step-4-4"]:
      "Then you will be sent to the ${1} settings. Please, do not leave that page until you’ve finished the ${2} of this instruction.",
    ["LiveChat.Step-5"]: "Setup an App Authorization:",
    ["LiveChat.Step-5-1"]:
      "In the ${1} configuration, find the ${2} and copy it into the input field below.",
    ["LiveChat.Step-5-2"]:
      "In the ${1} configuration, find the ${2} and copy it into the input field below.",
    ["LiveChat.Step-5-3"]:
      "You cannot recover the original Client Secret, so store it in a secure location.",
    ["LiveChat.Step-5-4"]:
      "Be careful, you won't be able to see the Client Secret after the page reloads. In this case, you will have to generate and provide us a new secret (it will reset the old one).",
    ["LiveChat.Step-5-5"]:
      "Go to the ${1} section and ${2} the following URLs to the list:",
    ["LiveChat.Step-5-6"]: "Go to the ${1} section and ${2} access scopes. Click the ${3} button for this section.",
    ["LiveChat.Step-5-7"]:
      "On the top of the ${1} page, click the ${2} button to become ready to deploy.",
    ["LiveChat.Step-5-8"]:
      "${1}. Click the ${2} button below to start the quick automated process. Please note that during the process you may be asked to log in to the Live Chat.",
    ["LiveChat.Step-6"]: "Setup a Webhook Block:",
    ["LiveChat.Step-6-1"]:
      "In your app, navigate to the ${1} section, find the ${2} block and click the ${3} button.",
    ["LiveChat.Step-6-2"]:
      "Copy the ${1} provided below and enter it in the appropriate field in the ${2} configuration.",
    ["LiveChat.Step-6-3"]:
      "Provide us with a ${1}. Click the ${2} button and copy the Secret Key into the input field below.",
    ["LiveChat.Step-6-4"]:
      "In the ${1} section, select the ${2}. For this event, select the options: ${3}, ${4}.",
    ["LiveChat.Step-6-5"]: "Click the ${1} button.",
    ["LiveChat.Step-7"]: "Click the ${1} button and follow the instructions in the LiveChat window to generate an access code.",
    ["LiveChat Developers Console"]: "LiveChat Developers Console",
    ["My Profile"]: "My Profile",
    ["Organization ID"]: "Organization ID",
    ["Building Blocks"]: "Building Blocks",
    ["App Authorization"]: "App Authorization",
    ["Get started"]: "Get started",
    ["Creating the OAuth2.1 Client"]: "Creating the OAuth2.1 Client",
    ["Server-side app"]: "Server-side app",
    ["Continue"]: "Continue",
    ["Client Id"]: "Client Id",
    ["Client Secret"]: "Client Secret",
    ["Redirect URI whitelist"]: "Redirect URI whitelist",
    ["Add"]: "Add",
    ["App scopes and API access"]: "App scopes and API access",
    ["Select All"]: "Select All",
    ["Chat Webhooks"]: "Chat Webhooks",
    ["Webhook URL"]: "Webhook URL",
    ["Secret Key"]: "Secret Key",
    ["Generate"]: "Generate",
    ["Webhook triggers"]: "Webhook triggers",
    ["author type"]: "author type",
    ["agent"]: "agent",
    ["Allow access to your App"]: "Allow access to your App",
    ["Allow Access"]: "Allow Access",
    ["Done, access allowed"]: "Done, access allowed.",
    ["Access denied. Please double check the steps above"]:
      "Access denied. Please double check the steps above and try again to allow access.",
    //permissions
    ["Roles"]: "Roles",
    ["Assign"]: "Assign",
    ["Select Bots"]: "Select Bots",
    ["Select Roles"]: "Select Roles",
    ["Update Roles"]: "Update Roles",
    ["Add new roles"]: "Add new roles",
    ["Account Deleting"]: "Account Deleting",
    ["Account Deleting 1"]: "Are you sure you want to ${1} account?",
    ["Account Deleting 2"]: "Keep in mind that deleting an account will ${1}",
    ["Account Deleting B"]: "erase all data and all its chatbots.",
    ["Delete Anyway"]: "Delete anyway",
    ["Information"]: "Information",
    ["Available to users"]: "Available to users",
    ["Author"]: "Author",
    ["Are you sure"]: "Are you sure you want to disable the bot",
    ["Main text about disable bot"]: `In the "maintenance" status, users will no longer be able to run the bot.`,
    // tuto
    ["Onboarding"]: "Onboarding",
    ["Registration"]: "Registration",
    ["Steps"]: "Steps",
    ["Create a bot from the template"]: "Create a bot from the template",
    ["Customize a design"]: "Customize a design",
    ["View Analytics"]: "View Analytics",
    ["Deploy a bot"]: "Deploy a bot",
    ["The tutorial completed"]: "The tutorial completed",
    ["Setups viewed"]: "Setups viewed",
    ["Registration completed"]: "Registration completed",
    ["Bot is created"]: "Bot is created",
    ["Onboarding completed"]: "Onboarding completed",
    ["Tutover1_1"]: "To complete the registration, please fill out this form. Thus you will help QuData improve the product and user experience more effectively.",
    ["Tutover2_1"]: "When you click a bot template, it opens in the editor, allowing you to explore its components in detail.",
    ["Tutover2_2"]: "You may click the Play button to start a chat with the bot and test it immediately.",
    ["Tutover2_3"]: "The Clone button creates a copy of the bot, and opens it in the bot editor for further modification.",
    ["Tutover2_4"]: "To begin with, try to chat with the bot templates. Choose a template you like and click Play to start a chat.",
    ["Tutover2_5"]: "Create your first bot based on a preconfigured template by clicking Clone. Then you can modify and customize the created bot, or choose another template to meet your needs.",
    ["Click anywhere"]: "Click anywhere to continue",
    ["Tutover3_1"]: "To customize the chat look and feel, run the bot to check the changes real-time.",
    ["Tutover3_2"]: "Change any settings of your bot to your liking. The new settings are applied immediately, right in the open chat window.",
    // Assistant Settings
    ["GPT Settings"]: "GPT Settings",
    // Assistant GPT Settings
    ["GPT Assistant Settings"]: "Assistant Settings",
    ["GPT System Message"]: "System Message",
    ["GPT System Placeholder"]: "You are the company's bot assistant. You answer user questions...",
    ["GPT System Hint"]: "The system message defines the identity and behavior of the bot. Describe in detail how you want the bot to behave, how it responds and who it appears to be.",
    ["GPT Prompt Message"]: "Prompt Message",
    ["GPT Prompt Placeholder"]: "Process the following user text: ...",
    ["GPT Prompt Hint"]: "A hint is a preface to each message that indicates how to respond to the user's text. As parvilo used in combination with a system message. For example, System Message: \"You are a helper bot that responds to reviews. Each review has the format Review: Review text.\" In this case, the tooltip may contain the \"Feedback:\" part, thereby specifying the input format for ChatGPT.",
    ["GPT Use model data"]: "Use only data from sources",
    ["GPT Use model data Hint"]: "The switch restricts the bot's knowledge base to only the data that is loaded as sources. It is important to remember that with a small number of sources, the bot may too often respond that it does not know anything.",
    ["GPT No doc answer"]: "Response text if no matching sources are found",
    ["GPT No doc answer Placeholder"]: "Sorry! Unfortunately, I don't know the answer to your question.",
    ["GPT No doc answer Hint"]: "If no answer was found in the sources, the paraphrased text of the \"I don't know\" field will be used.",

    ["GPT Use google"]: "Use Google to check and correct bot links",
    ["GPT Use google Hint"]: "If the bot gives the user a link, it will be checked before sending it and, if possible, it will be corrected",

    ["GPT Site name"]: "Use only links from the site:",
    ["GPT Site name Placeholder"]: "Example: qudata.com",
    ["GPT Site name Hint"]: "When checking the link, only data from the specified site will be used.",

    ["GPT No doc answer strong"]: "Restriction level in using sources.",
    ["GPT No doc answer strong Hint"]: "Bot knowledge restriction level. The larger the value, the stronger the restriction in the bot's response will be.",
    ["GPT Match threshold"]: "Threshold for selection of suitable sources.",
    ["GPT Match threshold Hint"]: "The source selection threshold is designed to filter out sources that do not match the user's question. The higher the threshold, the smaller the number and the more suitable sources will be selected. It is important to remember that if the threshold is too high, suitable sources may also be discarded due to a mismatch between the size of the source and the user's question.",
    ["GPT Documents count"]: "The maximum number of sources in the bot's response.",
    ["GPT Documents count Hint"]: "For more correct work and optimization of the bot, we do not use all the sources that you uploaded to the bot. The more sources are used, the more accurately the bot can answer the user's questions, but at the same time it will spend more tokens.",
    // Model GPT Settings
    ["GPT Chat History"]: "Chat history length",
    ["GPT Chat History Hint"]: "The number of messages that the assistant remembers.",
    ["GPT Model Settings"]: "GPT Model Settings",
    ["GPT Sources Settings"]: "GPT Sources Settings",
    ["GPT Model"]: "GPT Model",
    ["GPT Model Hint"]: "ID of the model to use.",
    ["gpt-3.5-turbo"]: "GPT 3.5 Turbo",
    ["gpt-3.5-turbo-0301"]: "GPT 3.5 Turbo-0301",
    ["gpt-3.5-turbo-0613"]: "GPT 3.5 Turbo-0613",
    ["gpt-3.5-turbo-16k"]: "GPT 3.5 Turbo 16k",
    ["gpt-3.5-turbo-16k-0613"]: "GPT 3.5 Turbo 16k-0613",
    ["gpt-4"]: "GPT 4 (beta, slower)",
    ["gpt-4-0613"]: "GPT 4-0613 (beta, slower)",
    ["gpt-4-32k"]: "GPT 4 32k (beta, slower)",
    ["gpt-4-32k-0613"]: "GPT 4 32k-0613 (beta, slower)",
    ["GPT Temperature"]: "Temperature",
    ["GPT Temperature Hint"]: "What sampling temperature to use, between 0 and 1. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic. \nWe generally recommend altering this or top_p but not both.",
    ["GPT Top p"]: "Choose tokens with more than confidence",
    ["GPT Top p Hint"]: "An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered. \nWe generally recommend altering this or temperature but not both.",
    ["GPT Max tokens"]: "Maximum number of tokens per request",
    ["GPT Max tokens Hint"]: "The maximum number of tokens to generate in the chat completion. \nThe total length of input tokens and generated tokens is limited by the model's context length.",
    ["GPT Presence penalty"]: "Presence penalty",
    ["GPT Presence penalty Hint"]: "Number between -2.0 and 2.0. Positive values penalize new tokens based on whether they appear in the text so far, increasing the model's likelihood to talk about new topics.",
    ["GPT Frequency penalty"]: "Frequency penalty",
    ["GPT Frequency penalty Hint"]: "Number between -2.0 and 2.0. Positive values penalize new tokens based on their existing frequency in the text so far, decreasing the model's likelihood to repeat the same line verbatim.",
    ["GPT Number generations"]: "Number of generations",
    ["GPT Number generations Hint"]: "How many chat completion choices to generate for each input message.",
    // Module ChatGPT
    ["ChatGPT.Step-1-1"]: "Register at #link# and create your account.",
    ["ChatGPT.Step-1-2"]: "Go to your profile menu and select \"View API keys\".",
    ["ChatGPT.Step-1-3"]: "Create a new key. Click on the \"Create new secret key\" button.",
    ["ChatGPT.Step-1-4"]: "Copy it and paste it into the field below.",
    ["ChatGPT.Step-1-4-1"]: "Copy it and paste it into the field above.",
    ["How to get the ChatGPT API key"]: "How to get the ChatGPT API key.",
    ["How will users use your assistant?"]: "How will users use your assistant?",
    ["Choose your assistant persona"]: "Choose your assistant persona.",
    ["Describe your assistant persona"]: "Describe your assistant persona.",
    ["Insert your ChatGPT key"]: "Insert your ChatGPT key.",
    ["Insert your ChatGPT key ..."]: "By entering your API key, you can use the ChatGPT feature for free. Without using the key, you will only have 30,000 free test tokens, after which you will need to buy a subscription.",
    ["This template has no parameter settings"]: "This template has no parameter settings.",
    ["This description will not ..."]: "(This description will not participate in the creation of the bot character.)",
    ["Create a new assistant with ..."]: "Create a new assistant with its own knowledge base and assistant. You will be able to modify these settings later.",
    ["Assistant description"]: "Assistant description",
    // Sources
    ["New Source"]: "New Source",
    ["Sources Library"]: "Sources Library",
    ["Source settings"]: "Source settings",
    ["Add Text source"]: "Add Text source",
    ["Add Link source"]: "Add Link source",
    ["Add sources from Site"]: "Add sources from Site",
    ["Add sources from File"]: "Add sources from File",
    ["Source tag"]: "Source tag",
    ["Source text"]: "Source text",
    ["Save source"]: "Save source",
    ["tokens have been used:"]: "Tokens have been used: ",
    ["N tokens have been used"]: "${1} tokens have been used.",
    ["N tokens will be used"]: "${1} tokens will be used",
    ["N tokens will be used and more"]: "The number of used tokens will be known after processing the source. Minimum will be used ${1} tokens.",
    ["Received"]: "Received",
    ["Success"]: "Success",
    ["Process"]: "Processing",
    ["Parsing"]: "Parsing",
    ["File"]: "File",
    ["Sitemap"]: "Sitemap",
    ["Link"]: "Link",
    ["Delete Source"]: "Delete Source",
    ["After deleting this source ..."]: "After deleting this source, you will no longer be able to use it.",
    ["The source was successfully deleted"]: "The source was successfully deleted",
    ["Source link"]: "Source link",
    ["Tag"]: "Tag",
    ["Type"]: "Type",
    ["Update At"]: "Update At",
    ["Actions"]: "Actions",
    ["Show"]: "Show",
    ["Page"]: "Page",
    ["of"]: "of",
    ["N"]: "N",

    // Templates
    ["URL"]: "Link",
    ["Company"]: "Company name",
    ["A general knowledge assistant for your company with multiple products, sources, teams, websites, documents, and more."]: "A general knowledge assistant for your company with multiple products, sources, teams, websites, documents, and more.",
    ["Expert on your content"]: "Expert on your content",
    ["An expert on your own data. Could be your business, a set of products, or internal knowledge for your team."]: "An expert on your own data. Could be your business, a set of products, or internal knowledge for your team.",

    ["Assistants"]: "Assistants",
    ["Assistants in various business areas."]: "Assistants in various business areas.",
    ["Manager"]: "Manager",
    ["Manager on your site."]: "Manager on your site.",

    ["Business plan Generator"]: "Business plan Generator",
    ["Business plan generator. Will create a comprehensive and organized plan including detailed market analysis, target audience, marketing strategies, financial forecasts and any other important aspects."]: "Business plan generator. Will create a comprehensive and organized plan including detailed market analysis, target audience, marketing strategies, financial forecasts and any other important aspects.",


    ["Product Manager"]: "Product Manager",
    ["Will write the product requirements document in the role of the primary product manager."]: "Will write the product requirements document in the role of the primary product manager.",

    ["IT Expert"]: "IT Expert",
    ["Helps to solve technical problems of users."]: "Helps to solve technical problems of users.",

    ["Code assistant"]: "Code assistant",
    ["Helps users write, debug, and optimize code for different programming languages ​​and platforms."]: "Helps users write, debug, and optimize code for different programming languages ​​and platforms.",

    ["Science Explainer"]: "Science Explainer",
    ["The bot explains scientific concepts, theories and phenomena in a fun and accessible way."]: "The bot explains scientific concepts, theories and phenomena in a fun and accessible way.",

    ["Marketing"]: "Marketing",
    ["Various bots useful in the field of marketing."]: "Various bots useful in the field of marketing.",

    ["PlanBot Marketer"]: "PlanBot Marketer",
    ["Create a marketing campaign plan"]: "Create a marketing campaign plan.",


    ["E-commerce SEO expert"]: "E-commerce SEO expert",
    ["Product descriptions for marketplaces. "]: "Product descriptions for marketplaces.",


    ["Bot Promo Planner"]: "Bot Promo Planner",
    ["The bot will help you think over an advertising campaign for your target audience."]: "The bot will help you think over an advertising campaign for your target audience.",


    // Sources Hints

    ["About Text Source"]: "A Text type source is a single source consisting of the text you enter.",

    ["About File Source"]: "A source of type File is a set of sources created from a file. The file must have a .csv extension and include a column called text. In addition, the file can have link columns - if the line describes the source Link or the question column if we are uploading a file of the FAQ type.",

    ["About Link Source"]: "A link type source is a single source that includes data from a single page of a site.",

    ["About Sitemap Source"]: "A sitemap source is a set of sources that will be taken from your site.",

    ["About Tag in Source"]: "You can specify a tag to identify the source.",

    ["Refresh source"]: "Reprocess the source",
    ["Edit source"]: "Edit Source",
    ["Remove source"]: "Delete source",

    ["Sources Success Hint"]: "Your source has been successfully processed and will be used in the chat bot.",
    ["Sources Received Hint"]: "Your source has been successfully uploaded to our server.",
    ["Sources Process Hint"]: "Your source is being processed and will soon be available for use in the chatbot.",
    ["Sources Parsing Hint"]: "Your source data is read from an external resource.",

    ["GPT Bot Card Info"]: "This is a ChatGPT bot",
    ["Show only GPT Bots"]: "Display only GPT bots",

    ["Bot type"]: "Bot type",
    ["All bots"]: "All bots",
    ["ChatGPT Bots"]: "ChatGPT Bots",
    ["Editor Bots"]: "Editor Bots",

    ["Sources create error"]: "Source creation error.",
    ["Source exists"]: "Source exists.",

    ["Get links"]: "Get links",
    ["Please, enter link"]: "Please, enter link",
    ["Please, choose the file"]: "Please, choose the file",
    ["Please click Get Links and select links."]: "Please click \"Get Links\" and select links.",
  },
};

export default en;
