
const INITIALIZE = "INITIALIZE";

const templatesState = {
  groups: [],
  templates: [],
  fields: [],
  isInitialized: false,
}

const TemplatesReducer = (state, action) => {
  const { groups } = action.payload || {};
  const templates = []
  const fields = []
  groups.forEach((group, gid) => {
    const { templates: i } = group
    group.value = gid
    i.forEach((template) => {
      const { fields: j } = template
      template.value = template.title.toLowerCase().replaceAll(' ', '_') + '_' + group.value;
      template.for = group.value
      j.forEach((field) => {
        field.for = template.value
        fields.push(field)
      })
      templates.push(template)
    })
  })

  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        groups,
        templates,
        fields,
        isInitialized: true,
      };
    default:
      return state;
  }
};

export {
  INITIALIZE,
  templatesState,
  TemplatesReducer,
};
