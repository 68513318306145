import React from "react";
import { Helmet } from "react-helmet-async";
import useBots from "../../../hooks/useBots";
import QDLoader from "../../../components/QDLoader";
import Editor from "../../../components/bots/Editor";
import useQubot from "../../../hooks/useQubot";
import { useTranslation } from "../../../hooks/useLocalization";

const EditorPage = () => {
  const { t } = useTranslation();
  const qubot = useQubot();
  const { isInitialized } = useBots();
  return (
    <React.Fragment>
      <Helmet title={t("Editor")} />
      {/* <Container fluid className="p-0">
        <Header /> */}
      {isInitialized && qubot ? <Editor /> : <QDLoader />}
      {/* </Container> */}
    </React.Fragment>
  );
};

export default EditorPage;
