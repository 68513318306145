import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BOT_CHANNEL, BOT_MODULE } from "../../../contexts/bots/BotsContext";
import { FEATURES } from "../../../contexts/billing/BillingContext";
import useBots from "../../../hooks/useBots";
import useMessage from "../../../hooks/useMessage";
import useBilling from "../../../hooks/useBilling";
import AntiSpam from "../../../utils/spam";
import { FacebookConfigure, FacebookGetHandlers } from "./bodies/Facebook";
import { TelegramConfigure, TelegramGetHandlers } from "./bodies/Telegram";
import { ViberConfigure, ViberGetHandlers } from "./bodies/Viber";
import { WebConfigure, WebGetHandlers } from "./bodies/Web";
import { WordpressConfigure, WordpressGetHandlers } from "./bodies/Wordpress";
import { WhatsappConfigure, WhatsappGetHandlers } from "./bodies/Whatsapp";
import { ChatGPTConfigure, ChatGPTGetHandlers } from "./bodies/ChatGPT";
import ChannelFacebookCard from "./cards/ChannelFacebookCard";
import ChannelTelegramCard from "./cards/ChannelTelegramCard";
import ChannelViberCard from "./cards/ChannelViberCard";
import ChannelWebCard from "./cards/ChannelWebCard";
import ChannelWordpressCard from "./cards/ChannelWordpressCard";
import ChannelWhatsappCard from "./cards/ChannelWhatsappCard";
import ChannelChatGPTCard from "./cards/ChannelChatGPTCard";
import ChannelPage from "./ChannelPage";
import ChannelDropboxCard from "./cards/ChannelDropboxCard";
import { DropboxConfigure, DropboxGetHandlers } from "./bodies/Dropbox";
import { ZendeskConfigure, ZendeskGetHandlers } from "./bodies/Zendesk";
import ChannelZendeskCard from "./cards/ChannelZendeskCard";
import { useTranslation } from "../../../hooks/useLocalization";
import ChannelGoogleDriveCard from "./cards/ChannelGoogleDriveCard";
import ChannelGoogleCalendarCard from "./cards/ChannelGoogleCalendarCard";
import ChannelGoogleSheetsCard from "./cards/ChannelGoogleSheetsCard";
import {
  GoogleDriveConfigure,
  GoogleDriveGetHandlers,
} from "./bodies/GoogleDrive";
import {
  GoogleCalendarConfigure,
  GoogleCalendarGetHandlers,
} from "./bodies/GoogleCalendar";
import {
  GoogleSheetsConfigure,
  GoogleSheetsGetHandlers,
} from "./bodies/GoogleSheets";
import { LiveChatConfigure, LiveChatGetHandlers } from "./bodies/LiveChat";
import ChannelLiveChatCard from "./cards/ChannelLiveChatCard";
import useLocationContext from "../../../hooks/useLocationContext";

const antiSpamFeatures = new AntiSpam();

const Channels = ({ channelName, setChannelName }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getSearchParam } = useLocationContext();
  const token_code = getSearchParam("code");
  const billing = useBilling();
  const { isProbilled, refreshFeatures, needUpgradePlan } = billing;
  const { showMessageWithDebug } = useMessage();
  const { activeBot, accessBotLevels, updateBot } = useBots();
  const { id, lang, channels = [], workflows = [], modules = [] } = activeBot || {};
  const [isEnabling, setEnabling] = useState();
  const [isReload, setReload] = useState();

  const workflowsList = useMemo(
    () => [
      BOT_CHANNEL.ZENDESK,
      BOT_CHANNEL.DROPBOX,
      BOT_CHANNEL.LIVE_CHAT,
      BOT_CHANNEL.GOOGLE_DRIVE,
      BOT_CHANNEL.GOOGLE_CALENDAR,
      BOT_CHANNEL.GOOGLE_SHEETS,
    ],
    []
  );

  const isWorkflow = useCallback(
    (channelName) => workflowsList.indexOf(channelName) > -1,
    [workflowsList]
  );

  const modulesList = useMemo(
    () => [
      BOT_MODULE.CHATGPT,
    ],
    []
  );

  const isModule = useCallback(
    (channelName) => modulesList.indexOf(channelName) > -1,
    [modulesList]
  );

  useEffect(() => {
    setReload(true);
    setTimeout(() => setReload(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (billing.isInitialized && !channelName) {
      antiSpamFeatures.action(async () => await refreshFeatures());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billing.isInitialized, isProbilled, channelName, id]);

  const getHandlers = useCallback(
    (id, levels, { channel, channelName, proPlan }) => {
      let list = {
        onSelect: () => setChannelName(channelName),
        onBack: () => setChannelName(null),
      };
      if (levels.manage && channel?.validated) {
        list = {
          ...list,
          onEnable: (isWorkflow(channelName) || isModule(channelName))
            ? null
            : proPlan
              ? () => navigate(`/account/pricing#${proPlan.index}`)
              : async (enabled) => {
                try {
                  setEnabling(true);
                  await updateBot(id, {
                    [isWorkflow(channelName) ? "workflows" : isModule(channelName) ? "modules" : "channels"]: [
                      { ...channel, enabled },
                    ],
                  });
                  await refreshFeatures();
                } catch (error) {
                  const { message = t("Something went wrong"), debug } = error;
                  showMessageWithDebug({ error: message, debug });
                } finally {
                  setEnabling(false);
                }
              },
        };
      }
      return list;
    },
    [
      navigate,
      setChannelName,
      showMessageWithDebug,
      updateBot,
      refreshFeatures,
      setEnabling,
      t,
      isWorkflow,
      isModule,
    ]
  );

  const props = useMemo(() => ({ id, lang }), [id, lang]);
  const levels = useMemo(
    () => accessBotLevels(activeBot),
    [accessBotLevels, activeBot]
  );

  const getProps = useCallback(
    (channelName) => {
      const buildProps = (channelName, channelHandlers) => {
        const channel =
          (isWorkflow(channelName) ? workflows : (isModule(channelName) ? modules : channels)).find(
            ({ name }) => name === channelName
          ) || {};
        // const validated = channel?.validated;
        const loading = !isProbilled;
        const enabling = isEnabling;
        const proPlan = needUpgradePlan(FEATURES.MESSENGERS, { channelName });
        return {
          loading,
          enabling,
          proPlan,
          channel,
          handlers: channelHandlers({
            ...props,
            ...channel,
            handlers: getHandlers(id, levels, {
              channel,
              channelName,
              proPlan,
            }),
          }),
        };
      };
      return (
        // ---------------
        //       web
        // ---------------
        (channelName === BOT_CHANNEL.WEB && {
          Card: ChannelWebCard,
          Page: WebConfigure,
          ...buildProps(channelName, WebGetHandlers),
        }) ||
        // ---------------
        //    telegram
        // ---------------
        (channelName === BOT_CHANNEL.TELEGRAM && {
          Card: ChannelTelegramCard,
          Page: TelegramConfigure,
          ...buildProps(channelName, TelegramGetHandlers),
        }) ||
        // ---------------
        //    viber
        // ---------------
        (channelName === BOT_CHANNEL.VIBER && {
          Card: ChannelViberCard,
          Page: ViberConfigure,
          ...buildProps(channelName, ViberGetHandlers),
        }) ||
        // ---------------
        //    zendesk
        // ---------------
        (channelName === BOT_CHANNEL.ZENDESK && {
          Card: ChannelZendeskCard,
          Page: ZendeskConfigure,
          ...buildProps(channelName, ZendeskGetHandlers),
        }) ||
        // ---------------
        //    google drive
        // ---------------
        (channelName === BOT_CHANNEL.GOOGLE_DRIVE && {
          Card: ChannelGoogleDriveCard,
          Page: GoogleDriveConfigure,
          ...buildProps(channelName, GoogleDriveGetHandlers),
        }) ||
        // ---------------
        //    google calendar
        // ---------------
        (channelName === BOT_CHANNEL.GOOGLE_CALENDAR && {
          Card: ChannelGoogleCalendarCard,
          Page: GoogleCalendarConfigure,
          ...buildProps(channelName, GoogleCalendarGetHandlers),
        }) ||
        // ---------------
        //    google sheets
        // ---------------
        (channelName === BOT_CHANNEL.GOOGLE_SHEETS && {
          Card: ChannelGoogleSheetsCard,
          Page: GoogleSheetsConfigure,
          ...buildProps(channelName, GoogleSheetsGetHandlers),
        }) ||
        // ---------------
        //    live chat
        // ---------------
        (channelName === BOT_CHANNEL.LIVE_CHAT && {
          Card: ChannelLiveChatCard,
          Page: LiveChatConfigure,
          ...buildProps(channelName, LiveChatGetHandlers),
        }) ||
        // ---------------
        //    facebook
        // ---------------
        (channelName === BOT_CHANNEL.FACEBOOK && {
          Card: ChannelFacebookCard,
          Page: FacebookConfigure,
          ...buildProps(channelName, FacebookGetHandlers),
        }) ||
        // ---------------
        //    dropbox
        // ---------------
        (channelName === BOT_CHANNEL.DROPBOX && {
          Card: ChannelDropboxCard,
          Page: DropboxConfigure,
          ...buildProps(channelName, DropboxGetHandlers),
        }) ||
        // ---------------
        //    wordpress
        // ---------------
        (channelName === BOT_CHANNEL.WORDPRESS && {
          Card: ChannelWordpressCard,
          Page: WordpressConfigure,
          ...buildProps(channelName, WordpressGetHandlers),
        }) ||
        // ---------------
        //    whatsapp
        // ---------------
        (channelName === BOT_CHANNEL.WHATSAPP && {
          Card: ChannelWhatsappCard,
          Page: WhatsappConfigure,
          ...buildProps(channelName, WhatsappGetHandlers),
        }) ||
        // ---------------
        //    chat_gpt
        // ---------------
        (channelName === BOT_MODULE.CHATGPT && {
          Card: ChannelChatGPTCard,
          Page: ChatGPTConfigure,
          ...buildProps(channelName, ChatGPTGetHandlers),
        }) ||
        {}
      );
    },
    [
      getHandlers,
      channels,
      workflows,
      id,
      levels,
      props,
      isProbilled,
      needUpgradePlan,
      isEnabling,
      isWorkflow,
      modules,
      isModule,
    ]
  );

  const renderCard = useCallback(
    (channelName) => {
      const { Card } = getProps(channelName);
      return Card ? (
        <Col className="d-flex flex-grow-auto">
          <Card
            {...props}
            {...getProps(channelName)}
            channelName={channelName}
          />
        </Col>
      ) : null;
    },
    [getProps, props]
  );

  const renderPage = useCallback(
    (channelName) => {
      const { Page, channel, handlers } = getProps(channelName);
      return Page && !isReload ? (
        <ChannelPage
          props={props}
          {...getProps(channelName)}
          setChannelName={setChannelName}
        >
          <Page
            channel={channel}
            levels={levels}
            bot={activeBot}
            handlers={handlers}
          />
        </ChannelPage>
      ) : null;
    },
    [getProps, activeBot, levels, props, setChannelName, isReload]
  );

  if (!!channelName) {
    return renderPage(channelName);
  }

  if (token_code) {
    setChannelName(BOT_CHANNEL.LIVE_CHAT); // NOTE: принудительно прыгаем в Livechat, так как он при логине бъет redirect (#)
  }

  const isWPBot = channels.find((item) => item.name === BOT_CHANNEL.WORDPRESS)

  return (
    <Container fluid className="p-0">
      <Row>
        <h3 className="mb-3">{t("Channels")}</h3>
        {renderCard(BOT_CHANNEL.WEB)}
        {renderCard(BOT_CHANNEL.TELEGRAM)}
        {renderCard(BOT_CHANNEL.FACEBOOK)}
        {renderCard(BOT_CHANNEL.VIBER)}
        {isWPBot && renderCard(BOT_CHANNEL.WORDPRESS)}
        {renderCard(BOT_CHANNEL.WHATSAPP)}
        <h3 className="mb-3 mt-3">{t("Workflow")}</h3>
        {renderCard(BOT_CHANNEL.ZENDESK)}
        {renderCard(BOT_CHANNEL.DROPBOX)}
        {renderCard(BOT_CHANNEL.GOOGLE_DRIVE)}
        {renderCard(BOT_CHANNEL.GOOGLE_CALENDAR)}
        {renderCard(BOT_CHANNEL.GOOGLE_SHEETS)}
        {renderCard(BOT_CHANNEL.LIVE_CHAT)}
        <h3 className="mb-3 mt-3">{t("Modules")}</h3>
        {renderCard(BOT_MODULE.CHATGPT)}
      </Row>
    </Container>
  );
};

export default Channels;
