import usFlag from "../assets/img/flags/us.png";
import uaFlag from "../assets/img/flags/ua.png";

export const Case = {
  Genitive: "|Genitive",
  Adjective: "|Adjective",
  Instrumental: "|Instrumental",
};

export const Names = {
  en: "English",
  uk: "Ukrainian - Українська",
};

export const Languages = {
  en: {
    icon: usFlag,
    name: Names.en,
  },
  uk: {
    icon: uaFlag,
    name: Names.uk,
  },
};
