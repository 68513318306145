import logger from "../../utils/logger";

class Mocks {
  static enabled = false;

  static subscriptions(subscriptions) {
    if (Mocks.enabled && subscriptions) {
      logger.warn("[Billing-mocks] subscriptions");
      subscriptions.forEach((s) => {
        const products = (
          s.products?.map(({ productID }) => productID) ||
          s.productsIDs ||
          []
        ).map((productID) => ({
          productID,
          price: {
            value: 49,
            currency: "USD",
          },
        }));
        s.products = products;
        delete s.productsIDs;
        s.time = Date.now();
      });
    }
    return subscriptions;
  }

  static payments(payments) {
    if (Mocks.enabled && payments) {
      logger.warn("[Billing-mocks] payments");
      payments.push.apply(payments, [
        {
          time: Date.now(),
          endTime: Date.now(),
          status: "pending",
          products: [
            {
              productID: "product.start-up",
              price: {
                value: 100,
                currency: "USD",
              },
            },
          ],
        },
      ]);
    }
    return payments;
  }

  static coupons(coupons) {
    if (Mocks.enabled && coupons) {
      logger.warn("[Billing-mocks] coupons");
      coupons.push.apply(coupons, [
        {
          productsIDs: ["product.premium"],
          price: {
            value: 19,
            currency: "USD",
          },
          billing: {
            fastspring: {
              code: "AG4_HB5-GL5BD46H_HLB-4F",
            },
          },
        },
      ]);
    }
  }
}

export default Mocks;
