/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from "react";
import useSidebar from "../../../hooks/useSidebar";
import IconBot from "../../../components/bots/cards/BotIcon";
import { isMobile } from "../../../utils/screen";
import { scrollTo } from "../../../utils/scroll";
import CustomRouterLink from "./CustomRouterLink";
import DropdownListBots from "../../DropdownListBots";

import useAnalytics from "../../../hooks/useAnalytics";

const NavListItemHead = ({ bot, title, href, depth = 0, icon: Icon }) => {
  const { isOpen, setIsOpen } = useSidebar();
  const { kind, iconSrc } = bot || {};
  const [open, setOpen] = useState(false);
  const { CONSOLE_EVENTS } = useAnalytics();

  const handleToggle = useCallback(
    (e) => {
      // e.preventDefault();
      setOpen(!open);
    },
    [setOpen, open]
  );
  return (
    <li className="sidebar-item head active" onClick={() => scrollTo(bot?.id)}>
      <div className="sidebar-link head top d-flex align-items-center pe-0">
        <CustomRouterLink
          depth={depth}
          to={href}
          activeclassname="active"
          className="sidebar-link d-flex flex-1 align-items-center"
          onClick={async () => {
            if (isMobile()) {
              setIsOpen(!isOpen);
            }
            await CONSOLE_EVENTS.EV_Locate.send({ params: { onmobile: isMobile(), href: href } });
          }}
        >
          {Icon && <Icon className="feather align-middle" />}{" "}
          <span
            className="align-middle d-inline-block text-truncate"
            style={{ maxWidth: "150px" }}
            depth={depth}
          >
            {title}
          </span>
        </CustomRouterLink>
        {bot && (
          <DropdownListBots
            className="me-2"
            btnDropdown={
              <div
                className={`d-flex align-items-center
                  ${open ? "" : "collapsed"}`}
                data-bs-toggle="collapse"
              >
                <IconBot
                  kind={kind}
                  src={iconSrc}
                  style={{ marginRight: "0.438rem" }}
                  className="align-middle img-fluid me-2"
                  size="1.875rem"
                />
              </div>
            }
            handleToggle={handleToggle}
          />
        )}
      </div>
    </li>
  );
};

export default NavListItemHead;
