import { useState } from "react";
import telegram from "../../../../assets/img/brands/telegram.svg";
import screen_1 from "../../../../assets/img/screenshots/channels/screen_telegram_1.png";
import screen_2 from "../../../../assets/img/screenshots/channels/screen_telegram_2.png";
import screen_3 from "../../../../assets/img/screenshots/channels/screen_telegram_3.png";
import { BOT_CHANNEL } from "../../../../contexts/bots/BotsContext";
import useBots from "../../../../hooks/useBots";
import { useTranslation } from "../../../../hooks/useLocalization";
import useMessage from "../../../../hooks/useMessage";
import { Accordion } from "../../../accordion/Accordion";
import ZoomImage from "../../../ZoomImage";
import ZoomVideo from "../../../ZoomVideo";
import ChannelInput from "./submit/ChannelInput";
import ChannelInputState from "./submit/ChannelInputState";
import ChannelState from "./submit/ChannelState";

const TelegramIcon = ({ className }) => (
  <img
    src={telegram || ""}
    alt=""
    width={20}
    height={20}
    className={className}
  />
);

const TelegramLogin = () => {
  const { tt } = useTranslation();
  return (
    <>
      <a
        href="https://web.telegram.org"
        alt="telegram"
        target="_blank"
        rel="noreferrer"
      >
        https://web.telegram.org
      </a>
      <ZoomImage>
        <img alt="screen_1" src={screen_1} className="m-3 ms-0 mw-100" />
      </ZoomImage>
      {tt(
        "Telegram.Step-1-1",
        <a
          href="https://web.telegram.org"
          alt="telegram"
          target="_blank"
          rel="noreferrer"
        >
          https://web.telegram.org
        </a>
      )}
    </>
  );
};

const TelegramBotFather = () => {
  return (
    <>
      <ZoomImage>
        <img alt="screen_2" src={screen_2} className="m-3 ms-0 mw-100" />
      </ZoomImage>
    </>
  );
};

const TelegramCreateBot = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      <ul>
        <li>{tt("Telegram.Step-3-1", <b>/newbot</b>)}</li>
        <li>{tt("Telegram.Step-3-2", <b>bot</b>)}</li>
        <li>
          {t("Telegram.Step-3-3")}
          <ZoomImage>
            <img alt="screen_3" src={screen_3} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
      </ul>
    </>
  );
};

const TelegramAPIToken = ({ id, channel, levels }) => {
  // limits
  // Telegram API Token (44-46)
  const { t } = useTranslation();
  const { updateBot } = useBots();
  const [isSubmitting, setSubmitting] = useState();
  const { /*showMessage, */ showMessageWithDebug } = useMessage();
  const onSubmit = async (value) => {
    try {
      setSubmitting(true);
      await updateBot(id, {
        channels: [{ ...channel, name: BOT_CHANNEL.TELEGRAM, token: value }],
      });
      // showMessage({ save: "" });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setSubmitting(false);
    }
  };
  const onChange = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit(value);
    }
  };
  return (
    <>
      <ChannelInput
        strict
        title={t("Telegram API Token")}
        field="token"
        channel={channel}
        disabled={!levels.manage}
        isSubmitting={isSubmitting}
        onChange={onChange}
      />
      <ChannelInputState
        fields={["token"]}
        channel={channel}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

const TelegramConfigure = ({ channel, levels, bot, handlers }) => {
  const { t, tt } = useTranslation();
  const { id } = bot || {};
  return (
    <>
      <ZoomVideo>
        <iframe width="50%" height="415" src="https://www.youtube.com/embed/aM8Ny2V6HK0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </ZoomVideo>
      <Accordion defaultActiveKey="0" className="accordion-steps no-animation">
        <Accordion.Item eventKey="0" className="bg-white">
          <Accordion.Header>{tt("Step N", 1)}</Accordion.Header>
          <Accordion.Body>
            <p>{t("Telegram.Step-1")}</p>
            <TelegramLogin />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{tt("Step N", 2)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{tt("Telegram.Step-2", <b>/start</b>)}</p>
            <TelegramBotFather />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{tt("Step N", 3)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Telegram.Step-3")}</p>
            <TelegramCreateBot />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{tt("Step N", 4)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Telegram.Step-4")}</p>
            <TelegramAPIToken id={id} channel={channel} levels={levels} />
            <ChannelState channel={channel} handlers={handlers} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const TelegramGetHandlers = ({ url, validated, handlers }) => {
  if (validated && url) {
    handlers = {
      ...handlers,
      onLink: () => {
        window.open(url, "_blank");
      },
    };
  }
  return handlers;
};

export { TelegramIcon, TelegramConfigure, TelegramGetHandlers };
