import { useEffect, useRef, useState } from "react";
import useBots from "../../hooks/useBots";
import useQubot from "../../hooks/useQubot";
import Bots from "../../modals/dashboards/Bots";
import CreateBot from "../../modals/bots/CreateBot";
import CloneBot from "../../modals/bots/CloneBot";
import DeleteBot from "../../modals/bots/DeleteBot";
import RenameBot from "../../modals/bots/RenameBot";
import Thread from "../../utils/thread";
import Templates from "../../modals/bots/Templates";
import { BOT_FILTER } from "../../pages/dashboards/Bots/utils";
import Wordpress from "../../modals/Wordpress";
import { Settings } from "react-feather";
import { useTranslation } from "../../hooks/useLocalization";
import { isMobile } from "../../utils/screen";
import DesktopOnly from "../DesktopOnly";

const MainThread = new Thread(100);

const Editor = () => {
  const { t } = useTranslation();
  const { activeBot, setBotActive, qubotType } = useBots();
  const { id: botID, name, description } = activeBot || {};
  const type = qubotType(activeBot);
  const qubot = useQubot();
  const sourceRef = useRef();
  const [isSupported, setSupported] = useState(!isMobile());
  const [isLoading, setLoading] = useState();
  const [showMyBots, setShowMyBots] = useState();
  const [showTemplates, setShowTemplates] = useState();
  const [showExamples, setShowExamples] = useState();
  const [showCreate, setShowCreate] = useState();
  const [showClone, setShowClone] = useState();
  const [showCloneBot, setShowCloneBot] = useState();
  const [showDelete, setShowDelete] = useState();
  const [showRename, setShowRename] = useState();
  const [showWordpress, setShowWordpress] = useState();

  const hideModals = () => {
    setShowMyBots(false);
    setShowTemplates(false);
    setShowExamples(false);
    setShowCreate(false);
    setShowDelete(false);
    setShowRename(false);
    setShowWordpress(false);
  };

  useEffect(() => {
    if (isSupported) {
      qubot?.showEditor({ botID, sourceElement: sourceRef?.current, type });
    } else {
      qubot?.hideEditor();
    }
  }, [isSupported, qubot, botID, type, name]);

  useEffect(
    () =>
      qubot?.ui({
        MyBots: () => setShowMyBots(true),
        Templates: () => setShowTemplates(true),
        Examples: () => setShowExamples(true),
        CloneBot: () => {
          setShowCloneBot({ id: botID, name, description });
          setShowClone(true);
        },
        CreateBot: () => setShowCreate(true),
        DeleteBot: () => setShowDelete(true),
        RenameBot: () => setShowRename(true),
      }),
    [
      qubot,
      botID,
      name,
      description,
      setShowMyBots,
      setShowTemplates,
      setShowExamples,
      setShowClone,
      setShowCloneBot,
      setShowCreate,
      setShowDelete,
      setShowRename,
    ]
  );

  useEffect(() => {
    MainThread.handler = qubot
      ? async () => {
          qubot?.resize();
          setLoading(!qubot?.isEditorShowed());
          setSupported(!isMobile());
        }
      : null;
    return () => {
      MainThread.handler = null;
      qubot?.hideEditor();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClone = (bot) => {
    hideModals();
    setShowCloneBot(bot);
    setShowClone(true);
    return true;
  };

  const onCreate = () => {
    hideModals();
    setShowCreate(true);
    return true;
  };

  const onWordpress = () => {
    hideModals();
    setShowWordpress(true);
    return true;
  };

  return (
    <div
      ref={sourceRef}
      className={`w-100 ${isSupported ? "vh-100" : ""}`}
      style={{ height: !isSupported && "calc(100vh - 4rem)" }}
    >
      {isSupported ? (
        isLoading && (
          <div className="context-icon">
            <Settings className="fa-spin text-primary" size={64} />
          </div>
        )
      ) : (
        <DesktopOnly />
      )}
      <Bots
        open={showMyBots}
        setOpen={(value) => {
          setShowMyBots(value);
          qubot?.validate();
        }}
        handlers={{ onSelect: hideModals, onClone, onCreate, onWordpress }}
      />
      <Templates
        open={showTemplates}
        setOpen={(value) => {
          setShowTemplates(value);
          qubot?.validate();
        }}
        handlers={{ onSelect: hideModals, onClone, onCreate }}
      />
      <Templates
        title={t("Examples")}
        filter={BOT_FILTER.EXAMPLE}
        open={showExamples}
        setOpen={(value) => {
          setShowExamples(value);
          qubot?.validate();
        }}
        handlers={{ onSelect: hideModals, onClone, onCreate }}
      />
      <CloneBot
        bot={showCloneBot}
        open={showClone}
        setOpen={setShowClone}
        onComplete={({ id }) => setBotActive(id)}
      />
      <CreateBot
        open={showCreate}
        setOpen={setShowCreate}
        onComplete={({ id }) => setBotActive(id)}
      />
      <RenameBot
        bot={{ id: botID, name, description }}
        open={showRename}
        setOpen={setShowRename}
      />
      <DeleteBot
        bot={{ id: botID }}
        open={showDelete}
        setOpen={setShowDelete}
      />
      <Wordpress
        open={showWordpress}
        setOpen={(value) => setShowWordpress(value)}
      />
    </div>
  );
};

export default Editor;
