import { Alert } from "react-bootstrap";
import { useTranslation } from "../../hooks/useLocalization";
import { atDevhost } from "../../system/system";

const RefreshAlert = ({ title, error, onRetry }) => {
  const { t, tt } = useTranslation();
  return (
    <Alert variant="danger">
      <div className="alert-message">
        {tt(
          "Refresh failed",
          <b>{title}</b>,
          <a
            target="_self"
            rel="noreferrer"
            href="/dashboard/bots"
            onClick={(e) => {
              e.preventDefault();
              onRetry();
            }}
          >
            {t("Try Again")}
          </a>
        )}
        {atDevhost() && (
          <>
            <br />
            <small>{error.message}</small>
          </>
        )}
      </div>
    </Alert>
  );
};

export default RefreshAlert;
