import { useState } from "react";
import { Badge, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Lock, Unlock } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../hooks/useLocalization";
import useLocationContext from "../../hooks/useLocationContext";

const ProLock = ({ proPlan, className, style, selected, type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setRedirect } = useLocationContext();
  const [hover, setHover] = useState(false);
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>{t("Upgrade your subscription")}</Tooltip>}
    >
      <div
        style={style}
        className={`pro-lock cursor-pointer ${
          selected || hover ? "pro-lock-selected" : ""
        } ${className}`}
        onMouseEnter={() => setHover(true)}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseOut={() => setHover(false)}
        onClick={(event) => {
          event.stopPropagation();
          setRedirect("billing");
          navigate(`/account/pricing#${proPlan.index}`);
        }}
      >
        {type === "switch" ? (
          <Form.Check type="switch" id="enabled" className="ps-0 pe-none" />
        ) : (
          <Badge bg="danger">
            {selected || hover ? (
              <Unlock className="me-1" size={14} />
            ) : (
              <Lock className="me-1" size={14} />
            )}
            {t(proPlan?.title) || t("Pro")}
          </Badge>
        )}
      </div>
    </OverlayTrigger>
  );
};

export default ProLock;
