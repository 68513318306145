import { Formik } from "formik";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import useBots from "../../hooks/useBots";
import useClipboard from "../../hooks/useClipboard";
import { useState } from "react";
import DeleteBotModal from "../../modals/bots/DeleteBot";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../hooks/useLocalization";

import useAnalytics from "../../hooks/useAnalytics";

const DeleteBot = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { CONSOLE_EVENTS } = useAnalytics();
  const { onCopyEvent } = useClipboard("Bot bundle");
  const { activeBot, generateBundle } = useBots();
  const [showModal, setShowModal] = useState();
  return (
    <>
      <DeleteBotModal bot={{ id: activeBot?.id }} open={showModal} setOpen={setShowModal} onComplete={() => navigate("/dashboard")} />
      <Card>
        <Card.Header>
          <Card.Title tag="h5" className="mb-0">
            {t("General info")}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Formik
            enableReinitialize
            initialValues={{
              name: activeBot?.name || "",
              lang: activeBot?.lang || "en",
              description: activeBot?.description || "",
            }}
            onSubmit={async () => {
              setShowModal(true);
            }}
          >
            {({ handleSubmit, values }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xl md="6">
                    <Form.Group className="mb-3">
                      <FloatingLabel label={`${t("Bot name")} *`}>
                        <Form.Control
                          disabled
                          id="name"
                          type="text"
                          name="name"
                          placeholder={`${t("Bot name")} *`}
                          value={values.name}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <FloatingLabel label={t("Bot bundle")}>
                        <Form.Control
                          readOnly
                          id="bundle"
                          type="text"
                          name="bundle"
                          placeholder={t("Bot bundle")}
                          className="cursor-copy"
                          value={generateBundle(values.name) || ""}
                          onClick={onCopyEvent}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <FloatingLabel label={t("Description")}>
                    <Form.Control
                      disabled
                      as="textarea"
                      id="description"
                      type="text"
                      name="description"
                      placeholder={t("Description")}
                      value={values.description}
                    />
                  </FloatingLabel>
                </Form.Group>

                <div className="text-center mt-3">
                  <Button type="submit" variant="danger"
                    onClick={async () => await CONSOLE_EVENTS.EV_DeleteBot.send({ params: { bid: activeBot?.id } })} >
                    {t("Delete Bot")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
};

export default DeleteBot;
