import React, { useState, useEffect } from "react";
import noIcon from "../../../assets/img/bots/no-icon.png";

function BotIcon({ src, size, style = {}, className = "" }) {
  const [iconSrc, setIconSrc] = useState(src);
  useEffect(() => {
    if (iconSrc !== src) {
      setIconSrc(src);
    }
  }, [src, iconSrc, setIconSrc]);
  return (
    <img
      src={iconSrc || noIcon}
      className={className}
      style={{ ...style, width: size || 18, height: size || 18 }}
      alt={""}
    />
  );
}

export default BotIcon;
