import { Button, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import { Copy } from "react-feather";
import google from "../../../../assets/img/brands/google-calendar.png";
import screen_1 from "../../../../assets/img/screenshots/channels/screen_google_calendar_00.png";
import screen_2 from "../../../../assets/img/screenshots/channels/screen_google_calendar_01.png";
import screen_3 from "../../../../assets/img/screenshots/channels/screen_google_calendar_02.png";
import screen_4 from "../../../../assets/img/screenshots/channels/screen_google_calendar_03.png";
import screen_5 from "../../../../assets/img/screenshots/channels/screen_google_calendar_04.png";
import screen_6 from "../../../../assets/img/screenshots/channels/screen_google_calendar_05.png";
import screen_7 from "../../../../assets/img/screenshots/channels/screen_google_calendar_06.png";
import screen_8 from "../../../../assets/img/screenshots/channels/screen_google_calendar_07.png";
import screen_9 from "../../../../assets/img/screenshots/channels/screen_google_calendar_08.png";
import screen_10 from "../../../../assets/img/screenshots/channels/screen_google_calendar_09.png";
import screen_11 from "../../../../assets/img/screenshots/channels/screen_google_calendar_10.png";
import screen_12 from "../../../../assets/img/screenshots/channels/screen_google_calendar_11.png";
import useClipboard from "../../../../hooks/useClipboard";
import { useTranslation } from "../../../../hooks/useLocalization";
import { Case } from "../../../../languages/languages";
import { Accordion } from "../../../accordion/Accordion";
import ZoomImage from "../../../ZoomImage";
import ChannelLinks from "./submit/ChannelLinks";

const GoogleCalendarIcon = ({ className }) => (
  <img
    src={google || ""}
    alt=""
    width={20}
    height={20}
    className={className}
    style={{ transform: "scale(0.9)" }}
  />
);

const GoogleCalendarLogin = () => {
  const { tt } = useTranslation();
  return (
    <>
      <ZoomImage>
        <img alt="screen_1" src={screen_1} className="m-3 ms-0 mw-100" />
      </ZoomImage>
      {tt(
        "GoogleCalendar.Step-1-1",
        <a
          href="https://accounts.google.com"
          alt="google"
          target="_blank"
          rel="noreferrer"
        >
          https://accounts.google.com
        </a>
      )}
    </>
  );
};

const GoogleCalendarOpenApp = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      {tt(
        "GoogleCalendar.Step-2-1",
        <a
          href="https://www.google.com/"
          alt="google"
          target="_blank"
          rel="noreferrer"
        >
          {t("Google homepage")}
        </a>
      )}
      <Row>
        <ZoomImage>
          <img alt="screen_2" src={screen_2} className="m-3 ms-0 mw-100" />
        </ZoomImage>
        <ZoomImage>
          <img alt="screen_3" src={screen_3} className="m-3 ms-0 mw-100" />
        </ZoomImage>
      </Row>
    </>
  );
};

const GoogleCalendarShare = () => {
  const { t, tt } = useTranslation();
  const { onCopyEvent } = useClipboard();
  const account = "qudata-bot-service@qudata-services.iam.gserviceaccount.com";
  return (
    <>
      <ul>
        <li>{t("GoogleCalendar.Step-3-1")}</li>
        <Row>
          <ZoomImage>
            <img alt="screen_4" src={screen_4} className="m-3 ms-0 mw-100" />
          </ZoomImage>
          <ZoomImage>
            <img alt="screen_5" src={screen_5} className="m-3 ms-0 mw-100" />
          </ZoomImage>
          <ZoomImage>
            <img alt="screen_6" src={screen_6} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </Row>
        <li>
          {tt("GoogleCalendar.Step-3-2", <b>{t("Settings and sharing")}</b>)}
          <Row>
            <ZoomImage>
              <img alt="screen_7" src={screen_7} className="m-3 ms-0 mw-100" />
            </ZoomImage>
            <ZoomImage>
              <img alt="screen_8" src={screen_8} className="m-3 ms-0 mw-100" />
            </ZoomImage>
          </Row>
        </li>
        <li>
          <p>
            {tt(
              "GoogleCalendar.Step-3-3",
              <b>{t("Share with specific people")}</b>,
              <b>{t("Add people")}</b>
            )}
          </p>
          <InputGroup>
            <FloatingLabel label={t("Account")} className="flex-grow-1">
              <Form.Control
                className="mb-3"
                type="text"
                rows="4"
                value={account || ""}
                readOnly
              />
            </FloatingLabel>
            <Button
              type="submit"
              variant="primary"
              onClick={(e) => onCopyEvent(account)}
            >
              <Copy className="feather me-1" />
              {t("Copy")}
            </Button>
          </InputGroup>
          <Row>
            <ZoomImage>
              <img alt="screen_9" src={screen_9} className="m-3 ms-0 mw-100" />
            </ZoomImage>
            <ZoomImage>
              <img
                alt="screen_10"
                src={screen_10}
                className="m-3 ms-0 mw-100"
              />
            </ZoomImage>
            <ZoomImage>
              <img
                alt="screen_11"
                src={screen_11}
                className="m-3 ms-0 mw-100"
              />
            </ZoomImage>
          </Row>
        </li>
        {tt(
          "GoogleCalendar.Step-3-4",
          <b>{t("See all event details")}</b>,
          <b>{t("Make changes and manage sharing")}</b>,
          <a
            href="https://support.google.com/calendar/answer/37082"
            alt="google"
            target="_blank"
            rel="noreferrer"
          >
            {t("here")}
          </a>
        )}
      </ul>
    </>
  );
};

const GoogleCalendarVerify = ({ id, channel, levels }) => {
  const { t } = useTranslation();
  const { links } = channel || {};
  const someErrors = links?.some(({ error }) => !!error);
  return (
    <>
      <ZoomImage>
        <img alt="screen_12" src={screen_12} className="m-3 ms-0 mw-100" />
      </ZoomImage>
      {t("GoogleCalendar.Step-4-1")}
      <div className="mt-4">
        <ChannelLinks
          links={links}
          emptyMessage={t("there are no calendars used in a bot")}
        />
        {someErrors && (
          <p className="lead text-danger">{t("GoogleCalendar.Step-4-2")}</p>
        )}
      </div>
    </>
  );
};

const GoogleCalendarConfigure = ({ channel, levels, bot, handlers }) => {
  const { t, tc, tt } = useTranslation();
  const { id } = bot || {};
  return (
    <>
      <p>{tt("GoogleCalendar.Header-1")}</p>
      <p>{tt("GoogleCalendar.Header-2")}</p>
      <Accordion defaultActiveKey="0" className="accordion-steps no-animation">
        <Accordion.Item eventKey="0" className="bg-white">
          <Accordion.Header>{tt("Step N", 1)}</Accordion.Header>
          <Accordion.Body>
            <p>
              {tt(
                "GoogleCalendar.Step-1",
                <a
                  href="https://accounts.google.com"
                  alt="google"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://accounts.google.com
                </a>
              )}
            </p>
            <GoogleCalendarLogin />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{tt("Step N", 2)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>
              {tt(
                "GoogleCalendar.Step-2",
                <b>{t("Google Calendar")}</b>,
                <a
                  href="https://calendar.google.com"
                  alt="google"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://calendar.google.com
                </a>
              )}
            </p>
            <GoogleCalendarOpenApp />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{tt("Step N", 3)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>
              {tt(
                "GoogleCalendar.Step-3",
                <b>{tc("Google Calendar", Case.Genitive)}</b>
              )}
            </p>
            <GoogleCalendarShare />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{tt("Step N", 4)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            {tt(
              "GoogleCalendar.Step-4",
              <b>{t("Access permissions for events")}</b>,
              <b>{t("Get shareable link")}</b>
            )}
            <GoogleCalendarVerify id={id} channel={channel} levels={levels} />
            {/* <ChannelState channel={channel} handlers={handlers} /> */}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const GoogleCalendarGetHandlers = ({ url, validated, handlers }) => {
  if (validated && url) {
    handlers = {
      ...handlers,
      onLink: () => {
        window.open(url, "_blank");
      },
    };
  }
  return handlers;
};

export {
  GoogleCalendarIcon,
  GoogleCalendarConfigure,
  GoogleCalendarGetHandlers,
};
