import { React, useMemo, useState } from "react";

import { Nav, Tab } from "react-bootstrap";
import { SOURCE_TYPES } from "../../../contexts/sources/SourcesContext";
import { useTranslation } from "../../../hooks/useLocalization";
import useMessage from "../../../hooks/useMessage";
import useSources from "../../../hooks/useSources";
import useBots from "../../../hooks/useBots";
import useAnalytics from "../../../hooks/useAnalytics";

import {
    X,
    HelpCircle
} from "react-feather";

import {
    Button,
    FloatingLabel,
    Form,
    Popover,
    OverlayTrigger,
    Spinner,
    Card
} from "react-bootstrap";

const GPTSourcesTabs = ({ values, touched, errors, handleBlur, handleChange, onTabChange }) => {
    const className = ''
    const { t, tt } = useTranslation();
    const { showMessage, showMessageWithDebug } = useMessage();
    const [activeItem, setActive] = useState(0)
    const { getSitemap } = useSources();
    const { activeBot } = useBots();

    const [isGettingSite, setGettingSite] = useState(false)

    const [alloyLinks, setLinks] = useState([])

    const { CONSOLE_EVENTS } = useAnalytics();

    const getParamsFromType = (values, touched, errors, handleBlur, handleChange) => {
        switch (+activeItem) {
            case SOURCE_TYPES.FILE.value:
                return <>
                    <Form.Group className="input-group mb-3">
                        <Form.Control
                            required
                            id="source_data_file"
                            type="file"
                            name="data.file"
                            value={values.data.file}
                            isInvalid={Boolean(
                                touched.data && errors.data && touched.data.file && errors.data.file
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {!!(touched.data && touched.data.file) && (
                            <Form.Control.Feedback type="invalid">
                                {(errors.data) ? errors.data.file : t("Something went wrong")}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group >
                    <Form.Group className="mb-3">
                        <FloatingLabel label={t("Source tag")}>
                            <Form.Control
                                id="title"
                                type="text"
                                name="title"
                                placeholder={t("Source tag Placeholder")}
                                value={values.title}
                                isInvalid={Boolean(
                                    touched.title && errors.title
                                )}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                maxLength={64}
                            />
                            {!!touched.title && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.title}
                                </Form.Control.Feedback>
                            )}
                        </FloatingLabel>
                    </Form.Group>
                    <div className="d-flex justify-content-end">{tt('N tokens will be used and more', values.title.length)}</div>
                </>
            case SOURCE_TYPES.SITEMAP.value:
                return <div className="d-flex">
                    <Form.Group className="input-group">
                        <span className='input-group-text'><Form.Label className='m-0' htmlFor='source_sitemap'>https://</Form.Label></span>
                        <Form.Control
                            required
                            id="source_data_link"
                            type="text"
                            name="data.link"
                            placeholder={t("Source sitemap or site url") + ' *'}
                            value={values.data.link}
                            isInvalid={Boolean(
                                touched.data && errors.data && touched.data.link && errors.data.link
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            maxLength={64}
                        />
                        {!!(touched.data && touched.data.link) && (
                            <Form.Control.Feedback type="invalid">
                                {(errors.data) ? errors.data.link : t("Something went wrong")}
                            </Form.Control.Feedback>
                        )}
                        <Button disabled={isGettingSite} onClick={async () => {
                            if (values?.data?.link.length > 0) {
                                await CONSOLE_EVENTS.EV_Sources.send({ bid: activeBot?.id, action: 'get_links', params: { ...values } })
                                setGettingSite(true)
                                const { links, error } = await getSitemap(values.data.link)
                                if (links)
                                    links.sort((x, y) => x.priority > y.priority)
                                setLinks(links || [])
                                if (error)
                                    showMessageWithDebug({ error: (error.errorMessage) ? error.errorMessage : error });
                                setGettingSite(false)
                            } else {
                                showMessage({ error: t('Please, enter link') })
                            }
                        }}>{t('Get links')}</Button>
                    </Form.Group >
                    {
                        (isGettingSite)
                            ? <div className="d-flex align-items-center justify-content-center" style={{ marginLeft: "1em" }}>
                                <Spinner animation="border" key="Primary" variant="primary" size="sm" />
                            </div>
                            : <></>
                    }
                </div>
            case SOURCE_TYPES.LINK.value:
                return <>
                    <Form.Group className="input-group mb-3">
                        <span className='input-group-text'><Form.Label className='m-0' htmlFor='source_link'>https://</Form.Label></span>
                        <Form.Control
                            required
                            id="source_data_link"
                            type="text"
                            name="data.link"
                            placeholder={t("Source link") + ' *'}
                            value={values.data.link}
                            isInvalid={Boolean(
                                touched.data && errors.data && touched.data.link && errors.data.link
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            maxLength={64}
                        />
                        {!!(touched.data && touched.data.link) && (
                            <Form.Control.Feedback type="invalid">
                                {(errors.data) ? errors.data.link : t("Something went wrong")}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group >
                    <Form.Group className="mb-3">
                        <FloatingLabel label={t("Source tag")}>
                            <Form.Control
                                id="title"
                                type="text"
                                name="title"
                                placeholder={t("Source tag Placeholder")}
                                value={values.title}
                                isInvalid={Boolean(
                                    touched.title && errors.title
                                )}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                maxLength={64}
                            />
                            {!!touched.title && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.title}
                                </Form.Control.Feedback>
                            )}
                        </FloatingLabel>
                    </Form.Group>
                    <div className="d-flex justify-content-end">{tt('N tokens will be used and more', values.data.link.length + values.title.length)}</div>
                </>
            case SOURCE_TYPES.TEXT.value:
                return <>
                    <Form.Group className="mb-3">
                        <FloatingLabel label={t("Source tag")}>
                            <Form.Control
                                id="title"
                                type="text"
                                name="title"
                                placeholder={t("Source tag Placeholder")}
                                value={values.title}
                                isInvalid={Boolean(
                                    touched.title && errors.title
                                )}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                maxLength={64}
                            />
                            {!!touched.title && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.title}
                                </Form.Control.Feedback>
                            )}
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <FloatingLabel label={t("Source text") + ' *'}>
                            <Form.Control
                                required
                                as="textarea"
                                id="source_text_data"
                                type="text"
                                name="data.text"
                                placeholder={t("Source text Placeholder") + ' *'}
                                value={values.data.text}
                                isInvalid={Boolean(
                                    touched.data && errors.data && touched.data.text && errors.data.text
                                )}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                style={{ height: '200px' }}
                            />
                            {!!(touched.data && touched.data.text) && (
                                <Form.Control.Feedback type="invalid">
                                    {(errors.data) ? errors.data.text : t("Something went wrong")}
                                </Form.Control.Feedback>
                            )}
                        </FloatingLabel>
                    </Form.Group>
                    <div className="d-flex justify-content-end">{tt('N tokens will be used', values.data.text.length + values.title.length)}</div>
                </>
            default:
                return <></>
        }
    }

    const getHelpText = () => {
        switch (+activeItem) {
            case SOURCE_TYPES.TEXT.value:
                return <div style={{ textAlign: 'justify' }}>
                    {t('About Text Source')} <br />
                    {t('About Tag in Source')}
                </div>
            case SOURCE_TYPES.FILE.value:
                return <div style={{ textAlign: 'justify' }}>
                    {t('About File Source')} <br />
                    {t('About Tag in Source')}
                </div>
            case SOURCE_TYPES.LINK.value:
                return <div style={{ textAlign: 'justify' }}>
                    {t('About Link Source')} <br />
                    {t('About Tag in Source')}
                </div>
            case SOURCE_TYPES.SITEMAP.value:
                return <div style={{ textAlign: 'justify' }}>
                    {t('About Sitemap Source')} <br />
                    {t('About Tag in Source')}
                </div>
            default:
                return t('Help')
        }
    }

    const columns = useMemo(() => SOURCE_TYPES.getTypes().map((item) => {
        return {
            icon: item.icon,
            value: item.value,
            title: item.title,
            description: item.description,
            disabled: item.disabled,
        }
    }), [])

    const helpContent = getHelpText()

    return <div className={"tab " + className}>
        <Form.Control
            id="source_type"
            type="hidden"
            name="type"
            value={activeItem}
        />
        <Tab.Container id="left-tabs-example" defaultActiveKey={0} onSelect={(eventKey, e) => {
            setActive(eventKey, e);
            if (onTabChange) onTabChange();
        }} >
            <Nav variant="tabs">
                {columns.map(column => {
                    return <Nav.Item key={column.value}>
                        <Nav.Link eventKey={column.value} disabled={column.disabled}>
                            <h4>{column.icon} {t(column.title)}</h4>
                            {t(column.description)}
                        </Nav.Link>
                    </Nav.Item>
                })}
            </Nav>
            <Tab.Content style={{ maxWidth: "800px" }} >
                <div className="d-flex mb-3 justify-content-between" style={{ cursor: 'default' }}>
                    <h3>{t('Source settings')}</h3>
                    <OverlayTrigger
                        placement='right'
                        overlay={
                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                <Popover.Body style={{ cursor: 'default' }}>{helpContent}</Popover.Body>
                            </Popover>
                        }
                    >
                        <HelpCircle className="icon-hover" style={{ cursor: 'help' }}></HelpCircle>
                    </OverlayTrigger>
                </div>
                {getParamsFromType(values, touched, errors, handleBlur, handleChange)}
                {
                    (alloyLinks.length > 0 && +activeItem === SOURCE_TYPES.SITEMAP.value)
                        ? <div id='sitemaps_links'>
                            <hr />
                            {alloyLinks.map((link, i) => {
                                return <Card id={'sitemaps_links_' + i} key={i} style={{ marginBottom: '12px' }}>
                                    <Card.Body className="d-flex justify-content-between align-items-center" style={{ padding: '.5rem 1.25rem', borderRadius: '.25rem' }}>
                                        <a href={link.link} title={link.link} style={{ width: '100%' }}>{link.link}</a>
                                        <Button className="btn-light" onClick={() => {
                                            document.getElementById('sitemaps_links').removeChild(document.getElementById('sitemaps_links_' + i));
                                            alloyLinks.splice(i, 1);
                                            //setLinks(alloyLinks);
                                        }}><X className="text-danger" /></Button>
                                    </Card.Body>
                                </Card>
                            })}
                        </div>
                        : <></>
                }
            </Tab.Content>
        </Tab.Container>
    </div>
}

export default GPTSourcesTabs;