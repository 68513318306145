import { createContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router";
import api from "../net/api";
import Tuner from "../components/Tuner";
import logger from "../utils/logger";

import { setSession } from "../net/jwt";
import { config } from "../config";
import provider from "../net/provider";
import Thread from "../utils/thread";
import { useTranslation } from "../hooks/useLocalization";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isMaintenance: false,
  authToken: null,
  accessToken: null,
  signInEmail: null,
  user: null,
};

const INITIALIZE = "INITIALIZE";
const SYNCHRONIZE = "SYNCHRONIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_IN_FAILED = "SIGN_IN_FAILED";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const VERIFY_EMAIL = "VERIFY_EMAIL";
const COMPLETE_PROFILE = "COMPLETE_PROFILE";
const UPDATE_PROFILE = "UPDATE_PROFILE";
const RESET_PASSWORD = "RESET_PASSWORD";
const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
const REQUEST_DELETION = "REQUEST_DELETION";
const DELETE_ACCOUNT = "DELETE_ACCOUNT";
const MAINTENANCE = "MAINTENANCE";

const QDReducer = (state, action) => {
  // logger.dev("[QDReducer]", action.type);
  const {
    user,
    signInEmail,
    isInitialized,
    isAuthenticated,
    authToken,
    accessToken,
  } = action.payload || {};

  let newState;
  switch (action.type) {
    case INITIALIZE:
      newState = {
        isInitialized,
        isAuthenticated,
        isMaintenance: false,
        authToken,
        accessToken,
        user,
      };
      break;

    case SIGN_IN:
      newState = {
        ...state,
        isAuthenticated: true,
        accessToken,
        signInEmail: null,
        user,
      };
      break;

    case SIGN_IN_FAILED:
      newState = {
        ...state,
        signInEmail,
      };
      break;

    case SIGN_OUT:
      newState = {
        ...state,
        isAuthenticated: false,
        isMaintenance: false,
        authToken: null,
        accessToken: null,
        signInEmail: null,
        user: null,
      };
      break;

    case DELETE_ACCOUNT:
      newState = {
        ...state,
        isAuthenticated: false,
        authToken: null,
        accessToken: null,
        signInEmail: null,
        user: null,
      };
      break;

    case SIGN_UP:
      newState = {
        ...state,
        isAuthenticated,
        accessToken,
        user,
      };
      break;

    case VERIFY_EMAIL:
      newState = {
        ...state,
        isAuthenticated,
        authToken: null,
        user,
      };
      break;

    case COMPLETE_PROFILE:
      newState = {
        ...state,
        isAuthenticated,
        user,
      };
      break;

    case UPDATE_PROFILE:
    case SYNCHRONIZE:
      newState = {
        ...state,
        user,
      };
      break;

    case RESET_PASSWORD:
    case REQUEST_DELETION:
      newState = {
        ...state,
      };
      break;

    case SET_NEW_PASSWORD:
      newState = {
        ...state,
        isAuthenticated,
        authToken: null,
        accessToken,
        user,
      };
      break;

    case MAINTENANCE:
      newState = {
        ...state,
        isMaintenance: true,
      };
      break;

    default:
      logger.dev(`[QDReducer] invalid action ${action}`);
  }

  if (newState) {
    const { accessToken, authToken, isInitialized, isAuthenticated } = newState;
    api.initialized(!!isInitialized);
    api.authenticated(!!isAuthenticated);
    api.configureToken({ accessToken, authToken });
    return newState;
  }

  return state;
};

const AuthContext = createContext(null);
const MainThread = new Thread(2000);
const isValidToken = (accessToken) => accessToken?.length > 0;
const restoreAccessToken = () => window.localStorage.getItem("accessToken");
let [synchronize, setSynchronize] = [false, (value) => (synchronize = value)];
const onFocus = () => setSynchronize(true);
const onBlur = () => { };

function AuthProvider({ children }) {
  const { i18n, t, ts } = useTranslation();
  const [state, dispatch] = useReducer(QDReducer, initialState);
  const location = useLocation();
  const { isInitialized, isAuthenticated, user } = state;

  // Restore Session
  // ------------------------
  useEffect(() => {
    let { search } = location;
    const params = new URLSearchParams(search);
    const authToken = params.get("authToken");
    const accessToken = params.get("accessToken") || restoreAccessToken();
    const { channelToken, routes } = config;
    api.configureToken({ channelToken, accessToken, authToken });
    api.configure([
      {
        server: routes.http.auth,
        socketID: provider.createSocket(routes.ws.auth),
        methods: [...Object.values(api.AUTH)],
      },
      {
        server: routes.http.bot,
        socketID: provider.createSocket(routes.ws.bot),
        methods: [...Object.values(api.BOT)],
      },
      {
        server: routes.http.botFiles,
        // socketID: provider.createSocket(routes.ws.botFiles),
        methods: [...Object.values(api.FILES)],
      },
      {
        server: routes.http.botManage,
        socketID: provider.createSocket(routes.ws.botManage),
        methods: [...Object.values(api.MANAGE), ...Object.values(api.SOURCE)],
      },
      {
        server: routes.http.botReports,
        socketID: provider.createSocket(routes.ws.botReports),
        methods: [...Object.values(api.REPORTS)],
      },
      {
        server: routes.http.botExtensions,
        socketID: provider.createSocket(routes.ws.botExtensions),
        methods: [...Object.values(api.DATA), ...Object.values(api.LIVE_CHAT), ...Object.values(api.PARSING)],
      },
      {
        server: routes.http.botBilling,
        socketID: provider.createSocket(routes.ws.botBilling),
        methods: [...Object.values(api.BILLING)],
      },
    ]);

    (async () => {
      if (accessToken && isValidToken(accessToken)) {
        try {
          setSession(accessToken);
          const response = await api.request(api.AUTH.GET);
          const { user } = response.data;
          const { isVerified, regComplete } = user || {};
          const isAuthenticated = !!(isVerified && regComplete);
          dispatch({
            type: INITIALIZE,
            payload: {
              isInitialized: true,
              isAuthenticated,
              authToken,
              accessToken,
              user,
            },
          });
        } catch (error) {
          logger.error(error);
          dispatch({
            type: INITIALIZE,
            payload: {
              isInitialized: true,
              isAuthenticated: false,
              authToken: null,
              accessToken: null,
              user: null,
            },
          });
        }
      } else {
        dispatch({
          type: INITIALIZE,
          payload: {
            isInitialized: true,
            isAuthenticated: false,
            authToken: null,
            accessToken: null,
            user: null,
          },
        });
      }
    })();

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);

      dispatch({
        type: INITIALIZE,
        payload: {
          isInitialized: false,
          isAuthenticated: false,
          authToken: null,
          accessToken: null,
          user: null,
        },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Language
  // ------------------------
  useEffect(() => api.setLanguage(i18n.language || "en"), [i18n.language]);

  // Synchronize User
  // ------------------------
  const trySynchronize = async () => {
    try {
      const accessToken = restoreAccessToken();
      api.configureToken({ accessToken });

      const response = await api.request(api.AUTH.GET);
      const { user, error } = response?.data || {};
      const { errorCode } = error || {};
      const { isVerified, regComplete } = user || {};
      const isAuthenticated = !!(isVerified && regComplete);

      if (state.isAuthenticated && (errorCode === 3 || errorCode === 5)) {
        state.isAuthenticated = false;
        dispatch({ type: SIGN_OUT });
      }

      if (!state.isAuthenticated && user) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isInitialized: true,
            isAuthenticated,
            authToken: null,
            accessToken,
            user,
          },
        });
      }

      if (
        state.isAuthenticated &&
        isAuthenticated &&
        JSON.stringify(state.user) !== JSON.stringify(user)
      ) {
        dispatch({
          type: SYNCHRONIZE,
          payload: { user },
        });
      }
    } catch (error) {
      // logger.error(error);
      state.isAuthenticated = false;
      dispatch({ type: MAINTENANCE });
    }
  };

  // Thread tasks
  // ------------------------
  useEffect(() => {
    const available = isInitialized && isAuthenticated;
    MainThread.handler = available
      ? async () => {
        if (synchronize) {
          trySynchronize();
          setSynchronize(false);
        }
      }
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, isAuthenticated, state]);

  // Show scrollbar
  // ------------------------
  useEffect(() => {
    if (isInitialized) {
      document.body.style.overflow = "";
    }
  }, [isInitialized]);

  // API functions
  // ------------------------

  // signIn
  const signIn = async (email, password) => {
    const response = await api.request(api.AUTH.LOGIN, {
      user: {
        email,
        password,
      },
    });

    const { accessToken, user, error, debug } = response.data;
    if (error) {
      dispatch({
        type: SIGN_IN_FAILED,
        payload: {
          signInEmail: email,
        },
      });
      throwServerError(error, debug);
    }

    if (!(accessToken && user)) {
      signOut();
      return;
    }

    setSession(accessToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        accessToken,
        user,
      },
    });
  };

  // socialIn
  const socialIn = async (network, token, email, firstName, lastName) => {
    const displayName = `${firstName} ${lastName}`;
    const response = await api.request(api.AUTH.SOCIAL, {
      user: {
        email,
        firstName,
        lastName,
        displayName,
        [network]: { accessToken: token },
      },
    });

    const { accessToken, user, error, debug } = response.data;
    if (error) {
      dispatch({
        type: SIGN_IN_FAILED,
        payload: {
          signInEmail: email,
        },
      });
      throwServerError(error, debug);
    }

    if (!(accessToken && user)) {
      signOut();
      return;
    }

    const { isVerified } = user || {};
    if (!isVerified) {
      throwServerError(
        {
          errorCode: 12,
          errorMessage: t("Your account has not been confirmed"),
        },
        debug
      );
    }

    setSession(accessToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        accessToken,
        user,
      },
    });
  };

  // googleIn
  const googleIn = async (accessToken, email, firstName, lastName) => {
    return socialIn("google", accessToken, email, firstName, lastName);
  };

  // facebookIn
  const facebookIn = async (accessToken, email, firstName, lastName) => {
    return socialIn("facebook", accessToken, email, firstName, lastName);
  };

  // sign details
  const signCheck = async () => {
    const response = await api.request(api.AUTH.GET);
    const { user } = response.data;
    const { isVerified, regComplete } = user || {};
    const isAuthenticated = !!(isVerified && regComplete);
    return { isVerified, regComplete, isAuthenticated };
  };

  // signUp
  const signUp = async (email, password, firstName, lastName, params) => {
    const url_params = [...(params?.entries() || [])].reduce(
      (p, [k, v]) => ({ ...p, [k]: v }),
      {}
    );
    const displayName = `${firstName} ${lastName}`;
    const response = await api.request(api.AUTH.REGISTER, {
      user: {
        email,
        password,
        firstName,
        lastName,
        displayName,
      },
      url_params,
    });

    const { accessToken, user, error, debug } = response.data;
    if (error) {
      throwServerError(error, debug);
    }

    if (!(accessToken && user)) {
      signOut();
      return;
    }

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        isAuthenticated: false,
        accessToken,
        user,
      },
    });
  };

  // verifyEmail
  const verifyEmail = async () => {
    let response = await api.request(api.AUTH.REGISTER_CONFIRM);

    const { error, debug } = response.data;
    if (error) {
      throwServerError(error, debug);
    }

    response = await api.request(api.AUTH.GET);
    const { user } = response.data;
    const { isVerified, regComplete } = user || {};
    const isAuthenticated = !!(isVerified && regComplete);
    dispatch({
      type: VERIFY_EMAIL,
      payload: {
        isAuthenticated,
        user,
      },
    });
  };

  // fastProfile
  const fastProfile = async (userProps) => {
    const response = await api.request(api.AUTH.REGISTER_FAST, {
      user: {
        ...state.user,
        ...userProps,
      },
    });

    const { user, error, debug } = response.data;
    if (error) {
      throwServerError(error, debug);
    }

    if (!user) {
      signOut();
      return;
    }

    const { isVerified, regComplete } = user || {};
    const isAuthenticated = !!(isVerified && regComplete);
    dispatch({
      type: COMPLETE_PROFILE,
      payload: {
        isAuthenticated,
        user,
      },
    });
  };

  // completeProfile
  const completeProfile = async (userProps) => {
    const response = await api.request(api.AUTH.REGISTER_FINALIZE, {
      user: {
        ...state.user,
        ...userProps,
      },
    });

    const { user, error, debug } = response.data;
    if (error) {
      throwServerError(error, debug);
    }

    if (!user) {
      signOut();
      return;
    }

    const { isVerified, regComplete } = user || {};
    const isAuthenticated = !!(isVerified && regComplete);
    dispatch({
      type: COMPLETE_PROFILE,
      payload: {
        isAuthenticated,
        user,
      },
    });
  };

  // Update License
  const updateLicense = async (data) => {
    const response = await api.request(api.AUTH.UPDATE, data)

    const { user, error } = response.data;
    if (error || !user) {
      throw new Error(error.errorMessage || t("Connection error"));
    }

    return user;
  }

  // updateProfile
  const updateProfile = async (userProps) => {
    let req = { ...state.user };
    req.license?.forEach((item) => (item.all_permissions) && delete item.all_permissions)

    const response = await api.request(api.AUTH.UPDATE, {
      user: {
        ...req,
        ...userProps,
      },
    });

    const { user, error } = response.data;
    if (error || !user) {
      throw new Error(error.errorMessage || t("Connection error"));
    }

    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        user,
      },
    });
  };

  // forgotPassword
  const forgotPassword = async (email) => {
    let response = await api.request(api.AUTH.PASSWORD_FORGOT, {
      user: { email },
    });

    const { error, debug } = response.data;
    if (error) {
      throwServerError(error, debug);
    }

    dispatch({
      type: RESET_PASSWORD,
    });
  };

  // resetPassword
  const resetPassword = async (password) => {
    let response = await api.request(api.AUTH.PASSWORD_RESTORE, {
      user: { password },
    });

    const { accessToken, error, debug } = response.data;
    if (error) {
      throwServerError(error, debug);
    }

    if (!accessToken) {
      signOut();
      return;
    }

    setSession(accessToken);
    api.configureToken({ accessToken });

    response = await api.request(api.AUTH.GET);
    const { user } = response.data;
    const { isVerified, regComplete } = user || {};
    const isAuthenticated = !!(isVerified && regComplete);

    dispatch({
      type: SET_NEW_PASSWORD,
      payload: {
        isAuthenticated,
        accessToken,
        user,
      },
    });

    return isAuthenticated;
  };

  const isUserLicense = () => {
    if (user?.license?.length > 0) {
      return !!user?.license[0]?.all_permissions?.length;
    }
  }

  const checkRole = (role) => {
    const permissions = user?.license?.map((item) => item);
    if (permissions) {
      const roles = permissions[0]?.roles || [];
      const isRole = roles?.find((item) => item.name === role);
      return !!isRole;
    }
    return false;
  }

  const checkPermission = (perm) => {
    const permissions = user?.license?.map((item) => item);
    if (permissions) {
      const all_permissions = permissions[0]?.all_permissions || []
      const isPerm = all_permissions?.indexOf(perm) > -1;
      return !!isPerm;
    }
    return false;
  }

  // UserActivityReport
  const activityReport = async () => {
    let response = await api.request(api.AUTH.FETCH);
    const { users, error, debug } = response.data;
    if (error) {
      throwServerError(error, debug);
    }
    return users;
  }

  // requestDeletion
  const requestDeletion = async (email) => {
    let response = await api.request(api.AUTH.DELETE);
    const { error, debug } = response.data;

    if (error) {
      throwServerError(error, debug);
    }

    dispatch({
      type: REQUEST_DELETION,
    });
  };

  // deleteAccount
  const deleteAccount = async (req = {}) => {
    let response = await api.request(api.AUTH.DELETE_CONFIRM, req);
    const { error, debug } = response.data;

    if (error) {
      throwServerError(error, debug);
    }

    if (Object.keys(req).length === 0 || user.uid === req.user?.uid) {
      dispatch({
        type: DELETE_ACCOUNT,
      });

      signOut();
    }
  };

  // signOut
  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  // secureAPIRequest raw
  const secureAPIRequestRaw = async (method, data = {}) => {
    return await api.request(method, data);
  };

  // secureAPIRequest
  const secureAPIRequest = async (method, data = {}, throwError = true) => {
    const response = await secureAPIRequestRaw(method, data);
    const { error, debug } = (response ? response.data : { error: {} }) || {};
    return (error && throwError) ? throwServerError(error, debug) : response;
  };

  const throwServerError = (serverError, serverDebug) => {
    const error = new Error(
      ts(`errorCode_${serverError.errorCode}`) ||
      t(serverError.errorMessage) ||
      t("Connection error")
    );
    error.debug = serverDebug;
    error.code = serverError.errorCode || 0;
    throw error;
  };

  const rolesFetch = async () => {
    let response = await api.request(api.AUTH.ROLES);
    const { roles, error, debug } = response.data;
    if (error) {
      throwServerError(error, debug);
    }
    return roles;
  }

  const permissionsFetch = async () => {
    let response = await api.request(api.AUTH.PERMISSIONS);
    const { permissions, error, debug } = response.data;
    if (error) {
      throwServerError(error, debug);
    }
    return permissions;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "qd",
        signIn,
        googleIn,
        facebookIn,
        signCheck,
        signOut,
        signUp,
        verifyEmail,
        fastProfile,
        completeProfile,
        updateProfile,
        forgotPassword,
        resetPassword,
        requestDeletion,
        deleteAccount,
        activityReport,
        secureAPIRequest,
        secureAPIRequestRaw,
        checkPermission,
        updateLicense,
        isUserLicense,
        checkRole,
        rolesFetch,
        permissionsFetch,
      }}
    >
      {children}
      {!state.isInitialized && <Tuner />}
      {/* {state.isMaintenance && (
        <Maintenance
          onRetry={async () => await trySynchronize()}
          onClose={() => dispatch({ type: SIGN_OUT })}
        />
      )} */}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
