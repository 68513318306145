import { useContext } from "react";
import { BillingContext } from "../contexts/billing/BillingContext";

const useBilling = () => {
  const context = useContext(BillingContext);

  if (!context)
    throw new Error("BillingContext must be placed within AuthProvider");

  return context;
};

export default useBilling;
