import { useEffect, useRef, useState } from "react";
import { Settings } from "react-feather";
import useBots from "../../hooks/useBots";
import { useTranslation } from "../../hooks/useLocalization";
import useQubot from "../../hooks/useQubot";
import { isMobile } from "../../utils/screen";
import Thread from "../../utils/thread";
import DesktopOnly from "../DesktopOnly";

const MainThread = new Thread(100);

const Setup = () => {
  const { activeBot, qubotType } = useBots();
  const { id: botID } = activeBot || {};
  const { t } = useTranslation();
  const type = qubotType(activeBot);
  const qubot = useQubot();
  const sourceRef = useRef();
  const [isSupported, setSupported] = useState(!isMobile());
  const [isLoading, setLoading] = useState();
  const [height, setHeight] = useState();
  const clientHeight =
    window.document.getElementById("qubot-page-setup")?.clientHeight;

  useEffect(() => {
    setHeight(clientHeight);
  }, [clientHeight]);

  useEffect(() => {
    if (isSupported) {
      qubot?.showSetup({ botID, sourceElement: sourceRef?.current, type });
    } else {
      qubot?.hideSetup();
    }
  }, [isSupported, qubot, botID, type]);

  useEffect(() => {
    MainThread.handler = qubot
      ? async () => {
        qubot?.resize();
        setLoading(!qubot?.isSetupShowed());
        setSupported(!isMobile());
      }
      : null;
    return () => {
      MainThread.handler = null;
      qubot?.hideSetup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={sourceRef}
      className="w-100"
      style={{
        height: isLoading
          ? `calc(100vh - ${isSupported ? 7.5 : 11.5}rem)`
          : height,
      }}
    >
      {isSupported ? (
        <>
          {isLoading && (
            <div className="context-icon">
              <Settings className="fa-spin text-primary" size={64} />
            </div>
          )}
          <div className="qubot-run-tuto" id="qubot-run-tuto2"><h3>1.</h3><div>{t("Tutover3_1")}</div></div>
          <div className="qubot-run-tuto23" id="qubot-run-tuto3"><h3>1.</h3><div>{t("Tutover3_2")}</div></div>
        </>
      ) : (
        <DesktopOnly />
      )}
    </div>
  );
};

export default Setup;
