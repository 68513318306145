class AntiSpam {
  id;
  time;
  handling;
  interval;

  constructor(interval = 500) {
    this.id = 0;
    this.time = 0;
    this.handling = false;
    this.interval = interval;
  }

  async action(handler) {
    if (Date.now() - this.time < this.interval || this.handling) {
      clearTimeout(this.id);
      this.id = setTimeout(() => this.action(handler), this.interval);
      return;
    }
    try {
      this.handling = true;
      this.time = Date.now();
      await handler();
    } catch (e) {
    } finally {
      this.handling = false;
    }
  }
}

export default AntiSpam;
