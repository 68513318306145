import { useEffect, useState } from "react";
import { Settings } from "react-feather";

const Tuner = () => {
  const [top, setTop] = useState(window.scrollY);

  useEffect(() => {
    window.onscroll = () => setTop(window.scrollY);
    return () => (window.onscroll = null);
  }, []);

  return (
    <>
      <div
        className="bg-body overlaps-body"
        style={{
          top: `${top}px`,
        }}
      >
        <div className="context-icon">
          <Settings className="fa-spin text-primary" size={64} />
        </div>
      </div>
    </>
  );
};

export default Tuner;
