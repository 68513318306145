import React from "react";
import { Outlet } from "react-router-dom";

import { BillingProvider } from "../contexts/billing/BillingContext";
import { BotsProvider } from "../contexts/bots/BotsContext";
import { TemplatesProvider } from "../contexts/bots/TemplatesContext";
import { AnalyticsProvider } from "../contexts/bots/AnalyticsContext";
import { ExtensionsProvider } from "../contexts/bots/ExtensionsContext";

import { SourcesProvider } from "../contexts/sources/SourcesContext";

import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Content from "../components/Content";
import SidebarBots from "../components/sidebar/SidebarBots";
import BotsGuard from "../components/guards/BotsGuard";
import JSBridge from "../components/JSBridge";
import QubotEnv from "../components/bots/QubotEnv";
import Navbar from "../components/navbar/Navbar";

const Dashboard = ({ children }) => {
  return (
    <Wrapper>
      <BillingProvider>
        <BotsProvider>
          <TemplatesProvider>
            <SourcesProvider>
              <BotsGuard path="bots" redirect="/dashboard">
                <AnalyticsProvider>
                  <ExtensionsProvider>
                    {/* <BotsDebug> */}
                    <SidebarBots />
                    <Main>
                      <QubotEnv />
                      <Navbar />

                      <Content>
                        {children}
                        <Outlet />
                      </Content>

                      {/* <Footer /> */}
                    </Main>
                    {/* </BotsDebug> */}
                  </ExtensionsProvider>
                </AnalyticsProvider>
              </BotsGuard>
              <JSBridge layout="Dashboard" />
              {/* <Settings /> */}
            </SourcesProvider>
          </TemplatesProvider>
        </BotsProvider>
      </BillingProvider>
    </Wrapper>
  );
};

export default Dashboard;
