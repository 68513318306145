export class Stats {
  source = null;
  // ctime = new Date();
  mtime = new Date();
  size = 0;

  async init(source) {
    this.source = source;
    if (source?.handle && !source.isDirectory) {
      let stats = await source.handle.getFile();
      // this.ctime = stats.lastModifiedDate;
      this.mtime = stats.lastModifiedDate;
      this.size = stats.size;
    }
  }
}

export class File {
  constructor(handle, parent) {
    this.handle = handle;
    this.parent = parent;
  }

  get dir() {
    let dir = "";
    let parent = this.parent;
    while (parent && !parent.isRoot) {
      dir = dir ? `${parent.name}/${dir}` : parent.name;
      parent = parent.parent;
    }
    return dir;
  }

  get name() {
    return this.handle.name;
  }

  get fullPath() {
    let { dir, name } = this;
    return dir ? `${dir}/${name}` : name;
  }

  get root() {
    let root = this;
    while (root?.parent) {
      root = root.parent;
    }
    return root;
  }

  get isRoot() {
    return this.root === this;
  }

  get isFile() {
    return this.handle.kind === "file";
  }

  get isDirectory() {
    return this.handle.kind === "directory";
  }

  async hasPermissions(mode = "readwrite") {
    return await FileSystem.verifyPermission(this.handle, {
      mode,
    });
  }

  async requestPermissions(mode = "readwrite") {
    return await FileSystem.verifyPermission(this.handle, {
      mode,
      request: true,
    });
  }

  stats() {
    return new Promise(async (resolve, reject) => {
      try {
        let stats = new Stats();
        await stats.init(this);
        resolve(stats);
      } catch (error) {
        reject(error);
      }
    });
  }

  text() {
    return new Promise(async (resolve, reject) => {
      if (this.isDirectory) {
        reject(`Unable to get text because it is a directory`);
        return;
      }
      try {
        let file = await this.handle.getFile();
        resolve(await file.text());
      } catch (error) {
        reject(error);
      }
    });
  }

  arrayBuffer() {
    return new Promise(async (resolve, reject) => {
      if (this.isDirectory) {
        reject(`Unable to get arrayBuffer because it is a directory`);
        return;
      }
      try {
        let file = await this.handle.getFile();
        resolve(await file.arrayBuffer());
      } catch (error) {
        reject(error);
      }
    });
  }
}

export class Directory extends File {
  resolve(dirPath) {
    return new Promise(async (resolve, reject) => {
      if (!dirPath) {
        resolve(this);
        return;
      }
      dirPath = this.__convertPath(dirPath);
      try {
        let handle = this.handle;
        let parts = dirPath.split(FileSystem.sep),
          handles = [];
        await parts.reduce(async (handle, partStr) => {
          handle = await handle;
          try {
            handle = await handle.getDirectoryHandle(partStr);
          } catch (error) {
            handle = await handle.getFileHandle(partStr);
          }
          handles.push(handle);
          return handle;
        }, Promise.resolve(handle));

        let dirHandle = handles.reduce(
          (parent, handle) => this.__createInstance(handle, parent),
          this
        );
        resolve(dirHandle);
      } catch (error) {
        reject(error);
      }
    });
  }

  readdir(path, options) {
    return new Promise(async (resolve, reject) => {
      path = this.__convertPath(path);
      const read = async (dir, files) => {
        return new Promise(async (resolve, reject) => {
          try {
            files = files ?? [];
            let resolvedDir = dir ? await this.resolve(dir) : this;
            if (options?.recursive) {
              let promises = [];
              for await (const [, value] of resolvedDir.handle.entries()) {
                let ins = this.__createInstance(value, resolvedDir);
                if (ins.isDirectory) {
                  promises.push(read(ins.fullPath, files));
                  continue;
                }
                files.push(ins);
              }
              if (promises.length) {
                await Promise.all(promises);
              }
              return resolve(files);
            }
            for await (const [, value] of resolvedDir.handle.entries()) {
              files.push(this.__createInstance(value, resolvedDir));
            }
            resolve(files);
          } catch (error) {
            reject(error);
          }
        });
      };
      read(path, []).then(resolve).catch(reject);
    });
  }

  writeFile(path, options) {
    return new Promise(async (resolve, reject) => {
      path = this.__convertPath(path);
      try {
        // not implemented
      } catch (error) {
        reject(error);
      }
    });
  }

  deleteFile(path, options) {
    return new Promise(async (resolve, reject) => {
      path = this.__convertPath(path);
      try {
        // not implemented
        let Directory = this.handle;
        await Directory.removeEntry(path, options);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  __createInstance(jsHandle, parent) {
    if (jsHandle.kind === "directory") {
      return new Directory(jsHandle, parent);
    }
    return new File(jsHandle, parent);
  }

  __convertPath(path) {
    return path && path.replace(/\\/g, FileSystem.sep);
  }
}

export default class FileSystem {
  static get sep() {
    return "/";
  }

  static async browseFile(options) {
    let handle = await window.showOpenFilePicker(options);
    return new File(handle);
  }

  static async browseDirectory(options) {
    let handle = await window.showDirectoryPicker(options);
    return new Directory(handle);
  }

  static async verifyPermission(jsHandle, options) {
    if ((await jsHandle.queryPermission(options)) === "granted") {
      return true;
    }
    if (options?.request) {
      delete options.request;
      if ((await jsHandle.requestPermission(options)) === "granted") {
        return true;
      }
    }
    return false;
  }
}
