import { useState } from "react";
import { Button } from "react-bootstrap";
import useBilling from "../../hooks/useBilling";
import { useTranslation } from "../../hooks/useLocalization";
import useMessage from "../../hooks/useMessage";

const BillingInformation = () => {
  const { t } = useTranslation();
  const { clearUser } = useBilling();
  const { showMessage } = useMessage();
  const [isSubmitting, setSubmitting] = useState(false);
  const [, /*submitted*/ setSubmitted] = useState(false);

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      await clearUser();
      showMessage({
        save: t(`Payment history has been deleted`),
      });
      setSubmitted(true);
    } catch (error) {
      const { message = t("Something went wrong") } = error;
      showMessage({ error: message });
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <p>{t("This feature is only available for test")}</p>
      <Button variant="danger" disabled={isSubmitting} onClick={onSubmit}>
        {t("Clear payment history")}
      </Button>
    </>
  );
};

export default BillingInformation;
