import { useState } from "react";
import { Row } from "react-bootstrap";
import zendesk from "../../../../assets/img/brands/zendesk.svg";
import screen_1 from "../../../../assets/img/screenshots/channels/screen_zendesk_1.png";
import screen_2 from "../../../../assets/img/screenshots/channels/screen_zendesk_2.png";
import screen_3 from "../../../../assets/img/screenshots/channels/screen_zendesk_3.png";
import screen_4 from "../../../../assets/img/screenshots/channels/screen_zendesk_4.png";
import screen_5 from "../../../../assets/img/screenshots/channels/screen_zendesk_5.png";
import { BOT_CHANNEL } from "../../../../contexts/bots/BotsContext";
import useBots from "../../../../hooks/useBots";
import { useTranslation } from "../../../../hooks/useLocalization";
import useMessage from "../../../../hooks/useMessage";
import { Accordion } from "../../../accordion/Accordion";
import ZoomImage from "../../../ZoomImage";
import ChannelInput from "./submit/ChannelInput";
import ChannelInputState from "./submit/ChannelInputState";
import ChannelState from "./submit/ChannelState";

const ZendeskIcon = ({ className }) => (
  <img
    src={zendesk || ""}
    alt=""
    width={20}
    height={20}
    className={className}
    style={{ transform: "scale(0.8)" }}
  />
);

const ZendeskLogin = ({ id, channel, levels }) => {
  // limits
  // Account URL (255)
  const { t, tt } = useTranslation();
  const { updateBot } = useBots();
  const [isSubmitting, setSubmitting] = useState();
  const { /*showMessage, */ showMessageWithDebug } = useMessage();
  const onSubmit = async (value) => {
    try {
      setSubmitting(true);
      await updateBot(id, {
        workflows: [
          { ...channel, name: BOT_CHANNEL.ZENDESK, account_url: value },
        ],
      });
      // showMessage({ save: "" });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setSubmitting(false);
    }
  };
  const onChange = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit(value);
    }
  };
  return (
    <>
      {tt(
        "Zendesk.Step-1-1",
        <a
          href="https://www.zendesk.com"
          alt="zendesk"
          target="_blank"
          rel="noreferrer"
        >
          https://www.zendesk.com
        </a>
      )}
      <ChannelInput
        strict
        title={t("Account URL")}
        field="account_url"
        channel={channel}
        disabled={!levels.manage}
        isSubmitting={isSubmitting}
        onChange={onChange}
      />
      {/* <ChannelInputState
        fields={["account_url"]}
        channel={channel}
        isSubmitting={isSubmitting}
      /> */}
      <p>{t("Zendesk.Step-1-2")}</p>
      <ul>
        <li>{t("Zendesk.Step-1-3")}</li>
        <li>{t("Zendesk.Step-1-4")}</li>
      </ul>
    </>
  );
};

const ZendeskCreateAdmin = () => {
  const { t } = useTranslation();
  return (
    <>
      {t("Zendesk.Step-2-1")}
      <Row>
        <ZoomImage>
          <img alt="screen_1" src={screen_1} className="m-3 ms-0 mw-100" />
        </ZoomImage>
        <ZoomImage>
          <img alt="screen_2" src={screen_2} className="m-3 ms-0 mw-100" />
        </ZoomImage>
      </Row>
    </>
  );
};

const ZendeskCreateAPIToken = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      <ul>
        <li>
          {tt("Zendesk.Step-3-1", <b>{t("Admin Center")}</b>)}
          <ZoomImage>
            <img alt="screen_3" src={screen_3} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
        <li>
          {tt(
            "Zendesk.Step-3-2",
            <b>{t("Apps and integrations > Zendesk API")}</b>
          )}
          <ZoomImage>
            <img alt="screen_4" src={screen_4} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
        <li>
          {tt(
            "Zendesk.Step-3-3",
            <b>{t("Add API token")}</b>,
            <b>{t("API token")}</b>,
            <b>{t("Save")}</b>
          )}
          <ZoomImage>
            <img alt="screen_5" src={screen_5} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
      </ul>
    </>
  );
};

const ZendeskAPIToken = ({ id, channel, levels }) => {
  // limits
  // API token (40)
  const { t } = useTranslation();
  const { updateBot } = useBots();
  const [isSubmitting, setSubmitting] = useState();
  const { /*showMessage, */ showMessageWithDebug } = useMessage();
  const onSubmit = async (value) => {
    try {
      setSubmitting(true);
      await updateBot(id, {
        workflows: [{ ...channel, name: BOT_CHANNEL.ZENDESK, token: value }],
      });
      // showMessage({ save: "" });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setSubmitting(false);
    }
  };
  const onChange = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit(value);
    }
  };
  return (
    <>
      <ChannelInput
        strict
        title={t("API token")}
        field="token"
        channel={channel}
        disabled={!levels.manage}
        isSubmitting={isSubmitting}
        onChange={onChange}
      />
      <ChannelInputState
        fields={["token", "account_url"]}
        channel={channel}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

const ZendeskConfigure = ({ channel, levels, bot, handlers }) => {
  const { t, tt } = useTranslation();
  const { id } = bot || {};
  return (
    <>
      <Accordion defaultActiveKey="0" className="accordion-steps no-animation">
        <Accordion.Item eventKey="0" className="bg-white">
          <Accordion.Header>{tt("Step N", 1)}</Accordion.Header>
          <Accordion.Body>
            <p>{t("Zendesk.Step-1")}</p>
            <ZendeskLogin id={id} channel={channel} levels={levels} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{tt("Step N", 2)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Zendesk.Step-2")}</p>
            <ZendeskCreateAdmin />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{tt("Step N", 3)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Zendesk.Step-3")}</p>
            <ZendeskCreateAPIToken />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{tt("Step N", 4)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Zendesk.Step-4")}</p>
            <ZendeskAPIToken id={id} channel={channel} levels={levels} />
            <ChannelState channel={channel} handlers={handlers} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const ZendeskGetHandlers = ({ url, validated, handlers }) => {
  if (validated && url) {
    handlers = {
      ...handlers,
      onLink: () => {
        window.open(url, "_blank");
      },
    };
  }
  return handlers;
};

export { ZendeskIcon, ZendeskConfigure, ZendeskGetHandlers };
