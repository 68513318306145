import React, { useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form, Row, Col, FloatingLabel } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import useMessage from "../../hooks/useMessage";
import { useTranslation } from "../../hooks/useLocalization";
import { googleTagNavigate } from "../GoogleTags";

function SignUpFast({ dashboard, hightlight }) {
  const { t } = useTranslation();
  const { showMessage } = useMessage();
  const { user, fastProfile, updateProfile } = useAuth();
  const websiteRef = useRef();
  useEffect(() => websiteRef.current?.focus(), [websiteRef]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        companyName: user?.companyName || "",
        websiteURL: user?.websiteURL || "",
        employeesNum: user?.employeesNum || "",
        industry: user?.industry || "",
        position: user?.position || "",
        phone: user?.phone || "",
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255),
        // .required("First name is required"),
        lastName: Yup.string().max(255),
        // .required("Last name is required"),
        websiteURL: Yup.string().max(255),
        // .required("Business website URL"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const {
            firstName,
            lastName,
            companyName,
            websiteURL,
            employeesNum,
            industry,
            position,
            phone,
          } = values;
          const displayName =
            dashboard && user?.displayName
              ? user.displayName
              : `${firstName} ${lastName}`;
          await (dashboard ? updateProfile : fastProfile)({
            firstName,
            lastName,
            displayName,
            companyName,
            websiteURL,
            employeesNum,
            industry,
            position,
            phone,
          });
          if (dashboard) {
            showMessage({ save: "" });
          } else {
            googleTagNavigate(
              "AW-437440652/3WsKCNuC38MDEIyhy9AB",
              "/dashboard/bots"
            );
            setTimeout(() => {
              const notifToggle = document.getElementById("qb-console-dnd-tuto");
              notifToggle.click();
            }, 3000)
          }
        } catch (error) {
          const { message = t("Something went wrong") } = error;
          if (dashboard) {
            showMessage({ error: message });
          }
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {!dashboard && errors.submit && (
            <Alert variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <FloatingLabel
                  label={`${t("First name")} *`}
                  className={hightlight && !values.firstName && "highlight"}
                >
                  <Form.Control
                    required
                    type="text"
                    name="firstName"
                    placeholder={`${t("First name")} *`}
                    value={values.firstName}
                    isInvalid={Boolean(touched.firstName && errors.firstName)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.firstName && (
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <FloatingLabel
                  label={`${t("Last name")} *`}
                  className={hightlight && !values.lastName && "highlight"}
                >
                  <Form.Control
                    required
                    type="text"
                    name="lastName"
                    placeholder={`${t("Last name")} *`}
                    value={values.lastName}
                    isInvalid={Boolean(touched.lastName && errors.lastName)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.lastName && (
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <FloatingLabel
              label={`${t("Business website URL")} *`}
              className={hightlight && !values.websiteURL && "highlight"}
            >
              <Form.Control
                required
                type="url"
                pattern="((http)|(https))://[^\.]+\.[^\.]+(.*)"
                name="websiteURL"
                value={values.websiteURL}
                placeholder={`${t("Business website URL")} *`}
                ref={websiteRef}
                isInvalid={Boolean(touched.websiteURL && errors.websiteURL)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.websiteURL && (
                <Form.Control.Feedback type="invalid">
                  {errors.websiteURL}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3">
            {/* <Form.Label htmlFor="password">Password</Form.Label> */}
            <Form.Control
              id="password"
              type="password"
              name="password"
              autoComplete="new-password"
              placeholder={t("Password")}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            {/* <Form.Label htmlFor="confirm-password">Confirm password</Form.Label> */}
            <Form.Control
              id="confirm-password"
              type="password"
              name="confirmPassword"
              autoComplete="new-password"
              placeholder={t("Confirm password")}
              isInvalid={Boolean(
                touched.confirmPassword && errors.confirmPassword
              )}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.confirmPassword && (
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {dashboard ? (
            <Button type="submit" variant="primary" disabled={isSubmitting}>
              {t("Save changes")}
            </Button>
          ) : (
            <div className="text-center mt-3 mb-4 w-100 d-grid">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}
              >
                {t("Complete")}
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default SignUpFast;
