import { BOT_KIND, BOT_STATUS } from "../../../contexts/bots/BotsContext";

const SORT_BY = {
  NAME: "name",
  RECENT: "recent",
};

const BOTS_TYPE = {
  ALL: 'all',
  GPT: "gpt",
  EDITOR: "editor",
};

const BOT_FILTER = {
  MY: "my",
  ALL: "all",
  SHARED: "shared",
  TEMPLATE: "template",
  EXAMPLE: "example",
  UNKNOWN: "unknown",

  title: (filter) =>
    (filter === BOT_FILTER.ALL && "All Bots") ||
    (filter === BOT_FILTER.SHARED && "Shared Bots") ||
    (filter === BOT_FILTER.TEMPLATE && "Templates") ||
    (filter === BOT_FILTER.EXAMPLE && "Examples") ||
    (filter === BOT_FILTER.UNKNOWN && "Unknown Bots") ||
    "My Bots",
};

const getBotFilter = (bot, filter, user) => {
  if (!(bot && user)) {
    return BOT_FILTER.MY;
  }
  const { author, status, kind } = bot;
  const isMy = author?.uid === user.uid;
  const isShared = status === BOT_STATUS.PUBLISH && kind === BOT_KIND.USER;
  const isTemplate =
    status === BOT_STATUS.PUBLISH && kind === BOT_KIND.TEMPLATE;
  const isExample = status === BOT_STATUS.PUBLISH && kind === BOT_KIND.EXAMPLE;
  const isUnknown = !(isMy || isShared || isTemplate || isExample);
  if (
    filter &&
    ((filter === BOT_FILTER.MY && isMy) ||
      (filter === BOT_FILTER.SHARED && isShared) ||
      (filter === BOT_FILTER.TEMPLATE && isTemplate) ||
      (filter === BOT_FILTER.EXAMPLE && isExample) ||
      (filter === BOT_FILTER.UNKNOWN && isUnknown))
  ) {
    return filter;
  }
  return isMy
    ? BOT_FILTER.MY
    : isShared
      ? BOT_FILTER.SHARED
      : isTemplate
        ? BOT_FILTER.TEMPLATE
        : isExample
          ? BOT_FILTER.EXAMPLE
          : BOT_FILTER.UNKNOWN;
};

export { SORT_BY, BOTS_TYPE, BOT_FILTER, getBotFilter };
