import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import useMessage from "../../hooks/useMessage";
import { useTranslation } from "../../hooks/useLocalization";

function ForgotPassword({ dashboard, onSubmit }) {
  const { t } = useTranslation();
  const { showMessage } = useMessage();
  const { forgotPassword, user, signInEmail } = useAuth();
  const [submitted, setSubmitted] = useState(false);
  const submitText = submitted
    ? t("Please check your email")
    : t("Reset Password");
  const emailRef = useRef();
  useEffect(() => emailRef.current?.focus(), [emailRef]);

  if (!dashboard && submitted) {
    return (
      <div className="alert-message d-flex align-items-center">
        <FontAwesomeIcon
          icon={faAt}
          size="3x"
          className="text-primary flex-shrink-0 me-3"
        />
        <div>{t("We have sent you an email with further instructions")}</div>
      </div>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{ email: user?.email || signInEmail || "", submit: false }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(t("Must be a valid email")).max(255),
        // .required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await forgotPassword(values.email);
          if (dashboard) {
            showMessage({
              info: t(`We have sent you an email with further instructions`),
            });
          }
          setSubmitted(true);
          if (onSubmit) onSubmit(true);
        } catch (error) {
          const { message = t("Something went wrong") } = error;
          if (dashboard) {
            showMessage({ error: message });
          }
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {!dashboard && errors.submit && (
            <Alert variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control
              required
              id="login"
              type="email"
              name="email"
              maxLength="150"
              label={t("Email Address")}
              ref={emailRef}
              readOnly={dashboard}
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Form.Control
              id="password"
              type="password"
              name="password"
              hidden
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {dashboard ? (
            <Button
              type="submit"
              variant="primary"
              disabled={isSubmitting || submitted}
            >
              {submitText}
            </Button>
          ) : (
            <div className="text-center mt-3 w-100 d-grid">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting || submitted}
              >
                {submitText}
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default ForgotPassword;
