const downloadBase64File = (contentBase64, fileName, mimeType) => {
  const linkSource = `data:${mimeType};base64,${contentBase64}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  downloadLink.click();
};

const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const arrayBufferToBase64Async = async (buffer) => {
  return new Promise((resolve) => resolve(arrayBufferToBase64(buffer)));
};

const stringToArrayBufferAsync = async (string) => {
  const blob = new Blob([string], { type: "text/plain; charset=utf-8" });
  return await blob.arrayBuffer();
};

export {
  downloadBase64File,
  arrayBufferToBase64,
  arrayBufferToBase64Async,
  stringToArrayBufferAsync,
};
