import React from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Info } from "react-feather";
import { useTranslation } from "../../hooks/useLocalization";

const InfoAccess = ({ bot }) => {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = React.useState(false);
  const owners = JSON.parse(bot?.available_to_users || "[]");

  const onClickHandler = () => {
    setModalShow(!modalShow)
  }

  const ModalInfoBots = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-flex flex-column justify-content-center">
          <button className="btn-close" onClick={() => setModalShow(!modalShow)}></button>
          <h4 className="d-block">{t("Available to users")}</h4>
          <div className="d-flex justify-content-between w-100">
            <div>{t("Author")}:<br /><b>{bot.author}</b></div>
            <div>{t("Bot")}:<br /><b>{bot.name}</b></div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {owners.map((user, i) => {
              return (
                <li key={i}>{user.email}</li>
              )
            })}
          </ul>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>{t("Information")}</Tooltip>}>
        <Button variant="light" className="m-1 p-1" onClick={onClickHandler}>
          <Info />
        </Button>
      </OverlayTrigger>
      <ModalInfoBots show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default InfoAccess;