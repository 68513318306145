import { atDevhost } from "../system/system";

class logger {
  static LOG_LEVEL = {
    DEVELOPER: 1,
    NETWORK: 2,
    RENDER: 4,
    WARNING: 8,
    ERROR: 16,
  };

  static level =
    logger.LOG_LEVEL.DEVELOPER |
    logger.LOG_LEVEL.NETWORK |
    // logger.LOG_LEVEL.RENDER |
    logger.LOG_LEVEL.WARNING |
    logger.LOG_LEVEL.ERROR;

  static dev(...args) {
    if (atDevhost() && logger.level & logger.LOG_LEVEL.DEVELOPER) {
      console.log.apply(null, args);
    }
  }

  static net(...args) {
    if (atDevhost() && logger.level & logger.LOG_LEVEL.NETWORK) {
      console.log.apply(null, args);
    }
  }

  static render(component) {
    if (atDevhost() && logger.level & logger.LOG_LEVEL.RENDER) {
      console.log.apply(null, [
        `%c render `,
        `background-color: grey; color: white`,
        component?.name,
      ]);
    }
  }

  static warn(...args) {
    if (atDevhost() && logger.level & logger.LOG_LEVEL.WARNING) {
      console.warn.apply(null, args);
    }
  }

  static error(...args) {
    if (atDevhost() && logger.level & logger.LOG_LEVEL.ERROR) {
      console.error.apply(null, args);
    }
  }
}

export default logger;
