import React, { useEffect, useReducer } from "react";
import api from "../../net/api";
import useAuth from "../../hooks/useAuth";
import * as CMD from "./reducer";
import useBots from "../../hooks/useBots";
import { createInterval, FILTERS, INTERVAL } from "./filters";

const INITIALIZE = "INITIALIZE";
const REPORTS_LOADING = "REPORTS_LOADING";
const FILTERS_UPDATED = "FILTERS_UPDATED";
const REPORTS_UPDATED = "REPORTS_UPDATED";
const SET_ACTIVE = "SET_ACTIVE";

const emptyFilters = () => ({
  [FILTERS.INTERVAL]: INTERVAL.TODAY,
  [FILTERS.INTERVAL_VALUES]: [],
  [FILTERS.CHANNELS]: [],
  [FILTERS.EVENT_NAME]: null,
});

const emptyReports = () => ({
  sessions: [],
  channels: [],
  uniqueUsers: [],
  eventsConversion: [],
  eventNames: [],
  eventProps: [],
  sessionsChannel: [],
});

const emptyLoading = () => ({
  all: false,
  sessions: false,
  channels: false,
  uniqueUsers: false,
  eventsConversion: false,
  eventNames: false,
  eventProps: false,
  sessionsChannel: false,
});

const initialState = {
  filters: emptyFilters(),
  reports: emptyReports(),
  isLoading: emptyLoading(),
  loadingError: null,
  activeBot: null,
  isInitialized: false,
};

const AnalyticsReducer = (state, action) => {
  // logger.dev("[AnalyticsReducer]", action.type, action.payload);
  const {
    filters,
    reports,
    isLoading,
    loadingError,
    activeBot,
    isInitialized,
  } = action.payload || {};

  switch (action.type) {
    case INITIALIZE:
      if (!isInitialized) {
        state = {
          ...state,
          filters: emptyFilters(),
          reports: emptyReports(),
        };
      }
      return {
        ...state,
        isLoading: emptyLoading(),
        loadingError,
        isInitialized,
      };
    case REPORTS_LOADING:
      for (let key in isLoading) state.isLoading[key] = isLoading[key];
      return { ...state, loadingError };
    case FILTERS_UPDATED:
      for (let key in filters) state.filters[key] = filters[key];
      return { ...state };
    case REPORTS_UPDATED:
      for (let key in reports) state.reports[key] = reports[key];
      return { ...state };
    case SET_ACTIVE:
      if (state.activeBot?.id !== activeBot?.id) {
        state = {
          ...state,
          filters: emptyFilters(),
          reports: emptyReports(),
          loading: emptyLoading(),
        };
      }
      return { ...state, activeBot };
    default:
      return state;
  }
};

const AnalyticsContext = React.createContext(CMD.initialState);

function AnalyticsProvider({ children }) {
  const { activeBot } = useBots();
  const { isInitialized, isAuthenticated, secureAPIRequest, user } = useAuth();
  const { uid } = user || {};
  const [state, dispatch] = useReducer(AnalyticsReducer, initialState);

  const prepareRequest = ({ name, action, params }) => {
    return [{
      uid: uid,
      timestamp: Date.now(),
      action: action || 'click',
      name: name || 'EV_Unknown_Event',
      params: params || {}
    }]
  }

  const CONSOLE_EVENTS = {
    EV_Wordpress:  // События wordpress 
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_Wordpress", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

    EV_CreateBot:  // Создание бота
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_CreateBot", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

    EV_CreateGPT: // Создание ГПТ бота 
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_CreateGPT", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

    EV_CloneBot: // Клонирование бота 
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_CloneBot", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

    EV_DeleteBot: // Удаление бота 
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_DeleteBot", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

    EV_RenameBot: // Переименование бота 
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_RenameBot", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

    EV_SelectBot: // Выбор бота 
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_SelectBot", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

    EV_CreateSource: // Удаление источника 
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_CreateSource", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

    EV_UpdateSource: // Удаление источника 
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_UpdateSource", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },


    EV_DeleteSource: // Удаление источника 
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_DeleteSource", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },


    EV_Channels: // События каналов 
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_Channels", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

    EV_RunBot: // События каналов 
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_RunBot", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },



    EV_Locate:
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_Locate", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

    EV_Action:
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_Action", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

    EV_GeneralSettings:
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_GeneralSettings", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

    EV_GPTSettings:
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_GPTSettings", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

    EV_Sources:
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_Sources", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

    EV_Templates:
    {
      send: async (params) => {
        let req = prepareRequest({ name: "EV_Templates", ...params });
        await addReports({ request: api.REPORTS.ADD_CONSOLE_EVENTS, events: req });
      }
    },

  }

  // Actualize Session
  // ------------------------
  useEffect(() => {
    (async () => {
      dispatch({
        type: INITIALIZE,
        payload: {
          isInitialized: isInitialized && isAuthenticated,
          loadingError: null,
          activeBot,
        },
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, isAuthenticated]);

  // Actualize activeBot
  // ------------------------
  useEffect(() => {
    dispatch({ type: SET_ACTIVE, payload: { activeBot } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBot]);

  // Loading status
  // ------------------------
  const dispatchLoading = (isLoading, loadingError) =>
    dispatch({
      type: REPORTS_LOADING,
      payload: { isLoading, loadingError },
    });

  // Add users
  // ------------------------
  const addReports = async (props) => {
    const { request } = props;
    switch (request) {
      case api.REPORTS.ADD_USERS:
        return await secureAPIRequest(api.REPORTS.ADD_USERS, props);
      case api.REPORTS.ADD_EVENTS:
        return await secureAPIRequest(api.REPORTS.ADD_EVENTS, props);
      case api.REPORTS.ADD_CONSOLE_EVENTS:
        return await secureAPIRequest(api.REPORTS.ADD_CONSOLE_EVENTS, props);
      default:
        throw new Error(`Unknown reports request (${request})`);
    }
  };

  // Refresh reports
  // ------------------------
  const refreshReports = async (filters) => {
    try {
      dispatchLoading({ all: true });
      const eventNames = await getEventNames(filters);
      getChannels(filters);
      getSessions(filters);
      getUniqueUsers(filters);
      getEventProps(filtersWithEvent(eventNames, filters));
      getEventsConversion(filters);
      getSessionsChannel(filters);
      dispatchLoading({ all: false });
    } catch (e) {
      // logger.error(e);
      dispatchLoading({ all: false }, e);
    }
  };

  // Sessions
  // ------------------------
  const getSessions = async (filters) => {
    try {
      dispatchLoading({ sessions: true });
      setFilters(filters);
      const response = await buildRequest(
        "sessions",
        api.REPORTS.SESSIONS,
        {},
        filters
      );
      dispatchLoading({ sessions: false });
      return response;
    } catch (e) {
      // logger.error(e);
      dispatchLoading({ sessions: false }, e);
    }
  };

  // Channels
  // ------------------------
  const getChannels = async (filters) => {
    try {
      dispatchLoading({ channels: true });
      setFilters(filters);
      const response = await buildRequest(
        "channels",
        api.REPORTS.CHANNELS,
        {},
        filters
      );
      dispatchLoading({ channels: false });
      return response;
    } catch (e) {
      // logger.error(e);
      dispatchLoading({ channels: false }, e);
    }
  };

  // Unique users
  // ------------------------
  const getUniqueUsers = async (filters) => {
    try {
      dispatchLoading({ uniqueUsers: true });
      setFilters(filters);
      const response = await buildRequest(
        "uniqueUsers",
        api.REPORTS.UNIQUE_USERS,
        {},
        filters
      );
      dispatchLoading({ uniqueUsers: false });
      return response;
    } catch (e) {
      // logger.error(e);
      dispatchLoading({ uniqueUsers: false }, e);
    }
  };

  // Events conversion
  // ------------------------
  const getEventsConversion = async (filters) => {
    try {
      dispatchLoading({ eventsConversion: true });
      setFilters(filters);
      const response = await buildRequest(
        "eventsConversion",
        api.REPORTS.EVENTS_CONVERSION,
        {},
        filters
      );
      dispatchLoading({ eventsConversion: false });
      return response;
    } catch (e) {
      // logger.error(e);
      dispatchLoading({ eventsConversion: false }, e);
    }
  };

  // Events names
  // ------------------------
  const getEventNames = async (filters) => {
    try {
      dispatchLoading({ eventNames: true });
      setFilters(filters);
      const response = await buildRequest(
        "eventNames",
        api.REPORTS.EVENT_NAMES,
        {},
        filters,
        {
          [FILTERS.INTERVAL]: true,
          [FILTERS.CHANNELS]: false,
          [FILTERS.EVENT_NAME]: false,
        }
      );
      dispatchLoading({ eventNames: false });
      return response;
    } catch (e) {
      // logger.error(e);
      dispatchLoading({ eventNames: false }, e);
    }
  };

  // Events props
  // ------------------------
  const getEventProps = async (filters) => {
    let value = filters[FILTERS.EVENT_NAME];
    if (!value || value !== state.filters[FILTERS.EVENT_NAME]) {
      dispatch({
        type: REPORTS_UPDATED,
        payload: { reports: { eventProps: [] } },
      });
    }

    const requestArgs = [
      "eventProps",
      api.REPORTS.EVENT_PROPS,
      { unique: true, limit: 10000 },
      filters,
      {
        [FILTERS.INTERVAL]: true,
        [FILTERS.CHANNELS]: true,
        [FILTERS.EVENT_NAME]: true,
      },
    ];

    if (!value) {
      return fakeRequest(...requestArgs);
    }

    try {
      dispatchLoading({ eventProps: true });
      setFilters(filters);
      const response = await buildRequest(...requestArgs);
      dispatchLoading({ eventProps: false });
      return response;
    } catch (e) {
      // logger.error(e);
      dispatchLoading({ eventProps: false }, e);
    }
  };

  // Sessions by channel
  // ------------------------
  const getSessionsChannel = async (filters) => {
    try {
      dispatchLoading({ sessionsChannel: true });
      setFilters(filters);
      const response = await buildRequest(
        "sessionsChannel",
        api.REPORTS.SESSIONS_CHANNEL,
        {},
        filters
      );
      dispatchLoading({ sessionsChannel: false });
      return response;
    } catch (e) {
      // logger.error(e);
      dispatchLoading({ sessionsChannel: false }, e);
    }
  };

  const buildRequest = async (
    reportsKey,
    request,
    params,
    filters,
    include = {
      [FILTERS.INTERVAL]: true,
      [FILTERS.CHANNELS]: true,
      [FILTERS.EVENT_NAME]: false,
    }
  ) => {
    const response = await secureAPIRequest(
      request,
      buildRequestParams(params, filters, { ...include, bid: true })
    );
    const reportsValue = await parseResponse(request, filters, response);
    dispatch({
      type: REPORTS_UPDATED,
      payload: { reports: { [reportsKey]: reportsValue } },
    });
    return reportsValue;
  };

  const buildRequestParams = (params, filters = {}, include = {}) => {
    const requestFilters = [];

    if (include.bid) {
      requestFilters.push({
        field: "bid",
        value: activeBot?.id || "", //bid_0
      });
    }

    if (include[FILTERS.INTERVAL]) {
      let [from, to] = createInterval(
        filters[FILTERS.INTERVAL],
        filters[FILTERS.INTERVAL_VALUES]
      );
      requestFilters.push({
        type: "interval",
        from: from.toISOString(),
        to: to.toISOString(),
      });
      const DAY = 24 * 60 * 60 * 1000;
      const group = to.getTime() - from.getTime() > DAY ? "day" : "hour";
      params.group = group;
      setFilters({ [FILTERS.GROUP]: group });
    }

    if (include[FILTERS.CHANNELS]) {
      const values = filters[FILTERS.CHANNELS];
      if (values.length > 1) {
        requestFilters.push({
          type: "in",
          field: "channel",
          values,
        });
      } else if (values.length > 0) {
        const value = values[0];
        requestFilters.push({
          field: "channel",
          value,
        });
      }
    }

    if (include[FILTERS.EVENT_NAME]) {
      const value = filters[FILTERS.EVENT_NAME];
      params.unique = true;
      requestFilters.push({
        field: "name",
        value,
      });
    }

    params.filters = requestFilters;
    return params;
  };

  const setFilters = (filters = {}) => {
    dispatch({ type: FILTERS_UPDATED, payload: { filters } });
    for (let key in filters) {
      state.filters[key] = filters[key];
    }
    return filters;
  };

  const filtersWithEvent = (eventNames, filters) => {
    const eventName = filters[FILTERS.EVENT_NAME];
    if (!(eventName && eventNames?.find(({ name }) => name === eventName))) {
      filters[FILTERS.EVENT_NAME] =
        (eventNames?.length && eventNames[0].name) || null;
      return setFilters(filters);
    }
    return filters;
  };

  const parseResponse = async (url, filters, response) => {
    // logger.dev("[AnalyticsContext] parseResponse", url, response);

    const { data } = response;
    if (!data) {
      throw new Error(`No data after loading`);
    }

    const { rows } = data;
    if (!Array.isArray(rows)) {
      throw new Error(`Unsupported response data format`);
    }

    const ISOTime = (rows) => {
      return rows
        .filter(({ time }) => typeof time === "string")
        .map((row) => ({
          ...row,
          time: /\.[0-9]+Z$/.test(row.time) ? row.time : `${row.time}.000Z`,
        }));
    };

    const rangeTime = (rows) => {
      const interval = filters[FILTERS.INTERVAL];
      const intervalValues = filters[FILTERS.INTERVAL_VALUES];
      const [from, to] =
        interval === INTERVAL.CUSTOM
          ? intervalValues || []
          : createInterval(interval);
      if (from && to) {
        if (to.getTime() > Date.now()) {
          to.setTime(Date.now());
        }
        if (!rows.find(({ time }) => new Date(time).getTime() <= from)) {
          rows.unshift({ time: from.toISOString(), count: 0 });
        }
        if (!rows.find(({ time }) => new Date(time).getTime() >= to)) {
          rows.push({ time: to.toISOString(), count: 0 });
        }
      }
      return rows;
    };

    const sortTime = (rows) =>
      rows.sort(
        ({ time: a }, { time: b }) =>
          new Date(a).getTime() - new Date(b).getTime()
      );

    const sortCount = (rows) =>
      rows.sort(({ count: a }, { count: b }) => b - a);

    switch (url) {
      // [{name: '']
      case api.REPORTS.CHANNELS:
        return (rows || []).map(({ name }) => name);
      // [{time: '', count: 0}]
      case api.REPORTS.SESSIONS:
      case api.REPORTS.UNIQUE_USERS:
      case api.REPORTS.EVENT_PROPS:
        return sortTime(rangeTime(ISOTime(rows)));
      // [{name: '']
      case api.REPORTS.EVENT_NAMES:
        return rows;
      // [{event: '', value: 0, count: 0, total: 0}]
      case api.REPORTS.EVENTS_CONVERSION:
        if (!rows.length) {
          return [{ event: "No events", value: 0 }];
        }
        return rows;
      // [{channel: '', count: 0}]
      case api.REPORTS.SESSIONS_CHANNEL:
        return sortCount(rows);
      default:
        return null;
    }
  };

  const fakeRequest = async (
    reportsKey,
    request,
    params,
    filters,
    include = {
      [FILTERS.INTERVAL]: true,
      [FILTERS.CHANNELS]: true,
      [FILTERS.EVENT_NAME]: false,
    },
    response = { data: { rows: [] } }
  ) => {
    const reportsValue = await parseResponse(request, filters, response);
    dispatch({
      type: REPORTS_UPDATED,
      payload: { reports: { [reportsKey]: reportsValue } },
    });
    return reportsValue;
  };

  return (
    <AnalyticsContext.Provider
      value={{
        ...state,

        addReports,
        refreshReports,
        getSessions,
        getChannels,
        getUniqueUsers,
        getEventsConversion,
        getSessionsChannel,
        getEventNames,
        getEventProps,
        CONSOLE_EVENTS
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
}

export { AnalyticsProvider, AnalyticsContext };
