import { useCallback } from "react";
import {
  initReactI18next,
  useTranslation as useTranslation_i18next,
} from "react-i18next";

const useTranslation = () => {
  const i18next = useTranslation_i18next();
  const { t } = i18next;
  const tc = useCallback(
    (id, Case) => {
      const text = t(`${id}${Case}`);
      return text !== `${id}${Case}` ? text : t(id);
    },
    [t]
  );
  const tt = useCallback(
    (id, ...args) => {
      let children = [],
        text = t(id),
        i = 0,
        newLines = (t) => {
          if (t.indexOf("\n") < 0) {
            return [t];
          }
          const lines = t.split("\n");
          return lines.map((v, i) => (
            <>
              {v}
              {i < lines.length - 1 && <br key={`br-${i}`} />}
            </>
          ));
        };
      while (i < text.length) {
        if (text.substr(i, 2) === "${") {
          let start = (i += 2),
            end = text.indexOf("}", i);
          if (end < 0) {
            return (
              <>
                ["{id}"]: <span className="text-danger">parse error</span>
              </>
            );
          }
          let index = text.substring(start, end) | 0;
          if (index < 1 || index > 8) {
            return (
              <>
                ["{id}"]: <span className="text-danger">invalid index</span>
              </>
            );
          }
          i = end + 1;
          children.push(args[index - 1]);
          continue;
        }
        let index = text.indexOf("${", i);
        if (index > -1) {
          children.push.apply(
            children,
            newLines(text.substring(i, (i = index)))
          );
          continue;
        }
        children.push.apply(children, newLines(text.substring(i, text.length)));
        break;
      }
      return (
        <>
          {children[0]}
          {children[1]}
          {children[2]}
          {children[3]}
          {children[4]}
          {children[5]}
          {children[6]}
          {children[7]}
          {children[8]}
          {children[9]}
          {children[10]}
          {children[11]}
          {children[12]}
          {children[13]}
          {children[14]}
          {children[15]}
          {children[16]}
        </>
      );
    },
    [t]
  );
  const ts = useCallback((id) => (t(id) === id ? null : t(id)), [t]);
  const tts = useCallback(
    (id, ...args) => (t(id) === id ? null : tt(id, ...args)),
    [t, tt]
  );
  return { ...i18next, tc, tt, ts, tts };
};

export { initReactI18next, useTranslation };
