import {
  Form,
  Container,
  Col,
  Row,
  Button,
  Card,
  FloatingLabel,
  InputGroup,
} from "react-bootstrap";
import { config } from "../../../../config";
import { BOT_CHANNEL } from "../../../../contexts/bots/BotsContext";
import useAuth from "../../../../hooks/useAuth";
import useClipboard from "../../../../hooks/useClipboard";
import provider from "../../../../net/provider";
import wordpress from "../../../../assets/img/brands/wordpress.svg";
import wordpress_colored from "../../../../assets/img/brands/wordpress-colored.svg";
import plugin_icon from "../../../../assets/img/plugins/wordpress.gif";
import screen_1 from "../../../../assets/img/screenshots/channels/screen_wordpress_1.png";
import screen_2 from "../../../../assets/img/screenshots/channels/screen_wordpress_2.png";
import { Copy } from "react-feather";
import ChannelState from "./submit/ChannelState";
import ZoomImage from "../../../ZoomImage";
import { Accordion } from "../../../accordion/Accordion";
import { useTranslation } from "../../../../hooks/useLocalization";

import useAnalytics from "../../../../hooks/useAnalytics";

const WordpressIcon = ({ className }) => (
  <img
    alt="Wordpress"
    src={wordpress}
    width="20"
    height="20"
    className={className}
  />
);

const WordpressColoredIcon = ({ className }) => (
  <img
    alt="Wordpress"
    src={wordpress_colored}
    width="20"
    height="20"
    className={className}
  />
);

const WordpressPlugin = ({ bot, className }) => {
  const { t } = useTranslation();
  const { channels = {} } = config;
  const { plugin = {} } = channels[BOT_CHANNEL.WORDPRESS];
  const { author = {} } = plugin;
  const { CONSOLE_EVENTS } = useAnalytics();
  return (
    <Card className={className}>
      {/* <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Plugin
        </Card.Title>
      </Card.Header> */}
      <Card.Body>
        <Container fluid className="p-0">
          <Row>
            <Col md="auto" className="pe-0">
              <img
                alt="plugin"
                src={plugin_icon}
                width="80"
                height="80"
                className="me-2"
              />
            </Col>
            <Col>
              <h2>{t("QuBot – Chatbot Builder with Templates")}</h2>
              <h4 className="text-muted">
                {t("By")}{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={provider.httpURL(author.link)}
                >
                  {t("QuData")}
                </a>
              </h4>
            </Col>
            <Col md="auto">
              <Button
                type="submit"
                variant="primary"
                onClick={async (e) => {
                  window.open(provider.httpURL(plugin.link), "_blank");
                  await CONSOLE_EVENTS.EV_Wordpress.send({ action: 'get_plugin' });
                }
                }
              >
                {t("Get Plugin")}
              </Button>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

const WordpressConfigure = ({ channel, levels, bot, handlers }) => {
  const { t, tt } = useTranslation();
  const { accessToken } = useAuth();
  const { onCopyEvent } = useClipboard();
  const { CONSOLE_EVENTS } = useAnalytics();
  return (
    <>
      <p>{t("Wordpress.Header")}</p>
      <WordpressPlugin className="card-invert" />
      <Accordion defaultActiveKey="0" className="accordion-steps no-animation">
        <Accordion.Item eventKey="0" className="bg-white">
          <Accordion.Header>{tt("Step N", 1)}</Accordion.Header>
          <Accordion.Body>
            <p>{t("Wordpress.Step-1")}</p>
            <p>{t("Wordpress.Step-1-1")}</p>
            <InputGroup>
              <FloatingLabel label={t("Access Token")} className="flex-grow-1">
                <Form.Control
                  className="mb-3"
                  type="text"
                  rows="4"
                  value={accessToken || ""}
                  readOnly
                />
              </FloatingLabel>
              <Button
                type="submit"
                variant="primary"
                onClick={async (e) => {
                  onCopyEvent(accessToken);
                  await CONSOLE_EVENTS.EV_Wordpress.send({ action: 'copy_key' })
                }}
              >
                <Copy className="feather me-1" />
                {t("Copy")}
              </Button>
            </InputGroup>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{tt("Step N", 2)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Wordpress.Step-2")}</p>
            <ul>
              <li>{t("Wordpress.Step-2-1")}</li>
              <li>
                {t("Wordpress.Step-2-2")}
                <ZoomImage>
                  <img
                    alt="screen_1"
                    src={screen_1}
                    className="m-3 ms-0 mw-100"
                  />
                </ZoomImage>
              </li>
              <li>
                {t("Wordpress.Step-2-3")}
                <ZoomImage>
                  <img
                    alt="screen_2"
                    src={screen_2}
                    className="m-3 ms-0 mw-100"
                  />
                </ZoomImage>
              </li>
            </ul>
            <p>{t("Wordpress.Step-2-4")}</p>
            <ChannelState channel={channel} handlers={handlers} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const WordpressGetHandlers = ({ handlers }) => {
  delete handlers.onEnable;
  return handlers;
};

export {
  WordpressIcon,
  WordpressColoredIcon,
  WordpressPlugin,
  WordpressConfigure,
  WordpressGetHandlers,
};
