import React, { useState } from "react";
import { useRef } from "react";
import { Button, Dropdown, Form, InputGroup } from "react-bootstrap";
import { Clipboard, Search, X } from "react-feather";
import useBots from "../hooks/useBots";
import { useTranslation } from "../hooks/useLocalization";
import TemplatesModal from "../modals/bots/Templates";
import IconBot from "./bots/cards/BotIcon";
import Scrollbar from "./Scrollbar";

const DropdownListBots = ({ className, btnDropdown, handleToggle }) => {
  const { t } = useTranslation();
  const [showTemplates, setShowTemplates] = useState();
  const [showExamples, setShowExamples] = useState();
  const [search, setSearch] = useState("");

  const hideModals = () => {
    setShowTemplates(false);
    setShowExamples(false);
  };

  const searchRef = useRef();

  const focusSearch = () => {
    searchRef.current?.focus();
    searchRef.current?.select();
  };

  const updateSearch = (event) => {
    const text = event.target.value || "";
    setSearch(text);
  };

  const searchFunc = (bot) => {
    let bln = true;
    if (search === "") {
      return !!bln
    } else {
      bln = search !== "" && bot.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
    }
    return !!bln;
  }

  const cancelSearch = () => {
    setSearch("");
  }

  const { bots, activeBot, setBotActive, qubotType } = useBots();
  return (
    <>
      <Dropdown
        className={`nav-item ${className}`}
        align="start"
        onToggle={handleToggle}
      >
        <Dropdown.Toggle className="dropdown-bots-toggle">
          {btnDropdown}
        </Dropdown.Toggle>
        <Dropdown.Menu drop="end" className="dropdown-bots-menu my-bots-header">
          <InputGroup className="input-group-my-bots">
            <Form.Control
              ref={searchRef}
              value={search}
              placeholder={t("Search")}
              onChange={updateSearch}
            />
            <Button variant="" onClick={focusSearch}>
              {(search?.length && (
                <X className="feather" onClick={cancelSearch} />
              )) || <Search className="feather" />}
            </Button>
          </InputGroup>
          <Dropdown.Header>{t("My Bots")}</Dropdown.Header>
          <Scrollbar maxH="25rem">
            {bots
              .filter((bot, i) => qubotType(bot) === "MyBots" && bot.id !== '' && searchFunc(bot))
              .map((bot) => (
                <Dropdown.Item
                  key={bot.id}
                  onClick={() => setBotActive(bot.id)}
                  className={bot.id === activeBot?.id ? "selected" : ""}
                >
                  <IconBot src={bot.iconSrc}></IconBot>
                  <span>{bot.name}</span>
                </Dropdown.Item>
              ))}
          </Scrollbar>
          <div>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() => {
                setShowTemplates(true);
              }}
            >
              <Clipboard className="feather me-2" />
              {t("Templates")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setShowExamples(true);
              }}
            >
              <Clipboard className="feather me-2" />
              {t("Examples")}
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <TemplatesModal
        open={showTemplates}
        setOpen={(value) => {
          setShowTemplates(value);
        }}
        handlers={{ onSelect: hideModals }}
      />
      <TemplatesModal
        title="Examples"
        filter="example"
        open={showExamples}
        setOpen={(value) => {
          setShowExamples(value);
        }}
        handlers={{ onSelect: hideModals }}
      />
    </>
  );
};

export default DropdownListBots;
