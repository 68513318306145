import React from "react";
import { BOT_CHANNEL } from "../../../../contexts/bots/BotsContext";
import { useTranslation } from "../../../../hooks/useLocalization";
import { DropboxIcon } from "../bodies/Dropbox";
import ChannelCard from "./ChannelCard";

const ChannelDropboxCard = (props) => {
  const { t } = useTranslation();
  const { channelName, channel, handlers } = props;
  const { url, validated } = channel;
  return (
    <ChannelCard
      {...props}
      name={t(BOT_CHANNEL.title(channelName))}
      icon={DropboxIcon}
      handlers={{
        ...handlers,
        onLink: url && validated ? handlers.onLink : null,
      }}
    />
  );
};

export default ChannelDropboxCard;
