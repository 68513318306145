import React, { useEffect, useState } from "react";
import { Button, Card, CardGroup } from "react-bootstrap";
import Select from "react-select";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "../hooks/useLocalization";
import useMessage from "../hooks/useMessage";

const AdminRoles = () => {
  const { t } = useTranslation();
  const [selRole, setSelRole] = useState("Choose role!");
  const { showMessageWithDebug } = useMessage();
  const { rolesFetch, permissionsFetch } = useAuth();
  const [allPerms, setAllPerms] = useState();
  const [allRoles, setAllRoles] = useState();
  const [optPerms, setOptPerms] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let roles = await rolesFetch()
        setAllRoles(roles);
        let perms = await permissionsFetch()
        setAllPerms(perms.map((perm) => { return { value: perm.name, label: perm.name } }));
      } catch (error) {
        const { message = t("Something went wrong"), debug } = error;
        showMessageWithDebug({ error: message, debug });
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clickRole = (role) => {
    setSelRole(role);
    let arr = [];

    allRoles?.forEach((item) => {
      if (item.name === role) {
        item.permissions.forEach((perm) => {
          arr.push({ value: perm.name, label: perm.name })
        })
        return arr;
      }
    })

    setOptPerms(arr);
  }

  const onChangeRoles = () => {

  }

  return (
    <Card>
      <h4 className="d-flex justify-content-center mt-3">Change roles</h4>
      <CardGroup className="d-flex justify-content-around my-3">
        <Button onClick={() => clickRole("Super Admin")}>Super Admin</Button>
        <Button onClick={() => clickRole("Admin")}>Admin</Button>
        <Button onClick={() => clickRole("QA Engineer")}>QA Engineer</Button>
        <Button onClick={() => clickRole("Account Manager")}>Account Manager</Button>
      </CardGroup>
      <CardGroup className="d-flex justify-content-center flex-column">
        <h5 className="d-flex justify-content-center">{selRole}</h5>
        <Select
          key={optPerms}
          className="mx-2"
          defaultValue={optPerms}
          closeMenuOnSelect={false}
          isMulti
          options={allPerms}
          onChange={(e) => onChangeRoles(e)}
        >
        </Select>
      </CardGroup>
    </Card>
  )
}

export default AdminRoles;