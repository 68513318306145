import { useState, useRef, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { ChevronLeft, ExternalLink } from "react-feather";
import { useTranslation } from "../../../hooks/useLocalization";
import ChannelDisableModal from "../../../modals/bots/ChannelDisable";
import ProLock from "../../pricing/ProLock";

const ChannelPageTitle = (props) => {
  const { t } = useTranslation();
  const { proPlan, channel, handlers } = props;
  const { validated, enabled: defaultEnabled } = channel;
  const [enabled, setEnabled] = useState(defaultEnabled);
  const { onBack, onEnable, onLink } = handlers;
  const enableRef = useRef();
  const enableBlocked = proPlan && validated;
  const [disableModal, setDisableModal] = useState();

  useEffect(() => {
    setEnabled(defaultEnabled);
    if (enableRef.current) {
      enableRef.current.checked = defaultEnabled;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultEnabled]);

  const renderEnable = (lock) =>
    lock ? (
      <ProLock proPlan={proPlan} type="switch" />
    ) : (
      <Form.Check
        ref={enableRef}
        type="switch"
        id="enabled"
        defaultChecked={enabled}
        disabled={!validated}
        onClick={async (event) => {
          event.stopPropagation();
          const { target } = event;
          const enabled = !!target?.checked;
          if (!enabled) {
            event.preventDefault();
            setDisableModal(true);
            return;
          }
          enableRef.current.checked = enabled;
          await onEnable(enabled);
          setEnabled(enabled);
        }}
      />
    );

  return (
    <>
      <Row>
        <Col>
          <Button
            variant="outline-secondary"
            className="shadow-sm me-2"
            onClick={() => onBack()}
          >
            <ChevronLeft className="feather align-middle" />
            <span>{t("Back")}</span>
          </Button>
        </Col>
        <Col>
          <Row
            xs="auto"
            className="channel-page-title text-end justify-content-end"
          >
            {onEnable &&
              (enableBlocked ? (
                renderEnable(true)
              ) : (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      {enabled
                        ? t("Channel is active")
                        : t("Channel deactivated")}
                    </Tooltip>
                  }
                >
                  {renderEnable()}
                </OverlayTrigger>
              ))}
            {onLink && (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t("Open")}</Tooltip>}
              >
                <Button
                  variant=""
                  className={`text-secondary p-0 ps-3 pe-2 ${
                    enabled ? "" : "opacity-25"
                  }`}
                  disabled={!enabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    onLink();
                  }}
                >
                  <ExternalLink className="feather w-100 h-100" />
                </Button>
              </OverlayTrigger>
            )}
          </Row>
        </Col>
      </Row>
      <ChannelDisableModal
        channelName={channel.name}
        open={disableModal}
        setOpen={setDisableModal}
        onSubmit={async (disable) => {
          if (disable) {
            const enabled = !disable;
            enableRef.current.checked = enabled;
            await onEnable(enabled);
            setEnabled(enabled);
          }
        }}
      />
    </>
  );
};

export default ChannelPageTitle;
