import logger from "../utils/logger";

export const googleTag = (tag_id) => {
  try {
    const report = window.gtag_report_conversion;
    if (typeof report === "function") {
      report(null, tag_id);
    } else {
      logger.error("gtag_report_conversion is undefined");
    }
  } catch (err) {
    logger.error(err);
  }
};

export const googleTagNavigate = (tag_id, url) => {
  try {
    const report = window.gtag_report_conversion;
    if (typeof report === "function") {
      report(url, tag_id);
    } else {
      logger.error("gtag_report_conversion is undefined");
    }
  } catch (err) {
    logger.error(err);
  }
};
