import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    Form,
    Table,
    ButtonGroup,
    Pagination,
    Popover,
    OverlayTrigger
} from "react-bootstrap";
import { SOURCE_TYPES, SOURCE_STATUS } from "../../../contexts/sources/SourcesContext";
import useBots from "../../../hooks/useBots";
import useSources from "../../../hooks/useSources";
import { useTranslation } from "../../../hooks/useLocalization";
import { useTable, usePagination } from "react-table";
import { useLocaleDateString } from "../../../utils/data";


import DeleteSourceModal from "../../../modals/sources/DeleteSource";

import {
    Edit,
    Trash2,
    ExternalLink,
    RefreshCw,
    HelpCircle
} from "react-feather";

import useQubot from "../../../hooks/useQubot";

import useAnalytics from "../../../hooks/useAnalytics";

const PaginationTable = () => {
    const { sources: data, tableColumns: columns, updateEmbeddings } = useSources()
    const { t } = useTranslation();
    const localeDateString = useLocaleDateString();
    const [showModal, setShowModal] = useState();
    const [deletedSource, setDeletedSource] = useState();
    const [activePage, setActivePage] = useState(0);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: activePage },
        },
        usePagination
    );
    const { CONSOLE_EVENTS } = useAnalytics();

    const checks = useRef([])

    const getStatusColor = (status, hint = '') => {
        let className = ''
        let content = <></>
        let title = t(SOURCE_STATUS.getTitle(status))
        switch (status) {
            case SOURCE_STATUS.SUCCESS:
                className = 'text-success'
                hint = t('Sources Success Hint')
                break
            case SOURCE_STATUS.PARSING:
                className = 'text-info'
                hint = t('Sources Parsing Hint')
                break
            case SOURCE_STATUS.RECEIVED:
                className = 'text-info'
                hint = t('Sources Received Hint')
                break
            case SOURCE_STATUS.PROCESS:
                className = 'text-info'
                hint = t('Sources Process Hint')
                break
            case SOURCE_STATUS.ERROR:
                className = 'text-danger'
                content = <HelpCircle style={{ marginLeft: '.2rem', opacity: '.85' }} size={14}></HelpCircle>
                break
            default:
                className = ''
        }
        if (hint.length > 0)
            return <>
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                            <Popover.Body className={className} style={{ cursor: 'default' }}>{hint}</Popover.Body>
                        </Popover>
                    }
                >
                    <span className={className + ' d-flex align-items-center justify-content-center'} style={{ cursor: 'help', whiteSpace: 'nowrap' }}>
                        {title}{content}</span>
                </OverlayTrigger>
            </>
        else
            return <>
                <span className={className} style={{ cursor: 'default' }}>{title}</span>
            </>
    }

    const getTypeText = (type) => {
        let className = ''
        let title = t(SOURCE_TYPES.getTitle(type))
        return <>
            <span className={className} style={{ cursor: 'default' }}>{title}</span>
        </>
    }

    const getTime = (timestamp) => {
        return <span style={{ cursor: 'default' }}>{localeDateString(timestamp)}</span>
    }

    const generateHeader = (column) => {
        switch (column.Header) {
            case 'Check':
                return <Form.Check></Form.Check>
            case 'Order':
                return t('N')
            case 'Actions1':
                return <ButtonGroup>
                    <button className="btn btn-outline-primary" disabled={data.length === 0}>
                        <RefreshCw style={{ transform: 'scale(0.8)' }} />
                    </button>
                    <button className="btn btn-outline-primary" disabled={disabled}>
                        <ExternalLink style={{ transform: 'scale(0.8)' }} />
                    </button>
                    <button className="btn btn-outline-danger" disabled={disabled}>
                        <Trash2 style={{ transform: 'scale(0.8)' }} />
                    </button>
                </ButtonGroup>
            default:
                return t(column.render('Header'))
        }
    }

    const generateCell = (cell, n) => {
        switch (cell.column.Header) {
            case 'Check':
                return <Form.Check></Form.Check>
            case 'Order':
                return <span style={{ cursor: 'default' }}>{(n + 1) + activePage * pageSize}</span>
            case 'Actions':
                return <ButtonGroup>
                    <OverlayTrigger placement='top'
                        overlay={
                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                <Popover.Body style={{ cursor: 'default' }}>{t('Refresh source')}</Popover.Body>
                            </Popover>
                        }
                    >
                        <button className="btn btn-outline-primary"
                            onClick={async () => {
                                await CONSOLE_EVENTS.EV_UpdateSource.send({ params: { sid: cell.value } })
                                updateEmbeddings(cell.value);
                            }
                            }>
                            <RefreshCw style={{ transform: 'scale(0.8)' }} />
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger placement='top'
                        overlay={
                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                <Popover.Body style={{ cursor: 'default' }}>{t('Edit source')}</Popover.Body>
                            </Popover>
                        }
                    >
                        <button className="btn btn-outline-primary" disabled={true}>
                            <Edit style={{ transform: 'scale(0.8)' }} />
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger placement='top'
                        overlay={
                            <Popover className="clearPopover" id={`popover-positioned-bottom`}>
                                <Popover.Body style={{ cursor: 'default' }}>{t('Remove source')}</Popover.Body>
                            </Popover>
                        }
                    >
                        <button className="btn btn-outline-danger" onClick={async () => {
                            await CONSOLE_EVENTS.EV_DeleteSource.send({ params: { sid: cell.value } })
                            setDeletedSource(cell.value);
                            setShowModal(true);
                        }}>
                            <Trash2 style={{ transform: 'scale(0.8)' }} />
                        </button>
                    </OverlayTrigger>
                </ButtonGroup>
            case 'Type':
                return getTypeText(cell.value)
            case 'Status':
                return getStatusColor(cell.value, data[n]?.error)
            case 'Create At': case 'Update At':
                return getTime(cell.value)
            case 'Description':
                if (cell.value) {
                    let postfix = (cell.value?.length > 255) ? '...' : ''
                    return <span style={{ cursor: 'default' }}>{t(cell.value.substring(0, 255) + postfix)}</span>
                } return ''
            case 'Tag':
                if (data[n]?.data.link)
                    return <span style={{ cursor: 'default' }}><a href={data[n]?.data.link}>{t(cell.value)}</a></span>
                else if (data[n]?.data.mimetype && data[n]?.data.data) {
                    return <span style={{ cursor: 'default' }}>
                        <a download={data[n]?.data.name || 'undefined'} href={`data:${data[n]?.data.mimetype};base64,` + data[n]?.data.data}>{t(cell.value)}</a>
                    </span>
                } else
                    return <span style={{ cursor: 'default' }}>{t(cell.value)}</span>
            default:
                return <span style={{ cursor: 'default' }}>{t(cell.value)}</span>
        }
    }

    let disabled = checks.current.length === 0 || checks.current.reduce((a, i) => a + i) === 0;
    return <>
        <DeleteSourceModal
            source={{ id: deletedSource }}
            open={showModal}
            setOpen={setShowModal}
            onComplete={() => {
                setShowModal(false);
                if (page.length - 1 === 0 && activePage > 0)
                    setActivePage(activePage - 1)
            }}
        />
        <Card>
            <Card.Body>
                <Table striped bordered {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {generateHeader(column)}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {generateCell(cell, i, data[i])}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                <div className="d-flex justify-content-between">
                    <div className="d-flex">
                        <Pagination className="me-2" style={{ margin: '0' }}>
                            <Pagination.First
                                onClick={() => { setActivePage(0); gotoPage(0); }}
                                disabled={!canPreviousPage}
                                className="btn-lg p-0"
                            />
                            <Pagination.Prev
                                onClick={() => { setActivePage(activePage - 1); previousPage(); }}
                                disabled={!canPreviousPage}
                                className="btn-lg p-0"
                            />
                            <Pagination.Next
                                onClick={() => { setActivePage(activePage + 1); nextPage(); }}
                                disabled={!canNextPage}
                                className="btn-lg p-0"
                            />
                            <Pagination.Last
                                onClick={() => { setActivePage(pageCount - 1); gotoPage(pageCount - 1); }}
                                disabled={!canNextPage}
                                className="btn-lg p-0"
                            />
                        </Pagination>
                        <span className="ms-3 me-2 d-flex align-items-center">{t('Show')}:</span>
                        <Form.Select
                            className="d-inline-block w-auto me-2"
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                                setActivePage(0)
                            }}
                            disabled={(data.length < 11)}
                        >
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </Form.Select>

                    </div>
                    <div className="d-flex">
                        <span className="ms-3 me-2 d-flex align-items-center">{t('Page')}:</span>
                        <Form.Control
                            className="d-inline-block"
                            type="number"
                            value={activePage + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                setActivePage(page)
                                gotoPage(page);
                            }}
                            min={1}
                            max={pageOptions.length}
                            style={{ width: "75px" }}
                            disabled={(pageOptions.length < 2)}
                        />
                        <span className="ms-2 me-2 d-flex align-items-center"><strong>{t('of')} {pageOptions.length || 1}</strong></span>
                    </div>
                    <div className="ms-2" style={{ width: '264px' }}></div>
                </div>
            </Card.Body>
        </Card>
    </>;
};

const GPTSources = () => {
    const { activeBot, qubotType } = useBots();
    const { id, lang, } = activeBot || {};
    const qubot = useQubot();
    const type = qubotType(activeBot);

    useEffect(() => {
        qubot?.showChat({ botID: id, type }, { start: false, lang: lang });
    }, [qubot, id, lang, type]);

    useEffect(() => {
        return () => !qubot?.isPageHasChat() && qubot?.hideChat();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        <PaginationTable />
    </>;
}

export default GPTSources;