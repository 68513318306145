import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Alert, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { atDevhost } from "../../../system/system";

const NoPermissionsAlert = ({ tooltip, clearTooltip, onRetry }) => (
  <Alert variant="warning">
    <div className="alert-icon">
      <FontAwesomeIcon icon={faBell} fixedWidth />
    </div>
    <div className="alert-message">
      To get permission to read files from disk {""}
      <OverlayTrigger
        show={!!tooltip}
        placement="top"
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        <a
          rel="noreferrer"
          href="/dashboard/bots"
          className="text-muted"
          onClick={async (e) => {
            e.preventDefault();
            if (clearTooltip) clearTooltip();
            await onRetry();
          }}
        >
          <strong>Click here</strong>
        </a>
      </OverlayTrigger>
      .
    </div>
  </Alert>
);

const ReadDirAlert = ({ error, tooltip, clearTooltip, onRetry }) => {
  const [loading, setLoading] = useState();
  return (
    <Alert variant="danger">
      <div className="alert-icon">
        <FontAwesomeIcon icon={faBell} fixedWidth />
      </div>
      <div className="alert-message">
        {loading ? (
          <div className="context-icon">
            <Spinner animation="border" variant="secondary" className="me-2" />
          </div>
        ) : (
          <>
            Unable to read the selected directory,{" "}
            <OverlayTrigger
              show={!!tooltip}
              placement="top"
              overlay={<Tooltip>{tooltip}</Tooltip>}
            >
              <a
                rel="noreferrer"
                href="/dashboard/bots"
                className="text-muted"
                onClick={(e) => {
                  e.preventDefault();
                  if (clearTooltip) clearTooltip();
                  setLoading(true);
                  setTimeout(async () => {
                    setLoading(false);
                    await onRetry();
                  }, 100);
                }}
              >
                <strong>Try again</strong>.
              </a>
            </OverlayTrigger>
            {atDevhost() && (
              <>
                <br />
                <small>{error.message}</small>
              </>
            )}
          </>
        )}
      </div>
    </Alert>
  );
};

export { NoPermissionsAlert, ReadDirAlert };
