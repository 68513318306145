import React, { useCallback, useMemo } from "react";

import {
  Col,
  Row,
  Card,
  Container,
  InputGroup,
  Button,
  Form,
  ProgressBar,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Folder, UploadCloud } from "react-feather";
import FilesTable from "./FilesTable";
import useContextMenu from "../../../hooks/useContextMenu";
import FileBadge from "./FileBadge";
import useBots from "../../../hooks/useBots";
import { NoPermissionsAlert, ReadDirAlert } from "./FilesAlert";
import {
  getBadgeRendererProps,
  getColumnClassName,
  getColumnStyle,
  getOptionByFile,
  getOptionContextMenuProps,
  getRowStyle,
  getTableData,
  isColumnRenderedAsBadge,
} from "./utils";
import useMessage from "../../../hooks/useMessage";
import useOffcanvas from "../../../hooks/useOffcanvas";

const BotFilesLocal = ({ required, clearRequired }) => {
  const {
    activeBot,
    chooseBotDirectory,
    requestLocalFiles,
    updateBotLocalFiles,
    setBotParam,
    setBotFileParam,
  } = useBots();
  const {
    id,
    uploadMode,
    localDirectory,
    hasPermissions,
    readdirError,
    isUpdating,
    updateError,
    updateErrorDebug,
    updatePercent,
  } = activeBot || {};
  const { showMessage, showMessageWithDebug, updateMessageDebug } =
    useMessage();
  const { showContextMenu } = useContextMenu();
  const { setOffcanvasData } = useOffcanvas();

  const isCreate = id === "";
  const uploadEnabled =
    localDirectory && hasPermissions && !readdirError && !isCreate;
  const uploadModeAuto = uploadMode === "auto";

  const onCellClick = useCallback(
    (cell) => {
      if (clearRequired) clearRequired();
      getOptionByFile(cell.row.values.path) &&
        showContextMenu(
          getOptionContextMenuProps({
            cell,
            onClick: async () => {
              const option = getOptionByFile(cell.row.values.path);
              await setBotFileParam(id, cell.row.values.path, { option });
            },
          })
        );
    },
    [id, setBotFileParam, showContextMenu, clearRequired]
  );

  const onChooseDirectoryClick = async () => {
    if (clearRequired) clearRequired();
    await chooseBotDirectory(id);
  };

  const onUploadClick = async () => {
    try {
      if (await updateBotLocalFiles(id)) {
        showMessage({ save: "Your files have been uploaded successfully" });
      } else {
        showMessage({ save: "The files are up to date" });
      }
      updateMessageDebug({
        debug: null,
        fallback: { level: "success", text: "No errors" },
      });
    } catch (error) {
      const { message = "Something went wrong", debug } = error;
      if (
        !updateMessageDebug({
          debug,
          fallback: { level: "error", text: message },
        })
      ) {
        showMessageWithDebug({ error: message, debug });
      }
    }
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: "Option",
        accessor: "option",
        disableSortBy: true,
      },
      {
        Header: "Path",
        accessor: "path",
      },
      {
        Header: "Size",
        accessor: "size",
        breakpoint: "lg",
      },
      // {
      //   Header: "Modified date",
      //   accessor: "date",
      //   breakpoint: "lg",
      //   disableSortBy: true,
      // },
      {
        Header: "Status",
        accessor: "status",
        breakpoint: "sm",
      },
    ],
    []
  );

  const tableData = useMemo(
    () => activeBot && getTableData(activeBot),
    [activeBot]
  );

  const progressVariant = updateError
    ? "danger"
    : updatePercent === 100
    ? "success"
    : isUpdating
    ? "primary"
    : "secondary";

  const progressLabel = updateError
    ? "Uploading error."
    : updatePercent === 100
    ? "Uploading complete."
    : isUpdating
    ? `${updatePercent | 0}%`
    : "";

  const dropdownTitle = uploadModeAuto
    ? isCreate
      ? "Auto upload"
      : "Auto"
    : isCreate
    ? "Upload mode"
    : "Mode";

  const dropdownVariant = uploadModeAuto
    ? updateError
      ? "outline-danger"
      : "success"
    : "outline-primary";

  return (
    <Container fluid className="p-0">
      <Card>
        <Card.Header>
          <Card.Title tag="h5" className="mb-0">
            Local directory
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md="7">
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <Folder className="feather text-muted" />
                </InputGroup.Text>
                <Form.Control
                  readOnly
                  id="directory"
                  name="directory"
                  placeholder="Directory not selected *"
                  className="cursor-text"
                  value={localDirectory || ""}
                  onClick={onChooseDirectoryClick}
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup className="mb-3">
                <OverlayTrigger
                  show={!!required?.directory}
                  placement="top"
                  overlay={<Tooltip>{required?.directory}</Tooltip>}
                >
                  <Button
                    variant="outline-primary"
                    className="w-100"
                    onClick={onChooseDirectoryClick}
                  >
                    Choose Directory
                  </Button>
                </OverlayTrigger>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col md="7">
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <UploadCloud className="feather text-muted" />
                </InputGroup.Text>
                <ProgressBar
                  striped
                  variant={progressVariant}
                  now={updatePercent}
                  label={progressLabel}
                  animated={!!isUpdating}
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup className="mb-3">
                <ButtonGroup className="w-100">
                  <Button
                    hidden={isCreate}
                    variant={
                      uploadModeAuto && updateError
                        ? "primary"
                        : "outline-primary"
                    }
                    disabled={
                      !uploadEnabled || (uploadModeAuto && !updateError)
                    }
                    onClick={onUploadClick}
                  >
                    Upload
                  </Button>

                  <DropdownButton
                    as={ButtonGroup}
                    id="bg-nested-dropdown"
                    title={dropdownTitle}
                    variant={dropdownVariant}
                  >
                    <Dropdown.Item
                      onClick={() =>
                        setBotParam(id, {
                          uploadMode: uploadModeAuto ? void 0 : "auto",
                        })
                      }
                    >
                      {uploadModeAuto
                        ? "Turn off auto upload"
                        : "Enable auto upload"}
                    </Dropdown.Item>
                  </DropdownButton>
                </ButtonGroup>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            {updateError && updateErrorDebug && (
              <Form.Text className="text-muted">
                To see the error details{" "}
                <a
                  rel="noreferrer"
                  href="/dashboard/bots"
                  className="text-muted"
                  onClick={async (e) => {
                    e.preventDefault();
                    setOffcanvasData({
                      title: "More details",
                      debug: updateErrorDebug,
                    });
                  }}
                >
                  <strong>Click here</strong>
                </a>
                .
              </Form.Text>
            )}
          </Row>
          <Row>
            <Col xl>
              {localDirectory &&
                ((!hasPermissions && (
                  <NoPermissionsAlert
                    onRetry={async () => await requestLocalFiles(id)}
                    tooltip={required?.permissions}
                    clearTooltip={clearRequired}
                  />
                )) ||
                  (readdirError && (
                    <ReadDirAlert
                      error={readdirError}
                      onRetry={async () => await requestLocalFiles(id)}
                      tooltip={required?.permissions}
                      clearTooltip={clearRequired}
                    />
                  )))}
            </Col>
          </Row>
        </Card.Body>
        {localDirectory && hasPermissions && !readdirError && (
          <>
            <OverlayTrigger
              show={!!required?.files}
              placement="top"
              overlay={<Tooltip>{required?.files}</Tooltip>}
            >
              <div />
            </OverlayTrigger>
            <FilesTable
              columns={tableColumns}
              data={tableData}
              getHeaderProps={(column) => ({})}
              getColumnProps={(column) => ({
                className: getColumnClassName(tableColumns, column),
                style: getColumnStyle(tableColumns, column),
              })}
              getRowProps={(row) => ({
                style: getRowStyle(row),
              })}
              getCellProps={(cell) => ({
                onClick: () => onCellClick(cell),
                className: getColumnClassName(tableColumns, cell.column),
                // style: { color: getStatusColor(cell.row.values.status) },
              })}
              getCellRenderer={(cell) =>
                isColumnRenderedAsBadge(cell.column) && FileBadge
              }
              getCellRendererProps={(cell) =>
                isColumnRenderedAsBadge(cell.column) &&
                getBadgeRendererProps(cell)
              }
            />
          </>
        )}
      </Card>
    </Container>
  );
};

export { BotFilesLocal };
