
const INITIALIZE = "INITIALIZE";
const APPEND = "APPEND";
const UPDATE = "UPDATE";
const DELETE = "DELETE";

const initialState = {
  sources: [],
  tableColumns: [],
  loadingError: null
};

const SourcesReducer = (state, action) => {
  // logger.dev("[SourcesReducer]", action.type);
  const { sources, loadingError, tableColumns } = action.payload || {};
  let old_sources = state.sources;
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        sources,
        tableColumns,
        loadingError,
        isInitialized: true,
        isLoading: false,
      };
    case APPEND:
      sources.forEach(source => {
        old_sources.push(source);
      });
      return {
        ...state,
        old_sources,
        tableColumns,
        loadingError,
      };
    case UPDATE:
      if (sources.length > 0) {
        let ind = old_sources.findIndex(source => source.id === sources[0].id);
        old_sources[ind] = sources[0];
      }
      return {
        ...state,
        old_sources,
        tableColumns,
        loadingError,
      };
    case DELETE:
      if (sources.length > 0) {
        let ind = old_sources.findIndex(source => source.id === sources[0].id);
        old_sources.splice(ind, 1);
      }
      return {
        ...state,
        old_sources,
        tableColumns,
        loadingError,
      };
    default:
      return state;
  }
};

export {
  INITIALIZE,
  APPEND,
  UPDATE,
  DELETE,
  initialState,
  SourcesReducer,
};
