import { Formik } from "formik";
import { useEffect, useRef } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import useBots from "../../hooks/useBots";
import * as Yup from "yup";
import { scrollIntoView } from "../../utils/scroll";
import useMessage from "../../hooks/useMessage";
import useClipboard from "../../hooks/useClipboard";
import logger from "../../utils/logger";
import { useTranslation } from "../../hooks/useLocalization";

import useAnalytics from "../../hooks/useAnalytics";

const CreateBot = ({ name = "", description = "", setOnSubmit, onComplete, }) => {
  const { t, tt } = useTranslation();
  const { CONSOLE_EVENTS } = useAnalytics();
  const submitRef = useRef();
  const { onCopyEvent } = useClipboard("Bot bundle");
  const { showMessageWithDebug } = useMessage();
  const { createBot, generateBundle } = useBots();
  // const [required, setRequired] = useState({});
  const nameRef = useRef();
  useEffect(() => nameRef.current?.focus(), [nameRef]);

  useEffect(() => {
    if (setOnSubmit) {
      setOnSubmit(() =>
        submitRef.current?.dispatchEvent(new MouseEvent("click"))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ name, description }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .matches(
              /^[aA-zZ\s0-9_()]+$/g,
              t("Must contain only Latin characters")
            )
            .min(5, tt("Must be at least N characters", 5))
            .max(150),
          // .required("Bot name is required"),
          description: Yup.string().max(255),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          ({ values } = {
            values: { ...values, bundle: generateBundle(values.name) },
          });


          // const {
          //   localDirectory,
          //   localFilesStatus,
          //   hasPermissions,
          //   readdirError,
          // } = tempBot || {};

          // if (!localDirectory) {
          //   setRequired({
          //     directory: "Please select a local directory.",
          //   });
          //   return;
          // }

          // if (!hasPermissions || readdirError) {
          //   setRequired({
          //     permissions: "Access to the file system is required.",
          //   });
          //   return;
          // }

          // if (
          //   !localFilesStatus.find(
          //     ({ option }) => option === BOT_FILE_OPTION.MAIN
          //   )
          // ) {
          //   setRequired({
          //     files:
          //       "Please select a main YML file. To do this, click the line with the YML file and select an option.",
          //   });
          //   return;
          // }

          try {
            scrollIntoView("name");
            const bot = await createBot({ ...values });
            // showMessage({ save: "Bot successfully created" });
            onComplete && onComplete(bot);
            await CONSOLE_EVENTS.EV_CreateBot.send({ action: 'complete', params: { bid: bot.id } })
          } catch (error) {
            logger.error(error);
            const { message = t("Something went wrong"), debug } = error;
            showMessageWithDebug({ error: message, debug });
            setErrors({ submit: message });
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, }) => (
          <Form onSubmit={handleSubmit}>
            {/* {errors.submit && (
                  <Alert className="align-items-center" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )} */}
            <Row>
              <Col xl md="6">
                <Form.Group className="mb-3">
                  <FloatingLabel label={`${t("Bot name")} *`}>
                    <Form.Control
                      required
                      id="name"
                      type="text"
                      name="name"
                      placeholder={`${t("Bot name")} *`}
                      ref={nameRef}
                      value={values.name}
                      isInvalid={Boolean(touched.name && errors.name)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.name && (
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col>
                <OverlayTrigger placement="bottom"
                  overlay={<Tooltip>{t("Bundle is generated automatically")}</Tooltip>} >
                  <Form.Group className="mb-3">
                    <FloatingLabel label={t("Bot bundle")}>
                      <Form.Control
                        readOnly
                        id="bundle"
                        type="text"
                        name="bundle"
                        placeholder={t("Bot bundle")}
                        className="cursor-copy"
                        value={generateBundle(values.name) || ""}
                        onClick={onCopyEvent}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </OverlayTrigger>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <FloatingLabel label={t("Description")}>
                <Form.Control
                  as="textarea"
                  id="description"
                  type="text"
                  name="description"
                  placeholder={t("Description")}
                  value={values.description}
                  isInvalid={Boolean(touched.description && errors.description)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.description && (
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Form.Group>

            <div className="text-center mt-3 w-100 d-grid">
              <Button hidden type="submit" variant="primary" ref={submitRef}
                disabled={isSubmitting}
              > {t("Create Bot")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {/* <BotFilesLocal
        className="mb-0"
        required={required}
        clearRequired={() => setRequired({})}
      /> */}
    </>
  );
};

export default CreateBot;
