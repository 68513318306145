import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import RenameBot from "../../components/bots/RenameBot";
import Scrollbar from "../../components/Scrollbar";
import { useTranslation } from "../../hooks/useLocalization";

import useAnalytics from "../../hooks/useAnalytics";

const RenameBotModal = ({ bot, open, setOpen, onComplete }) => {
  const { t } = useTranslation();
  const { CONSOLE_EVENTS } = useAnalytics();
  const { id, name, description, isUpdating } = bot || {};
  const [createBotBridge, setOnSubmit] = useState({ onSubmit: null });
  const onClose = async () => {
    setOpen(false);
    await CONSOLE_EVENTS.EV_RenameBot.send({ action: 'close' })
  }
  return (
    <Modal show={open} onHide={onClose} size="lg" enforceFocus={false}>
      <Helmet title={t("Rename Bot")} />
      <Modal.Header closeButton>{t("Rename")}</Modal.Header>
      <div className="modal-body-container ps-dark">
        <Scrollbar>
          <Modal.Body className="m-4">
            <RenameBot
              id={id}
              name={name}
              description={description}
              setOnSubmit={(onSubmit) => setOnSubmit({ onSubmit })}
              onComplete={(bot) => {
                setOpen(false);
                onComplete && onComplete(bot);
              }}
            />
          </Modal.Body>
        </Scrollbar>
      </div>
      <Modal.Footer>
        <Button disabled={!!isUpdating} variant="secondary" onClick={onClose}>
          {t("Close")}
        </Button>
        <Button
          disabled={!!isUpdating}
          variant="primary"
          onClick={createBotBridge.onSubmit}
        >
          {t("Rename Bot")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RenameBotModal;
