import { Button, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import { Copy } from "react-feather";
import google from "../../../../assets/img/brands/google-sheets.png";
import screen_1 from "../../../../assets/img/screenshots/channels/screen_google_sheets_00.png";
import screen_2 from "../../../../assets/img/screenshots/channels/screen_google_sheets_01.png";
import screen_3 from "../../../../assets/img/screenshots/channels/screen_google_sheets_02.png";
import screen_4 from "../../../../assets/img/screenshots/channels/screen_google_sheets_03.png";
import screen_5 from "../../../../assets/img/screenshots/channels/screen_google_sheets_04.png";
import screen_6 from "../../../../assets/img/screenshots/channels/screen_google_sheets_05.png";
import useClipboard from "../../../../hooks/useClipboard";
import { useTranslation } from "../../../../hooks/useLocalization";
import { Case } from "../../../../languages/languages";
import { Accordion } from "../../../accordion/Accordion";
import ZoomImage from "../../../ZoomImage";
import ChannelLinks from "./submit/ChannelLinks";

const GoogleSheetsIcon = ({ className }) => (
  <img
    src={google || ""}
    alt=""
    width={20}
    height={20}
    className={className}
    style={{ transform: "scale(0.9)" }}
  />
);

const GoogleSheetsLogin = () => {
  const { tt } = useTranslation();
  return (
    <>
      <ZoomImage>
        <img alt="screen_1" src={screen_1} className="m-3 ms-0 mw-100" />
      </ZoomImage>
      {tt(
        "GoogleSheets.Step-1-1",
        <a
          href="https://accounts.google.com"
          alt="google"
          target="_blank"
          rel="noreferrer"
        >
          https://accounts.google.com
        </a>
      )}
    </>
  );
};

const GoogleSheetsOpenApp = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      {tt(
        "GoogleSheets.Step-2-1",
        <a
          href="https://www.google.com/"
          alt="google"
          target="_blank"
          rel="noreferrer"
        >
          {t("Google homepage")}
        </a>
      )}
      <Row>
        <ZoomImage>
          <img alt="screen_2" src={screen_2} className="m-3 ms-0 mw-100" />
        </ZoomImage>
        <ZoomImage>
          <img alt="screen_3" src={screen_3} className="m-3 ms-0 mw-100" />
        </ZoomImage>
      </Row>
    </>
  );
};

const GoogleSheetsShare = () => {
  const { t, tt } = useTranslation();
  const { onCopyEvent } = useClipboard();
  const account = "qudata-bot-service@qudata-services.iam.gserviceaccount.com";
  return (
    <>
      <ul>
        <li>
          {tt("GoogleSheets.Step-3-1", <b>{t("Share")}</b>)}
          <ZoomImage>
            <img alt="screen_4" src={screen_4} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
        <li>
          <p>{tt("GoogleSheets.Step-3-2", <b>{t("First way")}</b>)}</p>
          <InputGroup>
            <FloatingLabel label={t("Account")} className="flex-grow-1">
              <Form.Control
                className="mb-3"
                type="text"
                rows="4"
                value={account || ""}
                readOnly
              />
            </FloatingLabel>
            <Button
              type="submit"
              variant="primary"
              onClick={(e) => onCopyEvent(account)}
            >
              <Copy className="feather me-1" />
              {t("Copy")}
            </Button>
          </InputGroup>
          <ZoomImage>
            <img alt="screen_5" src={screen_5} className="m-3 ms-0 mw-100" />
          </ZoomImage>
          {tt(
            "GoogleSheets.Step-3-3",
            <b>{t("Second way")}</b>,
            <b>{t("Get link")}</b>,
            <b>{t("Restricted")}</b>,
            <b>{t("Anyone with the link")}</b>
          )}
          <ZoomImage>
            <img alt="screen_6" src={screen_6} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
        <p>
          {tt(
            "GoogleSheets.Step-3-4",
            <b>{t("Viewer")}</b>,
            <b>{t("Editor")}</b>
          )}
        </p>
      </ul>
    </>
  );
};

const GoogleSheetsVerify = ({ id, channel, levels }) => {
  const { t } = useTranslation();
  const { links } = channel || {};
  const someErrors = links?.some(({ error }) => !!error);
  return (
    <>
      {t("GoogleSheets.Step-4-1")}
      <div className="mt-4">
        <ChannelLinks
          links={links}
          emptyMessage={t("there are no spreadsheets used in a bot")}
        />
        {someErrors && (
          <p className="lead text-danger">{t("GoogleSheets.Step-4-2")}</p>
        )}
      </div>
    </>
  );
};

const GoogleSheetsConfigure = ({ channel, levels, bot, handlers }) => {
  const { t, tt, tc } = useTranslation();
  const { id } = bot || {};
  return (
    <>
      <p>{tt("GoogleSheets.Header-1")}</p>
      <p>{tt("GoogleSheets.Header-2")}</p>
      <Accordion defaultActiveKey="0" className="accordion-steps no-animation">
        <Accordion.Item eventKey="0" className="bg-white">
          <Accordion.Header>{tt("Step N", 1)}</Accordion.Header>
          <Accordion.Body>
            <p>
              {tt(
                "GoogleSheets.Step-1",
                <a
                  href="https://accounts.google.com"
                  alt="google"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://accounts.google.com
                </a>
              )}
            </p>
            <GoogleSheetsLogin />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{tt("Step N", 2)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>
              {tt(
                "GoogleSheets.Step-2",
                <b>{t("Google Sheets")}</b>,
                <a
                  href="https://docs.google.com/spreadsheets"
                  alt="google"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://docs.google.com/spreadsheets
                </a>
              )}
            </p>
            <GoogleSheetsOpenApp />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{tt("Step N", 3)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>
              {tt(
                "GoogleSheets.Step-3",
                <b>{tc("Google Spreadsheet", Case.Instrumental)}</b>
              )}
            </p>
            <GoogleSheetsShare />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{tt("Step N", 4)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("GoogleSheets.Step-4")}</p>
            <GoogleSheetsVerify id={id} channel={channel} levels={levels} />
            {/* <ChannelState channel={channel} handlers={handlers} /> */}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const GoogleSheetsGetHandlers = ({ url, validated, handlers }) => {
  if (validated && url) {
    handlers = {
      ...handlers,
      onLink: () => {
        window.open(url, "_blank");
      },
    };
  }
  return handlers;
};

export { GoogleSheetsIcon, GoogleSheetsConfigure, GoogleSheetsGetHandlers };
