import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
  Button,
} from "react-bootstrap";
import { RefreshCw, RotateCw } from "react-feather";
import RefreshAlert from "../RefreshAlert";
import useExtensions from "../../../hooks/useExtensions";
import {
  createInterval,
  FILTERS,
  dateToString,
  INTERVAL,
} from "../../../contexts/bots/filters";
import AntiSpam from "../../../utils/spam";
import useUI from "../../../hooks/useUI";
import { useTranslation } from "../../../hooks/useLocalization";
import Navigation from "../../Navigation";
import ColumnSortingTable from "../../tables/ColumnSortingTable";
import {
  sortableDateString,
  sortableTimeString,
  uniqueArray,
} from "../../../utils/data";
import useBots from "../../../hooks/useBots";

const antiSpam = new AntiSpam();

const BotData = () => {
  const { t } = useTranslation();
  const { activeBot } = useBots();
  const { id } = activeBot || {};
  const { useOptionTemp } = useUI();
  const { TODAY, YESTERDAY, LAST_WEEK, LAST_MONTH, CUSTOM } = INTERVAL;
  const { isInitialized, getData } = useExtensions();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const [data, setData] = useState([]);
  const [navDate, setNavDate] = useOptionTemp("navDate", LAST_MONTH);
  const [intervalValues, setIntervalValues] = useOptionTemp(
    "intervalValues",
    createInterval(LAST_MONTH)
  );

  const getFilters = ({ interval, intervalValues }) => ({
    [FILTERS.INTERVAL]: interval,
    [FILTERS.INTERVAL_VALUES]: intervalValues,
  });

  const refresh = async () => {
    antiSpam.action(async () => {
      try {
        setIsLoading(true);
        const result = await getData(
          getFilters({ interval: navDate, intervalValues })
        );
        setData(result || []);
        setLoadingError(null);
      } catch (error) {
        setLoadingError(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (isInitialized) refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, navDate, intervalValues, id]);

  const onSelectInterval = (eventKey) => {
    setNavDate(eventKey);
  };

  const onFormDateChange = (event, index) => {
    const { value } = event.target;
    const newIntervalValues = [...intervalValues];
    newIntervalValues[index] = value ? new Date(value) : new Date();
    const [from = new Date(), to = new Date()] = newIntervalValues;
    if (from.getTime() > to.getTime()) from.setTime(new Date(to.getTime()));
    setIntervalValues(newIntervalValues);
  };

  const navItems = [
    { name: t("Today"), value: TODAY },
    { name: t("Yesterday"), value: YESTERDAY },
    { name: t("Last Week"), value: LAST_WEEK },
    { name: t("Last Month"), value: LAST_MONTH },
    { name: t("Custom"), value: CUSTOM },
  ];

  const getValues = useCallback(
    ({ value }) =>
      value && typeof value === "object"
        ? value
        : value === "undefined"
        ? { error: t("not serializable") }
        : {},
    [t]
  );

  const headers = useMemo(
    () =>
      uniqueArray(
        ["date", "time"].concat(
          data.reduce(
            (prev, item) => prev.concat(Object.keys(getValues(item))),
            []
          )
        )
      ),
    [data, getValues]
  );

  const MIN_ROWS = 5;
  const rows = useMemo(
    () =>
      data
        .map((item) => ({
          date: sortableDateString(item.time),
          time: sortableTimeString(item.time),
          ...getValues(item),
        }))
        .concat(
          new Array(Math.max(MIN_ROWS - data.length, 0)).fill(
            Object.assign({}, ...headers.map((key) => ({ [key]: "" })))
          )
        ),
    [data, headers, getValues]
  );

  const tableColumns = headers.map((name) => ({
    Header:
      typeof name === "string"
        ? t(name[0].toUpperCase() + name.substr(1))
        : t(name),
    accessor: name,
  }));

  const tableData = rows.map((val) =>
    Object.assign(
      {},
      ...Object.keys(val).map((key) => ({
        [key]:
          ["date", "time"].indexOf(key) < 0 &&
          val[key] &&
          typeof val[key] === "object"
            ? JSON.stringify(val[key])
            : val[key],
      }))
    )
  );

  return (
    <>
      <Card className="flex-fill mb-4">
        <Card.Body className="wordpress-header">
          <Row className="mt-n1">
            <Col className="d-flex gap-2 align-items-center">
              <Navigation
                activeKey={navDate}
                items={navItems}
                onSelect={onSelectInterval}
              />
              {navDate === CUSTOM && (
                <>
                  <Form.Control
                    type="date"
                    value={dateToString(intervalValues[0])}
                    onChange={(event) => onFormDateChange(event, 0)}
                    className="date-picker"
                  />
                  <Form.Control
                    type="date"
                    value={dateToString(intervalValues[1])}
                    onChange={(event) => onFormDateChange(event, 1)}
                    className="date-picker"
                  />
                </>
              )}
              <nav className="ms-auto" />
              <Button
                variant="outline-primary"
                className="shadow-sm"
                disabled={isLoading}
                onClick={refresh}
              >
                {isLoading ? (
                  <RotateCw className="feather fa-spin" />
                ) : (
                  <RefreshCw className="feather" />
                )}
                <span className="ms-2">{t("Refresh")}</span>
              </Button>
            </Col>
          </Row>
          <ProgressBar
            className={`position-absolute mt-2 ${isLoading ? "" : "opacity-0"}`}
            striped
            animated
            variant="primary"
            now={isLoading ? 90 : 0}
          />
        </Card.Body>
      </Card>
      {loadingError && (
        <Col md="12" className="mb-4">
          <RefreshAlert title="Data" error={loadingError} onRetry={refresh} />
        </Col>
      )}
      <Container fluid className="wordpress p-0">
        <Row>
          <Col md="12">
            <Card>
              <ColumnSortingTable columns={tableColumns} data={tableData} />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BotData;
