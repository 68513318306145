class Thread {
  constructor(interval) {
    setInterval(() => {
      const { handler } = this;
      if (typeof handler === "function") {
        handler();
      }
    }, interval);
  }
}

export default Thread;
