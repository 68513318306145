import { Accordion } from "react-bootstrap";
import { useTranslation } from "../../../../hooks/useLocalization";
import messenger from "../../../../assets/img/brands/whatsapp.svg";
import screen_1 from "../../../../assets/img/screenshots/channels/screen_whatsapp_1.png";
import screen_2 from "../../../../assets/img/screenshots/channels/screen_whatsapp_2.png";
import screen_3 from "../../../../assets/img/screenshots/channels/screen_whatsapp_3.png";
import screen_4 from "../../../../assets/img/screenshots/channels/screen_whatsapp_4.png";
import screen_5 from "../../../../assets/img/screenshots/channels/screen_whatsapp_5.png";
import ZoomImage from "../../../ZoomImage";
import ChannelInput from "./submit/ChannelInput";
import useBots from "../../../../hooks/useBots";
import { useState } from "react";
import { BOT_CHANNEL } from "../../../../contexts/bots/BotsContext";
import useMessage from "../../../../hooks/useMessage";
import ZoomVideo from "../../../ZoomVideo";
import ChannelState from "./submit/ChannelState";

const WhatsappIcon = ({ className }) => (
  <img
    src={messenger || ""}
    alt=""
    width={20}
    height={20}
    className={className}
  />
);

const WhatsappPrerequisites = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      <ul>
        <li>
          {tt(
            "Whatsapp.Step-1-1",
            <a
              href="https://developers.facebook.com/docs/whatsapp/phone-numbers#phone-numbers"
              alt="facebook"
              target="_blank"
              rel="noreferrer"
            >
              {t("here")}
            </a>
          )}
        </li>
        <li>
          {tt("Whatsapp.Step-1-2")}
        </li>
      </ul>
    </>
  );
}

const WhatsappStep2 = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      <p>
        {tt(
          "Whatsapp.Step-2-1",
          <a
            href="https://hub.360dialog.com/lp/whatsapp/VTOU8QPA"
            alt="whatsapp"
            target="_blank"
            rel="noreferrer"
          >
            https://hub.360dialog.com/lp/whatsapp/VTOU8QPA
          </a>
        )}
      </p>
      <p>
        {t("Whatsapp.Step-2-2")}
      </p>
      <ZoomImage>
        <img alt="screen_1" src={screen_1} className="m-3 ms-0 mw-100" />
      </ZoomImage>
      <p>
        {
          tt("Whatsapp.Step-2-3",
            <b>Accept Terms</b>
          )}
      </p>
    </>
  )
}

const WhatsappStep3 = ({ channel, id, levels }) => {
  const { t } = useTranslation();
  const { client_id } = channel || {};
  const { updateBot } = useBots();
  const [isSubmitting, setSubmitting] = useState();
  const { /*showMessage, */ showMessageWithDebug } = useMessage();
  const onSubmit = async (props) => {
    try {
      setSubmitting(true);
      await updateBot(id, {
        channels: [{ ...channel, name: BOT_CHANNEL.WHATSAPP, ...props }],
      });
      // showMessage({ save: "" });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setSubmitting(false);
    }
  };
  const onChange__phone_number = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit({ phone_number: value, client_id });
    }
  };
  return (
    <>
      <p>{t("Whatsapp.Step-3-1")}</p>
      <p>{t("Whatsapp.Step-3-2")}</p>
      <ul>
        <li>
          {t("Whatsapp.Step-3-3")}
        </li>
        <div className="d-flex">
          <ZoomImage>
            <img alt="screen_2" src={screen_2} className="m-3 ms-0 mw-100" />
          </ZoomImage>
          <ZoomVideo splash={screen_3}>
            <iframe width="100%" height="515" src="https://www.youtube.com/embed/_WxbHVdC8L4?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </ZoomVideo>
        </div>
        <li>
          {t("Whatsapp.Step-3-4")}
          <ChannelInput
            strict
            title={t("Phone number")}
            field="phone_number"
            channel={channel}
            disabled={!levels.manage}
            isSubmitting={isSubmitting}
            onChange={onChange__phone_number}
          />
        </li>
      </ul>
      {t("Whatsapp.Step-3-5")}
    </>)
}

const WhatsappGenerateAPIKey = ({ channel, id, levels }) => {
  const { t } = useTranslation();
  const { number_phone } = channel || {};
  const { updateBot } = useBots();
  const [isSubmitting, setSubmitting] = useState();
  const { /*showMessage, */ showMessageWithDebug } = useMessage();
  const onSubmit = async (props) => {
    try {
      setSubmitting(true);
      await updateBot(id, {
        channels: [{ ...channel, name: BOT_CHANNEL.WHATSAPP, ...props }],
      });
      // showMessage({ save: "" });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setSubmitting(false);
    }
  };
  const onChange__client_id = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit({ number_phone, client_id: value });
    }
  };
  return (
    <>
      <div className="d-flex">
        <ZoomImage>
          <img alt="screen_2" src={screen_4} className="m-3 ms-0 mw-100" />
        </ZoomImage>
        <ZoomImage>
          <img alt="screen_2" src={screen_5} className="m-3 ms-0 mw-100" />
        </ZoomImage>
      </div>
      {t("Whatsapp.Step-4-1")}
      <ChannelInput
        strict
        title={t("API Key")}
        field="client_id"
        channel={channel}
        disabled={!levels.manage}
        isSubmitting={isSubmitting}
        onChange={onChange__client_id}
      />
    </>
  )
}

const WhatsappConfigure = ({ channel, levels, bot, handlers }) => {
  const { t, tt } = useTranslation();
  const { id } = bot || {};
  return (
    <>
      <p>{t("Whatsapp.Header")}</p>
      <Accordion defaultActiveKey="0" className="accordion-steps no-animation">
        <Accordion.Item eventKey="0" className="bg-white">
          <Accordion.Header>{tt("Step N", 1)}</Accordion.Header>
          <Accordion.Body>
            <p>{t("Whatsapp.Step-1")}</p>
            <WhatsappPrerequisites />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{tt("Step N", 2)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <WhatsappStep2 />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{tt("Step N", 3)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <WhatsappStep3
              id={id}
              channel={channel}
              levels={levels}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{tt("Step N", 4)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Whatsapp.Step-4")}</p>
            <WhatsappGenerateAPIKey
              id={id}
              channel={channel}
              levels={levels}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>{tt("Step N", 5)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Whatsapp.Step-5")}</p>
            <p>{tt("Whatsapp.Step-5-1",
              <a
                href="https://www.facebook.com/business/help/257957338156440?id=180505742745347"
                alt="whatsapp"
                target="_blank"
                rel="noreferrer"
              >
                {t("here")}
              </a>
            )}</p>
            <ChannelState channel={channel} handlers={handlers} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const WhatsappGetHandlers = ({ url, validated, handlers }) => {
  if (validated && url) {
    handlers = {
      ...handlers,
      onLink: () => {
        window.open(url, "_blank");
      },
    };
  }
  return handlers;
};

export { WhatsappIcon, WhatsappConfigure, WhatsappGetHandlers };
