import React, { useEffect } from "react";

const JSBridge = ({ layout }) => {
  useEffect(() => {
    if (layout && typeof window.Landing === "function") {
      window.Landing[layout]();
    }
  }, [layout]);
  return <React.Fragment></React.Fragment>;
};

export default JSBridge;
