import { useCallback, useMemo, useState } from "react";
import { Form, Button, InputGroup, FloatingLabel, Row } from "react-bootstrap";
import { Copy, MessageCircle } from "react-feather";
import { config } from "../../../../config";
import { BOT_CHANNEL } from "../../../../contexts/bots/BotsContext";
import useAuth from "../../../../hooks/useAuth";
import useBilling from "../../../../hooks/useBilling";
import useClipboard from "../../../../hooks/useClipboard";
import api from "../../../../net/api";
import provider from "../../../../net/provider";
import screen_1 from "../../../../assets/img/screenshots/channels/screen_webchat_1.png";
import screen_2 from "../../../../assets/img/screenshots/channels/screen_webchat_2.png";
import useBots from "../../../../hooks/useBots";
import useMessage from "../../../../hooks/useMessage";
import ProLock from "../../../../components/pricing/ProLock";
import { FEATURES } from "../../../../contexts/billing/BillingContext";
import ChannelState from "./submit/ChannelState";
import ChannelSelect from "./submit/ChannelSelect";
import ZoomImage from "../../../ZoomImage";
import { Accordion } from "../../../accordion/Accordion";
import { useTranslation } from "../../../../hooks/useLocalization";
import ZoomVideo from "../../../ZoomVideo";

const getTestURL = ({ id, lang, open = -1 }) => {
  const route = api.route(api.BOT.RUN_STATE);
  const host = provider.httpURL(route?.server);
  const webServer = config.channels[BOT_CHANNEL.WEB]?.server;
  // const channel = channels.find(({ name }) => name === BOT_CHANNEL.WEB) || {};
  // const token = channel[TOKEN.CHANNEL] || "";
  const src = provider.httpURL(webServer);
  let result = ``;
  result += `${src}`;
  result += `?host=${new URL(host).host}`;
  result += `&id=${id}`;
  // result += `&uid=${uid}`;
  if (lang) {
    result += `&lang=${lang}`;
  }
  if (open >= 0) {
    result += `&open=${open}`;
  }
  // result += `&token=${token}`;
  result += `&bandle`;
  return result;
};

const WebIcon = ({ className }) => <MessageCircle className={className} />;

const WebDomainNames = ({ id, channel, levels }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { isProbilled, refreshFeatures, needUpgradePlan } = useBilling();
  const { websiteURL } = user || {};
  const { validated, whitelist } = channel || {};
  const { updateBot } = useBots();
  const { /*showMessage, */ showMessageWithDebug } = useMessage();
  const [isSubmitting, setSubmitting] = useState();

  const [selectedDomains, setSelectedDomains] = useState(
    (whitelist || [])
      .filter((name) => name !== "*")
      .map((name) => ({
        value: name,
        label: name,
      }))
  );

  const limit = 255;

  const listDomains = useMemo(
    () =>
      (websiteURL ? [websiteURL] : []).map((name) => ({
        value: name,
        label: name,
      })),
    [websiteURL]
  );

  const newDomainName = useCallback(
    (value) =>
      value.length > whitelist?.length ? value[value.length - 1]?.value : null,
    [whitelist]
  );

  const getProPlan = useCallback(
    (value) =>
      needUpgradePlan(FEATURES.DOMAINS, { domainName: newDomainName(value) }),
    [needUpgradePlan, newDomainName]
  );

  const isLimitReached = useCallback(
    (value) => value.some(({ value }) => value?.length > limit),
    [limit]
  );

  const isCreateBlocked = useCallback(
    (value) =>
      isLimitReached(value) ||
      !!(getProPlan(value) && (value.length > whitelist?.length) | 0),
    [isLimitReached, getProPlan, whitelist]
  );

  const onSubmit = useCallback(
    async (value = []) => {
      try {
        const whitelist = value.map(({ value }) => value);
        setSubmitting(true);
        await updateBot(id, {
          channels: [{ ...channel, name: BOT_CHANNEL.WEB, whitelist }],
        });
        await refreshFeatures();
        // showMessage({ save: "" });
      } catch (error) {
        const { message = t("Something went wrong"), debug } = error;
        showMessageWithDebug({ error: message, debug });
      } finally {
        setSubmitting(false);
      }
    },
    [
      id,
      channel,
      setSubmitting,
      updateBot,
      refreshFeatures,
      showMessageWithDebug,
      t,
    ]
  );

  const onChange = useCallback(
    async (value) => {
      if (levels.manage) {
        setSelectedDomains(value);
        if (isCreateBlocked(value)) {
          return;
        }
        await onSubmit(value);
      }
    },
    [levels.manage, setSelectedDomains, isCreateBlocked, onSubmit]
  );

  const proPlan = useMemo(
    () => getProPlan(selectedDomains),
    [getProPlan, selectedDomains]
  );

  return (
    <>
      <ChannelSelect
        limit={limit}
        options={listDomains}
        selected={selectedDomains}
        placeholder={t("Add Domain")}
        isDisabled={!(levels.manage && validated && isProbilled)}
        isSubmitting={isSubmitting}
        isLimitReached={isLimitReached}
        isCreateBlocked={isCreateBlocked}
        onChange={onChange}
      />
      {proPlan && (
        <Row>
          <ProLock proPlan={proPlan} style={{ width: "max-content" }} />
          {t("Upgrade your subscription to add more domain names")}
        </Row>
      )}
    </>
  );
};

const WebEmbedCode = ({ channel, bot = {} }) => {
  const { t, tt } = useTranslation();
  const { enabled } = channel || {};
  // const { user = {} } = useAuth();
  // const { uid } = user;
  const { id, lang /*, channels = []*/ } = bot;
  const { onCopyEvent } = useClipboard();
  const [open /*, setOpen*/] = useState(-1);
  const { showMessage } = useMessage();

  const embedCode = useMemo(() => {
    const route = api.route(api.BOT.RUN_STATE);
    const host = provider.httpURL(route?.server);
    const webServer = config.channels[BOT_CHANNEL.WEB]?.server;
    // const channel = channels.find(({ name }) => name === BOT_CHANNEL.WEB) || {};
    // const token = channel[TOKEN.CHANNEL] || "";
    const src = `${provider.httpURL(webServer)}bundle.min.js`;
    let result = ``;
    result += `<script`;
    result += ` src="${src}"`;
    result += ` host="${new URL(host).host}"`;
    result += ` id="${id}"`;
    // result += ` uid="${uid}"`;
    if (lang) {
      result += ` lang="${lang}"`;
    }
    if (open >= 0) {
      result += ` open="${open}"`;
    }
    // result += ` token="${token}"`;
    result += `></script>`;
    return result;
  }, [/*uid, channels, */ id, lang, open]);

  return (
    <ul>
      <li>
        <p className="mb-3">{t("Web.Step-2-1")}</p>
        <InputGroup>
          <FloatingLabel label={t("HTML code")} className="flex-grow-1">
            <Form.Control
              className="mb-3"
              type="text"
              rows="2"
              value={embedCode}
              readOnly
            />
          </FloatingLabel>
          <Button
            type="submit"
            variant="primary"
            className="mb-3"
            onClick={(e) => {
              onCopyEvent(embedCode);
              if (!enabled) {
                showMessage({
                  info: t(`While channel is deactivated...`),
                });
              }
            }}
          >
            <Copy className="feather me-1" />
            {t("Copy")}
          </Button>
        </InputGroup>
      </li>
      <li>
        {t("Web.Step-2-2")}
        <ZoomImage>
          <img alt="screen_1" src={screen_1} className="m-3 ms-0 mw-100" />
        </ZoomImage>
      </li>
      <li>
        {tt("Web.Step-2-3")}
        <ZoomImage>
          <img alt="screen_2" src={screen_2} className="m-3 ms-0 mw-100" />
        </ZoomImage>
      </li>
    </ul>
  );
};

const WebConfigure = ({ channel, levels, bot, handlers }) => {
  const { t, tt } = useTranslation();
  const { id } = bot || {};
  return (
    <>
      <ZoomVideo>
        <iframe width="50%" height="415" src="https://www.youtube.com/embed/VPfBYaJJ7jM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </ZoomVideo>
      <p>{t("Web.Header")}</p>
      <Accordion defaultActiveKey="0" className="accordion-steps no-animation">
        <Accordion.Item eventKey="0" className="bg-white">
          <Accordion.Header>{tt("Step N", 1)}</Accordion.Header>
          <Accordion.Body>
            <p>{t("Web.Step-1")}</p>
            <WebDomainNames id={id} channel={channel} levels={levels} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{tt("Step N", 2)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("Web.Step-2")}</p>
            <WebEmbedCode channel={channel} bot={bot} />
            <ChannelState channel={channel} handlers={handlers} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const WebGetHandlers = ({ id, lang, validated, handlers }) => {
  if (validated && id) {
    handlers = {
      ...handlers,
      onLink: () => {
        window.open(getTestURL({ id, lang }), "_blank");
      },
    };
  }
  return handlers;
};

export {
  getTestURL,
  WebIcon,
  WebDomainNames,
  WebEmbedCode,
  WebConfigure,
  WebGetHandlers,
};
