import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";

const InputPassword = (props) => {
  const { eye, isInvalid } = props;
  const { hidden, passwordHidden, onToggle } = eye || {};
  return (
    <div
      className={`d-flex form-control input-password ${
        isInvalid ? "is-invalid" : ""
      }`}
    >
      <Form.Control {...props} />
      {eye && (
        <i style={{ visibility: hidden ? "hidden" : "" }} onClick={onToggle}>
          <FontAwesomeIcon icon={passwordHidden ? faEyeSlash : faEye} />
        </i>
      )}
    </div>
  );
};

export default InputPassword;
