import { Card } from "react-bootstrap";
import ChannelPageTitle from "./ChannelPageTitle";

const ChannelPage = ({
  children,
  proPlan,
  props,
  channel,
  handlers,
  setChannelName,
}) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <ChannelPageTitle
            {...props}
            proPlan={proPlan}
            channel={channel}
            handlers={handlers}
          />
        </Card.Title>
      </Card.Header>
      <Card.Body className="channel-page">{children}</Card.Body>
    </Card>
  );
};

export default ChannelPage;
