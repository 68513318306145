import React, { useState } from "react";

const NOTIFICATION_STATUS = {
  NEW: "new",
  VIEWED: "viewed",
  CLICKED: "clicked",
};

const initialState = {};

const NotificationContext = React.createContext(initialState);

function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState([]);

  const findNotification = (findId) => {
    return notifications.find(({ id }) => id === findId);
  };

  const hasNotif = (value) => {
    return !!findNotification(value instanceof Object ? value.id : value);
  };

  const addNotif = (value) => {
    const notif = findNotification(value instanceof Object ? value.id : value);
    if (notif) {
      notifications.splice(notifications.indexOf(notif), 1, value);
    } else {
      notifications.push(value);
    }
    setNotifications([...notifications]);
  };

  const removeNotif = (value) => {
    const notif = findNotification(value instanceof Object ? value.id : value);
    if (notif) {
      notifications.splice(notifications.indexOf(notif), 1);
      setNotifications([...notifications]);
    }
  };

  const clearAllNotif = () => {
    notifications.length = 0;
    setNotifications([...notifications]);
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        hasNotif,
        addNotif,
        removeNotif,
        clearAllNotif,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export { NOTIFICATION_STATUS, NotificationProvider, NotificationContext };
