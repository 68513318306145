import { Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import IconBot from "../../components/bots/cards/BotIcon";
import DropdownListBots from "../../components/DropdownListBots";
import useBots from "../../hooks/useBots";
import { useTranslation } from "../../hooks/useLocalization";

const BreadcrumbHeader = ({ title, onTitle, subtitle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeBot } = useBots();
  const { name, kind, iconSrc } = activeBot || {};
  return (
    <Breadcrumb className="lead">
      <Breadcrumb.Item onClick={() => navigate("/dashboard/bots#clear")}>
        {t("All Bots")}
      </Breadcrumb.Item>
      <Breadcrumb.Item />
      <DropdownListBots
        btnDropdown={
          <Breadcrumb.Item>
            <IconBot
              kind={kind}
              src={iconSrc}
              className="align-middle img-fluid me-2 mb-1"
              size={18}
            />
            <span>{name}</span>
          </Breadcrumb.Item>
        }
      />
      <Breadcrumb.Item />
      {(subtitle && (
        <>
          <Breadcrumb.Item onClick={() => onTitle && onTitle()}>
            {title}
          </Breadcrumb.Item>
          <Breadcrumb.Item active className="text-dark font-weight-bold">
            {subtitle}
          </Breadcrumb.Item>
        </>
      )) || (
        <Breadcrumb.Item active className="text-dark font-weight-bold">
          {title}
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};

export default BreadcrumbHeader;
