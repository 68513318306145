import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form, Row, Col } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import { config } from "../../config";
import provider from "../../net/provider";
import useLocationContext from "../../hooks/useLocationContext";
import iconGoogle from "../../assets/img/brands/google.svg";
import iconFacebook from "../../assets/img/brands/facebook.svg";
import { useTranslation } from "../../hooks/useLocalization";
import { Case } from "../../languages/languages";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import logger from "../../utils/logger";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../../net/axios";

function SignUp({ onSubmit }) {
  const { t, tc, tt } = useTranslation();
  const navigate = useNavigate();
  const { searchParams } = useLocationContext();
  const [submitted, setSubmitted] = useState(false);
  const nameRef = useRef();
  const submitRef = useRef();
  const { googleIn, facebookIn, signCheck, signUp } = useAuth();
  const [isSocialIn, setSocialIn] = useState(false);
  const [URLparams] = useSearchParams();
  const [state, setState] = useState({
    passwordTyped: false,
    passwordHidden: true,
    socialIn: null,
  });

  useEffect(() => nameRef.current?.focus(), [nameRef]);
  useEffect(() => {
    if (document.referrer.indexOf("://qudata.com") > 0) {
      window.gtag('event', 'conversion', { 'send_to': 'AW-437440652/5vFCCOLmoMYDEIyhy9AB' })
    }

    if (URLparams.get("gevent") === "sign_up_link_abs") {
      window.gtag('event', "sign_up_link_abs")
    }

    if (URLparams.get("gevent") === "sign_up_link_qudata") {
      window.gtag('event', "sign_up_link_qudata")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const google_clientId = config.social.google.clientId;
  const facebook_appId = config.social.facebook.appId;
  const facebook_fields = config.social.facebook.fields;

  const socialResponse = (handler, args) => {
    setState({ ...state, socialIn: { handler, args } });
    submitRef.current?.dispatchEvent(new MouseEvent("click"));
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      logger.dev("[Google]", response);
      const { error, access_token: accessToken } = response;
      if (error || !accessToken) {
        setSocialIn(false);
        return;
      }

      const userInfo = await axiosInstance.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${response.access_token}` } },
      );

      const { email, given_name: firstName, family_name: lastName } = userInfo.data;
      socialResponse(googleIn, [accessToken, email, firstName, lastName]);
    },

    onError: errorResponse => console.log(errorResponse),
  });

  const responseFacebook = (response = {}) => {
    logger.dev("[Facebook]", response);
    const {
      error,
      accessToken,
      email,
      first_name: firstName,
      last_name: lastName,
    } = response;
    if (error || !accessToken) {
      setSocialIn(false);
      return;
    }
    socialResponse(facebookIn, [accessToken, email, firstName, lastName]);
  };

  if (submitted) {
    return (
      <div className="alert-message d-flex align-items-center">
        <FontAwesomeIcon
          icon={faAt}
          size="3x"
          className="text-primary flex-shrink-0 me-2"
        />
        <div>{t("You’re sign up!")}</div>
      </div>
    );
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
          submit: false,
        }}
        validationSchema={
          !isSocialIn
            ? Yup.object().shape({
              firstName: Yup.string().max(255),
              // .required("First name is required"),
              lastName: Yup.string().max(255),
              // .required("Last name is required"),
              email: Yup.string().email(t("Must be a valid email")).max(255),
              // .required("Email is required"),
              password: Yup.string()
                .min(6, tt("Must be at least N characters", 6))
                .max(255)
                .matches(
                  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,255}$/,
                  t("Password must contain")
                ),
              // .required("Password is required"),
              confirmPassword: Yup.string()
                // .required("Password is required")
                .when("password", {
                  is: (val) => (val && val.length > 0 ? true : false),
                  then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    t("Both password need to be the same")
                  ),
                }),
            })
            : null
        }
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const { socialIn } = state;
            if (isSocialIn && socialIn) {
              await socialIn.handler(...socialIn.args);
              setSocialIn(false);
              const { regComplete } = await signCheck();
              if (regComplete) {
                navigate("/dashboard");
              } else {
                navigate("/auth/sign-up-complete");
              }
            } else {
              await signUp(
                values.email,
                values.password,
                values.firstName,
                values.lastName,
                searchParams
              );
              setSubmitted(true);
              if (onSubmit) onSubmit(true);
            }
          } catch (error) {
            const { message = t("Something went wrong") } = error;
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  {/* <Form.Label htmlFor="firstName">First name</Form.Label> */}
                  <Form.Control
                    required={!isSocialIn}
                    id="firstName"
                    type="text"
                    name="firstName"
                    placeholder={t("First name")}
                    ref={nameRef}
                    value={values.firstName}
                    isInvalid={Boolean(touched.firstName && errors.firstName)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.firstName && (
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  {/* <Form.Label htmlFor="lastName">Last name</Form.Label> */}
                  <Form.Control
                    required={!isSocialIn}
                    id="lastName"
                    type="text"
                    name="lastName"
                    value={values.lastName}
                    placeholder={t("Last name")}
                    isInvalid={Boolean(touched.lastName && errors.lastName)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.lastName && (
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              {/* <Form.Label htmlFor="login">Email address</Form.Label> */}
              <Form.Control
                required={!isSocialIn}
                id="login"
                type="email"
                name="email"
                value={values.email}
                autoComplete="username"
                placeholder={t("Email address")}
                isInvalid={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              {/* <Form.Label htmlFor="password">Password</Form.Label> */}
              <Form.Control
                required={!isSocialIn}
                id="password"
                type="password"
                name="password"
                autoComplete="new-password"
                placeholder={t("Password")}
                isInvalid={Boolean(touched.password && errors.password)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              {/* <Form.Label htmlFor="confirm-password">Confirm password</Form.Label> */}
              <Form.Control
                required={!isSocialIn}
                id="confirm-password"
                type="password"
                name="confirmPassword"
                autoComplete="new-password"
                placeholder={t("Confirm password")}
                isInvalid={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.confirmPassword && (
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <div className="text-center mt-3 mb-3 w-100 d-grid">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                ref={submitRef}
                disabled={isSubmitting}
                onClick={() => {
                  window.gtag('event', "sign_up_email");
                }}
              >
                {t("Sign up")}
              </Button>
            </div>
            <div className="w-100 d-flex my-3">
              {google_clientId && (
                <Button
                  className="w-100"
                  style={{ marginRight: "0.5rem" }}
                  type="submit"
                  variant="outline-primary"
                  size="lg"
                  onClick={(event) => {
                    window.gtag('event', "sign_up_google");
                    event.preventDefault();
                    setSocialIn(true);
                    googleLogin();
                  }}
                >
                  <img
                    alt="Google"
                    src={iconGoogle}
                    width="22"
                    style={{ marginRight: "0.4rem" }}
                  />
                  {t("Sign up with Google")}
                </Button>
              )}
              {facebook_appId && facebook_fields && (
                <FacebookLogin
                  appId={facebook_appId}
                  fields={facebook_fields}
                  callback={responseFacebook}
                  render={(props) => (
                    <Button
                      className="w-100"
                      type="submit"
                      variant="outline-primary"
                      size="lg"
                      onClick={(event) => {
                        window.gtag('event', "sign_up_facebook");
                        event.preventDefault();
                        setSocialIn(true);
                        props.onClick(event);
                      }}
                    >
                      <img
                        alt="Facebook"
                        src={iconFacebook}
                        width="22"
                        style={{ marginRight: "0.4rem" }}
                      />
                      {t("Sign up with Facebook")}
                    </Button>
                  )}
                />
              )}
            </div>
            <Form.Text>
              {tt(
                "By clicking the Sign Up button",
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={provider.httpURL(config.links?.terms)}
                  className="text-primary"
                >
                  {tc("Terms & Conditions", Case.Instrumental)}
                </a>,
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={provider.httpURL(config.links?.privacy)}
                  className="text-primary"
                >
                  {tc("Privacy Policy", Case.Instrumental)}
                </a>
              )}
            </Form.Text>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default SignUp;
