import React, { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import Ansi from "ansi-to-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logLevelVariant } from "./utils";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import Scrollbar from "../../Scrollbar";
// import { RunBotContext, RunBotDebugContext } from "qubot";
const RunBotContext = null;
const RunBotDebugContext = null;

const DebugConsole = ({ id, isDebugOpen, setIsDebugOpen }) => {
  const { state } = useContext(RunBotContext);
  const { getData } = useContext(RunBotDebugContext);
  const logs = state[id]?.debug?.logs || [];
  const renderLogs = logs.slice(logs.length - 128, logs.length).reverse();

  const errors = renderLogs.filter(({ level }) => level === "error");
  const warnings = renderLogs.filter(({ level }) => level === "warning");
  const debugs = renderLogs.filter(({ level }) => level === "debug");
  const infos = renderLogs.filter(({ level }) => level === "info");

  useEffect(() => getData({ debug: ["*"] }), [id, getData]);

  const AlertCount = ({ logs }) =>
    !!logs.length && (
      <div
        className={`d-none d-sm-block text-${logLevelVariant(logs[0].level)}`}
      >
        <FontAwesomeIcon icon={faBell} fixedWidth />
        {logs.length}
      </div>
    );

  return (
    <Card className="testing-debug mb-0">
      <Card.Header onClick={() => setIsDebugOpen(!isDebugOpen)}>
        <Card.Title tag="h5" className="mb-0 w-100">
          <span>Debug console</span>
          <AlertCount logs={errors} />
          <AlertCount logs={warnings} />
          <AlertCount logs={debugs} />
          <AlertCount logs={infos} />
        </Card.Title>
      </Card.Header>
      <Scrollbar className="bg-dark">
        <Card.Body>
          {renderLogs.map(({ level, text = "" }, index) => (
            <span key={index} className="text-light">
              <Ansi useClasses>{text}</Ansi>
              <br />
            </span>
          ))}
        </Card.Body>
      </Scrollbar>
    </Card>
  );
};

export default DebugConsole;
