import { Row, Col, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router";

const NavbarDropdownItem = ({
  icon,
  title,
  description,
  time,
  spacing,
  href,
  mark,
  onClick,
}) => {
  const navigate = useNavigate();
  const style = {
    cursor: href && "pointer",
  };
  return (
    <ListGroup.Item
      onClick={(event) => {
        if (typeof onClick === "function") {
          onClick(event);
          if (event.defaultPrevented) return;
        }
        href && navigate(href);
        document.dispatchEvent(new MouseEvent("click"));
      }}
      style={style}
    >
      <Row className="align-items-center g-0">
        {mark && (
          <span className="text-primary w-0 position-absolute end-0 me-3">
            ●
          </span>
        )}
        <Col xs={2}>{icon}</Col>
        <Col xs={10} className={spacing ? "pl-2" : null}>
          <div className="text-dark">{title}</div>
          <div className="text-muted small mt-1">{description}</div>
          <div className="text-muted small mt-1">{time}</div>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default NavbarDropdownItem;
