import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import SignUp from "../../components/auth/SignUp";
import { Link } from "react-router-dom";
import { useTranslation } from "../../hooks/useLocalization";

const SignUpPage = () => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  return (
    <React.Fragment>
      <Helmet title={t("Sign Up")} />
      <div className="text-center mt-4">
        {!submitted && <h1 className="h2">{t("Sign up")}</h1>}
        <p className="lead">
          {t("Already have an account?")}
          <Link to="/auth"> {t("Login here")}</Link>
        </p>
      </div>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <SignUp onSubmit={setSubmitted} />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignUpPage;
