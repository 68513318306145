import { useState } from "react";
import { Badge, Button, Card, Spinner } from "react-bootstrap";
import { Check, RotateCw } from "react-feather";
import { useTranslation } from "../../../hooks/useLocalization";

const getBage = (title) => {
  if (title && title.length > 0)
    return <>
      <div className="btn btn-primary" style={{ marginLeft: '8px', padding: "0", cursor: 'default', minWidth: '42px', maxWidth: '42px', transform: "scale(0.75)" }}>
        {title}
      </div>
    </>
}

const PlanCard = ({
  pro,
  title,
  includes,
  selected,
  purchased,
  canceled,
  featured,
  loading,
  price,
  couponPrice,
  currencySymbol,
  extendedHeader,
  extendedBody,
  tryPlan,
  tryPlanText,
  tryPlanTextHover,
  tryPlanDescription,
  tryPlanOutline,
  tryPlanVariant,
  tryPlanDisabled,
}) => {
  const { tt } = useTranslation();
  const [hover, setHover] = useState();
  const [isLoading, setLoading] = useState();
  const currentPrice = couponPrice && price ? price - couponPrice : price;
  const renderSale = couponPrice > 0;
  const className = `price-card text-center h-100 ${selected ? "selected" : ""
    } ${featured ? "featured" : ""} ${extendedHeader ? "extended-title" : ""} ${extendedBody ? "extended-upgrade" : ""
    }`;
  return (
    <Card className={className}>
      <div className="text-end price-card-info">
        {selected && <Check className="text-success m-1" />}
        {!(purchased || selected || purchased) && pro && (
          <Badge bg="">{title}</Badge>
        )}
      </div>
      <Card.Body className="d-flex flex-column">
        <div className="price-card-title">
          <h4>{title}</h4>
          <div className="w-max">
            {loading ? (
              <Spinner
                animation="border"
                variant="secondary"
                className="mt-2"
              />
            ) : (
              <>
                <span
                  className={`display-5 ${renderSale ? "text-primary" : ""}`}
                >
                  {tt(
                    "Price /mo",
                    <small>{currencySymbol}</small>,
                    <big>{currentPrice}</big>
                  )}
                </span>
                {renderSale && (
                  <div className="price-card-sale">
                    {tt("Regular Price /mo", currencySymbol, price)}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <hr />
        <div className="price-card-upgrade mt-2 mb-4">
          {tryPlanText &&
            (tryPlanDisabled ? (
              <div className="pt-2 lead">
                <b>
                  <span className={`text-${tryPlanVariant}`}>
                    {tryPlanText}
                  </span>
                </b>
              </div>
            ) : (
              <Button
                size="lg"
                variant={
                  tryPlanOutline ? `outline-${tryPlanVariant}` : tryPlanVariant
                }
                disabled={isLoading || tryPlanDisabled}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={async () => {
                  if (typeof tryPlan === "function") {
                    setLoading(true);
                    await tryPlan();
                    setLoading(false);
                  }
                }}
              >
                {isLoading && <RotateCw className="feather fa-spin me-2" />}
                {(hover && tryPlanTextHover) || tryPlanText}
              </Button>
            ))}
          {tryPlanDescription && (
            <div className="price-card-description text-primary mt-2">
              {tryPlanDescription}
            </div>
          )}
        </div>
        <ul className="list-unstyled text-start mb-0">
          {includes.map((item, i) => (
            <li className="mb-2" key={i}>
              <Check
                className={`feather me-2 ${selected ? "text-success" : "text-primary"
                  }`}
              />
              {item.text}
              {getBage(item.bage)}
            </li>
          ))}
        </ul>
      </Card.Body>
    </Card>
  );
};

export default PlanCard;
