import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container } from "react-bootstrap";
import Header from "./Header";
import MyBots from "../../../components/bots/MyBots";
import useBots from "../../../hooks/useBots";
import QDLoader from "../../../components/QDLoader";
import RefreshAlert from "../../../components/bots/RefreshAlert";
import useUI from "../../../hooks/useUI";
import { BOT_FILTER, BOTS_TYPE, getBotFilter, SORT_BY } from "./utils";
import useAuth from "../../../hooks/useAuth";
import { botById } from "../../../contexts/bots/utils";
import Wordpress from "../../../modals/Wordpress";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "../../../hooks/useLocalization";
import useTutorial from "../../../hooks/useTutorial";

const Bots = ({ hideTemplates, handlers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { hash } = location || {};
  const auth = useAuth();
  const { user } = auth;
  const { useOption, useOptionTemp } = useUI();
  const { bots, activeBot, loadingError, refreshBots, isInitialized } =
    useBots();
  const [botType, setBotType] = useOption("botType", BOTS_TYPE.ALL);
  const [sortBy, setSortBy] = useOption("sortBy", SORT_BY.NAME);
  const [search, setSearch] = useOptionTemp("search", "");
  const { refTemplate, filter, setFilter } = useTutorial();
  const isReady = isInitialized && !!filter;
  const [showWordpress, setShowWordpress] = useState();

  const filterBot = useCallback(
    (bot, filter) => getBotFilter(bot, filter, user) === filter,
    [user]
  );

  useEffect(() => {
    if (hash === "#clear") {
      setSearch("");
      navigate("/dashboard/bots", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  useEffect(() => {
    if (isInitialized && !filter) {
      const bot = botById(bots, activeBot?.id);
      const filterByBot = getBotFilter(bot, filter, user);
      setFilter(
        filterByBot === BOT_FILTER.USER || filterByBot === BOT_FILTER.SHARED
          ? filterByBot
          : BOT_FILTER.MY
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  const showTemplates = filter === BOT_FILTER.MY && !hideTemplates;

  return (
    <React.Fragment>
      <Helmet title={t("Bots Dashboard")} />
      <Container fluid className="p-0">
        {isReady && (
          <Header
            filter={filter}
            setFilter={setFilter}
            sortBy={sortBy}
            setSortBy={setSortBy}
            search={search}
            setSearch={setSearch}
            botType={botType}
            setBotType={setBotType}
          />
        )}
        {loadingError && (
          <Col md="12" className="mb-4">
            <RefreshAlert
              title={t("My Bots")}
              error={loadingError}
              onRetry={refreshBots}
            />
          </Col>
        )}
        {isReady ? (
          <>
            <MyBots
              showTitle
              filter={filter}
              setFilter={setFilter}
              filterBot={filterBot}
              sortBy={sortBy}
              search={search}
              setSearch={setSearch}
              searchResults
              handlers={handlers}
              botType={botType}
              setBotType={setBotType}
            />
            {showTemplates && (
              <MyBots
                ref={refTemplate}
                showTitle
                filter={BOT_FILTER.TEMPLATE}
                filterBot={filterBot}
                sortBy={sortBy}
                search={search}
                handlers={handlers}
                botType={botType}
                setBotType={setBotType}
              />
            )}
          </>
        ) : (
          <QDLoader />
        )}
      </Container>
      <Wordpress
        open={showWordpress}
        setOpen={(value) => setShowWordpress(value)}
      />
    </React.Fragment>
  );
};

export default Bots;
