import React from "react";
import { matchPath, useLocation } from "react-router-dom";

const Content = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;
  const padding = matchPath({ path: "/bots/editor", end: true }, pathname)
    ? "p-0"
    : "";
  return <div className={`content ${padding}`}>{children}</div>;
};

export default Content;
