import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { matchPath } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { scrollTo } from "../../utils/scroll";

function RoutesGuard({ path, redirect, children }) {
  const { isInitialized } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;

  useEffect(() => {
    if (isInitialized) {
      scrollTo(window.document.body);
    }
  }, [isInitialized, pathname]);

  useEffect(() => {
    if (
      isInitialized &&
      matchPath({ path, exact: true }, pathname) &&
      redirect
    ) {
      return navigate(
        redirect.startsWith("/") ? redirect : `/${path}/${redirect}`,
        { replace: true }
      );
    }
  }, [path, redirect, isInitialized, navigate, pathname, search]);

  return <React.Fragment>{children}</React.Fragment>;
}

export default RoutesGuard;
