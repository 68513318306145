import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";
import useBilling from "../../hooks/useBilling";
import QDLoader from "../../components/QDLoader";
import Payments from "../../components/payments/Payments";
import { useTranslation } from "../../hooks/useLocalization";

const PaymentsPage = () => {
  const { t } = useTranslation();
  const { isInitialized } = useBilling();
  return (
    <React.Fragment>
      <Helmet title={t("Payments")} />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{t("Payments")}</h1>
        <Row>
          <Col md="12" className="mx-auto">
            {isInitialized ? <Payments /> : <QDLoader />}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default PaymentsPage;
