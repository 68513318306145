import { useCallback, useState } from "react";

const isMobile = () =>
  (window.document.body && getComputedStyle(window.document.body))?.zIndex ===
  "1";

const useFirstTouch = () => {
  const [touched, setTouched] = useState(false);

  const firstTouch = useCallback(
    (handler) => {
      if (touched) {
        handler();
      } else {
        window.document.body.addEventListener(
          "mousedown",
          () => {
            setTouched(true);
            handler();
          },
          { once: true }
        );
      }
    },
    [touched, setTouched]
  );

  return { touched, firstTouch };
};

export { isMobile, useFirstTouch };
