import React from "react";
import { BOT_CHANNEL } from "../../../../contexts/bots/BotsContext";
import { useTranslation } from "../../../../hooks/useLocalization";
import { GoogleSheetsIcon } from "../bodies/GoogleSheets";
import ChannelCard from "./ChannelCard";

const ChannelGoogleSheetsCard = (props) => {
  const { t } = useTranslation();
  const { channelName, channel, handlers } = props;
  const { url, validated } = channel;
  return (
    <ChannelCard
      {...props}
      name={t(BOT_CHANNEL.title(channelName))}
      icon={GoogleSheetsIcon}
      handlers={{
        ...handlers,
        onLink: url && validated ? handlers.onLink : null,
      }}
    />
  );
};

export default ChannelGoogleSheetsCard;
