import { Col, Row } from "react-bootstrap";
import BreadcrumbHeader from "../BreadcrumbHeader";

const Header = ({ title }) => {
  return (
    <Row className="mb-2 mb-xl-3">
      <Col xs="auto" /*className="d-none d-sm-block"*/>
        <BreadcrumbHeader title={title} />
      </Col>
    </Row>
  );
};

export default Header;
