import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import useLocationContext from "../../hooks/useLocationContext";
import AccountGuard from "./AccountGuard";

function AuthGuard({ children }) {
  const navigate = useNavigate();
  const { isInitialized, isAuthenticated, isMaintenance } = useAuth();
  const { setRedirect } = useLocationContext();
  const [redirectStored, setRedirectStored] = useState();

  useEffect(() => {
    if (isInitialized && !isAuthenticated && !isMaintenance) {
      const { pathname } = window.location;
      if (!redirectStored && pathname?.startsWith("/bots")) {
        setRedirect("bots");
        setRedirectStored(true);
      }
      navigate("/auth");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, isAuthenticated, isMaintenance, navigate]);

  return <AccountGuard>{children}</AccountGuard>;
}

export default AuthGuard;
