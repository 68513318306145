/*eslint no-new-func: "off"*/

/******************************************************************************
 *                      Setup environement API
 *
 *                                                   (c) 2021 qudata.com, steps
 *******************************************************************************/
class QuBotDashboardSetupEnv {
  #uiAPI = {};
  #useAPI = {};

  constructor(dhEnv, qbEnv, { type, cid }) {
    this.dhEnv = dhEnv;
    this.qbEnv = qbEnv;
    this.type = type;
    this.cid = cid;
    this.isToken = false;
  }

  /**************************************************************************
   */
  static isCodeLoaded() {
    return typeof this.libClass() === "function";
  }

  /**************************************************************************
   */
  static libClass() {
    return new Function("return QuBotSetup;")();
  }

  /**************************************************************************
   * UI api
   */
  async ui(value) {
    this.#uiAPI = { ...this.#uiAPI, ...value };
  }

  /**************************************************************************
   * Use api
   */
  async use(value) {
    this.#useAPI = { ...this.#useAPI, ...value };
  }

  /**************************************************************************
   * Create bot environement
   */
  async create({ rootDiv }) {
    // logger.dev(`[QuBotDashboardEditorEnv] create`);
    this.lib = new (QuBotDashboardSetupEnv.libClass())(this);
    window.qubotSetup = this.lib;
    return this.lib.create({
      rootDiv,
      botLib: this.qbEnv.lib,
      pathIcons: "/qubot/css/icons/",
      pathStyles: "/qubot/css/styles/",
    });
  }

  /**************************************************************************
   * Save bot
   */
  async saveBot(props) {
    return await this.dhEnv.saveBot(props);
  }

  /**************************************************************************
   * Load bot
   */
  async loadBot(props) {
    return await this.dhEnv.loadBot(props);
  }

  /**************************************************************************
   * Save setup
   */
  async saveSetup(props, from) {
    const { useTutorial } = this.#useAPI;
    const {
      tutoStorage,
      setProp,
      setTutoSTorage,
      tutorials,
      setTutorials,
      updateStep,
      checkTuto3Complete,
    } = useTutorial;
    if (from !== "fromCheckPos") {
      if (!tutoStorage.tuto_3._2) {
        let tuto2 = document.getElementById("qubot-run-tuto3");
        tuto2.style.display = "none";
        tutoStorage.tuto_3._1 = true;
        tutoStorage.tuto_3._2 = true;
        setProp("tutorial", tutoStorage)
        setTutoSTorage(tutoStorage);
        updateStep(2, 2);
        tutorials[2].onClick = null;
        setTutorials([...tutorials])
        checkTuto3Complete();
      }
    }
    return await this.dhEnv.saveSetup(props);
  }

  /**************************************************************************
   * Load setup
   */
  async loadSetup(props) {
    return await this.dhEnv.loadSetup(props);
  }

  /**************************************************************************
   * Save image
   */
  async saveFile(props) {
    return await this.dhEnv.saveFile(props);
  }

  /**************************************************************************
   * Load image URL
   */
  async loadFile(props) {
    return await this.dhEnv.loadFile(props);
  }

  /**************************************************************************
   * Load all image file names
   */
  async loadFileNames(props) {
    return await this.dhEnv.loadFileNames(props);
  }

  /**************************************************************************
   * Delete image
   */
  async deleteFile(props) {
    return await this.dhEnv.deleteFile(props);
  }

  /**************************************************************************
   * Resize window event
   */
  async onResizeEvent(event) {
    if (typeof this.lib.onResizeEvent === "function") {
      await this.lib.onResizeEvent();
    }
  }

  /**************************************************************************
   * Before unload event
   */
  async onBeforeUnloadEvent() {
    if (typeof this.lib.onBeforeUnloadEvent === "function") {
      await this.lib.onBeforeUnloadEvent();
    }
  }
}

export default QuBotDashboardSetupEnv;
