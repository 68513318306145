import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Button } from "react-bootstrap";
import { useTranslation } from "../../hooks/useLocalization";

const Page404 = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet title={`404 ${t("Error")}`} />
      <div className="text-center">
        <h1 className="display-1 fw-bold">404</h1>
        <p className="h1">{t("Page not found")}</p>
        <p className="h2 fw-normal mt-3 mb-4">
          {t("The page you are looking for might have been removed")}
        </p>
        <Link to="/dashboard">
          <Button variant="primary" size="lg">
            {t("Return to website")}
          </Button>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default Page404;
