import { get, set, remove } from "./esm";
import FileSystem, { Directory } from "./FileSystem";

export class BotDirectory extends Directory {
  constructor(botId, handle) {
    super(handle);
    this.botId = botId;
  }
}

export default class BotFileSystem extends FileSystem {
  static async restoreDirectory(botId) {
    let jsHandle = await get(botId);
    if (jsHandle != null) {
      return new BotDirectory(botId, jsHandle);
    }
    return null;
  }

  static async selectDirectory(botId) {
    let jsHandle = await FileSystem.browseDirectory();
    return await BotFileSystem.storeDirectory(botId, jsHandle);
  }

  static async storeDirectory(botId, jsHandle) {
    if (jsHandle) {
      const { handle } = jsHandle;
      await set(botId, handle);
      return new BotDirectory(botId, handle);
    }
  }

  static async forgetDirectory(botId) {
    await remove(botId);
  }
}
