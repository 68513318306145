import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, ListGroup, OverlayTrigger, Popover, Row } from "react-bootstrap";
// import * as Yup from "yup";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUpload } from "@fortawesome/free-solid-svg-icons";

// import avatar from "../../assets/img/avatars/no-avatar.jpg";
import { useLocation } from "react-router";
// import { Formik } from "formik";
// import useAuth from "../../hooks/useAuth";
import SignUpComplete from "../../components/auth/SignUpComplete";
import ForgotPassword from "../../components/auth/ForgotPassword";
import SignXProfile from "../../components/auth/SignXProfile";
import SignXDelete from "../../components/auth/SignXDelete";
// import { isExperimental } from "../../system/system";
import BillingInformation from "../../components/billing/BillingInformation";
import { useTranslation } from "../../hooks/useLocalization";
import ActivityReport from "../../components/ActivityReport";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faVial } from "@fortawesome/free-solid-svg-icons";
import AdminPanel from "../../components/AdminPanel";
import useAuth from "../../hooks/useAuth";
import useTutorial from "../../hooks/useTutorial";
import api from "../../net/api";

const Navigation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { hash } = location;
  const { isUserLicense, checkRole } = useAuth();
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("Account Settings")}
        </Card.Title>
      </Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item tag="a" href="#public" action active={showPublic(hash)}>
          {t("Profile")}
        </ListGroup.Item>
        <ListGroup.Item
          tag="a"
          href="#private"
          action
          active={showPrivate(hash) || showPrivateH(hash)}
        >
          {t("Details")}
        </ListGroup.Item>
        <ListGroup.Item
          tag="a"
          href="#password"
          action
          active={showPassword(hash)}
        >
          {t("Password")}
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="#delete" action active={showDelete(hash)}>
          {t("Delete Account")}
        </ListGroup.Item>
        {isUserLicense() && (
          <ListGroup.Item
            tag="a"
            href="#admin"
            action
            active={showAdmin(hash)}
          >
            {t("Admin Panel")}
          </ListGroup.Item>
        )}
        {(checkRole(api.ROLES.SUPER_ADMIN) || checkRole(api.ROLES.ACC_MANAGER)) && (
          <ListGroup.Item
            tag="a"
            href="#report"
            action
            active={showReport(hash)}
          >
            {t("Activity Report")}
          </ListGroup.Item>
        )}
        {(checkRole(api.ROLES.SUPER_ADMIN) || checkRole(api.ROLES.ADMIN) || checkRole(api.ROLES.QA)) && (
          <ListGroup.Item
            tag="a"
            href="#billing"
            action
            active={showBilling(hash)}
          >
            {t("Clear billing")}
          </ListGroup.Item>
        )}
      </ListGroup>
    </Card>
  );
};

const PublicInfo = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("User Profile")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <SignXProfile dashboard />
      </Card.Body>
    </Card>
  );
};


const PrivateInfo = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const hightlight = showPrivateH(location.hash);
  const { showTuto1_1 } = useTutorial();

  const tutover1 = (
    <Popover className="tuto-popover">
      <Popover.Header as="h3" className="tuto-popover-header">1.</Popover.Header>
      <Popover.Body>
        {t("Tutover1_1")}
      </Popover.Body>
    </Popover>
  );

  return (
    <Card>
      <Card.Header>
        <OverlayTrigger
          show={showTuto1_1}
          placement="left"
          overlay={tutover1}
        >
          <Card.Title tag="h5" className="mb-0">
            {t("Additional Details")}
          </Card.Title>
        </OverlayTrigger>
      </Card.Header>
      <Card.Body>
        <SignUpComplete dashboard hightlight={hightlight} />
      </Card.Body>
    </Card>
  );
};

const BilingInformation = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("Billing Information")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <BillingInformation />
      </Card.Body>
    </Card>
  );
};

const ChangePassword = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("Change Password")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <ForgotPassword dashboard />
      </Card.Body>
    </Card>
  );
};

const DeleteAccount = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("Delete Account")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <SignXDelete dashboard />
      </Card.Body>
    </Card>
  );
};

const showPublic = (hash) => hash === "" || hash === "#public";
const showPrivate = (hash) => hash === "#private";
const showPrivateH = (hash) => hash === "#private!";
const showBilling = (hash) => hash === "#billing";
const showPassword = (hash) => hash === "#password";
const showDelete = (hash) => hash === "#delete";
const showReport = (hash) => hash === "#report";
const showAdmin = (hash) => hash === "#admin";

const Settings = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { hash } = location;
  return (
    <React.Fragment>
      <Helmet title={t("Settings")} />
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Settings</h1> */}
        <Row>
          <Col md="3" xl="2">
            <Navigation />
          </Col>
          <Col md="9" xl="10">
            {showPublic(hash) && <PublicInfo />}
            {(showPrivate(hash) || showPrivateH(hash)) && <PrivateInfo />}
            {showBilling(hash) && <BilingInformation />}
            {showPassword(hash) && <ChangePassword />}
            {showAdmin(hash) && <AdminPanel />}
            {showDelete(hash) && <DeleteAccount />}
            {showReport(hash) && <ActivityReport />}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Settings;
