import React from "react";
import { Helmet } from "react-helmet-async";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import useBilling from "../../hooks/useBilling";
import QDLoader from "../../components/QDLoader";
import Traffic from "../../components/payments/Traffic";
import { useTranslation } from "../../hooks/useLocalization";
import { useNavigate } from "react-router-dom";

const TrafficPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isInitialized, paymentsAvailable } = useBilling();
  return (
    <React.Fragment>
      <Helmet title={t("Traffic tracking")} />
      <Container fluid className="p-0">
        {paymentsAvailable && (
          <Breadcrumb className="lead">
            <Breadcrumb.Item onClick={() => navigate("/account/payments")}>
              {t("Payments")}
            </Breadcrumb.Item>
            <Breadcrumb.Item active className="text-dark font-weight-bold">
              {t("Traffic tracking")}
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
        <Row>
          <Col md="12" className="mx-auto">
            {isInitialized ? <Traffic /> : <QDLoader />}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default TrafficPage;