import React from "react";
import AddPayCard from "./AddPayCard";
import TrafficTrackingCard from "./TrafficTrackingCard";

const Traffic = () => {

  return (
    <>
      <TrafficTrackingCard>

      </TrafficTrackingCard>
      <AddPayCard>

      </AddPayCard>
    </>
  )
}

export default Traffic;