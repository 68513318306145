import { botById } from "./utils";

const INITIALIZE = "INITIALIZE";
const REFRESH_START = "REFRESH_START";
const REFRESH_END = "REFRESH_END";
const BOTS_UPDATED = "BOTS_UPDATED";
const SET_SETTINGS = "SET_SETTINGS";
const SET_ACTIVE = "SET_ACTIVE";

const initialState = {
  helpBot: null,
  bots: [],
  settings: {},
  activeBot: null,
  loadingError: null,
  isInitialized: false,
  isLoading: false,
};

const BotsReducer = (state, action) => {
  // logger.dev("[BotsReducer]", action.type);
  const { bots, settings, activeBot, loadingError, helpBot } = action.payload || {};

  const extendBot = (bot, settings) => {
    const localState = bot && botById(settings.bots, bot.id);
    return localState ? { ...bot, ...localState } : bot;
  };

  const extendBots = (bots, settings) => {
    return bots && bots.map((bot) => extendBot(bot, settings));
  };

  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        helpBot,
        bots: extendBots(bots, state.settings),
        activeBot: extendBot(activeBot, state.settings),
        loadingError,
        isInitialized: true,
        isLoading: false,
      };
    case REFRESH_START:
      return {
        ...state,
        isLoading: true,
        loadingError: null,
      };
    case REFRESH_END:
      return {
        ...state,
        helpBot,
        bots: extendBots(bots, state.settings),
        activeBot: extendBot(
          !activeBot || activeBot.id === state.activeBot?.id
            ? activeBot
            : state.activeBot,
          state.settings
        ),
        isLoading: false,
        loadingError,
      };
    case BOTS_UPDATED:
      return {
        ...state,
        bots: extendBots(bots, state.settings),
        activeBot: extendBot(
          !activeBot || activeBot.id === state.activeBot?.id
            ? activeBot
            : state.activeBot,
          state.settings
        ),
      };
    case SET_SETTINGS:
      return {
        ...state,
        bots: extendBots(state.bots, settings),
        activeBot: extendBot(state.activeBot, settings),
        settings,
      };
    case SET_ACTIVE:
      return {
        ...state,
        activeBot: extendBot(activeBot, state.settings),
      };
    default:
      return state;
  }
};

export {
  INITIALIZE,
  REFRESH_START,
  REFRESH_END,
  BOTS_UPDATED,
  SET_SETTINGS,
  SET_ACTIVE,
  initialState,
  BotsReducer,
};
