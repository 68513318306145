import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import SignUpComplete from "../../components/auth/SignUpComplete";
import { useTranslation } from "../../hooks/useLocalization";

const SignUpCompletePage = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet title={t("Sign Up")} />
      <div className="text-center mt-4">
        <h1 className="h2">{t("Sign up")}</h1>
        <p className="lead">
          {t("Please, fill in the form to complete the registration")}
        </p>
      </div>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <SignUpComplete />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignUpCompletePage;
