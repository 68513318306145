import { Alert, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "../../../../../hooks/useLocalization";

const ChannelState = ({ channel, handlers }) => {
  const { t } = useTranslation();
  const { validated } = channel || {};
  return validated ? (
    <Alert variant="primary" className="mt-4">
      <div className="alert-message">
        <h4 className="alert-heading">{t("That’s it!")}</h4>
        <p>{t("This was all the steps")}</p>
        <i>{t("To activate your chatbot, click...")}</i>
        <hr />
        <Button variant="light" className="me-1" onClick={handlers?.onBack}>
          {t("Integrations")}
        </Button>
      </div>
    </Alert>
  ) : null;
};

export default ChannelState;
