import logger from "../utils/logger";
import { hasTokens } from "./token";

// WebSocket
// ------------------------
class socket {
  #id;
  #url;

  #ws;
  #query;
  #reconnect;
  #connecting;
  #connected;
  #handlers;
  #tokensSent;

  constructor(url) {
    this.#id = socket.genId.next().value;
    this.#url = url;
    this.#query = [];
    this.ontick();
  }

  get id() {
    return this.#id;
  }

  get url() {
    return this.#url;
  }

  get isConnected() {
    return !!this.#connected;
  }

  get isConnecting() {
    return !!this.#connecting;
  }

  connect = ({ reconnect = 2000, handlers = {} }) => {
    if (this.#connecting || this.#connected) {
      return;
    }

    const url = this.#url;
    if (!url) {
      logger.warn(`[socket-${this.#id}] Connection error (No url specified)`);
      return;
    }

    this.#reconnect = reconnect;
    this.#handlers = handlers;
    this.close();

    try {
      logger.net(`[socket-${this.#id}] Connecting`);
      const ws = new WebSocket(url);
      ws.onopen = this.onopen;
      ws.onerror = this.onerror;
      ws.onmessage = this.onmessage;
      ws.onclose = this.onclose;

      this.#ws = ws;
      this.#connecting = true;
    } catch (error) {
      logger.warn(`[socket-${this.#id}] Connection error (${error.message})`);
      this.#ws = null;
      this.#connecting = false;
    }
  };

  disconnect = ({ handlers = {} }) => {
    this.#reconnect = 0;
    this.#handlers = handlers;
    this.close();
  };

  send = ({
    data,
    callback,
    timeout = 5000,
    retriesNum = 1,
    dropMessages = false,
  }) => {
    const taskId = socketTask.genId.next().value;
    const taskIns = new socketTask(taskId, null, callback, timeout, retriesNum);

    if (!this.#connected && dropMessages) {
      taskIns.onerror(this.#id, {
        error: {
          errorCode: -1,
          errorMessage: `Connection error`,
        },
      });
      return;
    }

    try {
      data = { ...(data || {}), request_id: taskId };
      taskIns.data = JSON.stringify(data);
      taskIns.hasTokens = hasTokens(data);
    } catch (error) {
      taskIns.onerror(this.#id, {
        error: {
          errorCode: -1,
          errorMessage: `Serialization error (${error.message})`,
        },
      });
      return;
    }

    this.#query.push(taskIns);
    return taskIns;
  };

  clean = () => {
    this.cleanQuery();
  };

  ontick = () => {
    (
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      ((c) => setTimeout(c, 1000 / 60))
    )(this.ontick);

    const query = this.#query;
    if (this.#connecting || !(this.#connected && query?.length)) {
      return;
    }

    let index = 0;
    while (index < query.length) {
      const task = query[index];
      const { id, receivedData, hasTokens } = task;

      if (!(this.#tokensSent || hasTokens)) {
        // The first tokens or their sending are obligatory
        index++;
        continue;
      }

      if (task.hasResponse()) {
        this.#tokensSent ||= hasTokens;
        task.oncomplete(this.#id, receivedData);
        query.splice(index, 1);
        continue;
      }

      if (task.needToSend()) {
        const canToSend = task.tryToSend();

        if (!canToSend && hasTokens) {
          // Tokens are very important, try again automatically
          task.reset();
          index++;
          continue;
        }

        if (!canToSend) {
          task.onerror(this.#id, {
            error: {
              errorCode: -1,
              errorMessage: `Connection timeout`,
            },
          });
          query.splice(index, 1);
          continue;
        }

        try {
          this.#ws.send(task.data);
          logger.net(`[socket-${this.#id}] [task/${id}] Flush data`);
        } catch (error) {
          logger.warn(
            `[socket-${this.#id}] [task/${id}] Sending error (${error.message})`
          );
          query.splice(index, 1);
          continue;
        }
      }

      index++;
    }
  };

  onopen = () => {
    logger.net(`[socket-${this.#id}] Connected`);
    this.#connecting = false;
    this.#connected = true;
    const { onopen } = this.#handlers || {};
    onopen && onopen();
  };

  onmessage = (event) => {
    let receivedData;
    let request_id;

    try {
      receivedData = JSON.parse(event.data);
      ({ request_id } = receivedData);
    } catch (error) {
      logger.warn(
        `[socket-${this.#id}] Serialization error (${error.message})`
      );
    }

    const query = this.#query;
    const task = query?.find(({ id }) => id === request_id);
    if (!task) {
      logger.warn(
        `[socket-${this.#id}] Response error (No task with id "${request_id}"")`
      );
      return;
    }

    task.receivedData = receivedData;
  };

  onerror = (event) => {
    logger.warn(`[socket-${this.#id}] Connection ${event.type}`);
    const { onerror } = this.#handlers || {};
    onerror && onerror();
  };

  onclose = () => {
    logger.net(`[socket-${this.#id}] Disconnected`);
    this.close();
    this.refreshQuery();
    const { onclose } = this.#handlers || {};
    onclose && onclose();

    const reconnect = this.#reconnect;
    if (reconnect > 0) {
      const handlers = this.#handlers;
      setTimeout(() => this.connect({ reconnect, handlers }), reconnect);
    }
  };

  close = () => {
    try {
      this.#ws.close();
      this.#ws.onopen = null;
      this.#ws.onerror = null;
      this.#ws.onmessage = null;
      this.#ws.onclose = null;
    } catch (e) {
    } finally {
      this.#ws = null;
    }
    this.#connecting = this.#connected = this.#tokensSent = false;
  };

  refreshQuery = () => {
    const query = this.#query;
    if (query?.length) {
      let index = 0;
      while (index < query.length) {
        const taskIns = query[index];
        const { receivedData } = taskIns;
        if (receivedData === null) {
          taskIns.sentStamp = 0;
        }
        index++;
      }
    }
  };

  cleanQuery = () => {
    const query = this.#query;
    while (query?.length) {
      const taskIns = query.shift();
      taskIns.onerror(this.#id, {
        error: {
          errorCode: -1,
          errorMessage: `Connection error`,
        },
      });
    }
  };

  static genId = (function* () {
    let id = 0;
    while (true) yield id++;
  })();
}

// WebSocket Task
// ------------------------
class socketTask {
  sentStamp = 0;
  hasTokens = false;
  receivedData = null; // object | arraybuffer
  retriesNum = 0;
  retriesNumArg = 0;

  constructor(id, data, callback, timeout, retriesNum) {
    this.id = id;
    this.data = data; // string | arraybuffer
    this.callback = callback;
    this.timeout = timeout | 0;
    this.retriesNum = retriesNum | 0;
    this.retriesNumArg = this.retriesNum;
  }

  needToSend() {
    return !this.sentStamp || Date.now() - this.sentStamp > this.timeout;
  }

  tryToSend() {
    if (this.retriesNum < 0) {
      return false;
    }
    this.retriesNum--;
    this.sentStamp = Date.now();
    return true;
  }

  hasResponse() {
    return this.sentStamp && this.receivedData !== null;
  }

  reset() {
    this.sentStamp = 0;
    this.retriesNum = this.retriesNumArg;
  }

  oncomplete(socketID, response) {
    this.dispatch(socketID, "complete", response);
  }

  onerror(socketID, response) {
    this.dispatch(socketID, "failed", response);
  }

  dispatch(socketID, message, data) {
    const id = this.id;
    const callback = this.callback;
    if (typeof callback !== "function") {
      logger.warn(
        `[socket-${socketID}] [task/${id}] ${message} (without dispatch)`
      );
      return;
    }

    try {
      callback(data);
      (message === "failed" ? logger.warn : logger.net)(
        `[socket-${socketID}] [task/${id}] ${message}`
      );
    } catch (error) {
      logger.warn(
        `[socket-${socketID}] [task/${id}] ${message} (with error "${error.message}")`
      );
    } finally {
      this.callback = null;
    }
  }

  static genId = (function* () {
    let id = 0;
    while (true) yield id++;
  })();
}

export default socket;
