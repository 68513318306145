/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import useAnalytics from "../../../hooks/useAnalytics";

const NavListItemAction = ({ title, onClick, depth = 0, icon: Icon }) => {
  const { CONSOLE_EVENTS } = useAnalytics();

  return (
    <li className="sidebar-item">
      <a
        rel="noreferrer"
        href="/"
        activeclassname="active"
        className="sidebar-link d-flex align-items-center"
        onClick={async (event) => {
          await CONSOLE_EVENTS.EV_Action.send({ params: { title: title } })
          event.preventDefault();
          onClick();
        }}
      >
        {Icon && <Icon className="feather align-middle" />}{" "}
        <span
          className="align-middle d-inline-block text-truncate"
          style={{ maxWidth: "150px" }}
          depth={depth}
        >
          {title}
        </span>
      </a>
    </li>
  );
};

export default NavListItemAction;
