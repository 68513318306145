import React, { useState } from "react";
import { Badge, Button, ListGroup, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Users, X } from "react-feather";
import Select from "react-select";
import useAuth from "../../hooks/useAuth";
import useBots from "../../hooks/useBots";
import { useTranslation } from "../../hooks/useLocalization";
import useMessage from "../../hooks/useMessage";
import api from "../../net/api";

const EditUserRoles = ({ account: acc }) => {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = React.useState(false);
  const [license, setLicense] = useState(JSON.parse(acc.license || "[]"));
  const { bots: allBots } = useBots();

  const optionsBots = allBots.filter((a, i) => i !== 0).map((bot) => {
    return {
      value: bot.id, label: bot.name
    }
  })

  const optionsRoles = [];
  for (let key in api.ROLES) {
    optionsRoles.push({ value: api.ROLES[key], label: api.ROLES[key] })
  }

  const onClickHandler = () => {
    setModalShow(!modalShow);
  }

  const ModalEditUsers = (props) => {
    const { updateLicense } = useAuth();
    const { showMessageWithDebug, showMessage } = useMessage();
    const [selRow, setSelRow] = useState(-1);
    const [iRow, setIRow] = useState(-1);
    const [selBots, setSelBots] = useState([]);
    const [selRoles, setSelRoles] = useState([]);

    const deleteRoleHandler = async (row, bot) => {
      let newLicense = license
        .map((item) => {
          delete item.all_permissions;
          if (item === row && !item.bots) return undefined;
          if (item === row) {
            item.bots = item.bots?.filter((id) => id !== bot)
            if (item.bots?.length === 0) return undefined;
          }
          return item;
        })
        .filter((item) => item !== undefined)

      let req = {
        user: {
          uid: acc.uid,
          license: newLicense
        }
      }

      try {
        let user = await updateLicense(req);
        setLicense(user.license);
        showMessage({ save: "" })
      } catch (error) {
        const { message = t("Something went wrong"), debug } = error;
        showMessageWithDebug({ error: message, debug });
      }
    }

    const onUpdateRolesHandler = async () => {
      let req = {}
      if (selRow === -1) {
        if (selBots.length > 0) {
          license.forEach(item => delete item.all_permissions)
          req = {
            user: {
              uid: acc.uid,
              license: [
                ...license,
                {
                  bots: selBots.map((id) => id.value),
                  roles: selRoles.map((role) => { return { name: role.value } })
                }
              ]
            }
          }
        } else {
          license.forEach(item => {
            delete item.all_permissions;
            if (!item.bots) {
              item.roles = selRoles.map((role) => { return { name: role.value } })
            }
          })

          if (license.length === 0) license[0] = { roles: selRoles.map((role) => { return { name: role.value } }) }
          else license.unshift({ roles: selRoles.map((role) => { return { name: role.value } }) })

          req = {
            user: {
              uid: acc.uid,
              license: [
                ...license,
              ]
            }
          }
        }
      } else {
        if (selBots.length > 0) {
          license.forEach(item => {
            delete item.all_permissions;
            if (item === selRow) {
              // if (item.bots) {
              item.bots = selBots.map((id) => id.value);
              item.roles = selRoles.map((role) => { return { name: role.value } });
              // } else {

              // }
            }
          })

          req = {
            user: {
              uid: acc.uid,
              license: [
                ...license,
              ]
            }
          }
        } else {
          license.forEach(item => {
            delete item.all_permissions;
            if (!item.bots) {
              item.roles = selRoles.map((role) => { return { name: role.value } })
            }
          })

          req = {
            user: {
              uid: acc.uid,
              license: [
                ...license,
              ]
            }
          }
        }
      }

      try {
        let user = await updateLicense(req);
        setLicense(user.license);
        showMessage({ save: "" })
      } catch (error) {
        const { message = t("Something went wrong"), debug } = error;
        showMessageWithDebug({ error: message, debug });
      }
    }

    const selectBotRow = (objRow, i) => {
      setIRow(i);
      setSelRow(objRow)

      let selectedBots = objRow.bots?.map((id) => { return { value: id, label: getBotName(id) } }) || [];
      setSelBots(selectedBots);

      let selectedRoles = objRow.roles?.map((role) => { return { value: role.name, label: role.name } }) || [];
      setSelRoles(selectedRoles);
    }

    const getBotName = (id) => {
      return allBots.filter((bot) => bot.id === id)[0]?.name;
    }

    const onChangeRoles = (e) => {
      setSelRoles(e);
    }

    const onChangeBots = (e) => {
      setSelBots(e);
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="h-90"
      >
        <Modal.Header className="d-flex flex-column justify-content-center">
          <button className="btn-close" onClick={() => setModalShow(!modalShow)}></button>
          <h3>Edit Roles</h3>
          <div className="d-flex justify-content-between w-100">
            <div>{t("User")}:<br /><b>{acc.displayName}</b></div>
            <div>{t("Email")}:<br /><b>{acc.email}</b></div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <h4>{t("Roles")}</h4>
          <ListGroup className="scrollbar-container">
            {license.map((row, i) => {
              if (row.roles && !row.bots) {
                return (
                  <ListGroup.Item
                    as="a" key={i}
                    action
                    onClick={() => selectBotRow(row, i)}
                    className={`d-flex justify-content-between ${iRow === i ? "active" : ""}`}
                  >
                    <div className="d-flex">
                      <b>All Bots&nbsp;</b> - | {row.roles.map((role) => `${role.name} | `)}
                    </div>
                    <Button className="btn-danger p-0" onClick={() => deleteRoleHandler(row, "All")}>
                      <X />
                    </Button>
                  </ListGroup.Item>
                )
              }
              if (row.roles && row.bots) {
                return (
                  row.bots.map((botID, ii) => {
                    return (
                      <ListGroup.Item
                        as="a"
                        key={i + ii}
                        action
                        onClick={() => selectBotRow(row, i + ii)}
                        className={`d-flex justify-content-between ${iRow === i + ii ? "active" : ""}`}
                      >
                        <div className="d-flex">
                          <b>{getBotName(botID)}&nbsp;</b> - | {row.roles.map((role) => `${role.name} | `)}
                        </div>
                        <Button className="btn-danger p-0" onClick={() => deleteRoleHandler(row, botID)}>
                          <X />
                        </Button>
                      </ListGroup.Item>
                    )
                  })
                )
              }
              return null
            })}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100"><h4>{t("Assign")} {t("Roles")}</h4></div>
          <div className="w-100"><h5>{t("Select Bots")}</h5></div>
          <div className="w-100">
            <Select
              placeholder="All Bots"
              key={selBots}
              defaultValue={selBots}
              closeMenuOnSelect={false}
              isMulti
              options={optionsBots}
              onChange={(e) => onChangeBots(e)}
            >
            </Select>
          </div>
          <div className="w-100"><h5>{t("Select Roles")}</h5></div>
          <div className="w-100">
            <Select
              key={selRoles}
              defaultValue={selRoles}
              closeMenuOnSelect={false}
              isMulti
              options={optionsRoles}
              onChange={(e) => onChangeRoles(e)}
            >
            </Select>
          </div>
          <Button onClick={onUpdateRolesHandler} disabled={!selRoles.length}>{t("Assign")}</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>{t("Edit")}</Tooltip>}>
        <Button variant="light" className="m-1 p-1 position-relative" onClick={onClickHandler}>
          {license.length ? <Badge className="position-absolute" style={{ display: "block", height: "10px", width: "10px", borderRadius: "505%", right: 0 }}><span></span></Badge> : null}
          <Users />
        </Button>
      </OverlayTrigger>
      <ModalEditUsers show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

export default EditUserRoles;