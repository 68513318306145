/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import useSidebar from "../../../hooks/useSidebar";
import useTutorial from "../../../hooks/useTutorial";
import { isMobile } from "../../../utils/screen";
import NavbarNotifications from "../../navbar/NavbarNotifications";

import useAnalytics from "../../../hooks/useAnalytics";

const NavListItemHeadUser = ({ title, href, depth = 0, icon: Icon }) => {
  const dropRef = useRef();
  const navigate = useNavigate();
  const { isOpen, setIsOpen } = useSidebar();
  const { tutorials } = useTutorial();
  const { CONSOLE_EVENTS } = useAnalytics();

  return (
    <li className="sidebar-item head user active">
      <div
        className="sidebar-link head user d-flex align-items-center pe-0"
        onClick={async (event) => {
          const { target } = event;
          if (dropRef.current?.contains(target)) {
            return; // NOTE: Click on Notifications
          }
          if (isMobile()) {
            setIsOpen(!isOpen);
          }
          navigate(href);
          await CONSOLE_EVENTS.EV_Locate.send({ params: { onmobile: isMobile(), href: href } });
        }}
      >
        <div className="flex-grow-1">
          {Icon && <Icon className="feather align-middle" />}{" "}
          <span
            className="align-middle d-inline-block text-truncate"
            style={{ maxWidth: "120px" }}
            depth={depth}
          >
            {title}
          </span>
        </div>
        <div ref={dropRef}>
          <NavbarNotifications tutorials={tutorials} />
        </div>
      </div>
    </li>
  );
};

export default NavListItemHeadUser;
