/*eslint no-new-func: "off"*/
import logger from "../utils/logger";

/******************************************************************************
 *                        Dashboard editor environement
 *
 *                                                   (c) 2021 qudata.com, steps
 *******************************************************************************/
class QuBotDashboardEditorEnv {
  #uiAPI = {};
  #useAPI = {};

  constructor(dhEnv, qbEnv, { type, cid }) {
    this.dhEnv = dhEnv;
    this.qbEnv = qbEnv;
    this.type = type;
    this.cid = cid;
    this.clientToken = null;
    this.isToken = false;
    this.channelToken = null;
    this.setClientToken(null);
  }

  /**************************************************************************
   */
  static isCodeLoaded() {
    return typeof this.libClass() === "function";
  }

  /**************************************************************************
   */
  static libClass() {
    return new Function("return QuBotDashboardEditorLib;")();
  }

  /**************************************************************************
   * UI api
   */
  async ui(value) {
    this.#uiAPI = { ...this.#uiAPI, ...value };
  }

  /**************************************************************************
   * Use api
   */
  async use(value) {
    this.#useAPI = { ...this.#useAPI, ...value };
  }

  /**************************************************************************
   * Create editor environement
   */
  async create({ rootDiv }) {
    // logger.dev(`[QuBotDashboardEditorEnv] create`);
    this.lib = new (QuBotDashboardEditorEnv.libClass())(this);
    return this.lib.create({
      rootDiv,
      loadBots: false,
      botLib: this.qbEnv.lib,
    });
  }

  changeLang(lang) {
    this.lib.changeLang(lang);
  }

  /**************************************************************************
   * Save editor param
   */
  async save({ param, data }) {
    const { useOption } = this.#useAPI;
    if (useOption) {
      const [option, setOption] = useOption;
      if (typeof data === "string") {
        try {
          data = JSON.parse(data);
        } catch (e) { }
      }
      setOption({ ...option, [param]: data });
    }
    return { status: true };
  }

  /**************************************************************************
   * Load editor param
   */
  async load({ param }) {
    const { useOption } = this.#useAPI;
    if (useOption) {
      const [option] = useOption;
      return option[param];
    }
    return null;
  }

  /**************************************************************************
   * Save bot
   */
  async saveBot(props) {
    return await this.dhEnv.saveBot(props);
  }

  /**************************************************************************
   * Load bot
   */
  async loadBot(props) {
    return await this.dhEnv.loadBot(props);
  }

  /**************************************************************************
   * Save setup
   */
  async saveSetup(props) {
    return await this.dhEnv.saveSetup(props);
  }

  /**************************************************************************
   * Load setup
   */
  async loadSetup(props) {
    return await this.dhEnv.loadSetup(props);
  }

  /**************************************************************************
   * Save image
   */
  async saveFile(props) {
    return await this.dhEnv.saveFile(props);
  }

  /**************************************************************************
   * Load image URL
   */
  async loadFile(props) {
    return await this.dhEnv.loadFile(props);
  }

  /**************************************************************************
   * Load all image file names
   */
  async loadFileNames(props) {
    return await this.dhEnv.loadFileNames(props);
  }

  /**************************************************************************
   * Delete image
   */
  async deleteFile(props) {
    return await this.dhEnv.deleteFile(props);
  }

  /**************************************************************************
   * New bot
   */
  async newBot() {
    // return null;
  }

  /**************************************************************************
   * Delete bot
   */
  async deleteBot({ botID }) {
    // localStorage.removeItem(`qubotEditorMyBots.${botID}`);
  }

  /**************************************************************************
   * Retrieving data from storage.
   *
   * * @param {*} last - How many recent entries. If last < 0 then everything.
   */
  async receive({ storage, ownerID, botID, last = -1 }) {
    // let id = `qubotSaveData.${ownerID}.${botID}`;
    // let data = localStorage.getItem(id);
    // if (data) {
    //   data = JSON.parse(data);
    //   if (last > 0) data = data.slice(-last);
    // }
    // return data;
  }

  /**************************************************************************
   * Send bot manager request
   *
   * * @param {*} request - request object.
   */
  async sendBotManager({ request }) {
    //   if (!this.clientToken) {
    //     return;
    //   }
    //   request["plugin"] = "qudata";
    //   let requestName = request["request"];
    //   //console.log(requestName)
    //   let url = window.editorConfig.botManager + requestName + "/";
    //   let headers = {
    //     "Qubot-Token-Channel": this.channelToken,
    //     "Qubot-Token-Client": this.clientToken,
    //   };
    //   this.sendPost(url, JSON.stringify(request), headers, callback);
  }

  /**************************************************************************
   * Run extension method
   *
   * * @param {*} extensionName - extension name
   * * @param {*} version - version of extension/request
   * * @param {*} requestName - request name
   * * @param {*} requestParams - request params
   */
  async runExtension(extensionName, version, requestName, requestParams) {
    // return null;
  }

  /**************************************************************************
   * Click event
   */
  async onClick({ target }) {
    const {
      MyBots,
      Templates,
      Examples,
      CreateBot,
      CloneBot,
      DeleteBot,
      RenameBot,
    } = this.#uiAPI;
    switch (target) {
      case "MyBots":
        return MyBots();
      case "Templates":
        return Templates();
      case "Examples":
        return Examples();
      case "EditBot.New":
        return CreateBot();
      case "EditBot.Clone":
        return CloneBot();
      case "EditBot.Delete":
        return DeleteBot();
      case "EditBot.Rename":
        return RenameBot();
      default:
        logger.dev("[QuBotDashboardEditorEnv] onClick", target);
    }
  }

  /**************************************************************************
   * Resize window event
   */
  async onResizeEvent(event) {
    if (typeof this.lib.onResizeEvent === "function") {
      await this.lib.onResizeEvent();
    }
  }

  /**************************************************************************
   * Before unload event
   */
  async onBeforeUnloadEvent() {
    if (typeof this.lib.onBeforeUnloadEvent === "function") {
      await this.lib.onBeforeUnloadEvent();
    }
  }

  /**************************************************************************
   * Set client token
   *
   * * @param {*} token  - client token.
   */
  setClientToken(token) {
    this.isToken = token?.length > 10;
    this.clientToken = this.isToken ? token : null;
  }

  /**************************************************************************
   * Get client token
   *
   */
  getClientToken() {
    return this.clientToken;
  }

  /**************************************************************************
   */
  getDate(today) {
    let mm = (today.getMonth() + 1).toString(),
      dd = today.getDate().toString();
    if (mm.length === 1) {
      mm = "0" + mm;
    }
    if (dd.length === 1) {
      dd = "0" + dd;
    }
    return today.getFullYear() + "-" + mm + "-" + dd;
  }

  /**************************************************************************
   */
  getTime(today) {
    let hh = today.getHours().toString(),
      mm = today.getMinutes().toString(),
      ss = today.getSeconds().toString();
    if (hh.length === 1) {
      hh = "0" + hh;
    }
    if (mm.length === 1) {
      mm = "0" + mm;
    }
    if (ss.length === 1) {
      ss = "0" + ss;
    }
    return hh + ":" + mm + ":" + ss;
  }

  /**************************************************************************
   */
  getDateTime(today) {
    return this.getDate(today) + " " + this.getTime(today);
  }
}

export default QuBotDashboardEditorEnv;
