import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { BOT_CHANNEL } from "../../contexts/bots/BotsContext";
import { useTranslation } from "../../hooks/useLocalization";

import useAnalytics from "../../hooks/useAnalytics";

const ChannelDisableModal = ({ channelName, open, setOpen, onSubmit }) => {
  const { t, tt } = useTranslation();
  const { CONSOLE_EVENTS } = useAnalytics();
  const onClose = async (disable) => {
    onSubmit(!!disable);
    setOpen(false);
    await CONSOLE_EVENTS.EV_Channels.send({ action: (disable) ? 'disable' : 'disable_close', params: { name: channelName } })
  };
  return (
    <Modal show={open} onHide={onClose} enforceFocus={false}>
      <Helmet title={t("Deactivate the channel")} />
      <Modal.Header closeButton>{t("Deactivate the channel")}</Modal.Header>
      <div className="modal-body-container ps-dark">
        <Modal.Body className="m-4">
          <p>
            {tt(
              "Are you sure that you want to deactivate the X",
              t(BOT_CHANNEL.title(channelName))
            )}
          </p>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose(false)}>
          {t("Cancel")}
        </Button>{" "}
        <Button variant="primary" onClick={() => onClose(true)}>
          {t("Deactivate")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChannelDisableModal;
