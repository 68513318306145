import React, { useMemo } from "react";
import useAuth from "../hooks/useAuth";
import useBots from "../hooks/useBots";
import { useTranslation } from "../hooks/useLocalization";
import { uniqueArray } from "../utils/data";
import AdminBotsTable from "./tables/AdminBotsTable";

const AdminBots = () => {
  const { t } = useTranslation();
  const { bots } = useBots();
  const { user } = useAuth();
  const permissions = user?.license?.map((item) => item);

  const headers = useMemo(
    () =>
      uniqueArray(
        ["Bots", "Bundle", "Owner", "Status", "Actions"]
      ),
    []
  );

  const tableColumns = headers.map((name) => {
    let accessor;
    switch (name) {
      case "Bots": accessor = "name"; break;
      case "Bundle": accessor = "bundle"; break;
      case "Owner": accessor = "author"; break;
      case "Status": accessor = "status"; break;
      case "Actions": accessor = "actions"; break;
      default:
    }
    return {
      Header: name,
      accessor
    }
  });

  const validStatus = (val) => {
    if (val === "review") return t("Under review");
    if (val === "maintenance") return t("Maintenance");
    if (val === "publish") return t("Published");
    if (val === "dev") return t("In development");
    return val;
  }

  const tableData = bots.map((val) =>
    Object.assign(
      {},
      ...Object.keys(val).map((key) => ({
        [key]:
          ["name", "bundle", "author", "status"].indexOf(key) < 0 &&
            val[key] &&
            typeof val[key] === "object"
            ? JSON.stringify(val[key])
            : key === "author"
              ? val[key]["login"] || val[key]["displayName"]
              : key === "status"
                ? validStatus(val[key])
                : val[key],
      }))
    )
  )
    .filter((a, i) => i !== 0)
    .map((item) => {
      return {
        ...item,
        actions: permissions ? permissions[0] : {},
      }
    })

  return (
    <>
      <AdminBotsTable columns={tableColumns} data={tableData} wheelPropagation={true} />
    </>
  )
}

export default AdminBots;