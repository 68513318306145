import { useTranslation } from "../hooks/useLocalization";

const USDateStr = (str, splitter = "-") => {
  if (typeof str !== "string") {
    return str;
  }
  const [dd, mm, yy] = str.split(splitter);
  return [mm, dd, yy].join(splitter);
};

const dateString = (time, splitter = "/") => {
  return typeof time === "string" || typeof time === "number"
    ? USDateStr(
      new Date(time).toLocaleDateString("en-GB").replace(/\//g, splitter),
      splitter
    )
    : "-";
};

const localeDateString = (time, locale = "en") => {
  return typeof time === "string" || typeof time === "number"
    ? new Date(time).toLocaleDateString(locale, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })
    : "-";
};

const useLocaleDateString = () => {
  const { i18n } = useTranslation();
  return (time) => localeDateString(time, i18n.language);
};

const reverseDateStr = (str, splitter = "-") => {
  return typeof str === "string"
    ? str.split(splitter).reverse().join(splitter)
    : str;
};

const sortableDateString = (time, splitter = "-") => {
  return typeof time === "string" || typeof time === "number"
    ? reverseDateStr(
      new Date(time).toLocaleDateString("en-GB").replace(/\//g, splitter),
      splitter
    )
    : "-";
};

const sortableTimeString = (time) => {
  return typeof time === "string" || typeof time === "number"
    ? new Date(time).toLocaleTimeString("en-GB")
    : "-";
};

const uniqueArray = (arrArg) => {
  return arrArg.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
};

export {
  dateString,
  localeDateString,
  useLocaleDateString,
  sortableDateString,
  sortableTimeString,
  uniqueArray,
};
