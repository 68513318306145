import { useMemo } from "react";
import { config } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import useBots from "../../../hooks/useBots";

const useDebugProps = () => {
  const { routes, channelToken, voiceProcessing } = config;
  const { isAuthenticated, user } = useAuth();
  const { uid } = user || {};
  const { activeBot, requestLocalFiles, getParam, setParam, setBotParam } =
    useBots();

  const {
    id = "",
    updateRevision,
    testingRevision,
    localDirectory,
    hasPermissions,
    readdirError,
    updateError,
  } = activeBot || {};

  return [
    // Qubot
    useMemo(
      () => ({
        id,
        uid,
        routes,
        open: 0,
        channelToken,
        voiceProcessing,
      }),
      [id, uid, channelToken, routes, voiceProcessing]
    ),
    // Dashboard
    {
      isAuthenticated,
      requestLocalFiles,
      updateRevision,
      testingRevision,
      localDirectory,
      hasPermissions,
      readdirError,
      updateError,
      getParam,
      setParam,
      setBotParam,
    },
  ];
};

const logLevelVariant = (level) =>
  (level === "error" && "danger") ||
  (level === "debug" && "secondary") ||
  ((level === "warning" || level === "info") && level) ||
  "primary";

export { useDebugProps, logLevelVariant };
