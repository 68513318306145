/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import { Badge, Collapse } from "react-bootstrap";
import useSidebar from "../../../hooks/useSidebar";
import { isMobile } from "../../../utils/screen";
import CustomRouterLink from "./CustomRouterLink";

import useAnalytics from "../../../hooks/useAnalytics";

const NavListItem = ({ title, href, depth = 0, children, icon: Icon, badge, variant, open: openProp, onClick, }) => {
  const { isOpen, setIsOpen } = useSidebar();
  const [open, setOpen] = useState(openProp);
  const { CONSOLE_EVENTS } = useAnalytics();

  useEffect(() => {
    if (openProp) {
      setOpen(openProp);
    }
  }, [openProp]);

  const handleToggle = (event) => {
    if (typeof onClick === "function") {
      onClick(event);
    }
    if (!event.defaultPrevented) {
      setOpen(!open);
    }
  };

  if (children) {
    return (
      <li className={`sidebar-item ${open ? "active" : ""}`}>
        <a
          className={`d-flex align-items-center sidebar-link ${open ? "" : "collapsed"
            }`}
          data-bs-toggle="collapse"
          aria-expanded={open ? "true" : "false"}
          depth={depth}
          onClick={handleToggle}
        >
          {Icon && <Icon className="feather align-middle" />}{" "}
          <span className="align-middle" depth={depth}>
            {title}
          </span>
          {typeof badge === "string" && (
            <Badge
              className={`rounded-pill badge-sidebar-${variant ? variant : "primary"
                }`}
              bg=""
              size={18}
            >
              {badge}
            </Badge>
          )}
        </a>
        <Collapse in={open}>
          <ul className="sidebar-dropdown list-unstyled">{children}</ul>
        </Collapse>
      </li>
    );
  }

  return (
    <li className="sidebar-item">
      <CustomRouterLink
        depth={depth}
        to={href || "/"}
        activeclassname="active"
        className="sidebar-link d-flex align-items-center"
        onClick={async (event) => {
          await CONSOLE_EVENTS.EV_Locate.send({ params: { onmobile: isMobile(), href: href } });
          if (isMobile()) {
            setIsOpen(!isOpen);
          }
          if (onClick) {
            event.preventDefault();
            onClick(event);
          }
        }}
      >
        {Icon && <Icon className="feather align-middle" />}{" "}
        <span
          className="align-middle d-inline-block text-truncate"
          style={{ maxWidth: "150px" }}
          depth={depth}
        >
          {title}
        </span>
        {typeof badge === "string" && (
          <Badge
            className={`rounded-pill badge-sidebar-${variant ? variant : "primary"
              }`}
            bg=""
            size={18}
          >
            {badge}
          </Badge>
        )}
      </CustomRouterLink>
    </li>
  );
};

export default NavListItem;
