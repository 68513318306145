import React, { useCallback, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "../../hooks/useLocalization";
import { Languages } from "../../languages/languages";
import useQubot from "../../hooks/useQubot";

const NavbarLanguages = ({ className, style, align, open, setOpen }) => {
  const { i18n, t } = useTranslation();
  const selectedLanguage = Languages[i18n.language] || Languages.en;
  const menuRef = useRef();
  const hideToggle = typeof open === "boolean";

  const qubot = useQubot();

  const isContains = useCallback(
    (e) => menuRef.current?.contains(e.target),
    [menuRef]
  );

  const handleEscape = useCallback(
    (e) => {
      if (e.key === "Escape") {
        typeof setOpen === "function" && setOpen(false);
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [setOpen]
  );

  useEffect(() => {
    window.removeEventListener("keydown", handleEscape, { capture: true });
    if (open) {
      window.addEventListener("keydown", handleEscape, { capture: true });
    }
    window.onmouseup = (e) => {
      if (open && !isContains(e)) {
        typeof setOpen === "function" && setOpen(false);
      }
    };
    return () => {
      window.removeEventListener("keydown", handleEscape, { capture: true });
      window.onmouseup = null;
    };
  }, [open, isContains, setOpen, handleEscape]);

  return (
    <Dropdown
      className={`me-2 nav-item ${className}`}
      style={style}
      align={align}
      show={open}
    >
      <Dropdown.Toggle
        as="a"
        className={`nav-link nav-flag ${hideToggle ? "opacity-0 user-select-none" : ""
          }`}
      >
        <img src={selectedLanguage?.icon} alt={selectedLanguage?.name} />
      </Dropdown.Toggle>
      <Dropdown.Menu ref={menuRef}>
        {Object.keys(Languages).map((language) => (
          <Dropdown.Item
            key={language}
            onClick={() => {
              i18n.changeLanguage(language);
              window.localStorage.setItem("language", language);
              qubot?.changeLang(language);
            }}
          >
            <img
              src={Languages[language].icon}
              alt="flag"
              width="20"
              className="align-middle me-1"
            />
            <span className="align-middle">{t(Languages[language].name)}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarLanguages;
