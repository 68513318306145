import { Container, Row, Col } from "react-bootstrap";
import { useDebugProps } from "./debug/utils";
import States from "./debug/States";
import Slots from "./debug/Slots";
import DebugConsole from "./debug/DebugConsole";
import useBots from "../../hooks/useBots";
import { useContext } from "react";
// import { ChatBotComponent, BotsContext } from "qubot";
const ChatBotComponent = null;
const BotsContext = null;

const Testing = () => {
  const { id, apiIsReady } = useContext(BotsContext);
  const [, props] = useDebugProps();
  const panelProps = { id, ...props };
  const { activeBot, getParam, setParam } = useBots();
  const isDebugOpen = !!getParam("isDebugOpen", true);
  return (
    apiIsReady() &&
    id === activeBot.id && (
      <Container
        fluid
        className={`testing ${!isDebugOpen ? "debug-collapsed" : ""}`}
      >
        <Row className="testing-start">
          <Col>
            <States {...panelProps} />
          </Col>
          <Col>
            <Slots {...panelProps} />
          </Col>
        </Row>
        <Row className="testing-end">
          <Col>
            <DebugConsole
              {...panelProps}
              isDebugOpen={isDebugOpen}
              setIsDebugOpen={(value) => setParam("isDebugOpen", value)}
            />
          </Col>
        </Row>
        <div className="chat-bot">
          <ChatBotComponent hideBot={() => {}} />
        </div>
      </Container>
    )
  );
};

export default Testing;
