import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import useBots from "../../hooks/useBots";
import { useTranslation } from "../../hooks/useLocalization";
import useMessage from "../../hooks/useMessage";

import useAnalytics from "../../hooks/useAnalytics";

const DeleteBotModal = ({ bot, open, setOpen, onComplete }) => {
  const { t } = useTranslation();
  const { id } = bot || {};
  const { CONSOLE_EVENTS } = useAnalytics();
  const { deleteBot } = useBots();
  const [isSubmitting, setSubmitting] = useState();
  const { showMessage, showMessageWithDebug } = useMessage();
  const onClose = async () => {
    setOpen(false);
    await CONSOLE_EVENTS.EV_DeleteBot.send({ action: 'close' })
  }
  return (
    <Modal show={open} onHide={onClose} enforceFocus={false}>
      <Helmet title={t("Delete Bot")} />
      <Modal.Header closeButton>{t("Delete")}</Modal.Header>
      <Modal.Body className="text-center m-3">
        <p className="mb-0">{t("After deleting this bot")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {t("Close")}
        </Button>{" "}
        <Button disabled={isSubmitting} variant="danger"
          onClick={async () => {
            try {
              setSubmitting(true);
              await deleteBot(id);
              showMessage({ save: t("The bot was successfully deleted") });
              onComplete && onComplete(id);
              await CONSOLE_EVENTS.EV_DeleteBot.send({ action: 'complete', params: { bid: id } })
            } catch (error) {
              const { message = t("Something went wrong"), debug } = error;
              showMessageWithDebug({ error: message, debug });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {t("Delete Bot")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteBotModal;
