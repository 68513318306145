import { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

const Scrollbar = ({ className, children, wheelPropagation = false, maxH }) => {
  const ref = useRef();
  const [downTarget, setDownTarget] = useState();
  useEffect(() => {
    const handleDown = (event) => setDownTarget(event.target);
    const handleClickOutside = (event) => {
      if (
        ref.current?.contains(downTarget) &&
        !ref.current?.contains(event.target)
      ) {
        event.preventDefault();
        event.stopPropagation();
        setDownTarget(null);
      }
    };
    document.addEventListener("mousedown", handleDown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleDown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [downTarget]);
  return (
    <div ref={ref} style={{ display: "contents" }}>
      <PerfectScrollbar
        style={{ maxHeight: maxH }}
        className={className}
        options={{ wheelPropagation }}
      >
        {children}
      </PerfectScrollbar>
    </div>
  );
};

export default Scrollbar;
