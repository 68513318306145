import copyText from "../utils/copyText";
import { useTranslation } from "./useLocalization";
import useMessage from "./useMessage";

const useClipboard = (target) => {
  const { t } = useTranslation();
  const { showMessage } = useMessage();

  const onCopyEvent = (value) => {
    if (copyText(value)) {
      showMessage({ save: `${t(target || "Text")} ${t("copied")}` });
    }
  };

  return { onCopyEvent };
};

export default useClipboard;
