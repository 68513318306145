import { useEffect, useState } from "react";
import {
  Button,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { Check, Copy, X } from "react-feather";
import livechat from "../../../../assets/img/brands/livechat.svg";
import screen_1 from "../../../../assets/img/screenshots/channels/screen_livechat_00.png";
import screen_2 from "../../../../assets/img/screenshots/channels/screen_livechat_01.png";
import screen_3 from "../../../../assets/img/screenshots/channels/screen_livechat_02.png";
import screen_4 from "../../../../assets/img/screenshots/channels/screen_livechat_03.png";
import screen_5 from "../../../../assets/img/screenshots/channels/screen_livechat_04.png";
import screen_6 from "../../../../assets/img/screenshots/channels/screen_livechat_05.png";
import screen_7 from "../../../../assets/img/screenshots/channels/screen_livechat_06.png";
import screen_8 from "../../../../assets/img/screenshots/channels/screen_livechat_07.png";
import screen_9 from "../../../../assets/img/screenshots/channels/screen_livechat_08.png";
import screen_10 from "../../../../assets/img/screenshots/channels/screen_livechat_09.png";
import screen_11 from "../../../../assets/img/screenshots/channels/screen_livechat_10.png";
import screen_12 from "../../../../assets/img/screenshots/channels/screen_livechat_11.png";
import screen_13 from "../../../../assets/img/screenshots/channels/screen_livechat_12.png";
import screen_14 from "../../../../assets/img/screenshots/channels/screen_livechat_13.png";
import screen_15 from "../../../../assets/img/screenshots/channels/screen_livechat_14.png";
import screen_16 from "../../../../assets/img/screenshots/channels/screen_livechat_15.png";
import screen_17 from "../../../../assets/img/screenshots/channels/screen_livechat_16.png";
import screen_18 from "../../../../assets/img/screenshots/channels/screen_livechat_17.png";
import screen_19 from "../../../../assets/img/screenshots/channels/screen_livechat_18.png";
import screen_20 from "../../../../assets/img/screenshots/channels/screen_livechat_19.png";
import screen_21 from "../../../../assets/img/screenshots/channels/screen_livechat_20.png";
import screen_22 from "../../../../assets/img/screenshots/channels/screen_livechat_21.png";
import screen_23 from "../../../../assets/img/screenshots/channels/screen_livechat_22.png";
import { BOT_CHANNEL } from "../../../../contexts/bots/BotsContext";
import useBots from "../../../../hooks/useBots";
import useClipboard from "../../../../hooks/useClipboard";
import { useTranslation } from "../../../../hooks/useLocalization";
import useMessage from "../../../../hooks/useMessage";
// import useExtensions from "../../../../hooks/useExtensions";
import { Accordion } from "../../../accordion/Accordion";
import ZoomImage from "../../../ZoomImage";
import ChannelInput from "./submit/ChannelInput";
import ChannelInputState from "./submit/ChannelInputState";
import ChannelState from "./submit/ChannelState";
import useLocationContext from "../../../../hooks/useLocationContext";
import ZoomVideo from "../../../ZoomVideo";

const useRedirectURI = () => {
  const { activeBot } = useBots();
  const { origin } = window.location;
  return `${origin}/bots/channels?bid=${activeBot?.id}#live_chat`;
};

const LiveChatIcon = ({ className }) => (
  <img
    src={livechat || ""}
    alt=""
    width={20}
    height={20}
    className={className}
  />
);

const LiveChatLogin = () => {
  const { tt } = useTranslation();
  return (
    <>
      {tt(
        "LiveChat.Step-1-1",
        <a
          href="https://accounts.livechat.com/signup"
          alt="livechat"
          target="_blank"
          rel="noreferrer"
        >
          https://accounts.livechat.com/signup
        </a>
      )}
    </>
  );
};

const LiveChatOrganization = ({ id, channel, levels }) => {
  // limits
  // Organization ID (36)
  const { t, tt } = useTranslation();
  const { updateBot } = useBots();
  const [isSubmitting, setSubmitting] = useState();
  const { /*showMessage, */ showMessageWithDebug } = useMessage();
  const onSubmit = async (value) => {
    try {
      setSubmitting(true);
      await updateBot(id, {
        workflows: [
          { ...channel, name: BOT_CHANNEL.LIVE_CHAT, organization_id: value },
        ],
      });
      // showMessage({ save: "" });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setSubmitting(false);
    }
  };
  const onChange = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit(value);
    }
  };
  return (
    <>
      <p>
        {tt(
          "LiveChat.Step-2-1",
          <a
            href="https://developers.livechat.com/console"
            alt="console"
            target="_blank"
            rel="noreferrer"
          >
            {t("LiveChat Developers Console")}
          </a>,
          <b>{t("My Profile")}</b>,
          <b>{t("Organization ID")}</b>
        )}
      </p>
      <a
        href="https://developers.livechat.com/console"
        alt="console"
        target="_blank"
        rel="noreferrer"
      >
        https://developers.livechat.com/console
      </a>
      <Row>
        <ZoomImage>
          <img alt="screen_1" src={screen_1} className="m-3 ms-0 mw-100" />
        </ZoomImage>
        <ZoomImage>
          <img alt="screen_2" src={screen_2} className="m-3 ms-0 mw-100" />
        </ZoomImage>
      </Row>
      <ChannelInput
        strict
        title={t("Organization ID")}
        field="organization_id"
        channel={channel}
        disabled={!levels.manage}
        isSubmitting={isSubmitting}
        onChange={onChange}
      />
    </>
  );
};

const LiveChatCreateApp = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      {tt(
        "LiveChat.Step-3-1",
        <a
          href="https://developers.livechat.com"
          alt="livechat"
          target="_blank"
          rel="noreferrer"
        >
          {t("LiveChat Developers Console")}
        </a>,
        <b>{t("My Apps")}</b>
      )}
      <br />
      <a
        href="https://developers.livechat.com/console"
        alt="console"
        target="_blank"
        rel="noreferrer"
      >
        https://developers.livechat.com/console
      </a>
      <Row>
        <ZoomImage>
          <img alt="screen_3" src={screen_3} className="m-3 ms-0 mw-100" />
        </ZoomImage>
        <ZoomImage>
          <img alt="screen_4" src={screen_4} className="m-3 ms-0 mw-100" />
        </ZoomImage>
        <ZoomImage>
          <img alt="screen_5" src={screen_5} className="m-3 ms-0 mw-100" />
        </ZoomImage>
      </Row>
    </>
  );
};

const LiveChatAuthorizationBlock = () => {
  const { t, tt } = useTranslation();
  return (
    <>
      <ul>
        <li>
          {tt("LiveChat.Step-4-1", <b>{t("Building Blocks")}</b>)}
          <Row>
            <ZoomImage>
              <img alt="screen_6" src={screen_6} className="m-3 ms-0 mw-100" />
            </ZoomImage>
            <ZoomImage>
              <img alt="screen_7" src={screen_7} className="m-3 ms-0 mw-100" />
            </ZoomImage>
          </Row>
        </li>
        <li>
          {tt(
            "LiveChat.Step-4-2",
            <b>{t("App Authorization")}</b>,
            <b>{t("Get started")}</b>
          )}
          <ZoomImage>
            <img alt="screen_8" src={screen_8} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
        <li>
          {tt(
            "LiveChat.Step-4-3",
            <b>{t("Creating the OAuth2.1 Client")}</b>,
            <b>{t("Server-side app")}</b>,
            <b>{t("Continue")}</b>
          )}
          <ZoomImage>
            <img alt="screen_9" src={screen_9} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
      </ul>
      {tt(
        "LiveChat.Step-4-4",
        <b>{t("App Authorization")}</b>,
        <b>{tt("Step N", 5)}</b>
      )}
    </>
  );
};

const LiveChatAppAuthorization = ({ id, channel, levels }) => {
  // limits
  // Client Id (?)
  // Client Secret (?)
  const { t, tt } = useTranslation();
  const { client_id, client_secret } = channel || {};
  const { updateBot } = useBots();
  const [isSubmitting, setSubmitting] = useState();


  const onSubmit = async (props) => {
    try {
      setSubmitting(true);
      await updateBot(id, {
        workflows: [
          { ...channel, name: BOT_CHANNEL.LIVE_CHAT, ...props },
        ],
      });
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const onChange__client_id = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit({ client_id: value, client_secret });
    }
  };

  const onChange__client_secret = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit({ client_id, client_secret: value });
    }
  };

  return (
    <>
      <ul>
        <li>
          {tt(
            "LiveChat.Step-5-1",
            <b>{t("App Authorization")}</b>,
            <b>{t("Client Id")}</b>
          )}
          <ZoomImage>
            <img alt="screen_10" src={screen_10} className="m-3 ms-0 mw-100" />
          </ZoomImage>
          <ChannelInput
            strict
            title={t("Client Id")}
            field="client_id"
            channel={channel}
            disabled={!levels.manage}
            isSubmitting={isSubmitting}
            onChange={onChange__client_id}
          />
        </li>
        <li>
          {tt(
            "LiveChat.Step-5-2",
            <b>{t("App Authorization")}</b>,
            <b>{t("Client Secret")}</b>
          )}
          <ZoomImage>
            <img alt="screen_11" src={screen_11} className="m-3 ms-0 mw-100" />
          </ZoomImage>
          <ChannelInput
            strict
            title={t("Client Secret")}
            field="client_secret"
            channel={channel}
            disabled={!levels.manage}
            isSubmitting={isSubmitting}
            onChange={onChange__client_secret}
          />
          {t("LiveChat.Step-5-3")}
          <p>{t("LiveChat.Step-5-4")}</p>
        </li>
        <li>
          <p>
            {tt(
              "LiveChat.Step-5-5",
              <b>{t("Redirect URI whitelist")}</b>,
              <b>{t("Add")}</b>
            )}
          </p>
          {[
            "https://localhost",
            "https://accounts.livechat.com",
            "https://www.livechat.com",
            window.location.origin,
          ].map((href) => (
            <div key={href}>
              <a href={href} alt="whitelist" target="_blank" rel="noreferrer">
                {href}
              </a>
              <br />
            </div>
          ))}
          <Row>
            <ZoomImage>
              <img
                alt="screen_12"
                src={screen_12}
                className="m-3 ms-0 mw-100"
              />
            </ZoomImage>
            <ZoomImage>
              <img
                alt="screen_13"
                src={screen_13}
                className="m-3 ms-0 mw-100"
              />
            </ZoomImage>
          </Row>
        </li>
        <li>
          {tt(
            "LiveChat.Step-5-6",
            <b>{t("App scopes and API access")}</b>,
            <b>{t("Select All")}</b>,
            <b>{t("Save changes")}</b>
          )}
          <Row>
            <ZoomImage>
              <img
                alt="screen_14"
                src={screen_14}
                className="m-3 ms-0 mw-100"
              />
            </ZoomImage>
            <ZoomImage>
              <img
                alt="screen_15"
                src={screen_15}
                className="m-3 ms-0 mw-100"
              />
            </ZoomImage>
            <ZoomImage>
              <img
                alt="screen_16"
                src={screen_16}
                className="m-3 ms-0 mw-100"
              />
            </ZoomImage>
          </Row>
        </li>
        <li>
          {tt(
            "LiveChat.Step-5-7",
            <b>{t("App Authorization")}</b>,
            <b>{t("Save changes")}</b>
          )}
          <ZoomImage>
            <img alt="screen_17" src={screen_17} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
      </ul>
    </>
  );
};

const LiveChatWebhookBlock = ({ id, channel, levels }) => {
  // limits
  // Secret Key (?)
  const { t, tt } = useTranslation();
  const { webhook_url, client_id, client_secret, access_token } = channel || {};
  const { onCopyEvent } = useClipboard();
  const { updateBot } = useBots();
  const [allowAccessError, setAllowAccessError] = useState();
  // const [liveChatToken, setLiveChatToken] = useState();
  const [isAccessing, setAccessing] = useState();
  const [isSubmitting, setSubmitting] = useState();
  // const [isSubmittingKey, setSubmittingKey] = useState();
  // const isTokenSubmitting = isSubmitting && isSubmittingKey === "access_token";
  const { showMessage, showMessageWithDebug } = useMessage();
  const redirect_uri = useRedirectURI();
  const { getSearchParam, deleteSearchParam } = useLocationContext();
  const token_code = getSearchParam("code");
  // const { getToken } = useExtensions();
  let valid = channel.validated || false;

  const getAccessToken = async () => {
    try {
      setAccessing(true);
      const response = await updateBot(id, {
        workflows: [
          { ...channel, name: BOT_CHANNEL.LIVE_CHAT, client_id, client_secret, token_code },
        ],
      });
      const { workflows } = response;
      const channelLC = workflows.filter((a) => a.name === BOT_CHANNEL.LIVE_CHAT)[0]
      valid = channelLC.validated;

      // setLiveChatToken(access_token);
      deleteSearchParam("code");
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setAccessing(false);
      if (!valid) {
        setAllowAccessError(true);
        showMessage({
          error: t("Access denied. Please double check the steps above"),
        });
      } else {
        setAllowAccessError(false);
        showMessage({ info: t("Done, access allowed") });
      }
    }
  };

  const onSubmit = async (props) => {
    const access_token = client_id && client_secret ? props.access_token : null;
    try {
      setSubmitting(true);
      await updateBot(id, {
        workflows: [
          { ...channel, name: BOT_CHANNEL.LIVE_CHAT, ...props, access_token },
        ],
      });
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    } finally {
      setSubmitting(false);
    }
  };

  const onChange = async (event) => {
    if (levels.manage) {
      const { value } = event.target;
      await onSubmit({ secret_key: value, access_token });
    }
  };

  const openLiveChat = () => {
    const url = `https://accounts.livechat.com/?response_type=code&client_id=${client_id}&state=&redirect_uri=${redirect_uri}`;
    window.open(url, "_blank");
  };

  const onAllowAccess = () => {
    setAllowAccessError(false);
    openLiveChat();
  };

  const scrollToAllowAccess = () => {
    const element = window.document.getElementById("allowAccess");
    if (element) {
      window.scrollTo({
        top:
          element.getBoundingClientRect().top -
          ((window.innerHeight - element.clientHeight) >> 1),
        behavior: "instant",
      });
      setAccessing(true); // NOTE: из-за setTimeout(getAccessToken)
    }
  };

  const button_disabled = isAccessing // || isTokenSubmitting;
  const button_variant =
    valid && !isAccessing && !allowAccessError
      ? "outline-secondary"
      : "primary";
  const button_spinner = isAccessing //|| isTokenSubmitting;
  const button_spinner_variant =
    valid && !isAccessing ? "dark" : "light";
  const button_message = !isAccessing;

  useEffect(() => {
    let timer;
    if (token_code) {
      scrollToAllowAccess();
      timer = setTimeout(getAccessToken, 500); // NOTE: setTimeout из-за того что несклько раз перестраивается окно
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     if (liveChatToken) {
  //       const value = liveChatToken;
  //       setSubmittingKey("access_token");
  //       await onSubmit({ client_id, client_secret, access_token: value });
  //       showMessage({ info: t("Done, access allowed") });
  //     }
  //   })();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [liveChatToken]);

  return (
    <>
      <ul>
        <li>
          {tt(
            "LiveChat.Step-6-1",
            <b>{t("Building Blocks")}</b>,
            <b>{t("Chat Webhooks")}</b>,
            <b>{t("Get started")}</b>
          )}
          <ZoomImage>
            <img alt="screen_18" src={screen_18} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
        <li>
          <p>
            {tt(
              "LiveChat.Step-6-2",
              <b>{t("Webhook URL")}</b>,
              <b>{t("Chat Webhooks")}</b>
            )}
          </p>
          <InputGroup>
            <FloatingLabel label={t("Webhook URL")} className="flex-grow-1">
              <Form.Control
                className="mb-3"
                type="text"
                rows="3"
                value={webhook_url || t("Please follow the previous steps")}
                readOnly
              />
            </FloatingLabel>
            <Button
              type="submit"
              variant="primary"
              disabled={!webhook_url}
              onClick={(e) => onCopyEvent(webhook_url)}
            >
              <Copy className="feather me-1" />
              {t("Copy")}
            </Button>
          </InputGroup>
          <ZoomImage>
            <img alt="screen_19" src={screen_19} className="m-3 ms-0 mw-100" />
          </ZoomImage>
        </li>
        <li>
          {tt(
            "LiveChat.Step-6-3",
            <b>{t("Secret Key")}</b>,
            <b>{t("Generate")}</b>
          )}
          <Row>
            <ZoomImage>
              <img
                alt="screen_20"
                src={screen_20}
                className="m-3 ms-0 mw-100"
              />
            </ZoomImage>
            <ZoomImage>
              <img
                alt="screen_21"
                src={screen_21}
                className="m-3 ms-0 mw-100"
              />
            </ZoomImage>
          </Row>
          <ChannelInput
            strict
            title={t("Secret Key")}
            field="secret_key"
            channel={channel}
            disabled={!levels.manage}
            isSubmitting={isSubmitting}
            onChange={onChange}
          />
        </li>
        <li>
          {tt(
            "LiveChat.Step-6-4",
            <b>{t("Webhook triggers")}</b>,
            <span className="bg-light">{t("incoming_event")}</span>,
            <b>{t("author type")}</b>,
            <b>{t("agent")}</b>
          )}
          <Row>
            <ZoomImage>
              <img
                alt="screen_22"
                src={screen_22}
                className="m-3 ms-0 mw-100"
              />
            </ZoomImage>
            <ZoomImage>
              <img
                alt="screen_23"
                src={screen_23}
                className="m-3 ms-0 mw-100"
              />
            </ZoomImage>
          </Row>
        </li>
        <li>{tt("LiveChat.Step-6-5", <b>{t("Save changes")}</b>)}</li>
        <li>
          <p>
            {tt(
              "LiveChat.Step-5-8",
              <b>{t("Allow access to your App")}</b>,
              <b>{t("Allow Access")}</b>
            )}
          </p>
          <Row className="ms-0">
            <Button
              id="allowAccess"
              onClick={onAllowAccess}
              size="lg"
              className="me-3 mb-2"
              disabled={button_disabled}
              variant={button_variant}
              style={{ flex: 0, minWidth: "max-content" }}
            >
              {button_spinner ? (
                <Spinner
                  animation="border"
                  variant={button_spinner_variant}
                  size="sm"
                  className="me-2"
                  style={{ marginBottom: "-2px" }}
                />
              ) : (
                <LiveChatIcon className="me-2" />
              )}
              {t("Allow Access")}
            </Button>
            <span style={{ flex: 1 }} className="lead align-self-center">
              {button_message &&
                (allowAccessError ? (
                  <>
                    <X className="text-danger me-2" />
                    {t("Access denied. Please double check the steps above")}
                  </>
                ) : (
                  valid && (
                    <>
                      <Check className="text-success me-2" />
                      {t("Done, access allowed")}
                    </>
                  )
                ))}
            </span>
          </Row>
        </li>
      </ul>
      <ChannelInputState
        fields={["organization_id", "client_id", "client_secret", "secret_key"]}
        channel={channel}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

const GetLiveChatAcc = ({ workflows }) => {
  const { t, tt } = useTranslation();
  const license_exists = workflows?.find((item) => item.name === "live_chat")?.license_exists;
  const [isDis, setDis] = useState(false);
  const { getLicense } = useBots();
  const { showMessageWithDebug } = useMessage();

  const onClick = async () => {
    try {
      let res = await getLicense(BOT_CHANNEL.LIVE_CHAT, {});
      if (res.result === "success") setDis(true);
    } catch (error) {
      const { message = t("Something went wrong"), debug } = error;
      showMessageWithDebug({ error: message, debug });
    }
  };

  useEffect(() => {
    if (license_exists) setDis(true);
  }, [license_exists])

  return (
    <div className="card-container">
      <h4 style={{ marginBottom: "1rem" }}>
        {t("LiveChat.Header-1")}
      </h4>
      <p>{tt("LiveChat.Header-2")}</p>
      <Button
        disabled={isDis}
        onClick={onClick}
      >
        {tt("LiveChat.Header-3",
          <LiveChatIcon className="me-2" />
        )}
      </Button>
      {isDis &&
        <span style={{ flex: 1, fontStyle: "italic", color: "#1659c7" }} className="d-block font-italic">
          {t("LiveChat.Header-3-1")}
        </span>
      }
    </div >
  )
}

// const LiveChatAPIToken = ({ id, channel, levels }) => {
//   // limits
//   // OAuth 2 access token (138)
//   const { t, tt } = useTranslation();
//   const { client_id, client_secret } = channel || {};
//   const { updateBot } = useBots();
//   const [isSubmitting, setSubmitting] = useState();
//   const { /*showMessage, */ showMessageWithDebug } = useMessage();
//   const onSubmit = async (value) => {
//     try {
//       setSubmitting(true);
//       await updateBot(id, {
//         workflows: [{ ...channel, name: BOT_CHANNEL.DROPBOX, token: value }],
//       });
//       // showMessage({ save: "" });
//     } catch (error) {
//       const { message = t("Something went wrong"), debug } = error;
//       showMessageWithDebug({ error: message, debug });
//     } finally {
//       setSubmitting(false);
//     }
//   };
//   const onChange = async (event) => {
//     if (levels.manage) {
//       const { value } = event.target;
//       await onSubmit(value);
//     }
//   };

//   const openLiveChat = () => {
//     const url = `https://accounts.livechat.com?response_type=code&client_id=${client_id}&token_access_type=offline`;
//     window.open(url, "_blank");
//   };

//   const onGetCode = () => {
//     if (client_id && client_secret) openLiveChat();
//     else showMessageWithDebug({ error: t("Finish Step 4 to continue") });
//   }

//   return (
//     <>
//       {/* <div className="d-flex">
//         <ZoomImage>
//           <img alt="screen_3" src={screen_5_1} className="m-3 ms-0 mw-100" />
//         </ZoomImage>
//         <ZoomImage>
//           <img alt="screen_3" src={screen_5_2} className="m-3 ms-0 mw-100" />
//         </ZoomImage>
//         <ZoomImage>
//           <img alt="screen_3" src={screen_5_3} className="m-3 ms-0 mw-100" />
//         </ZoomImage>
//       </div> */}
//       <Button
//         onClick={onGetCode}
//         className="btn-primary">
//         {t("Get code")}
//       </Button>
//       <p className="mt-3">{tt("Dropbox.Step-5-1", <b>{t("Access Code")}</b>)}</p>
//       <ChannelInput
//         strict
//         title={t("Access Code")}
//         field="token"
//         channel={channel}
//         disabled={!levels.manage}
//         isSubmitting={isSubmitting}
//         onChange={onChange}
//       />
//       <ChannelInputState
//         fields={["token"]}
//         channel={channel}
//         isSubmitting={isSubmitting}
//       />
//     </>
//   );
// };

const LiveChatConfigure = ({ channel, levels, bot, handlers }) => {
  const { t, tt } = useTranslation();
  const { getSearchParam } = useLocationContext();
  const token_code = getSearchParam("code");
  const defaultActiveKey = token_code ? "5" : "0";
  const { id, workflows } = bot || {};
  return (
    <>
      <ZoomVideo>
        <iframe width="50%" height="415" src="https://www.youtube.com/embed/R94c7RWBofQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </ZoomVideo>
      <p>{t("LiveChat.Header")}</p>
      <GetLiveChatAcc workflows={workflows} />
      <Accordion
        defaultActiveKey={defaultActiveKey}
        className="accordion-steps no-animation"
      >
        <Accordion.Item eventKey="0" className="bg-white">
          <Accordion.Header>{tt("Step N", 1)}</Accordion.Header>
          <Accordion.Body>
            <p>
              {tt(
                "LiveChat.Step-1",
                <a
                  href="https://www.livechat.com"
                  alt="livechat"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.livechat.com
                </a>
              )}
            </p>
            <LiveChatLogin />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{tt("Step N", 2)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("LiveChat.Step-2")}</p>
            <LiveChatOrganization id={id} channel={channel} levels={levels} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{tt("Step N", 3)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("LiveChat.Step-3")}</p>
            <LiveChatCreateApp />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{tt("Step N", 4)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("LiveChat.Step-4")}</p>
            <LiveChatAuthorizationBlock />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>{tt("Step N", 5)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("LiveChat.Step-5")}</p>
            <LiveChatAppAuthorization
              id={id}
              channel={channel}
              levels={levels}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>{tt("Step N", 6)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{t("LiveChat.Step-6")}</p>
            <LiveChatWebhookBlock id={id} channel={channel} levels={levels} />
            <ChannelState channel={channel} handlers={handlers} />
          </Accordion.Body>
        </Accordion.Item>
        {/* <Accordion.Item eventKey="6">
          <Accordion.Header>{tt("Step N", 7)}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <p>{tt("LiveChat.Step-7")}</p>
            <LiveChatAPIToken id={id} channel={channel} levels={levels} />
            <ChannelState channel={channel} handlers={handlers} />
          </Accordion.Body>
        </Accordion.Item> */}
      </Accordion>
    </>
  );
};

const LiveChatGetHandlers = ({ url, validated, handlers }) => {
  if (validated && url) {
    handlers = {
      ...handlers,
      onLink: () => {
        window.open(url, "_blank");
      },
    };
  }
  return handlers;
};

export { LiveChatIcon, LiveChatConfigure, LiveChatGetHandlers };
