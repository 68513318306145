import axios from "axios";
import { BOT_FILE_GROUP, BOT_FILE_OPTION } from "../contexts/bots/BotsContext";
import { botById } from "../contexts/bots/utils";
import api from "../net/api";
import {
  arrayBufferToBase64Async,
  stringToArrayBufferAsync,
} from "../utils/download";
import { REGEXP_JSON } from "../utils/files";
import logger from "../utils/logger";
import AntiSpam from "../utils/spam";
import QuBotDashboardEditorEnv from "./QuBotDashboardEditorEnv";
import QuBotDashboardQBEnv from "./QuBotDashboardQBEnv";
import QuBotDashboardSetupEnv from "./QuBotDashboardSetupEnv";

const antiSpam0 = new AntiSpam();
const antiSpam1 = new AntiSpam();

/******************************************************************************
 *                          Dashboard environement
 *
 *                                                   (c) 2021 qudata.com, steps
 *******************************************************************************/
class QuBotDashboardEnv {
  #helpBot = null;
  #assistant = null;
  #created = false;
  #creating = false;
  #currentEnv = null;
  #showPageProps = null;
  #showChatProps = null;
  #sourceElement = null;
  #sourceViewport = null;
  #uiAPI = {};
  #useAPI = {};

  constructor() {
    const props = {
      type: "dashboard",
      cid: "ba342655c1989f3bbee65d9ce1157658",
    };
    this.qb = new QuBotDashboardQBEnv(this, props);
    this.setup = new QuBotDashboardSetupEnv(this, this.qb, props);
    this.editor = new QuBotDashboardEditorEnv(this, this.qb, props);
  }

  /**************************************************************************
   */
  static root() {
    return document.getElementById("qubot-page-root");
  }

  /**************************************************************************
   */
  static isCodeLoaded() {
    return (
      !!QuBotDashboardEnv.root() &&
      QuBotDashboardQBEnv.isCodeLoaded() &&
      QuBotDashboardSetupEnv.isCodeLoaded() &&
      QuBotDashboardEditorEnv.isCodeLoaded()
    );
  }

  /**************************************************************************
   */
  static isCreated() {
    return this.#created;
  }

  /**************************************************************************
   * UI api
   */
  async ui(value) {
    this.#uiAPI = { ...this.#uiAPI, ...value };
    this.qb.ui(value);
    this.setup.ui(value);
    this.editor.ui(value);
  }

  /**************************************************************************
   * Use api
   */
  async use(value) {
    this.#useAPI = { ...this.#useAPI, ...value };
    this.qb.use(value);
    this.setup.use(value);
    this.editor.use(value);

    const { useBots } = this.#useAPI;
    const { helpBot } = useBots;
    this.#helpBot = helpBot;
  }

  changeLang(lang) {
    this.editor.changeLang(lang);
  }

  /**************************************************************************
   * Create environement
   */
  async create() {
    if (this.#created || this.#creating) {
      return;
    }

    // logger.dev(`[QuBotDashboardEnv] create`);
    this.#creating = true;
    window.qubotCodeInput.registerTemplate(
      "syntax-highlighted",
      window.qubotCodeInput.templates.prism(window.Prism)
    );
    const props = { rootDiv: QuBotDashboardEnv.root() };
    await this.qb.create(props);
    await this.setup.create(props);
    await this.editor.create(props);
    window.onresize = this.onResizeEvent;
    window.onbeforeunload = this.onBeforeUnloadEvent;
    this.#created = true;
    this.#creating = false;

    this.#assistant = document.getElementById("qubot-assistant");
    this.#assistant.onclick = () => {
      if (this.#helpBot) {
        const { id } = this.#helpBot
        this.showChat({ botID: id }, { start: true })
      } else {
        logger.dev(`[QuBotDashboardEnv] showChat`, this.#helpBot);
      }
    }
  }

  /**************************************************************************
   * Show editor
   */
  async showEditor({ botID, sourceElement, type = "External" }) {
    if (this.#created) {
      if (!(botID && sourceElement)) {
        await this.hideEditor();
        return;
      }
      // logger.dev(`[QuBotDashboardEnv] showEditor`, { botID, type });
      this.#currentEnv = this.editor;
      this.#sourceElement = sourceElement;
      this.#showPageProps = this.#showChatProps = { botID, type };
      const root = QuBotDashboardEnv.root();
      root.style.visibility = "unset";
      this.resize();
      await this.editor.lib.selectBot({ botID, type });
      await this.editor.lib.show();
    }
  }

  /**************************************************************************
   * Hide editor
   */
  async hideEditor() {
    if (this.#created) {
      //   logger.dev(`[QuBotDashboardEnv] hideEditor`);
      this.reset();
      this.#showPageProps = this.#showChatProps = null;
      const root = QuBotDashboardEnv.root();
      root.style.visibility = "hidden";
      await this.editor.lib.hide();
    }
  }

  /**************************************************************************
   */
  isEditorShowed() {
    return (
      document.getElementById("qubot-page-editor")?.style.display !== "none"
    );
  }

  /**************************************************************************
   * Show setup
   */
  async showSetup({ botID, sourceElement, type = "External" }) {
    if (this.#created) {
      if (!(botID && sourceElement)) {
        await this.hideSetup();
        return;
      }
      // logger.dev(`[QuBotDashboardEnv] showSetup`, { botID });
      this.#currentEnv = this.setup;
      this.#sourceElement = sourceElement;
      this.#showPageProps = this.#showChatProps = { botID, type };
      const root = QuBotDashboardEnv.root();
      root.style.visibility = "unset";
      this.resize();
      await this.setup.lib.selectBot({ botID });
      await this.setup.lib.show();
    }
  }

  /**************************************************************************
   * Hide setup
   */
  async hideSetup() {
    if (this.#created) {
      //   logger.dev(`[QuBotDashboardEnv] hideSetup`);
      this.reset();
      this.#showPageProps = this.#showChatProps = null;
      const root = QuBotDashboardEnv.root();
      root.style.visibility = "hidden";
      await this.setup.lib.hide();
    }
  }

  /**************************************************************************
   */
  isSetupShowed() {
    return (
      document.getElementById("qubot-page-setup")?.style.display !== "none"
    );
  }

  /**************************************************************************
   * Show chat
   */
  async showChat({ botID, type = "External" }, params) {
    if (this.#created) {
      // logger.dev(`[QuBotDashboardEnv] showChat`, { botID, type });
      this.#showChatProps = { botID, type };
      await this.qb.lib.setBotID(botID, { type });
      this.qb.lib.bot.lang = params?.lang || "en"
      this.qb.lib.showButton();
      if (params?.start) {
        this.qb.lib.start(true);
      }
    }
  }

  /**************************************************************************
   * Hide chat
   */
  async hideChat() {
    if (this.#created) {
      //   logger.dev(`[QuBotDashboardEnv] hideChat`);
      this.#showChatProps = null;
      await this.qb.lib.hide();
    }
  }

  /**************************************************************************
   */
  isPageHasChat() {
    return !!this.#currentEnv;
  }

  /**************************************************************************
   */
  validate() {
    const showPageProps = this.#showPageProps;
    const showChatProps = this.#showChatProps;
    if (showPageProps && !(showPageProps?.botID === showChatProps?.botID)) {
      this.showChat(showPageProps);
    }
  }

  /**************************************************************************
   * Reset current view
   */
  reset() {
    this.#sourceElement = document.createElement("div");
    this.resize();
    this.#currentEnv = null;
    this.#sourceElement = null;
  }

  /**************************************************************************
   * Resize current view
   */
  resize() {
    const currentEnv = this.#currentEnv;
    const sourceElement = this.#sourceElement;
    if (!(currentEnv && sourceElement)) {
      return;
    }
    const root = QuBotDashboardEnv.root();
    const top = sourceElement.offsetTop | 0;
    const left = sourceElement.offsetLeft | 0;
    const width = sourceElement.clientWidth | 0;
    const height = sourceElement.clientHeight | 0;
    if (
      !(
        this.#sourceViewport &&
        this.#sourceViewport.top === top &&
        this.#sourceViewport.left === left &&
        this.#sourceViewport.width === width &&
        this.#sourceViewport.height === height
      )
    ) {
      root.style.top = `${top}px`;
      root.style.left = `${left}px`;
      root.style.width = `${width}px`;
      root.style.height = `${height}px`;
      this.#sourceViewport = { top, left, width, height };
      currentEnv.onResizeEvent();
    }
  }

  /**************************************************************************
   * Resize event
   */
  onResizeEvent = () => {
    this.resize();
  };

  /**************************************************************************
   * Before unload event
   */
  onBeforeUnloadEvent = () => {
    this.editor.onBeforeUnloadEvent();
  };

  /**************************************************************************
   * Save bot
   */
  async saveBot({ botID, data }) {
    const { useBots } = this.#useAPI;
    const { bots, updateBotFiles } = useBots;
    const bot = botById(bots, botID);
    if (!bot) {
      logger.error(
        `[QuBotDashboardEditorEnv] saveBot botID not found (${botID})`
      );
      return { status: false };
    }
    const { files } = bot;
    antiSpam0.action(
      async () =>
        await updateBotFiles(botID, null, [
          {
            data: await arrayBufferToBase64Async(
              await stringToArrayBufferAsync(data || "{}")
            ),
            path:
              this.findBotFileJSON({ files, type: BOT_FILE_OPTION.MAIN })
                ?.path || "config.json",
            type: BOT_FILE_OPTION.MAIN,
          },
        ])
    );
    return { status: true };
  }

  /**************************************************************************
   * Load bot
   */
  async loadBot({ botID }) {
    const { useBots } = this.#useAPI;
    const { bots } = useBots;
    const bot = botById(bots, botID);
    if (!bot) {
      logger.error(
        `[QuBotDashboardEditorEnv] loadBot botID not found (${botID})`
      );
      return { id: botID, name: "Unknown" };
    }
    const { id, name } = bot;
    // await new Promise((resolve) => setTimeout(resolve, 2000)).then(() => {});
    const config = await this.loadBotFile({
      botID,
      type: BOT_FILE_OPTION.MAIN,
    });
    return { ...(config || {}), id, name };
  }

  /**************************************************************************
   * Save setup
   */
  async saveSetup({ botID, params }) {
    const data = typeof params === "object" ? JSON.stringify(params) : params;
    const { useBots } = this.#useAPI;
    const { bots, updateBotFiles } = useBots;
    const bot = botById(bots, botID);
    if (!bot) {
      logger.error(
        `[QuBotDashboardEditorEnv] saveSetup botID not found (${botID})`
      );
      return { status: false };
    }
    const { files } = bot;
    antiSpam1.action(async () => {
      const sendFiles = [
        {
          data: await arrayBufferToBase64Async(
            await stringToArrayBufferAsync(data || "{}")
          ),
          path:
            this.findBotFileJSON({ files, type: BOT_FILE_OPTION.SETUP })
              ?.path || "setup.json",
          type: BOT_FILE_OPTION.SETUP,
        },
      ];
      await this.addBotIconFileFromSetup({ botID, files, params, sendFiles });
      await updateBotFiles(botID, null, sendFiles);
    });
    return { status: true };
  }

  /**************************************************************************
   * Load setup
   */
  async loadSetup({ botID }) {
    const { useBots } = this.#useAPI;
    const { bots } = useBots;
    const bot = botById(bots, botID);
    if (!bot) {
      return { id: botID };
    }
    return await this.loadBotFile({
      botID,
      type: BOT_FILE_OPTION.SETUP,
    });
  }

  /**************************************************************************
   * Save image
   */
  async saveFile({ botID, fileName, arrayBuffer }) {
    if (!(fileName && arrayBuffer)) {
      logger.error(
        `[QuBotDashboardEditorEnv] saveFile fileName and arrayBuffer are mandatory`
      );
      return null;
    }

    const { useBots } = this.#useAPI;
    const { bots, updateBotFiles } = useBots;
    const bot = botById(bots, botID);
    if (!bot) {
      logger.error(
        `[QuBotDashboardEditorEnv] saveFile botID not found (${botID})`
      );
      return null;
    }

    if (
      bot.files.some(
        ({ path, group }) => path === fileName && group !== BOT_FILE_GROUP.FILE
      )
    ) {
      const index = fileName.lastIndexOf(".");
      fileName = `${fileName.substring(0, index)} (1)${fileName.substr(index)}`;
    }

    const updatedBot = await updateBotFiles(botID, null, [
      {
        data: await arrayBufferToBase64Async(arrayBuffer),
        path: fileName,
        group: BOT_FILE_GROUP.FILE,
      },
    ]);
    const file = updatedBot.files.find(({ path }) => path === fileName);
    return file ? api.fileSrc(file.src, botID) : null;
  }

  /**************************************************************************
   * Load image URL
   */
  async loadFile({ botID, fileName }) {
    if (!fileName) {
      logger.error(`[QuBotDashboardEditorEnv] loadFile fileName is mandatory`);
      return null;
    }

    const { useBots } = this.#useAPI;
    const { bots } = useBots;
    const bot = botById(bots, botID);
    if (!bot) {
      logger.error(
        `[QuBotDashboardEditorEnv] loadFile botID not found (${botID})`
      );
      return null;
    }

    const file = bot.files.find(({ path }) => path === fileName);
    return file ? api.fileSrc(file.src, botID) : null;
  }

  /**************************************************************************
   * Load all image file names
   */
  async loadFileNames({ botID }) {
    const { useBots } = this.#useAPI;
    const { bots } = useBots;
    const bot = botById(bots, botID);
    if (!bot) {
      logger.error(
        `[QuBotDashboardEditorEnv] loadFileNames botID not found (${botID})`
      );
      return null;
    }

    return bot.files
      .filter(({ group }) => group === BOT_FILE_GROUP.FILE)
      .map(({ path }) => path);
  }

  /**************************************************************************
   * Delete image
   */
  async deleteFile({ botID, fileName }) {
    if (!fileName) {
      logger.error(
        `[QuBotDashboardEditorEnv] deleteFile fileName is mandatory`
      );
      return null;
    }

    const { useBots } = this.#useAPI;
    const { bots, updateBotFiles } = useBots;
    const bot = botById(bots, botID);
    if (!bot) {
      logger.error(
        `[QuBotDashboardEditorEnv] deleteFile botID not found (${botID})`
      );
      return null;
    }

    const updatedBot = await updateBotFiles(botID, null, [
      {
        delete: true,
        path: fileName,
      },
    ]);
    const file = updatedBot.files.find(({ path }) => path === fileName);
    return { status: !file };
  }

  /**************************************************************************
   * Push bot file icon from setup
   */
  async addBotIconFileFromSetup({ botID, files, params, sendFiles }) {
    if (!(!!params && typeof params === "object")) {
      return;
    }
    const val = params["icon-logo1"];
    if (!(typeof val === "string" && val.length > 0)) {
      return;
    }
    const result = /url\('(.*)'\)/.exec(val.trim());
    if (!(result?.length > 1 && result[1])) {
      return;
    }
    try {
      const response = await axios.get(`/qubot/css/${result[1]}`, {
        responseType: "blob",
      });
      const { data } = response;
      sendFiles.push({
        data: await arrayBufferToBase64Async(await data.arrayBuffer()),
        path:
          this.findBotFile({ files, type: BOT_FILE_OPTION.ICON })?.path ||
          "icon.png",
        type: BOT_FILE_OPTION.ICON,
      });
    } catch (e) {
      logger.error(e);
    }
  }

  /**************************************************************************
   * Load bot file JSON
   */
  async loadBotFile({ botID, type }) {
    const { useBots } = this.#useAPI;
    const { bots } = useBots;
    const bot = botById(bots, botID);
    if (!bot) {
      logger.warn(
        `[QuBotDashboardEditorEnv] loadBotFile bot not found (botID="${botID}")`
      );
      return {};
    }
    const { files } = bot;
    const file = this.findBotFileJSON({ files, type });
    if (!file) {
      logger.warn(
        `[QuBotDashboardEditorEnv] loadBotFile file "${type}" not found (botID="${botID}")`
      );
      return null;
    }
    const { src } = file;
    const response = await axios.get(api.fileSrc(src, botID));
    const { data } = response;
    return data;
  }

  /**************************************************************************
   * Find bot file name
   */
  findBotFile({ files, type }) {
    return files?.find(({ type: t }) => t === type);
  }

  /**************************************************************************
   * Find bot file name (JSON)
   */
  findBotFileJSON({ files, type }) {
    const file = this.findBotFile({ files, type });
    return file && REGEXP_JSON.test(file.path) ? file : null;
  }

  /**************************************************************************
   * Generate unique id string
   */
  generateUID() {
    const s = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    return (
      s() + s() + "-" + s() + "-" + s() + "-" + s() + "-" + s() + s() + s()
    );
  }
}

export default QuBotDashboardEnv;
