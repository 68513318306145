import React, { useCallback, useMemo } from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { HelpCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { BillingPlan } from "../../contexts/billing/BillingContext";
import useBilling from "../../hooks/useBilling";
import { useTranslation } from "../../hooks/useLocalization";
import ColumnSortingTable from "../tables/ColumnSortingTable";
import {
  getStatusRendererProps,
  PaymentAmount,
  getAmountRendererProps,
  PaymentPeriod,
  getPeriodRendererProps,
  PaymentPurpose,
  PaymentUpdatedAt,
} from "./utils";

// const CHART_TYPE = {
//   MESSAGES: "messages",
// };

const AddPayCard = () => {
  const { t, tt } = useTranslation();
  const {
    payments,
    getSubscriptionByProduct,
    payInvoice,
    getProduct,
    features,
    plan,
  } = useBilling();
  const navigate = useNavigate();

  const getMessagesCount = useCallback(() => {
    let messages = features.filter((item) => item.id === "feature.messages")[0];
    return messages
  }, [features])

  const getPaymentCurrency = useCallback(
    (payment) =>
      (payment.products || [])
        .map((segment) => getProduct(segment))
        .reduce(
          (currency, product) => currency || product?.price?.currency,
          null
        ),
    [getProduct]
  );

  const calcPaymentAmount = useCallback(
    (payment) =>
      (payment.products || [])
        .map((segment) => getProduct(segment))
        .reduce((amount, product) => amount + (product?.price?.value || 0), 0),
    [getProduct]
  );

  const getProductDestination = useCallback(
    (product) => {
      const subscription = getSubscriptionByProduct(product?.productID);
      return subscription
        ? tt("X subscription", t(BillingPlan.title(subscription.id)))
        : t("Third-party services");
    },
    [getSubscriptionByProduct, t, tt]
  );

  const getPaymentAmountDetails = useCallback(
    (payment) =>
      (payment.products || [])
        .map((segment) => getProduct(segment))
        .map(
          (product) => ({
            name: getProductDestination(product),
            ...product?.price,
          }),
          0
        ) || 0,
    [getProduct, getProductDestination]
  );

  const headers = useMemo(() => ["purpose", "period", "updated", "amount",], []);

  const MIN_ROWS = 5;
  const rows = useMemo(
    () =>
      payments
        .map((payment) => {
          const { time, endTime, status, updated } = payment;
          return {
            period: { time, endTime },
            amount: {
              value: calcPaymentAmount(payment),
              currency: getPaymentCurrency(payment),
              details: getPaymentAmountDetails(payment),
            },
            status: { value: status, payInvoice },
            purpose: getPaymentAmountDetails(payment)[0],
            updated,
          };
        })
        .concat(
          new Array(Math.max(MIN_ROWS - payments.length, 0)).fill(
            Object.assign({}, ...headers.map((key) => ({ [key]: "" })))
          )
        ),
    [
      payments,
      headers,
      payInvoice,
      getPaymentCurrency,
      calcPaymentAmount,
      getPaymentAmountDetails,
    ]
  );

  const tableColumns = headers.map((name) => ({
    Header: t(name),
    accessor: name,
  }));

  const tableData = rows;

  // const chartSessions = useMemo(
  //   () => parseSessions(sessions, options(CHART_TYPE.SESSIONS, t("Sessions"))),
  //   [sessions, options, t]
  // );

  return (
    <>
      <Card>
        {/* <Row>
          <Col md="12">
            <ReportMessagesCard
            // report={chartSessions}
            // proPlan={checkPro(CHART_TYPE.MESSAGES)}
            // isLoading={isLoading.sessions}
            />
          </Col>
        </Row> */}
        <Card.Header>
          <Card.Title className="d-flex">
            <h4 className="mt-2">{t("Messages")}</h4>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>{t("MessagesTooltip")}</Tooltip>}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="/"
                onClick={(e) => e.preventDefault()}
                className="nav-link nav-icon ms-2 p-0"
              >
                <HelpCircle className="feather" />
              </a>
            </OverlayTrigger>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <p>{tt("Messages total: N", getMessagesCount().current || 0)}</p>
          <p>{tt("Free messages: N", getMessagesCount().total || 0)}</p>
          <p>{tt("Paid messages: N", getMessagesCount().current - getMessagesCount().total > 0 ? getMessagesCount().current - getMessagesCount().total : 0)}</p>
          <p>{tt(
            "Current plan",
            <b>{plan?.title || "Free"}</b>,
            `${plan?.total || "10K"}`,
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                navigate("/account/pricing")
              }}
            >{t("here")}</a>
          )}</p>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title className="d-flex">
            <h4 className="mt-2">{t("Additional Payments")}</h4>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>{"123"}</Tooltip>}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="/"
                onClick={(e) => e.preventDefault()}
                className="nav-link nav-icon ms-2 p-0"
              >
                <HelpCircle className="feather" />
              </a>
            </OverlayTrigger>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <ColumnSortingTable
            isHidden={false}
            columns={tableColumns}
            data={tableData}
            getCellRenderer={(cell) =>
              (cell.column.id === "purpose" && PaymentPurpose) ||
              (cell.column.id === "period" && PaymentPeriod) ||
              (cell.column.id === "amount" && PaymentAmount) ||
              (cell.column.id === "updated" && PaymentUpdatedAt)
            }
            getCellRendererProps={(cell) =>
              (cell.column.id === "period" &&
                getPeriodRendererProps(cell)) ||
              (cell.column.id === "amount" &&
                getAmountRendererProps(cell)) ||
              (cell.column.id === "status" && getStatusRendererProps(cell))
            }
          />
        </Card.Body>
      </Card>
    </>
  )
}

export default AddPayCard;