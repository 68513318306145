import { useTranslation } from "../../hooks/useLocalization";

const CURRENCY_SYMBOLS = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
};

const NOTIFICATION = {
  SUBSCRIPTION_NEXT_3: "notification.next-subscription-3",
  SUBSCRIPTION_NEXT_2: "notification.next-subscription-2",
  SUBSCRIPTION_NEXT_1: "notification.next-subscription-1",
  INVOICE: "notification.invoice",
  INVOICE_ASAP: "notification.invoice-asap",
  SUBSCRIPTION_CANCELED: "notification.subscription-canceled",
  SUBSCRIPTION_RENEWED: "notification.subscription-renewed",
};

const getNotif = (id, { t }) => {
  if (id === NOTIFICATION.SUBSCRIPTION_NEXT_3) {
    return {
      id,
      title: t("Reminder"),
      description: t(id),
      href: "/account/payments",
    };
  }
  if (id === NOTIFICATION.SUBSCRIPTION_NEXT_2) {
    return {
      id,
      title: t("Reminder"),
      description: t(id),
      href: "/account/payments",
    };
  }
  if (id === NOTIFICATION.SUBSCRIPTION_NEXT_1) {
    return {
      id,
      title: t("Reminder"),
      description: t(id),
      href: "/account/payments",
    };
  }
  if (id === NOTIFICATION.INVOICE) {
    return {
      id,
      type: "default",
      title: t("Packages & Prices"),
      description: t(id),
      href: "/account/payments",
    };
  }
  if (id === NOTIFICATION.INVOICE_ASAP) {
    return {
      id,
      type: "important",
      title: t("Packages & Prices"),
      description: t(id),
      href: "/account/payments",
    };
  }
  if (id === NOTIFICATION.SUBSCRIPTION_CANCELED) {
    return {
      id,
      type: "default",
      title: t("Packages & Prices"),
      description: t(id),
      href: "/account/pricing",
    };
  }
  if (id === NOTIFICATION.SUBSCRIPTION_RENEWED) {
    return {
      id,
      type: "success",
      title: t("Packages & Prices"),
      description: t(id),
      href: "/account/payments",
    };
  }
};

const useNotif = () => {
  const { t } = useTranslation();
  return (id) => getNotif(id, { t });
};

export { CURRENCY_SYMBOLS, NOTIFICATION, useNotif };
