import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Eye } from "react-feather";
import { useNavigate } from "react-router-dom";
import useBots from "../../hooks/useBots";
import { useTranslation } from "../../hooks/useLocalization";

const SeeBot = ({ bot }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setBotActive } = useBots();

  const onClickHandler = () => {
    setBotActive(bot.id)
    navigate("/bots/editor?bid=" + bot.id);
  }
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>{t("Testing")}</Tooltip>}>
      <Button variant="light" className="m-1 p-1" onClick={onClickHandler}>
        <Eye />
      </Button>
    </OverlayTrigger>
  )
}

export default SeeBot;