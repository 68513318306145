import React, { useEffect, useRef, useState } from "react";
import useAuth from "../hooks/useAuth";
import useBots from "../hooks/useBots";
import { useTranslation } from "../hooks/useLocalization";
import useMessage from "../hooks/useMessage";
import { BOT_FILTER } from "../pages/dashboards/Bots/utils";

const initialState = {};

const TUTORIAL = [
  {
    id: "REGISTRATION",
    title: "Registration",
    completedSteps: 0,
    totalSteps: 1,
    href: "/account/settings/#private!",
  },
  {
    id: "BOT_FROM_TEMPLATE",
    title: "Create a bot from the template",
    completedSteps: 0,
    totalSteps: 4,
    href: "/dashboard/bots/",
  },
  {
    id: "DESIGN",
    title: "Customize a design",
    completedSteps: 0,
    totalSteps: 2,
    href: "",
  },
  // {
  //   id: "ANALYTICS",
  //   title: "View Analytics",
  //   completedSteps: 0,
  //   totalSteps: 1,
  //   href: "",
  // },
  // {
  //   id: "DEPLOY_BOT",
  //   title: "Deploy a bot",
  //   completedSteps: 0,
  //   totalSteps: 2,
  //   href: "",
  // },
];

const initialStorage = { tuto_1: {}, tuto_2: {}, tuto_3: {}, tuto_4: {}, tuto_5: {} }

const TutorialContext = React.createContext(initialState);

function TutorialProvider({ children }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [tutorials, setTutorials] = useState(TUTORIAL);
  const { showMessage } = useMessage();
  const { activeBot } = useBots();
  const [tutoStorage, setTutoSTorage] = useState(JSON.parse(window.localStorage.getItem("tutorial")) || initialStorage);
  const refTemplate = useRef(null);
  const [showTuto1_1, setTuto1_1] = useState(false)
  const [showTuto2_1, setTuto2_1] = useState(false)
  const [showTuto2_2, setTuto2_2] = useState(false)
  const [showTuto2_3, setTuto2_3] = useState(false)
  const [filter, setFilter] = useState();
  const notifToggle = document.getElementById("qb-console-dnd-tuto");

  const findTutorial = (findId) => {
    return tutorials.find(({ id }) => id === findId);
  };

  const hasTuto = (value) => {
    return !!findTutorial(value instanceof Object ? value.id : value);
  };

  const addTuto = (value) => {
    const tuto = findTutorial(value instanceof Object ? value.id : value);
    if (tuto) {
      tutorials.splice(tutorials.indexOf(tuto), 1, value);
    } else {
      tutorials.push(value);
    }
    setTutorials([...tutorials]);
  };

  const removeTuto = (value) => {
    const tuto = findTutorial(value instanceof Object ? value.id : value);
    if (tuto) {
      tutorials.splice(tutorials.indexOf(tuto), 1);
      setTutorials([...tutorials]);
    }
  };

  const clearAllTuto = () => {
    tutorials.length = 0;
    setTutorials([...tutorials]);
  };

  const hasProp = (prop) => {
    let data = JSON.parse(window.localStorage.getItem(prop))
    return !!data;
  }

  const setProp = (name, value) => {
    window.localStorage.setItem(name, JSON.stringify(value))
  }

  const createFixedBG = (nextStep, curState) => {
    let fixedBack = document.createElement("div");
    fixedBack.style.position = "fixed";
    fixedBack.style.width = '100%'
    fixedBack.style.height = '100%'
    fixedBack.style.top = "0"
    fixedBack.style.left = "0"
    fixedBack.style.zIndex = "9999"

    if (nextStep) {
      fixedBack.onclick = () => {
        fixedBack.remove();
        nextStep();
      };
    } else {
      fixedBack.onclick = () => {
        fixedBack.remove();
        curState(false)
      }
    }
    document.body.appendChild(fixedBack);
  }

  const checkTuto1Complete = (checkAll) => {
    if (tutoStorage.tuto_1._1) {
      if (checkAll) return true;
      showMessage({ save: t("Registration completed") })
      setTimeout(() => notifToggle?.click(), 100)
      if (checkTuto2Complete(true) && checkTuto3Complete(true)) {
        setTimeout(() => {
          document.body.addEventListener("click", tutoCompleted)
        }, 100)
      }
    }
  }

  const setTuto_1 = () => {
    if (tutoStorage.tuto_1._1) {
      updateStep(0, 1);
      return
    }

    if (
      user &&
      (
        user.firstName &&
        user.lastName &&
        user.displayName &&
        user.companyName &&
        user.websiteURL &&
        // user.employeesNum &&
        user.industry &&
        user.position &&
        user.phone
      )
    ) {
      if (!tutoStorage.tuto_1._1) {
        tutoStorage.tuto_1._1 = true;
        setProp("tutorial", tutoStorage)
        setTutoSTorage(tutoStorage);
        updateStep(0, 1);
        checkTuto1Complete();
      }
    } else {
      tuto_1_1();
    }
  }

  const tuto_1_1 = () => {
    TUTORIAL[0].onClick = () => {
      setTimeout(() => {
        setTuto1_1(true);
        document.body.addEventListener("click", () => setTuto1_1(false));
      }, 100)
    };
    setTutorials([...TUTORIAL]);
  }

  const updateStep = (i, j) => {
    TUTORIAL[i].completedSteps = j;
    setTutorials([...TUTORIAL]);
  }

  const checkTuto2Complete = (checkAll) => {
    if (
      tutoStorage.tuto_2._1 &&
      tutoStorage.tuto_2._2 &&
      tutoStorage.tuto_2._3 &&
      tutoStorage.tuto_2._4
    ) {
      if (checkAll) return true;
      showMessage({ save: t("Bot is created") })
      setTimeout(() => notifToggle.click(), 100)
      if (checkTuto1Complete(true) && checkTuto3Complete(true)) {
        setTimeout(() => {
          document.body.addEventListener("click", tutoCompleted)
        }, 100)
      }
    }
  }

  const setTuto_2 = () => {
    if (tutoStorage.tuto_2._4) {
      TUTORIAL[1].onClick = null;
      TUTORIAL[1].href = "";
      updateStep(1, 4);
      setTutorials([...TUTORIAL]);
      if (tutoStorage.tuto_2._1 && tutoStorage.tuto_2._2 && tutoStorage.tuto_2._3) return
    }
    if (tutoStorage.tuto_2._3) {
      TUTORIAL[1].onClick = tuto_2_4;
      TUTORIAL[1].href = "/bots/editor?id=" + activeBot?.id;
      updateStep(1, 3);
      setTutorials([...TUTORIAL]);
      if (tutoStorage.tuto_2._1 && tutoStorage.tuto_2._2) return
    }
    if (tutoStorage.tuto_2._2) {
      TUTORIAL[1].onClick = tuto_2_3;
      updateStep(1, 2);
      setTutorials([...TUTORIAL]);
      if (tutoStorage.tuto_2._1) return
    }
    if (tutoStorage.tuto_2._1) {
      TUTORIAL[1].onClick = tuto_2_2;
      updateStep(1, 1);
      return
    }
    TUTORIAL[1].onClick = tuto_2_1;
  }

  const tuto_2_1 = () => {
    setFilter(BOT_FILTER.MY);
    setTimeout(() => {
      refTemplate.current.scrollIntoView({ behavior: 'smooth' })
      setTuto2_1(true)
      createFixedBG(tuto_2_2, setTuto2_1);

      if (!tutoStorage.tuto_2._1) {
        tutoStorage.tuto_2._1 = true;
        setProp("tutorial", tutoStorage)
        setTutoSTorage(tutoStorage);
        TUTORIAL[1].onClick = tuto_2_2;
        updateStep(1, 1);
        setTutorials([...TUTORIAL])
        checkTuto2Complete();
      }
    }, 100)
  }

  const tuto_2_2 = () => {
    setFilter(BOT_FILTER.MY);
    setTimeout(() => {
      refTemplate.current.scrollIntoView({ behavior: 'smooth' })
      setTuto2_1(false)
      setTuto2_2(true)
      document.addEventListener("mouseup", () => setTuto2_2(false))
    }, 100)
  }

  const tuto_2_3 = () => {
    setFilter(BOT_FILTER.MY);
    setTimeout(() => {
      refTemplate.current.scrollIntoView({ behavior: 'smooth' })
      setTuto2_3(true);
      document.addEventListener("mouseup", () => setTuto2_3(false))
    }, 100)
  }

  const tuto_2_4 = () => {
    let tuto = document.getElementById("qubot-editor-run-tuto");
    let tuto1 = document.getElementById("qubot-run-tuto");
    let open = document.getElementById("qubot-open");
    let runBot = document.getElementById("qubot-editor-run");
    tuto.style.display = tuto1.style.display = "block";

    function displayNone() {
      tuto.style.display = tuto1.style.display = "none";
      document.removeEventListener("mouseup", displayNone)
    }

    document.addEventListener("mouseup", displayNone)

    open.onmouseup = runBot.onmouseup = () => {
      if (!tutoStorage.tuto_2._4) {
        tutoStorage.tuto_2._4 = true;
        setProp("tutorial", tutoStorage)
        setTutoSTorage(tutoStorage);
        updateStep(1, 4, "from CloneBot");
        tutorials[1].onClick = null;
        setTutorials([...tutorials])
        checkTuto2Complete();
      }
    }
  }

  const tutoCompleted = () => {
    document.body.removeEventListener("click", tutoCompleted);
    showMessage({ save: t("Onboarding completed") })
    tutoStorage.allTuto = true;
    setProp("tutorial", tutoStorage)
    setTutoSTorage(tutoStorage);
    clearAllTuto();
  }

  const checkTuto3Complete = (checkAll) => {
    if (
      tutoStorage.tuto_3._1 &&
      tutoStorage.tuto_3._2
    ) {
      if (checkAll) return true;
      showMessage({ save: t("Setups viewed") })
      setTimeout(() => notifToggle.click(), 100)
      if (checkTuto1Complete(true) && checkTuto2Complete(true)) {
        setTimeout(() => {
          document.body.addEventListener("click", tutoCompleted)
        }, 100)
      }
    }
  }

  const setTuto_3 = () => {
    if (tutoStorage.tuto_3._2) {
      TUTORIAL[2].onClick = null;
      TUTORIAL[2].href = "";
      updateStep(2, 2);
      return
    }
    if (tutoStorage.tuto_3._1) {
      TUTORIAL[2].onClick = tuto_3_2;
      TUTORIAL[2].href = "/bots/setup?id=" + activeBot?.id;
      updateStep(2, 1);
      return
    }
    TUTORIAL[2].onClick = tuto_3_1;
    TUTORIAL[2].href = "/bots/setup?id=" + activeBot?.id;
  }

  const tuto_3_1 = () => {
    setTimeout(() => {
      let tuto2 = document.getElementById("qubot-run-tuto2");
      tuto2.style.display = "block";
      let open = document.getElementById("qubot-open");

      function displayNone() {
        tuto2.style.display = "none";
        document.removeEventListener("mouseup", displayNone)
      }

      document.addEventListener("mouseup", displayNone)

      open.onmouseup = () => {
        if (!tutoStorage.tuto_3._1) {
          tutoStorage.tuto_3._1 = true;
          setProp("tutorial", tutoStorage)
          setTutoSTorage(tutoStorage);
          updateStep(2, 1);
          tutorials[2].onClick = tuto_3_2;
          setTutorials([...tutorials])
          tuto_3_2();
        }
      }
    }, 100)
  }

  const tuto_3_2 = () => {
    setTimeout(() => {
      let tuto2 = document.getElementById("qubot-run-tuto3");
      tuto2.style.display = "block";
    }, 100)
  }

  useEffect(() => {
    if (!hasProp("tutorial")) {
      setProp("tutorial", initialStorage)
    }

    if (tutoStorage.allTuto) return clearAllTuto();

    setTuto_1();
    setTuto_2();
    setTuto_3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <TutorialContext.Provider
      value={{
        showTuto1_1,
        showTuto2_1,
        showTuto2_2,
        showTuto2_3,
        refTemplate,
        tutorials,
        hasTuto,
        addTuto,
        removeTuto,
        clearAllTuto,
        tutoStorage,
        setTutoSTorage,
        setProp,
        updateStep,
        setTutorials,
        tuto_2_3,
        tuto_2_4,
        checkTuto2Complete,
        checkTuto3Complete,
        filter,
        setFilter,
      }}
    >
      {children}
    </TutorialContext.Provider>
  );
}

export { TutorialProvider, TutorialContext }