import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import SignXDeleteConfirm from "../../components/auth/SignXDeleteConfirm";
import { useTranslation } from "../../hooks/useLocalization";

const SignXDeleteConfirmPage = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet title={t("Sign Up")} />
      <div className="text-center mt-4">
        <h1 className="h2">{t("Confirm deletion")}</h1>
        <p className="lead">{t("Please wait for the operation to complete")}</p>
      </div>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <SignXDeleteConfirm />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignXDeleteConfirmPage;
