/*eslint no-new-wrappers: "off"*/
import React, { useCallback, useEffect, useReducer, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "../../hooks/useLocalization";
import useNotifications from "../../hooks/useNotifications";
import useSidebar from "../../hooks/useSidebar";
import api from "../../net/api";
import { atDevhost } from "../../system/system";
import logger from "../../utils/logger";
import Thread from "../../utils/thread";
import { BOT_CHANNEL } from "../bots/BotsContext";
import { NOTIFICATION_STATUS } from "../NotificationContext";
import Mocks from "./mocks";
import { NOTIFICATION, useNotif } from "./utils";

const initialState = {
  plan: null,
  subscriptions: null,
  products: null,
  coupons: null,
  features: null,
  features_info: null,
  notifications: null,
  notificationsBatch: null,
  payments: null,
  paymentsAvailable: false,
  paymentsPending: null,
  settings: { autoBilling: false },
  loadingError: null,
  isInitialized: false,
  isProbilled: false,
  isLoading: false,
};

const INITIALIZE = "INITIALIZE";
const REFRESH_START = "REFRESH_START";
const REFRESH_END = "REFRESH_END";
const SUBSCRIPTIONS_UPDATED = "SUBSCRIPTIONS_UPDATED";
const PRODUCTS_UPDATED = "PRODUCTS_UPDATED";
const COUPONS_UPDATED = "COUPONS_UPDATED";
const FEATURES_UPDATED = "FEATURES_UPDATED";
const NOTIFICATIONS_UPDATED = "NOTIFICATIONS_UPDATED";
const NOTIFICATIONS_BATCH_UPDATED = "NOTIFICATIONS_BATCH_UPDATED";
const PAYMENTS_UPDATED = "PAYMENTS_UPDATED";
const SETTINGS_UPDATED = "SETTINGS_UPDATED";
const ALL_UPDATED = "ALL_UPDATED";

const PLAN_STATUS = {
  ORDERED: "ordered",
  PAID: "paid",
  EXPIRED: "expired",
  CANCELED: "canceled",
};

const PAYMENTS_STATUS = {
  PENDING: "pending",
  PROCESSING: "processing",
  COMPLETED: "completed",
  SCHEDULED: "scheduled",
};

const FEATURES = {
  MESSENGERS: "feature.messengers",
  WHATSAPP: "feature.whatsapp",
  LIVECHAT: "feature.livechat",
  ANALYTICS: "feature.analytics",
  DOMAINS: "feature.domains",
  MESSAGES: "feature.messages",
};

const BILLING_FASTSPRING = "fastspring";
const BILLING_NAME = BILLING_FASTSPRING;

class BillingPlan {
  static FREE = "subscription.free";
  static START_UP = "subscription.start-up";
  static PREMIUM = "subscription.premium";
  static ENTERPRISE = "subscription.enterprise";

  static index(id) {
    return (
      (id === BillingPlan.START_UP && 1) ||
      (id === BillingPlan.PREMIUM && 2) ||
      (id === BillingPlan.ENTERPRISE && 3) ||
      0
    );
  }

  static nextId(index) {
    return (
      (index === 0 && BillingPlan.START_UP) ||
      (index === 1 && BillingPlan.PREMIUM) ||
      (index === 2 && BillingPlan.ENTERPRISE) ||
      null
    );
  }

  static title(id) {
    return (
      (id === BillingPlan.START_UP && "Start-Up") ||
      (id === BillingPlan.PREMIUM && "Premium") ||
      (id === BillingPlan.ENTERPRISE && "Enterprise") ||
      "Free"
    );
  }

  static total(id) {
    return (
      (id === BillingPlan.START_UP && "50K") ||
      (id === BillingPlan.PREMIUM && "250K") ||
      (id === BillingPlan.ENTERPRISE && "1M") ||
      "10K"
    );
  }

  constructor(subscription) {
    for (let key in subscription) {
      this[key] = subscription[key];
    }
    this.index = BillingPlan.index(this.id);
    this.title = BillingPlan.title(this.id);
    this.total = BillingPlan.total(this.id);
  }
}

const BillingContext = React.createContext(initialState);
const MainThread = new Thread(2000);

const BillingReducer = (state, action) => {
  // logger.dev("[BillingReducer]", action.type);
  const {
    plan,
    subscriptions,
    products,
    coupons,
    features,
    features_info,
    notifications,
    notificationsBatch,
    payments,
    settings,
    loadingError,
    isProbilled,
  } = action.payload || {};

  Mocks.subscriptions(subscriptions);
  Mocks.payments(payments);
  Mocks.coupons(coupons);

  const paymentsAvailable = payments?.length > 0;
  const paymentsPending =
    payments?.filter(({ status }) => status === PAYMENTS_STATUS.PENDING) || [];

  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        loadingError,
        isInitialized: true,
        isProbilled,
        isLoading: false,
      };
    case REFRESH_START:
      return { ...state, isLoading: true, loadingError: null };
    case REFRESH_END:
      return {
        ...state,
        payments,
        paymentsAvailable,
        paymentsPending,
        settings,
        isLoading: false,
        loadingError,
      };
    case SUBSCRIPTIONS_UPDATED:
      return { ...state, subscriptions, plan };
    case PRODUCTS_UPDATED:
      return { ...state, products };
    case COUPONS_UPDATED:
      return { ...state, coupons };
    case FEATURES_UPDATED:
      return { ...state, features, features_info };
    case NOTIFICATIONS_UPDATED:
      return { ...state, notifications };
    case NOTIFICATIONS_BATCH_UPDATED:
      return {
        ...state,
        notificationsBatch: notificationsBatch?.length
          ? [...(state.notificationsBatch || []), ...notificationsBatch]
          : [],
      };
    case PAYMENTS_UPDATED:
      return {
        ...state,
        payments,
        paymentsAvailable,
        paymentsPending,
        settings,
      };
    case SETTINGS_UPDATED:
      return { ...state, settings: { ...state.settings, ...settings } };
    case ALL_UPDATED:
      return {
        ...state,
        plan,
        subscriptions,
        products,
        coupons,
        features,
        features_info,
        notifications,
        payments,
        paymentsAvailable,
        paymentsPending,
        settings,
      };
    default:
      return state;
  }
};

function BillingProvider({ children }) {
  const { i18n } = useTranslation();
  const getNotif = useNotif();
  const { setSidebarProps } = useSidebar();
  const { addNotif, removeNotif } = useNotifications();
  const { isInitialized, isAuthenticated, user, secureAPIRequest } = useAuth();
  const [state, dispatch] = useReducer(BillingReducer, initialState);
  const [sessionRestored, setSessionRestored] = useState();
  const [sessionID, setSessionID] = useState(0);

  const loadStorefront = async (data_storefront) => {
    return new Promise((resolve, reject) => {
      if (BILLING_NAME === BILLING_FASTSPRING) {
        const scriptAPI = document.createElement("script");
        scriptAPI.setAttribute("id", "fsc-api");
        scriptAPI.setAttribute("type", "text/javascript");
        scriptAPI.setAttribute("data-access-key", "VY0N4RXFRT2Q8CLTMTCC_G");
        scriptAPI.setAttribute("data-storefront", data_storefront);
        scriptAPI.setAttribute(
          "data-popup-closed",
          "fastpring_onStorefrontClosed"
        );
        scriptAPI.setAttribute(
          "data-error-callback",
          "fastpring_onStorefrontError"
        );
        scriptAPI.src =
          "https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.6/fastspring-builder.min.js";
        scriptAPI.onload = resolve;
        scriptAPI.onerror = reject;
        document.head.appendChild(scriptAPI);
      }
    });
  };

  const loadScripts = () => {
    return new Promise((resolve, reject) => {
      if (BILLING_NAME === BILLING_FASTSPRING) {
        const document = window.document;
        if (document.getElementById("fsc-api")) {
          resolve();
          return;
        }

        // Callbacks
        const scriptCallback = document.createElement("script");
        const inlineScript = document.createTextNode(`
          function fastpring_onStorefrontClosed(order) {
            var handler = window.Qudata_fastspring_resolve;
            if (typeof handler !== 'function') {
                throw new Error('Invalid Fastspring integration: storefront handler "window.Qudata_fastspring_resolve" is null');
            }
            handler(order);
          }
          function fastpring_onStorefrontError(code, message) {
            var handler = window.Qudata_fastspring_reject;
            if (typeof handler !== 'function') {
              throw new Error('Invalid Fastspring integration: storefront handler "window.Qudata_fastspring_reject" is null');
            }
            handler(new Error(code + ' ' + message));
          }`);
        scriptCallback.appendChild(inlineScript);
        document.head.appendChild(scriptCallback);

        // Storefront
        loadStorefront(`qudata.onfastspring.com/popup-qudata`);
      }
    });
  };

  const updateScripts = () => {
    return new Promise(async (resolve, reject) => {
      if (BILLING_NAME === BILLING_FASTSPRING) {
        const document = window.document;
        const scriptAPI = document.getElementById("fsc-api");
        if (scriptAPI && atDevhost()) {
          // Destroy prev Storefront
          if (scriptAPI.parentElement) {
            scriptAPI.parentElement.removeChild(scriptAPI);
          }
          Object.defineProperty(window, "fastspring", {
            value: null,
            writable: true,
          });

          // Storefront
          await loadStorefront(`qudata.test.onfastspring.com/popup-qudata`);
        }
        resolve();
      }
    });
  };

  // Restore Session
  // ------------------------
  useEffect(() => {
    (async () => {
      if (!(isInitialized && user)) {
        return; // waiting for auth
      }
      try {
        // if (sessionID < 2) throw new Error();
        await loadScripts();
        await updateScripts();

        dispatch({
          type: ALL_UPDATED,
          payload: await fetchAll(),
        });
        dispatch({
          type: INITIALIZE,
          payload: {
            isProbilled: true,
            loadingError: null,
          },
        });
        setSessionRestored(true);
      } catch (e) {
        logger.error(e);
        dispatch({
          type: INITIALIZE,
          payload: { isProbilled: false, loadingError: e },
        });
        setSessionRestored(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, user, sessionID]);

  // Thread tasks
  // ------------------------
  useEffect(() => {
    const available = isInitialized && isAuthenticated;
    MainThread.handler = available
      ? async () => {
        if (state.isInitialized && !sessionRestored) {
          setSessionID(sessionID + 1);
        }
        if (state.notificationsBatch?.length) {
          const response = await secureAPIRequest(
            api.BILLING.NOTIFICATIONS_UPDATE,
            { notifications: state.notificationsBatch }
          );
          const notifications = state.notifications.map(
            (item) =>
              response.data.notifications.find((id) => id === item.id) || item
          );
          applyNotifications(notifications);
          dispatch({
            type: NOTIFICATIONS_UPDATED,
            payload: { notifications },
          });
          dispatch({
            type: NOTIFICATIONS_BATCH_UPDATED,
            payload: { notificationsBatch: null },
          });
        }
      }
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInitialized,
    isAuthenticated,
    state.isInitialized,
    sessionRestored,
    sessionID,
    setSessionID,
    state.notifications,
    state.notificationsBatch,
  ]);

  // Language
  // ------------------------
  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    const language = i18n.language || "en";
    if (BILLING_NAME === BILLING_FASTSPRING) {
      const fastspring = window.fastspring;
      fastspring?.builder?.language(language.toUpperCase());
    }
  }, [isInitialized, i18n.language]);

  // Sidebar
  // ------------------------
  useEffect(() => {
    const { plan, isProbilled, paymentsPending } = state;
    setSidebarProps({
      plan,
      isLoading: !isProbilled,
      paymentsPending: paymentsPending?.length > 0,
    });
  }, [setSidebarProps, state]);

  // Detect current plan
  // ---------------------------------
  const detectCurrentPlan = useCallback((subscriptions) => {
    subscriptions = subscriptions?.filter(({ id }) => id !== BillingPlan.FREE);
    const subscription = subscriptions?.find(
      ({ time, endTime }) => time <= Date.now() && endTime >= Date.now()
    );
    const plan = new BillingPlan(subscription);
    return plan?.index > 0 ? plan : null;
  }, []);

  // Update notifications
  // ------------------------
  const updateNotifications = useCallback(async (notifications) => {
    // logger.dev(`[BillingProvider] updateNotifications`);
    dispatch({
      type: NOTIFICATIONS_BATCH_UPDATED,
      payload: { notificationsBatch: notifications },
    });
  }, []);

  // Apply notifications
  // ------------------------
  const applyNotifications = useCallback(
    (notifications) => {
      Object.values(NOTIFICATION).forEach((id) => removeNotif(id));
      notifications?.forEach(({ id, status }) => {
        const notif = getNotif(id);
        if (notif && status !== NOTIFICATION_STATUS.CLICKED) {
          addNotif({
            ...notif,
            status,
            onView:
              !(
                status === NOTIFICATION_STATUS.VIEWED ||
                status === NOTIFICATION_STATUS.CLICKED
              ) &&
              (async () =>
                await updateNotifications([
                  { id, status: NOTIFICATION_STATUS.VIEWED },
                ])),
            onClick: async () =>
              !(status === NOTIFICATION_STATUS.CLICKED) &&
              (await updateNotifications([
                { id, status: NOTIFICATION_STATUS.CLICKED },
              ])),
          });
        }
      });
    },
    [addNotif, removeNotif, updateNotifications, getNotif]
  );

  // Fetch: subscriptions and active plan
  // ---------------------------------
  const fetchSubscriptions = useCallback(async () => {
    // logger.dev(`[BillingProvider] fetchSubscriptions`);
    const response = await secureAPIRequest(api.BILLING.SUBSCRIPTIONS, {});
    const { subscriptions } = response.data;
    const plan = detectCurrentPlan(subscriptions);
    return { plan, subscriptions };
  }, [secureAPIRequest, detectCurrentPlan]);

  // Fetch: billing products
  // ------------------------
  const fetchProducts = useCallback(async () => {
    // logger.dev(`[BillingProvider] fetchProducts`);
    const response = await secureAPIRequest(api.BILLING.PRODUCTS, {});
    const { products } = response.data;
    return { products };
  }, [secureAPIRequest]);

  // Fetch: billing coupons
  // ------------------------
  const fetchCoupons = useCallback(async () => {
    // logger.dev(`[BillingProvider] fetchCoupons`);
    const response = await secureAPIRequest(api.BILLING.COUPONS, {});
    response.data.coupons ||= []; // compatibility
    const { coupons } = response.data;
    return { coupons };
  }, [secureAPIRequest]);

  // Fetch: features
  // ------------------------
  const fetchFeatures = useCallback(async () => {
    // logger.dev(`[BillingProvider] fetchFeatures`);
    const response = await secureAPIRequest(api.BILLING.FEATURES, {});
    const { features, features_info } = response.data;
    return { features, features_info };
  }, [secureAPIRequest]);

  // Fetch: notifications
  // ------------------------
  const fetchNotifications = useCallback(async () => {
    // logger.dev(`[BillingProvider] fetchNotifications`);
    const response = await secureAPIRequest(api.BILLING.NOTIFICATIONS, {});
    const { notifications } = response.data;
    applyNotifications(notifications);
    return { notifications };
  }, [secureAPIRequest, applyNotifications]);

  // Fetch: payments
  // ------------------------
  const fetchPayments = useCallback(async (uid) => {
    // logger.dev(`[BillingProvider] fetchPayments`);
    const response = await secureAPIRequest(api.BILLING.PAYMENTS, uid && { user: { uid } });
    const { payments, settings } = response.data;
    return { payments, settings };
  }, [secureAPIRequest]);

  // Fetch: all data
  // ------------------------
  const fetchAll = useCallback(async () => {
    // logger.dev(`[BillingProvider] fetchAll`);
    const response = await secureAPIRequest(api.BILLING.FETCH, {});
    response.data.coupons ||= []; // compatibility
    const { subscriptions, notifications } = response.data;
    applyNotifications(notifications);
    return {
      plan: detectCurrentPlan(subscriptions),
      ...response.data,
    };
  }, [secureAPIRequest, detectCurrentPlan, applyNotifications]);

  // Product
  // ------------------------
  const getProduct = useCallback(
    (segment) => {
      const origin = state.products?.find(
        ({ productID }) => productID === segment?.productID
      );
      const coupon = state.coupons?.find(
        ({ productsIDs }) => productsIDs?.indexOf(origin?.productID) > -1
      );
      return { ...origin, ...segment, coupon };
    },
    [state]
  );

  // Subscription product
  // ------------------------
  const getSubscriptionProduct = useCallback(
    (subscriptionId, productID = null) => {
      const { subscriptions } = state;
      const { products, productsIDs } =
        subscriptions?.find(({ id }) => id === subscriptionId) || {};
      const availableProducts =
        products || productsIDs?.map((productID) => ({ productID })) || [];
      return getProduct(
        productID
          ? availableProducts.find((product) => product.productID === productID)
          : availableProducts[0]
      );
    },
    [state, getProduct]
  );

  // Subscription by product
  // ------------------------
  const getSubscriptionByProduct = useCallback(
    (productID) => {
      const { subscriptions } = state;
      return subscriptions?.find(({ products, productsIDs }) =>
        (
          products ||
          productsIDs?.map((productID) => ({ productID })) ||
          []
        ).find((product) => product.productID === productID)
      );
    },
    [state]
  );

  // Subscription
  // ------------------------
  const getSubscription = useCallback(
    (subscriptionId) => {
      const { subscriptions } = state;
      return subscriptions?.find(
        ({ id }) => id === (subscriptionId || BillingPlan.FREE)
      );
    },
    [state]
  );

  // Feature details
  // ------------------------
  const getFeature = useCallback(
    (featureID) => state.features?.find(({ id }) => id === featureID),
    [state.features]
  );

  // Feature enabled
  // ------------------------
  const isFeatureEnabled = useCallback(
    (featureID) => getFeature(featureID)?.status !== "disabled",
    [getFeature]
  );

  // Feature enabled and not limited
  // ------------------------
  const isFeatureAvailable = useCallback(
    (featureID, props) => {
      if (!isFeatureEnabled(featureID)) {
        return false;
      }

      const feature = getFeature(featureID);
      const { current, total } = feature || {};

      if (featureID === FEATURES.DOMAINS) {
        const { domainName } = props || {};
        const { domains } = feature || {};
        return (
          total < 0 || current < total || domains?.indexOf(domainName) > -1
        );
      }

      if (featureID === FEATURES.MESSENGERS) {
        const { channelName } = props || {};
        const { messengers } = feature || {};
        return (
          total < 0 ||
          current < total ||
          messengers?.indexOf(channelName) > -1 ||
          channelName === BOT_CHANNEL.WEB ||
          channelName === BOT_CHANNEL.WORDPRESS
        );
      }

      return !total || total < 0 || current < total;
    },
    [getFeature, isFeatureEnabled]
  );

  // Suitable plan to unlock some features
  // ------------------------
  const suitableFeaturePlan = useCallback(
    (feature) => {
      const { plan, subscriptions, features_info } = state;
      const nextId = BillingPlan.nextId(plan?.index | 0);
      const nextSubscription = subscriptions?.find(({ id }) => id === nextId);
      const suitableId = features_info
        ?.find(({ id }) => id === feature)
        ?.subscriptions?.find(({ status }) => status !== "disabled")?.id;
      const suitableSubscription = subscriptions?.find(
        ({ id }) => id === suitableId
      );
      const subscription =
        BillingPlan.index(suitableSubscription?.id) > (plan?.index || 0)
          ? suitableSubscription
          : nextSubscription;
      return subscription ? new BillingPlan(subscription) : null;
    },
    [state]
  );

  // Need to upgrade current plan to get some features
  // ------------------------------------------------
  const needUpgradePlan = useCallback(
    (feature, props) => {
      return isFeatureAvailable(feature, props)
        ? null
        : suitableFeaturePlan(feature);
    },
    [isFeatureAvailable, suitableFeaturePlan]
  );

  // Is it possible to cancel the plan
  // ------------------------------------------------
  const hasCancelablePlan = useCallback(() => {
    const { subscriptions } = state;
    return subscriptions?.some(
      ({ id, status }) =>
        typeof status === "string" &&
        status !== PLAN_STATUS.CANCELED &&
        id !== BillingPlan.FREE
    );
  }, [state]);

  // Encrypt payload
  // ------------------------
  const encryptPayload = useCallback(
    async (payload) => {
      // logger.dev(`[BillingProvider] encryptPayload`, payload);
      const country =
        document.querySelector("[data-fsc-order-country]")?.innerHTML || "US";
      const language =
        document.querySelector("[data-fsc-order-language]")?.innerHTML || "en";
      const response = await secureAPIRequest(api.BILLING.ENCRYPT, {
        billing: BILLING_NAME,
        payload: JSON.stringify(payload),
        account: {
          country,
          language,
        },
      });
      return response.data;
    },
    [secureAPIRequest]
  );

  // Call billing checkout
  // ------------------------
  const billingCheckout = useCallback(
    async (productList) => {
      // logger.dev(`[BillingProvider] billingCheckout`, productList);
      const products = (productList || [])
        .map((segment) => getProduct(segment))
        .filter((product) => !!product);

      if (!products?.length) {
        throw new Error(`Product ids not found`);
      }

      if (BILLING_NAME === BILLING_FASTSPRING) {
        const productsIDs = products.map(({ productID }) => productID);

        const fastspring_products = products
          .map((product) => ({
            ...product,
            productID: product.billing?.fastspring?.productId || null,
          }))
          .filter(({ productID }) => !!productID);

        const fastspring_promoCodes = products
          .map(({ coupon }) => coupon?.billing?.fastspring?.code)
          .filter((code) => !!code);

        if (!fastspring_products.length) {
          throw new Error(
            `Product ids not found for current billing system (${BILLING_FASTSPRING})`
          );
        }

        const { email, firstName, lastName } = user;
        const { order } = await new Promise(async (resolve, reject) => {
          window.Qudata_fastspring_resolve = (order) => resolve({ order });
          window.Qudata_fastspring_reject = (error) => reject(error);
          const fastspring = window.fastspring;
          fastspring.builder.clean();
          fastspring.builder.recognize({ email, firstName, lastName });
          const payload = {
            items: fastspring_products.map(({ productID, price }) => ({
              quantity: 1,
              product: productID,
              pricing: { price: { [price.currency]: price.value } },
            })),
          };
          if (fastspring_promoCodes.length) {
            const coupon = fastspring_promoCodes.shift();
            payload.coupon = coupon;
          }
          if (atDevhost()) {
            fastspring.builder.secure(payload);
            fastspring.builder.checkout();
          } else {
            try {
              const { secureKey, securedPayload, sessionID } =
                await encryptPayload(payload);
              fastspring.builder.secure(securedPayload, secureKey);
              fastspring.builder.checkout(sessionID);
            } catch (error) {
              reject(error);
              return;
            }
          }
        }).then();

        if (!order) {
          return null; // Operation canceled
        }

        try {
          await secureAPIRequest(api.BILLING.CHECKOUT, {
            order,
            billing: BILLING_NAME,
            products: productsIDs.map((productID) => ({
              productID,
              quantity: 1,
            })),
          });
        } catch (e) {
          logger.error(e);
          throw e;
        }

        return order;
      }

      throw new Error(`Billing system is not specified`);
    },
    [user, secureAPIRequest, getProduct, encryptPayload]
  );

  // Refresh subscriptions
  // ------------------------
  const refreshSubscriptions = useCallback(async () => {
    // logger.dev(`[BillingProvider] refreshSubscriptions`);
    const { plan, subscriptions } = await fetchSubscriptions();
    dispatch({
      type: SUBSCRIPTIONS_UPDATED,
      payload: { plan, subscriptions },
    });
    return { plan, subscriptions };
  }, [fetchSubscriptions]);

  // Refresh products
  // ------------------------
  const refreshProducts = useCallback(async () => {
    // logger.dev(`[BillingProvider] refreshProducts`);
    const { products } = await fetchProducts();
    dispatch({
      type: PRODUCTS_UPDATED,
      payload: { products },
    });
    return { products };
  }, [fetchProducts]);

  // Refresh coupons
  // ------------------------
  const refreshCoupons = useCallback(async () => {
    // logger.dev(`[BillingProvider] refreshCoupons`);
    const { coupons } = await fetchCoupons();
    dispatch({
      type: COUPONS_UPDATED,
      payload: { coupons },
    });
    return { coupons };
  }, [fetchCoupons]);

  // Refresh features
  // ------------------------
  const refreshFeatures = useCallback(async () => {
    // logger.dev(`[BillingProvider] refreshFeatures`);
    const { features, features_info } = await fetchFeatures();
    dispatch({
      type: FEATURES_UPDATED,
      payload: { features, features_info },
    });
    return { features, features_info };
  }, [fetchFeatures]);

  // Refresh payments
  // ------------------------
  const refreshPayments = useCallback(async () => {
    // logger.dev(`[BillingProvider] refreshPayments`);
    try {
      dispatch({ type: REFRESH_START });
      const { payments, settings } = await fetchPayments();
      dispatch({
        type: REFRESH_END,
        payload: {
          payments,
          settings,
          loadingError: null,
        },
      });
      return { payments, settings };
    } catch (e) {
      logger.error(e);
      dispatch({
        type: REFRESH_END,
        payload: {
          ...state,
          loadingError: e,
        },
      });
    }
  }, [state, fetchPayments]);

  // Refresh notifications
  // ------------------------
  const refreshNotifications = useCallback(async () => {
    // logger.dev(`[BillingProvider] refreshNotifications`);
    const { notifications } = await fetchNotifications();
    dispatch({
      type: NOTIFICATIONS_UPDATED,
      payload: { notifications },
    });
    return { notifications };
  }, [fetchNotifications]);

  // Refresh all
  // ------------------------
  const refreshAll = useCallback(async () => {
    dispatch({
      type: ALL_UPDATED,
      payload: await fetchAll(),
    });
  }, [fetchAll]);

  // Billing settings
  // ------------------------
  const setBillingSettings = useCallback(
    async (newSettings) => {
      // logger.dev(`[BillingProvider] setBillingSettings`, newSettings);
      const response = await secureAPIRequest(api.BILLING.PAYMENTS_TUNE, {
        settings: { ...state.settings, ...newSettings },
      });
      const { settings } = response.data;
      dispatch({
        type: SETTINGS_UPDATED,
        payload: { settings },
      });
      return { settings };
    },
    [state.settings, secureAPIRequest]
  );

  // Buy plan
  // ------------------------
  const buyPlan = useCallback(
    async (subscriptionId, productID = null) => {
      // logger.dev(`[BillingProvider] buyPlan`, subscriptionId, productID);
      if (!subscriptionId) {
        return;
      }

      const { plan } = state;
      if (
        subscriptionId === plan?.id &&
        (plan.status === PLAN_STATUS.ORDERED ||
          plan.status === PLAN_STATUS.PAID)
      ) {
        throw new Error(`Plan with id "${subscriptionId}" is already active`);
      }

      const product = getSubscriptionProduct(subscriptionId, productID);
      if (!product) {
        throw new Error(
          `Can't find product at subscription with id = "${subscriptionId}"`
        );
      }

      // logger.dev("[BillingProvider] buyPlan", subscriptionId);
      const order = await billingCheckout([product]);
      await refreshAll();
      return order;
    },
    [state, getSubscriptionProduct, billingCheckout, refreshAll]
  );

  // Subscription change
  // ------------------------
  const subscriptionChange = useCallback(
    async (subscriptionId) => {
      // logger.dev(`[BillingProvider] subscriptionChange`);
      await secureAPIRequest(api.BILLING.SUBSCRIPTION_CHANGE, {
        subscription: { id: subscriptionId },
      });
    },
    [secureAPIRequest]
  );

  // Subscription undo
  // ------------------------
  const subscriptionUndo = useCallback(async () => {
    // logger.dev(`[BillingProvider] subscriptionUndo`);
    await secureAPIRequest(api.BILLING.SUBSCRIPTION_UNDO, {});
  }, [secureAPIRequest]);

  // Unsubscribe
  // ------------------------
  const unsubscribe = useCallback(async () => {
    // logger.dev(`[BillingProvider] unsubscribe`);
    if (hasCancelablePlan()) {
      await secureAPIRequest(api.BILLING.UNSUBSCRIBE, {});
    }
  }, [hasCancelablePlan, secureAPIRequest]);

  // Upgrade plan
  // ------------------------
  const upgradePlan = useCallback(
    async (subscriptionId, productID = null) => {
      // logger.dev(`[BillingProvider] upgradePlan`, subscriptionId, productID);
      if (!subscriptionId) {
        await unsubscribe();
        return await refreshAll();
      }
      try {
        return await buyPlan(subscriptionId, productID);
      } catch (e) {
        logger.error(e);
        throw e;
      } finally {
        refreshAll();
      }
    },
    [unsubscribe, buyPlan, refreshAll]
  );

  // Change plan
  // ------------------------
  const changePlan = useCallback(
    async (subscriptionId) => {
      // logger.dev(`[BillingProvider] changePlan`);
      await subscriptionChange(subscriptionId || BillingPlan.FREE);
      await refreshAll();
    },
    [subscriptionChange, refreshAll]
  );

  // Change plan undo
  // ------------------------
  const changePlanUndo = useCallback(async () => {
    // logger.dev(`[BillingProvider] changePlan`);
    await subscriptionUndo();
    await refreshAll();
  }, [subscriptionUndo, refreshAll]);

  // Cancel all plans
  // ------------------------
  const cancelAllPlans = useCallback(async () => {
    // logger.dev(`[BillingProvider] cancelAllPlans`);
    await unsubscribe();
    await refreshAll();
  }, [unsubscribe, refreshAll]);

  // Pay on invoice
  // ------------------------
  const payInvoice = useCallback(
    async (productList = null) => {
      // logger.dev(`[BillingProvider] payInvoice`, productList);
      const { paymentsPending } = state;
      productList ||= paymentsPending?.reduce(
        (p, c) => p.concat(c.products || []),
        []
      );
      const order = await billingCheckout(productList);
      await refreshAll();
      return order;
    },
    [state, billingCheckout, refreshAll]
  );

  // Clear user
  // ------------------------
  const clearUser = useCallback(async () => {
    // logger.dev(`[BillingProvider] clearUser`);
    await secureAPIRequest(api.BILLING.DELETE_USER, {});
    await refreshAll();
  }, [secureAPIRequest, refreshAll]);

  return (
    <BillingContext.Provider
      value={{
        ...state,

        upgradePlan,
        changePlan,
        changePlanUndo,
        cancelAllPlans,
        payInvoice,
        clearUser,

        refreshSubscriptions,
        refreshProducts,
        refreshCoupons,
        refreshPayments,
        refreshFeatures,
        refreshNotifications,
        refreshAll,

        setBillingSettings,
        hasCancelablePlan,
        needUpgradePlan,
        getSubscription,
        getSubscriptionProduct,
        getSubscriptionByProduct,
        getProduct,
        fetchPayments,
      }}
    >
      {children}
    </BillingContext.Provider>
  );
}

export {
  BillingProvider,
  BillingContext,
  BillingPlan,
  PLAN_STATUS,
  PAYMENTS_STATUS,
  FEATURES,
};
